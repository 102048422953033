import React, { useEffect, useState } from "react";
import CustomerList from "../../components/customer";
import CustomerListNew from "../../components/customer/indexNew";
import AppbarComponent from "../../components/AppBar";
import { t } from "i18next";
import { Stack } from "@mui/material";
import { ToastContainer } from "react-toastify";

const CustomerModule = () => {

  const [selectedOption, setSelectedOption] = useState(t("Customer.CustomerList"));
  let labels = [t("Customer.CustomerList")];

  const handleClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <Stack sx={{ maxHeight: "100%", overflowY: 'scroll' }}>
      {/* <ToastContainer /> */}
      <AppbarComponent labels={labels} selectedButton={selectedOption} handleClick={handleClick} />
      <Stack sx={{ paddingLeft: 4, paddingRight: 4 }}>

        {/* {selectedOption === t('Customer.CustomerList') && <CustomerList />} */}
        {selectedOption === t('Customer.CustomerList') && <CustomerListNew />}

      </Stack>
    </Stack>
  );
};
export default CustomerModule;