import { CircularProgress, Stack } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { t } from "i18next";
import FSelect from "../commonComponents/F_Select";
import FButton from "../commonComponents/F_Button";
import FPrimaryHeading from "../commonComponents/F_PrimaryHeading";
import { useNavigate } from "react-router-dom";
import FCommonTable from "../commonComponents/F_commonTable";
import apiFetcher from "../../utils/interCeptor";
import axios, { HttpStatusCode } from "axios";
import { toast, ToastContainer } from "react-toastify";
import FTextInput from "../commonComponents/F_TextInput";
import { debounce } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";


// Define columns explicitly


const CustomerListNew = () => {
    const user = useSelector((state) => state.user.data)

    // moment.locale('da')
    const [apiData, setApiData] = useState({
        employees: [],
        customers: [],
    })
    const [employeeObjForSelect, setEmployeeObjForSelect] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    // const [searchedCustomer, setSearchedCustomer] = useState("");
    const [cancelToken, setCancelToken] = useState(null);
    const [loading, setLoading] = useState({
        page: false,
        table: false,
    });
    const navigate = useNavigate();
    const columns = [
        { id: 'id', label: `${t('Common.ID')}`, sortable: true },
        { id: 'name', label: `${t('Common.Name')}`, sortable: true },
        { id: 'phone', label: `${t('Common.Phone')}`, sortable: false },
        { id: 'email', label: `${t('Common.Email')}`, sortable: false },
        { id: 'lastBooking', label: `${t('Common.LastBooking')}`, sortable: true },
        { id: 'Bookings', label: `${t('Common.Bookings')}`, sortable: true },
        { id: 'AssignedTo', label: `${t('Common.AssignedTo')}`, sortable: false },
        { id: 'GoTo', label: ' ', sortable: false },
    ];

    const fetchSuggestions = debounce(async ({ cus, emp }) => {

        if (cus.trim() || (emp === '' || emp)) {
            if (cancelToken) {
                cancelToken.cancel('Canceling previous request');
            }
            const source = axios.CancelToken.source();
            setCancelToken(source);
            setLoading((prev) => ({
                ...prev,
                table: true,
            }));
            axios.get(`${process.env.REACT_APP_URL}/api/v1/store/customer/outlet?search=${cus}&employees=${emp}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
                },
                cancelToken: source.token
            })
                .then(response => {
                    if (response.status === HttpStatusCode.Ok) {
                        setApiData({ ...apiData, customers: response?.data?.data?.data });
                    }
                })
                .catch(thrown => {
                    if (axios.isCancel(thrown)) {
                        console.error('Request canceled:', thrown.message);
                    } else {
                        console.error('Error fetching suggestions:', thrown);
                    }
                })
                .finally(() => {
                    setLoading((prev) => ({
                        ...prev,
                        table: false,
                    }));
                });
        }
    }, 500);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                setLoading((prev) => ({
                    ...prev,
                    page: true,
                }));
                const response = await apiFetcher.get(`api/v1/store/employee/get`)
                if (response.status === HttpStatusCode.Ok) {
                    setApiData({ ...apiData, employees: response.data.data });
                }
            } catch (error) {
                toast.error(t("Customer.GetempFail"));
            } finally {
                setLoading((prev) => ({
                    ...prev,
                    page: false,
                }));
            }
        }
        fetchEmployees();
    }, []);


    useEffect(() => {
        fetchSuggestions({ cus: '', emp: selectedEmployee.length === employeeObjForSelect.length ? "" : selectedEmployee });
    }, []);

    useEffect(() => {
        if (apiData.employees && apiData.employees.length > 0) {
            let newData = apiData.employees.map((data) => ({
                value: data.id,
                label: data.name
            }));

            // Only update if there's new data
            setEmployeeObjForSelect((prev) => [
                ...newData
            ]);

            setSelectedEmployee(newData.map((data) => data.value));
        }
    }, [apiData.employees]);



    const handleRowClick = (row) => {
        navigate(`/customers/${row.id}/customerinformation`, { state: { data: row } });
    };

    const handleCreateCustomer = () => {
        navigate('/customers/create',{state:{isEdit:false}});
    }
    // const handleTextChange = (e) => {
    //     setSearchedCustomer(e.target.value);

    // }
    const handleSelectEmployee = (e) => {
        const selectedValues = e.target.value;

        let allID = employeeObjForSelect?.map((data) => data.value);

        if ((selectedValues === 0 || selectedValues.includes(0)) && allID.length === selectedEmployee.length) {
            setSelectedEmployee([]);
        }
        else if (selectedValues === 0 || selectedValues.includes(0)) {
            setSelectedEmployee(allID);
        }
        else if (selectedValues.length === 0) {
            setSelectedEmployee([]);
        }
        else {
            setSelectedEmployee(selectedValues);
        }
    };

    const formatPhoneNumber = (number) => {
        if (number != "") {
            return number?.replace(/(\d{2})(?=\d)/g, "$1 ");
        } else {
            return "";
        }
    };


    // const titleForColumn = 
    const dataForColumn = apiData.customers.map((data) => ({
        id: data.id,
        name: data.name,
        phone: (data.phone_number.length > 0) ? `+45 ${formatPhoneNumber(data.phone_number)}` : '',
        email: data.email,
        lastBooking: data.last_booking_date ? `${moment(data.last_booking_date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM-YY")} kl. ${moment(data.last_booking_date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")}` : '',
        Bookings: data.total_bookings,
        AssignedTo: data.employees.map((emp) => emp?.name).join(', '),
        GoTo: data.id
    }))
    // create column prop whic excludes id
    // const filterdRows = CustomerApi.filter((row) => row.id !== 'id');
    if (loading?.page) return <Stack sx={{
        position: 'absolute',
        zIndex: 110,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.1)'
    }}>
        <CircularProgress size='2.5rem' sx={{ color: '#6f6f6f' }} />
    </Stack>
    return (
        <Stack pb={4} >
            <ToastContainer />
            <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2} p={2}>
                <FPrimaryHeading text={t("Common.Customers")} />
                <FTextInput
                    backgroundColor='#fff'
                    borderRadius={50}
                    mt={0}
                    sx={{ ml: 'auto', width: '20%', px: 1 }}
                    placeholder={`${t("Common.Search")}...`}
                    onChange={(e) => { fetchSuggestions({ cus: e.target.value, emp: selectedEmployee.length === employeeObjForSelect.length ? "" : selectedEmployee }) }}
                />

                
                <FSelect
                selectAllRenderText={t("Common.AllEmployees")}
                    backgroundColor='#fff'
                    isMultiSelect={true}
                    value={selectedEmployee}
                    TextToDisplayWithCount={`${t("Common.Employees")}`}
                    sx={{ width: '20%' }}
                    onChange={handleSelectEmployee}
                    placeholderText={t("Common.AllEmployees")}
                    selectAllRenderCheckBoxText={t("Common.AllEmployees")}
                    options={employeeObjForSelect}
                    borderRadius={50}
                    padding={0}
                    onClose={() => {
                        fetchSuggestions({ cus: '', emp: selectedEmployee.length === employeeObjForSelect.length ? "" : selectedEmployee });
                    }}
                />

                {user?.settings.create_customers &&
                    <FButton
                        width="100%"
                        height={40}
                        variant={'save'}
                        title={`+ ${t("Customer.AddNewCustomer")}`}
                        onClick={handleCreateCustomer}
                        sx={{ borderRadius: 50, py: 1 }}
                    />}
            </Stack>

            <FCommonTable
                visibleColumns={['name', 'phone', 'email', 'lastBooking', 'Bookings', 'AssignedTo', 'GoTo']}
                defaultOrder="name"
                columnWidths={{ name: '15%', phone: '15%', email: '20%', lastBooking: '20%', Bookings: '10%', AssignedTo: '15%', GoTo: '5%' }}
                loading={loading?.table}
                columns={columns}
                data={dataForColumn}
                onRowClick={handleRowClick} />
        </Stack>
    );
};

export default CustomerListNew;
