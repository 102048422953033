import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const [socketIsOn, setSocketIsOn] = useState(null)
   const token =  localStorage.getItem('auth_token')

    useEffect(() => {
        const url = `${process.env.REACT_APP_URL}`;
        const ws = io(url, {
            path: "/api/v1/sio",
            forceNew: true,
            reconnectionAttempts: 3,
            timeout: 2000,
            auth: {
                token: token,
            }
        });

        ws.on("connect", () => {
            setSocketIsOn(ws)
        });

        return () => {
            if (ws) {
                ws.disconnect();
            }
        }


    }, [token])

    return (
        <SocketContext.Provider value={socketIsOn}>
            {children}
        </SocketContext.Provider>
    )


}

export const useSocket = () => useContext(SocketContext);