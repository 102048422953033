import { Box, Divider, Stack, Typography } from "@mui/material";
import CustomCheckbox from "../../../commonComponents/F_Checkbox";
import { t } from "i18next";
import { useEffect, useState } from "react";
import moment from "moment";

const CommonServiceModal = ({ height, mt, setData, mb, archive, setSelectedIds, selectedIds ,isJournalModal = false}) => {

  const [allSelectedInArchieve, setAllSelectedInArchieve] = useState(false);
  const langugae = localStorage.getItem("language");

 
  const handleCheckchange = (group, isParent = false, itemId = null) => {

    setSelectedIds((prevSelected) => {
      const selectedGroupIds = prevSelected[group.group_id] || [];
      const allChildIds = group.journals.map((item) => item.id);

      let newSelectedIds;

      if (isParent) {
        // Toggle all items in the group
        newSelectedIds = allChildIds.every((id) => selectedGroupIds.includes(id))
          ? []
          : allChildIds;
      } else {
        // Toggle a single item
        newSelectedIds = selectedGroupIds.includes(itemId)
          ? selectedGroupIds.filter((id) => id !== itemId)
          : [...selectedGroupIds, itemId];
      }

      setData((prevData) => {
        return prevData.map((g) => {
          if (g.group_id === group.group_id) {
            return {
              ...g,
              journals: g.journals.filter((item) => newSelectedIds.includes(item.id)), // Only keep selected journals
            };
          }
          return g;
        });
      });

      return { ...prevSelected, [group.group_id]: newSelectedIds };
    });
  };

  const initializeSelection = () => {

   
    archive.forEach((group) => {
      const allChildIds = group.journals.map((item) => item.id);
      setSelectedIds((prevSelected) => ({
        ...prevSelected,
        [group.group_id]: allChildIds,
      }));
    });
  };


useEffect(() => {
  if (isJournalModal) {
    initializeSelection();
  }
}, [archive, isJournalModal]);


useEffect(() => {
 if(!isJournalModal){
  const allIds = archive.map((com) => {
    return com.journals.every((item) => selectedIds[com.group_id]?.includes(item.id))
   })
   const valid = allIds?.every((item) => item === true)

  setAllSelectedInArchieve(valid)
 }
},[selectedIds])


const selectAllIdsFromArchieve = () => {

  if(!allSelectedInArchieve){
  initializeSelection();
}
  else {
    setSelectedIds([]);
  }
}


  if (!archive.find((item) => item.journals.length >0)) return <Typography
    sx={{
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "600",
      color: "rgba(0, 0, 0, 0.6)",

    }}
  >
    {t("Customer.NoArchives")}
  </Typography>

  return (
    <Stack
      sx={{
        mb: mb ? mb : 3,
        maxHeight: height ? height : "75%",
        border: "1px solid #D9D9D9",
        overflow: "auto",
        scrollbarWidth: "none",
        mt: mt ? mt : 1,
        borderRadius: 5,
      }}
    >
      <Stack
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          py: 0.5,
          backgroundColor: "#f1f1f1",
        }}
      >
        <Typography variant="body1" fontWeight={700} width={"15%"} pl={2.2} my={"auto"}>
          {t("Common.Info")}
        </Typography>
        <Typography variant="body1" fontWeight={700} width={"55%"} pl={2} my={"auto"}>
          {t("Customer.Journal")}
        </Typography>
        <Typography variant="body1" fontWeight={700} width={"20%"} my={"auto"}>
          {t("Common.Picture")}
        </Typography>
        <Stack
          sx={{
            width: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            height: "100%",
          }}
        >
          <Typography variant="body1" fontWeight={700} sx={{ ml: -3 }}>
            {t("Common.Choose")}
          </Typography>
        {!isJournalModal && <CustomCheckbox 
        sx={{ mt: 0 ,mb:0}}
        checked={allSelectedInArchieve ? true : false}
        onChange={(e) => selectAllIdsFromArchieve()}/>}
          
         
        </Stack>
      </Stack>

      <Stack sx={{ maxHeight: "50%" }}>
        {archive && archive.map((com) => {

          if (!com?.journals?.length) return
          const selectedGroupIds = selectedIds[com?.group_id] || [];
          const allChildrenSelected = com?.journals?.every((item) =>
            selectedGroupIds?.includes(item?.id)
          );

          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#F8F8F8",
                  gap: 2,
                  px: 2,

                  border: "1px solid ##D9D9D9",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={700}
                  width={"15%"}
                  my={"auto"}
                >
                  {com?.group_name}
                </Typography>

                <Stack width={"75%"} />

                <Stack
                  width={"10%"}
                  sx={{
                  pl: !isJournalModal &&  langugae === "en" ? 6.85 :4.4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CustomCheckbox
                    checked={com.journals.length > 0 ? allChildrenSelected : false}
                    onChange={() => handleCheckchange(com, true)}
                  />
                </Stack>
              </Box>
              {com.journals.map((group, index) => (
                <>
                  <Stack key={group.id} sx={{ mb: 4, mt: 2 }}>
                    <Stack>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                          px: 2,
                        }}
                      >
                        <Box width={"15%"}>
                          <Box sx={{ mb: 2 }}>
                            <Typography
                              variant="body1"
                              fontWeight={800}
                              color="text.secondary"
                            >
                              {t("Customer.WrittenBy")}
                            </Typography>
                            <Typography>{group?.employee_name}</Typography>
                          </Box>
                          <Box sx={{ mb: 2 }}>
                            <Typography
                              variant="body1"
                              fontWeight={800}
                              color="text.secondary"
                            >
                              {t("Common.Date")}
                            </Typography>
                            <Typography> {moment(group.created_at).format('DD/MM-YYYY')}</Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="body1"
                              fontWeight={800}
                              color="text.secondary"
                            >
                              {t("Common.Time")}
                            </Typography>
                            <Typography> {
                              moment(group.created_at, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}</Typography>

                          </Box>
                        </Box>

                        <Box width={"55%"}>
                          <Typography
                            variant="body1"
                            fontWeight={700}
                            sx={{ mb: 1 }}
                          >
                            {group.title}
                          </Typography>
                          <div
                            style={{ marginTop: "8px" }}
                            dangerouslySetInnerHTML={{
                              __html: group.journal_entry || "",
                            }}
                          ></div>
                        </Box>

                        <Stack
                          width={"20%"}
                          sx={{
                            display: "flex",
                            alignItems: "start",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          {group.attachments.map((item) => (
                            <Stack
                              key={item.id}
                              component="img"
                              src={
                                item.attachment &&
                                  typeof item.attachment === "string"
                                  ? `${process.env.REACT_APP_IMG_URL}${item.attachment}`
                                  : URL.createObjectURL(item)
                              }
                              alt="Patient photo"
                              sx={{
                                width: "50%",
                                ml: 1,

                                borderRadius: 1,
                                objectFit: "cover",
                              }}
                            />
                          ))}
                        </Stack>

                        <Box
                          width={"10%"}
                          sx={{
                            pl: !isJournalModal &&  langugae === "en" ? 6.85 :4.47,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CustomCheckbox
                            checked={selectedGroupIds.includes(group.id)}
                            onChange={() =>
                              handleCheckchange(com, false, group.id)
                            }
                          />
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>
                  {index <= com.journals.length - 2 && (
                    <Divider sx={{ border: "2.5px solid #bbb" }} />
                  )}
                </>
              ))}
            </>
          );
        })}
      </Stack>

 
    </Stack>
  );
};

export default CommonServiceModal;
