import { Box, Dialog, DialogContent, Divider, Grid, MenuItem, Select, Typography } from "@mui/material";
import { FDialogTitle } from "../../components/commonComponents/F_DialogTItle";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import TimeSlotPicker from "./TimeSlot";
import FButton from "../../components/commonComponents/F_Button";
import { t } from "i18next";

export default function BookingTimeSlotModal({
  setShowTimeSlotModal,
  setShowServiceModal,
  showTimeSlotModal,
  selectedDate,
  selectedTimeSlots,
  setSelectedDate,
  availableTimeSlots,
  timeSlots,
  openinghours,
  closinghours,
  handleSelectedTimeSlot,
  openConfirmationModal,
  isSelected,
  setIsSelected,
  closeForm,
  selectedEmployee,
  setSelectedEmployee,
  employees,
  isLoadingSlots
}) {

  function handleSubmit() {

    if (selectedTimeSlots.length > 0) {

      openConfirmationModal()
    }
    else {
      toast.error(t("Calendar.SelectTimeSlot"))
    }

  }


  let lenguage = localStorage.getItem("language");

  return (
    <>

      <Dialog
        maxWidth="md"

        PaperProps={{
          style: {
            maxHeight: "90vh", margin: "auto", borderRadius: 20, overflowY: "auto",
          }
        }}

        open={showTimeSlotModal}
        onClose={() => setShowTimeSlotModal(false)}

      >
        <FDialogTitle
          height={3}
          onBack={() => {
            setShowTimeSlotModal(false);
            setShowServiceModal(true);
          }}
          onClose={closeForm}
        >
          {t("Calendar.ManualBooking")}
        </FDialogTitle>

        <DialogContent
          sx={{ padding: "10px 40px", marginTop: 3, height: "100%" }}
        >

          <Grid container spacing={2} className="mb-4">
            <Grid item md={9} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h4"
                sx={{
                  color: "#545454",
                  fontWeight: "bold",
                  marginLeft: "25px",
                  marginBottom: 0,
                }}
              >
                {t("Calendar.SelectDateTime")}
              </Typography>
            </Grid>
            <Grid item md={3}>
              {employees.length > 0 ? (

                <Select
                  value={selectedEmployee.id}
                  onChange={(e) => {
                    const selectedId = e.target.value;
                    const selectedEmployeeObj = employees.find(
                      (emp) => emp.id === selectedId
                    );
                    const selectedName = selectedEmployeeObj
                      ? selectedEmployeeObj.name
                      : "";


                    setSelectedEmployee({
                      id: selectedId,
                      name: selectedName,
                    });
                  }}

                  sx={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    border: "3px solid #BBB0B0",
                    color: "#A79C92",
                    backgroundColor: "#fff",
                    "& .MuiSelect-select": {
                      color: "#545454",
                      fontSize: "15px",
                      fontWeight: 400,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#BBB0B0",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#BBB0B0",
                    },
                    "& .MuiSelect-icon": {
                      color: "#000",
                    },
                  }}
                >
                  {employees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ marginBottom: 0 }}
                >
                  {t("Calendar.NoEmpAvailable")}
                </Typography>
              )}
            </Grid>
          </Grid>


          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",

              border: `3px solid #BBB0B0`,
              borderRadius: "15px",
              width: "100%",

              height: "50vh", // Ensures the outer container remains fixed
              overflow: "hidden", // Prevents outer scrolling
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                justifyContent: "center",

                backgroundColor: "white",
                flex: 1,
                mb: 4,
                width: "100%",
                height: "100%",
              }}
            >
              {/* Date Picker */}
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={lenguage === 'da' ? 'da' : 'en-gb'}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40%",
                    height: "100%",
                    flexShrink: 0,
                  }}
                >
                  <DateCalendar

                    defaultValue={dayjs()}
                    value={selectedDate}
                    disablePast
                    sx={{
                      height: "100%",
                      width: { xs: "320px" },
                      mt: 2,
                      "& .Mui-selected": {
                        backgroundColor: "#BBB0A4 !important", // Ensures selected color stays
                        color: "white !important",
                        fontWeight: "bold",
                      },
                      "& .Mui-selected:hover": {
                        backgroundColor: "#9E9084 !important", // Slightly darker for hover effect
                      },
                      "& .Mui-selected.Mui-focused": {
                        backgroundColor: "#BBB0A4 !important",
                        color: "white !important",
                      },
                      "&:hover": { color: "#BBB0A4" },
                      "& .Mui-focused": { color: "#BBB0A4" },
                    }}

                    onChange={setSelectedDate}
                  />
                </Box>
              </LocalizationProvider>

              {/* Divider */}
              <Divider
                orientation={{ xs: "horizontal", md: "vertical" }}
                sx={{
                  borderColor: "grey",
                  borderRightWidth: { md: "2px" },
                  borderTopWidth: { xs: "2px", md: 0 },
                  borderRadius: "10px",
                }}
                variant="middle"
                flexItem
              />

              {/* Time Slot Picker */}
              <Box
                sx={{
                  height: "100%",
                  flex: 1,

                  width: "60%",
                  padding: "20px 10px",
                }}
              >
                <TimeSlotPicker
                  availableTimeSlots={availableTimeSlots}
                  timeSlots={timeSlots}
                  openingHours={openinghours}
                  closingHours={closinghours}
                  selectedTimeSlots={selectedTimeSlots}
                  handleSelectedTimeSlot={handleSelectedTimeSlot}
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  selectedDate={selectedDate}
                  isLoading={isLoadingSlots}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>

        <FButton
          title={t("Calendar.ConfirmBooking")}
          onClick={handleSubmit}
          variant={"save"}
          sx={{
            width: "34%",
            backgroundColor: "#BBB0A4",
            margin: "auto",
            marginBottom: 5,
            marginTop: 5
          }}
        />
      </Dialog>
    </>
  );
}
