import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/locales";
import { Provider } from "react-redux";
import store, { persistor } from "./context/store";
import { PersistGate } from "redux-persist/integration/react";
import LoginFlow from "./scenes/LoginForm";
import ProtectedRoute from "./protectedRoute";
import CustomCalendar from "./scenes/Calendar";
import EmployeeTable from "./scenes/Employee/Index";
import AddCustomerForm from "./scenes/Coustmer";
import CustomerDetail from "./scenes/Coustmer/customerDetail";

import Journal from "./scenes/journal";
import JournalDetail from "./scenes/journal/JournalDetail";
import EditJournal from "./scenes/journal/EditJournal";
import JournalBox from "./scenes/journal";
import HistoryTable from "./scenes/History";
import Services from "./scenes/Services";
import Specialoffers from "./scenes/Specialoffers";
import SettingsOption from "./scenes/Settings/Index";
import InsightsPage from "./scenes/Insights";
import { DropDown } from "./components/insight/customDropDown";
import Bookings from "./components/customer/customerDetail/Bookings";
import CustomerInformation from "./components/customer/customerDetail/Customer-Information";
import JournalGroups from "./components/customer/customerDetail/journal_groups";
import AdvancedJournal from "./components/customer/customerDetail/Advanced-journal";
import CreateCustommerForm from "./components/customer/customerDetail/CreateCustomerForm";


const root = ReactDOM.createRoot(document.getElementById("root")); // Import the service worker to enable 


const router = createBrowserRouter([{
  path: "/",
  element: <App />,
  children: [
    {
      path: "/",
      element: <LoginFlow />
    },
    {
      path: "calendar",
      element: <ProtectedRoute element={<CustomCalendar />} />,
    },
    {
      path: "customers",
      element: <ProtectedRoute element={<AddCustomerForm />} />,

    },
    {
      path: "customers/:id",
      element: <ProtectedRoute element={<CustomerDetail />} />,
      children: [
        {
          path: "bookings", // Removed "customers/" and :id (it is inherited)
          element: <ProtectedRoute element={<Bookings />} />,
        },
        {
          path: "customerinformation", // Removed "customers/" and :id
          element: <ProtectedRoute element={<CustomerInformation />} />,
        },
        {
          path: "journalgroups",
          element: <ProtectedRoute element={<JournalGroups />} />
        },
        {
          path: "advancedjournal",
          element: <ProtectedRoute element={<AdvancedJournal />} />
        },
        {
          path: "",
          element: <ProtectedRoute element={<CustomerInformation />} />
        }
      ]
    },

    {
      path: "customers/create",
      element: <ProtectedRoute element={<CreateCustommerForm />} />,
    },
    {
      path: "history",
      element: <ProtectedRoute element={<HistoryTable />} />,
    },
    {
      path: "services",
      element: <ProtectedRoute element={<Services />} />,
    },
    {
      path: "specialOffers",
      element: <ProtectedRoute element={<Specialoffers />} />,
    },
    {
      path: "settings",
      element: <ProtectedRoute element={<SettingsOption />} />,
    },
    {
      path: "insights",
      element: <ProtectedRoute element={<InsightsPage />} />,
    },
    {
      path: "/*",
      element: <Navigate to="/calendar" />,
    },
  ]
}])


const Main = () => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>

          <RouterProvider router={router} />

        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

root.render(<Main />);