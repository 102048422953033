import React, { useEffect, useState } from "react";
import "./index.css";

import Masonry from "@mui/lab/Masonry";
import {
  Box,
  Typography,
  Grid2,
  IconButton,
  Stack,
  Divider,
  TextareaAutosize,
  Button,
  Checkbox,
  InputAdornment,
  Paper,
  Skeleton,
  AppBar,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CustomTextField from "../commonTextinput";
import PrimaryHeading from "../commonPrimaryHeading";
import SecondaryHeading from "../commonSecondaryHeading";
import { Add, Cancel } from "@mui/icons-material";
import { useFormik } from "formik";
// import { generalSettingsValidate } from "./validations";
import apiFetcher from "../../../utils/interCeptor";
import { toast, ToastContainer } from "react-toastify";
import CommonButton from "../commonButton";
import _ from "lodash";
import FimageUpload from "../../commonComponents/F_imageUpload";
import { useSelector } from "react-redux";
import { t } from "i18next";
import * as Yup from 'yup';
import FTextInput from "../../commonComponents/F_TextInput";
import FTextArea from "../../commonComponents/F_TextArea";

const extractCoordinates = (geoLocation) => {
  if (!geoLocation) return { lng: "", lat: "" }; // Default values if geoLocation is missing

  const match = geoLocation.match(/POINT \(([-\d.]+) ([-\d.]+)\)/);
  if (match) {
    return {
      lng: parseFloat(match[1]), // Longitude
      lat: parseFloat(match[2]), // Latitude
    };
  }

  return { lng: "", lat: "" }; // Return empty values if format is incorrect
};

const GeneralSettingsOption = () => {
  const user = useSelector((state) => state.user.data);
  const [file, setNewFile] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: "",
    address: "",
    city: "",
    contact_number: "",
    contact_number2: "",
    about: "",
    cvr_number: "",
    lng: "",
    zip_code: "",
    lat: "",
    email: "",
    hide_email: false,
    website: "",
    instagram: "",
    facebook: "",
    tiktok: "",
    profile_image: "",
    banner_image: "",
    images: [],
    gtmId : ""
  });
  const [bookingUrl, setBookingUrl] = useState("");

  const [profile, setProfile] = useState("");
  const [Banner, setBanner] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChanges, setIsChanges] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await apiFetcher.get("/api/v1/store/setting/profile");
        const getImg = await apiFetcher.get("/api/v1/store/gallery");

        setBookingUrl(response.data.data.web_store_name);

        const data = response.data.data;
        const mainResonse = { ...data, ...getImg.data.data };

        const images = mainResonse.images;
        const profile = mainResonse.profile_image;
        const banner = mainResonse.banner_image;

        setProfile(profile);
        setBanner(banner);
        setFiles(images);

        const { lng, lat } = extractCoordinates(data.geo_location);

        setInitialValues((prevValues) => ({
          ...prevValues,
          ...mainResonse,
          lng,
          lat,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Setting.BusinessNameIsRequired")).typeError(t("Setting.PleaseEnterValidBusinessName")),
    address: Yup.string().required(t("Setting.BusinessStreetNameIsRequired")).typeError(t("Setting.PleaseEnterValidAddress")),

    about: Yup.string().nullable(),

    contact_number: Yup.string().required(t("Setting.BusinessPhoneNumberIsRequired"))
      .min(8, t("Setting.MustBeExactly8Digits"))
      .max(8, t("Setting.MustBeExactly8Digits")).typeError(t("Setting.BusinessPhoneNumberIsRequired")),

    contact_number2: Yup.string().nullable()
      .min(8, t("Setting.MustBeExactly8Digits"))
      .max(8, t("Setting.MustBeExactly8Digits")).typeError(t("Setting.BusinessPhoneNumberIsRequired")),

    cvr_number: Yup.string().required(t("Setting.CVRNumberIsRequired")).typeError(t("Setting.PleaseEnterValidCvrNumber")),
    lng: Yup.number().typeError(t("Setting.LongitudeMustBeANumber")).required(t("Setting.LongitudeIsRequired")).typeError(t("Setting.PleaseEnterValidLongitude")),
    lat: Yup.number().typeError(t("Setting.LatitubeMustBeANumber")).required(t("Setting.LatitudeIsRequired")).typeError(t("Setting.PleaseEnterValidLatitude")),
    email: Yup.string().email(t("Setting.InvalidEmailFormat")).required(t("Setting.EmailIsRequired")),
    hide_email: Yup.boolean().default(false),
    website: Yup.string().nullable().typeError(t("Setting.PleaseEnterValidWebsite")),
    instagram: Yup.string().nullable().typeError(t("Setting.PleaseEnterInstagramUrl")),
    facebook: Yup.string().nullable().typeError(t("Setting.PleaseEnterFacebookUrl")),
    tiktok: Yup.string().nullable().typeError(t("Setting.PleaseEnterTiktokUrl")),
    images: Yup.array(),
    profile_image: Yup.string(),
    banner_image: Yup.string(),
    gtmId : Yup.string().nullable()
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handlesubmit(values);
    },
  });

  useEffect(() => {
    setIsChanges(!_.isEqual(initialValues, formik.values));
  }, [formik.values]);

  //
  const handleCopy = () => {
    navigator.clipboard
      .writeText(`fiind.app/${bookingUrl}`)
      .then(() => {
        // alert("Text copied");
        toast.info(t("Setting.BookingURLCopied"))
      })
      .catch((err) => {
        console.error('err', err);


      });
  };

  const handleDrop = (setter) => (acceptedFiles) => {
    setter((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  // Generic Remove Handler

  const handleFileRemove = async (fil) => {

    try {

      formik.setFieldValue(
        "images",
        files.filter((f) => f !== fil)
      );
      setFiles((prevFiles) => prevFiles.filter((f) => f !== fil));

      const check = file.find((f) => f.id === fil.id);

      if (check === undefined) {
        await apiFetcher.delete("/api/v1/store/gallery", {
          data: { ids: [fil.id], profile_image: false, banner_image: false },
        });

        toast.success(t("Setting.ImageRemoved"));

      }
    } catch (err) {
      toast.error(t("Setting.FailedToRemoveImage"));

    }
  };

  const ProfileRemove = async () => {
    try {

      formik.setFieldValue("profile_image", "");
      setProfile("");


      const res = await apiFetcher.delete("/api/v1/store/gallery", {
        data: { ids: [], profile_image: true, banner_image: false },
      });

      toast.success(t("Setting.ProfileImageRemoved"));
    } catch (err) {
      toast.error(t("Setting.FailedToRemoveProfileImage"));

    }
  };

  const BannerRemove = async () => {

    try {

      formik.setFieldValue("banner_image", "");
      setBanner("");


      const res = await apiFetcher.delete("/api/v1/store/gallery", {
        data: { ids: [], profile_image: false, banner_image: true },
      });

      toast.success(t("Setting.BannerImageRemoved"));

    } catch (err) {
      toast.error(t("Setting.FailedToRemoveBannerImage"));

    }
  };

  // Dropzone Configuration for Profile Image

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop(setFiles),
    accept: "image/*",
    multiple: true,
  });

  const handleInputImageFileChange = (event) => {
    const filee = event.target.files[0];
    //  if(setNewFile((f) => ({ ...f, filee }));

    if (file.length !== 0) {
      // If the state already contains files, append the new file to the array
      setNewFile((prevFiles) => [...prevFiles, filee]);
    } else {
      // If the state is empty, initialize it with the new file as an array
      setNewFile([filee]);
    }

    if (filee) {
      const reader = new FileReader();
      reader.onload = () => {
        formik.setFieldValue("images", [...files, URL.createObjectURL(filee)]);
        setFiles((prevFiles) => [...prevFiles, filee]);
      };
      reader.readAsDataURL(filee);
    }
  };

  const handlesubmit = async (values) => {
    const profile_validation = typeof formik.values.profile_image === "object";
    const banner_validation = typeof formik.values.banner_image === "object";
    const file_validation = typeof file === "object" && file.length !== 0;

    try {
      if (file.length === 0 && !profile_validation && !banner_validation) {
        setIsChanges(false);
        const response = await apiFetcher.post(
          `/api/v1/store/setting/profile`,
          values
        );
        const { success } = response.data;

        if (success) {
          toast.success(t("Setting.GeneralSettingsUpdated"));
          setInitialValues(formik.values);
        } else {
          toast.error(t("Setting.FailedToUpdateGeneralSettings"));
        }
      }

      const formdata = new FormData();

      // Append single images
      if (profile_validation)
        formdata.append("profile_image", formik.values.profile_image);

      if (banner_validation)
        formdata.append("banner_image", formik.values.banner_image);
      // Append multiple images correctly
      if (file_validation) {
        file.forEach((singleFile, index) => {
          formdata.append(`images`, singleFile); // 'images' is the key expected by the API
        });
      }

      if (file_validation || profile_validation || banner_validation) {
        setIsChanges(false);

        try {
          const imgup = await apiFetcher.post(
            "/api/v1/store/gallery",
            formdata
          );
          const { success } = imgup.data;

          if (success) {
            toast.success(t("Setting.GeneralSettingsUpdated"));
          } else {
            toast.error(t("Setting.FailedToUpdateGeneralSettings"));
          }
        } catch (error) {
          console.error("Error uploading images:", error.images);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatPhoneNumber = (number) => {
    if (number !== "") {
      return number.replace(/(\d{2})(?=\d)/g, "$1 ");
    } else {
      return "";
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    // const value =  e.target.value.replace(/\D/g, "");
    formik.setFieldValue("contact_number2", value);
  };

  const handleAlternativePhoneChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    // const value =  e.target.value.replace(/\D/g, "");
    formik.setFieldValue("contact_number", value);
  };


  return (
    <>
      <form
        onReset={() => {
          setFiles([]);
        }}
        style={{ display: "flex", justifyContent: "center", flexDirection: 'column' }}
      >
        {isChanges && (
          <AppBar
            sx={{
              position: "sticky",
              zIndex: 20,
              top: 45,
              left: 0,
              py: 1,
              px: 4,
              height: 50,
              bgcolor: "#fff",
              display: "flex",
              // justifyContent: "flex-end",
              alignItems: "flex-end",
              width: "100%",
              borderWidth: 0,
              // boxShadow:'none'
            }}
          >
            <CommonButton
              onClick={() => {
                formik.handleSubmit()
              }}
              width="auto"
              ml={'auto'}
              height={40}
              title={t("Setting.SaveChanges")}
            // loading={formik.isSubmitting}
            // disabled={formik.isSubmitting}
            />
          </AppBar>
        )}
        <Stack
          sx={{
            p: 4,
            width: "100%",
          }}
        >


          <ToastContainer />

          <Stack
            spacing={1}
            sx={{
              bgcolor: "#fff",
              borderRadius: "25px",
              // height: "86vh",
              //    overflowY:'auto',
              scrollbarWidth: "none",
              overflowX: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                p: 5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  pr: 3,
                }}
              >
                <PrimaryHeading text={t("Setting.StoreInformation")} />
                <SecondaryHeading text={t("Setting.Description1")} />
              </Box>

              {loading ?
                <Stack flexDirection={"column"} width={"67%"} justifyContent={"flex-start"} >
                  {/* make it copy for 5 rows */}
                  {Array.from({ length: 5 }, (_, i) => {
                    return (
                      <Stack flexDirection={"row"} width={"100%"} justifyContent={"flex-start"} gap={6} mb={2} >
                        <Stack sx={{ display: "flex", flexDirection: "column", width: "100%", }} >
                          <Skeleton variant="text" width={"50%"} height={40} />
                          <Skeleton variant="rounded" width={"100%"} height={40} />
                        </Stack>
                        <Stack sx={{ display: "flex", flexDirection: "column", width: "100%", }} >
                          <Skeleton variant="text" width={"50%"} height={40} />
                          <Skeleton variant="rounded" width={"100%"} height={40} />
                        </Stack>
                      </Stack>
                    )
                  })}


                </Stack>

                :
                <Stack
                  flexDirection={"column"}
                  width={"67%"}
                  justifyContent={"flex-start"}
                >


                  <Stack
                    flexDirection={"row"}
                    width={"100%"}
                    justifyContent={"flex-start"}
                    gap={6}
                    mb={2}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "49%",
                      }}
                    >
                      <CommonTypoGraphy text={t("Setting.NameOfYourBusiness")} />
                      <CustomTextField
                        disabled={
                          user?.role !== "ADMIN" && !user?.settings.edit_about_us
                        }
                        {...formik.getFieldProps("name")}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        id={"name"}
                        name={"name"}
                      />

                      {formik.errors.name && formik.touched.name && (
                        <Box
                          component={"span"}
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {formik.errors.name}
                        </Box>
                      )}

                      <Stack style={{ marginTop: 20 }} />
                      <CommonTypoGraphy text={t("Setting.BookingURL")} />
                      <CustomTextField
                        disabled={
                          user?.role !== "ADMIN" && !user?.settings.edit_about_us
                        }
                        value={bookingUrl !== "" && `fiind.app/${bookingUrl}`}
                        onFocus={(e) => e.target.select()}
                        onChange={() => { }}
                        showCopyButton
                        handleCopy={handleCopy}
                        bgColor={"#D9D9D9"}
                      />

                      <Stack style={{ marginTop: 20 }} />
                      <CommonTypoGraphy text={t("Setting.BusinessPhoneNumber")} />
                      {/* <CustomTextField
                {...formik.getFieldProps("contact_number")}
                value={formik.values.contact_number  ? formatPhoneNumber(formik.values.contact_number):''}
                onChange={formik.handleChange}
                name={"contact_number"}
                id={"contact_number"}
              /> */}
                      <CustomTextField
                        disabled={
                          user?.role !== "ADMIN" && !user?.settings.edit_about_us
                        }
                        id="contact_number"
                        value={
                          formik.values.contact_number
                            ? formatPhoneNumber(formik.values.contact_number)
                            : ""
                        }
                        mt={1}
                        // maxLength={8}
                        inputProps={{ maxLength: 11 }}
                        onChange={handleAlternativePhoneChange}
                        placeholder={t("Setting.AlternativeMobileNumber")}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography sx={{ color: "#1f1f1f" }}>
                                  +45
                                </Typography>
                              </InputAdornment>
                            ),
                          },
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("contact_number", true)
                        }
                      />

                      {formik.errors.contact_number &&
                        formik.touched.contact_number && (
                          <Box
                            component={"span"}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            <Typography variant="caption" color="red">
                              {formik.errors.contact_number}
                            </Typography>
                          </Box>
                        )}


                      <Stack style={{ marginTop: 20 }} />
                      <CommonTypoGraphy text={t("Setting.AlternativePhoneNumber")} />

                      <CustomTextField
                        disabled={
                          user?.role !== "ADMIN" && !user?.settings.edit_about_us
                        }
                        id="contact_number2"
                        value={
                          formik.values.contact_number2
                            ? formatPhoneNumber(formik.values.contact_number2)
                            : ""
                        }
                        mt={1}
                        // maxLength={8}
                        inputProps={{ maxLength: 11 }}
                        onChange={handlePhoneChange}
                        placeholder={t("Setting.MobileNumber")}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography sx={{ color: "#1f1f1f" }}>
                                  +45
                                </Typography>
                              </InputAdornment>
                            ),
                          },
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("contact_number2", true)
                        }
                      />
                      {formik.touched.contact_number2 &&
                        formik.errors.contact_number2 && (
                          <Box
                            component={"span"}
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            <Typography variant="caption" color="red">
                              {formik.errors.contact_number2}
                            </Typography>
                          </Box>
                        )}


                      <Stack style={{ marginTop: 20 }} />
                      <CommonTypoGraphy text={t("Setting.Email")} />
                      <CustomTextField
                        disabled={
                          user?.role !== "ADMIN" && !user?.settings.edit_about_us
                        }
                        {...formik.getFieldProps("email")}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name={"email"}
                        id={"email"}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <Box
                          component={"span"}
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          <Typography variant="caption" color="red">
                            {formik.errors.email}
                          </Typography>
                        </Box>
                      )}

                      <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Checkbox
                          disabled={
                            user?.role !== "ADMIN" &&
                            !user?.settings.edit_about_us
                          }
                          {...formik.getFieldProps("hide_email")}
                          onChange={formik.handleChange}
                          name={"hide_email"}
                          id={"hide_email"}
                          checked={formik.values.hide_email}
                        />
                        <Typography
                          sx={{
                            height: 20,
                            fontWeight: 100,
                            fontFamily: "DM Sans",
                            marginTop: 1.15,
                          }}
                        >
                          {t("Setting.Don’tShareEmailAddress")}
                        </Typography>
                      </Stack>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "49%",
                      }}
                    >
                      <CommonTypoGraphy text={t("Setting.Street")} />
                      <CustomTextField
                        disabled={
                          user?.role !== "ADMIN" && !user?.settings.edit_about_us
                        }
                        {...formik.getFieldProps("address")}
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        name={"address"}
                        id={"address"}
                      />
                      {formik.errors.address && formik.touched.address && (
                        <Box
                          component={"span"}
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {formik.errors.address}
                        </Box>
                      )}

                      <Stack
                        style={{
                          display: "flex",
                          marginTop: 20,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {/* <>
                  <Stack
                    style={{
                      display: "flex",
                      width: "150px",
                      flexDirection: "column",
                    }}
                  >
                    <CommonTypoGraphy text="Zip code" />
                    <CustomTextField
                    disabled = {user?.role !== "ADMIN" && !user?.settings.edit_about_us}
                      {...formik.getFieldProps("zip_code")}
                      value={formik.values.zip_code}
                      onChange={formik.handleChange}
                      name={"zip_code"}
                      id={"zip_code"}
                    />

                    {formik.errors.zip_code &&
                      formik.touched.zip_code && (
                        <Box
                          component={"span"}
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {formik.errors.zip_code}
                        </Box>
                      )}
                  </Stack>

                  <Stack
                    style={{
                      display: "flex",
                      marginLeft: 10,
                      flexDirection: "column",
                    }}
                  >
                    <CommonTypoGraphy text="City" />
                    <CustomTextField
                    disabled = {user?.role !== "ADMIN" && !user?.settings.edit_about_us}
                      {...formik.getFieldProps("city")}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      name={"city"}
                      id={"city"}
                    />
                    {formik.errors.city && formik.touched.city && (
                      <Box
                        component={"span"}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.city}
                      </Box>
                    )}
                  </Stack>
                </> */}
                      </Stack>

                      <Stack
                        style={{
                          display: "flex",
                          // marginTop: 20,
                          flexDirection: "column",
                        }}
                      >
                        <>
                          <CommonTypoGraphy text={t("Setting.MarketplacePointer")} />
                          <Stack
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Stack
                              style={{
                                display: "flex",
                                width: "203px",
                                flexDirection: "column",
                              }}
                            >
                              <SecondaryHeading text={t("Setting.Latitude")} />
                              <CustomTextField
                                disabled={
                                  user?.role !== "ADMIN" &&
                                  !user?.settings.edit_about_us
                                }
                                {...formik.getFieldProps("lat")}
                                value={formik.values.lat || ""}
                                onChange={(e) => {
                                  const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and dot
                                  const dotCount = (value.match(/\./g) || []).length; // Count the number of dots
                                  if (dotCount <= 1) { // Allow only one dot
                                    formik.setFieldValue("lat", parseFloat(value));
                                  }
                                }}
                                name={"lat"}
                                id={"lat"}
                              />
                              {formik.errors.lat && formik.touched.lat && (
                                <Box
                                  component={"span"}
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {formik.errors.lat}
                                </Box>
                              )}
                            </Stack>

                            <Stack
                              style={{
                                display: "flex",
                                marginLeft: 10,
                                flexDirection: "column",
                                width: "203px",
                              }}
                            >
                              <SecondaryHeading text={t("Setting.Longitude")} />
                              <CustomTextField
                                disabled={
                                  user?.role !== "ADMIN" &&
                                  !user?.settings.edit_about_us
                                }
                                {...formik.getFieldProps("lng")}
                                value={formik.values.lng || ""}
                                onChange={(e) => {
                                  const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and dot
                                  const dotCount = (value.match(/\./g) || []).length; // Count the number of dots
                                  if (dotCount <= 1) { // Allow only one dot
                                    formik.setFieldValue("lng", parseFloat(value));
                                  }
                                }}
                                name={"lng"}
                                id={"lng"}
                              />
                              {formik.errors.lng && formik.touched.lng && (
                                <Box
                                  component={"span"}
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {formik.errors.lng}
                                </Box>
                              )}
                            </Stack>
                          </Stack>
                        </>

                        <Stack style={{ marginTop: 95 }} />
                        <CommonTypoGraphy text={t("Setting.CVRNumber")} />
                        <CustomTextField
                          disabled={
                            user?.role !== "ADMIN" &&
                            !user?.settings.edit_about_us
                          }
                          {...formik.getFieldProps("cvr_number")}
                          value={formik.values.cvr_number}
                          onChange={formik.handleChange}
                          name={"cvr_number"}
                          id={"cvr_number"}
                        />
                        {formik.errors.cvr_number &&
                          formik.touched.cvr_number && (
                            <Box
                              component={"span"}
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {formik.errors.cvr_number}
                            </Box>
                          )}
                      </Stack>
                    </Box>
                  </Stack>

                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "flex-end",
                      marginTop: 20,
                    }}
                  >
                    <Box style={{ width: "100%" }}>
                      <CommonTypoGraphy text={t("Setting.AboutUs")} />
                      <TextareaAutosize
                        disabled={
                          user?.role !== "ADMIN" && !user?.settings.edit_about_us
                        }
                        {...formik.getFieldProps("about")}
                        minRows={10}
                        maxRows={50}
                        value={formik.values.about}
                        onChange={formik.handleChange}
                        name="about"
                        id="about"
                        style={{
                          width: "100%",
                          height: "20px",
                          padding: "10px",
                          fontSize: "16px",
                          borderRadius: "10px",
                          border: "2px solid #D9D9D9",
                          outline: "none",
                          resize: "none",
                          marginTop: 10,
                        }}
                      />
                      {formik.errors.about && formik.touched.about && (
                        <Box
                          component={"span"}
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {formik.errors.about}
                        </Box>
                      )}
                    </Box>
                  </Box>


                </Stack>

              }
            </Box>


            <Divider
              sx={{
                marginTop: 5,
                marginBottom: 5,
                border: "2.5px solid #D9D9D9",
                backgroundColor: "#F3F3F3",
                width: "100%",
              }}
            />

            {!loading &&

              <Grid2 sx={{ px: 5, py: 5 }} container spacing={2}>

                <Grid2 size={4}>
                  <PrimaryHeading text={t("Setting.GtmTitle")} />
                  <SecondaryHeading text={t("Setting.GtmDescription")} />
                </Grid2>

                <Grid2 size={8}>
                  <CommonTypoGraphy text={t("Setting.GtmCode")} />
                  <FTextInput
                    disabled={user?.role !== "ADMIN" && !user?.settings.edit_about_us}
                    value={formik.values.gtmId}
                    name="GTM"
                    placeholder={'GTM-XXXXXXXX'}
                    onChange={(e) => formik.setFieldValue("gtmId", e.target.value)}
                  />


                  <FTextArea
                    disabled={
                      user?.role !== "ADMIN" && !user?.settings.edit_about_us
                    }
                    rows={10}
                    value={`<script>
(function() {
  var originalPush = window.dataLayer.push;
  window.dataLayer.push = function(obj) {
    originalPush.apply(window.dataLayer, arguments);
    if (obj && (obj.event === 'purchase' || obj.event === 'aboutUsVisit' || obj.event === 'picturesVisit'||obj.event === 'profileVisit')) {
      fetch('https://api-pdn.fiind.app/api/v1/web/save_gtm_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      });
    }
  };
})();
</script>`}
                    onFocus={(e) => e.target.select()}
                    onChange={() => { }}
                    showCopyButton
                    handleCopy={() => {
                      navigator.clipboard
                        .writeText(`<script>
(function() {
  var originalPush = window.dataLayer.push;
  window.dataLayer.push = function(obj) {
    originalPush.apply(window.dataLayer, arguments);
    if (obj && (obj.event === 'purchase' || obj.event === 'aboutUsVisit' || obj.event === 'picturesVisit'||obj.event === 'profileVisit')) {
      fetch('https://api-pdn.fiind.app/api/v1/web/save_gtm_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      });
    }
  };
})();
</script>`)
                        .then(() => {
                          toast.info(t("Setting.GTMCodeCopied"));
                        })
                        .catch((err) => {
                          console.err('err', err);
                        });
                    }}
                    bgColor={"#D9D9D9"}
                  />
                </Grid2>
              </Grid2>
            }

            <Divider
              sx={{
                marginTop: 5,
                marginBottom: 5,
                border: "2.5px solid #D9D9D9",
                backgroundColor: "#F3F3F3",
                width: "100%",
              }}
            />
            {!loading &&

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 5,
                  pt: 2,
                  pb: 5,
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", width: "33%", pr: 3, }}
                >
                  <PrimaryHeading text={t("Setting.MarketplaceLinks")} />
                  <SecondaryHeading text={t("Setting.Description2")} />
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "67%" }}
                  gap={2}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "49%",
                    }}
                  >
                    <CommonTypoGraphy text={t("Setting.WebsiteLink")} />
                    <CustomTextField
                      disabled={
                        user?.role !== "ADMIN" && !user?.settings.edit_about_us
                      }
                      {...formik.getFieldProps("website")}
                      value={formik.values.website}
                      onChange={formik.handleChange}
                      id={"website"}
                      name={"website"}
                    />
                    {formik.errors.website && formik.touched.website && (
                      <Box
                        component={"span"}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.website}
                      </Box>
                    )}

                    <Stack style={{ marginTop: 20 }} />
                    <CommonTypoGraphy text={t("Setting.TikTokLink")} />
                    <CustomTextField
                      disabled={user?.role !== "ADMIN" && !user?.settings.edit_about_us}
                      {...formik.getFieldProps("tiktok")}
                      id={"tiktok"}
                      name={"tiktok"}
                      value={formik.values.tiktok}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        newValue = newValue.replace(/^([^@])/, '@$1');
                        formik.setFieldValue("tiktok", newValue);
                      }}
                      onBlur={() => {
                        let currentValue = formik.values.tiktok;
                        currentValue = currentValue.replace(/^([^@])/, '@$1');
                        formik.setFieldValue("tiktok", currentValue);
                      }}
                    />

                    {formik.errors.tiktok && formik.touched.tiktok && (
                      <Box
                        component={"span"}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.tiktok}
                      </Box>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "49%",
                    }}
                  >
                    <CommonTypoGraphy text={t("Setting.InstagramLink")} />
                    <CustomTextField
                      disabled={
                        user?.role !== "ADMIN" && !user?.settings.edit_about_us
                      }
                      id={"instagram"}
                      name={"instagram"}
                      {...formik.getFieldProps("instagram")}
                      value={formik.values.instagram}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.instagram && formik.touched.instagram && (
                      <Box
                        component={"span"}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.instagram}
                      </Box>
                    )}

                    <Stack style={{ marginTop: 20 }} />
                    <CommonTypoGraphy text={t("Setting.FacebookLink")} />
                    <CustomTextField
                      disabled={
                        user?.role !== "ADMIN" && !user?.settings.edit_about_us
                      }
                      id="facebook"
                      name={"facebook"}
                      {...formik.getFieldProps("facebook")}
                      value={formik.values.facebook}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.facebook && formik.touched.facebook && (
                      <Box
                        component={"span"}
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {formik.errors.facebook}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>}

            <Divider
              sx={{
                marginTop: 5,
                marginBottom: 5,
                border: "2.5px solid #D9D9D9",
                backgroundColor: "#F3F3F3",
                width: "100%",
              }}
            />

            {!loading &&
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  minHeight: "200px",
                  px: 5,
                  pt: 2,
                  pb: 5,
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", width: "33%", pr: 3 }}
                >
                  <PrimaryHeading text={t("Setting.Gallery")} />
                  <SecondaryHeading
                    text={t("Setting.Description3")}
                  />
                </Box>

                <Box
                  style={{
                    width: "67%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {(user?.role === "ADMIN" || user?.settings.upload_pictures) && (
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <CommonTypoGraphy text={t("Setting.ProfilePicture")} />
                        <Grid2
                          container
                          marginLeft={-2.2}
                          spacing={2}
                          sx={{ marginTop: "20px" }}
                        >
                          <FimageUpload
                            formik={formik}
                            setter={setProfile}
                            id={"profile_image"}
                            field={profile}
                            handleFileRemove={() => ProfileRemove()}
                          />
                        </Grid2>
                      </div>

                      <div style={{ marginLeft: "10px", width: "50%" }}>
                        <CommonTypoGraphy text={t("Setting.BannerPicture")} />
                        <Grid2
                          container
                          spacing={2}
                          style={{ marginTop: "20px", marginLeft: "-16px" }}
                        >
                          <FimageUpload
                            formik={formik}
                            setter={setBanner}
                            id="banner_image"
                            field={Banner}
                            handleFileRemove={() => BannerRemove()}
                          />
                        </Grid2>
                      </div>
                    </Box>
                  )}

                  <Stack mt={5} />
                  <CommonTypoGraphy text={t("Setting.Gallery")} />

                  <Box
                    sx={{
                      position: "relative",
                      maxWidth: "100%"
                    }}
                  >
                    <Masonry

                      columns={{ xs: 1, sm: 2, lg: 3, xl: 4 }}
                      spacing={5}
                      sx={{ maxWidth: "100%" , zIndex: 0}}

                    >
                      {files.map((item, index) => (
                        <Paper
                          key={index}
                          style={{

                            position: "relative",
                            zIndex: 0,
                            maxWidth: "25%"


                          }}
                        >


                          {(user?.role === "ADMIN" ||
                            user?.settings.upload_pictures) && (
                              // <CardContent>
                              //   <IconButton
                              //     onClick={() => handleFileRemove(item)}
                              //     color="secondary"
                              //   >
                              //     <Typography variant="h6">Remove</Typography>
                              //   </IconButton>
                              // </CardContent>
                              <IconButton sx={{ position: "absolute", top: 0, right: 7 }} disableRipple onClick={() => handleFileRemove(item)}> <Cancel sx={{ backgroundColor: "transparent", color: "white" }} /></IconButton>
                            )}
                          <img
                            // component={"img"}

                            src={
                              item?.image && typeof item?.image === "string"
                                ? `${process.env.REACT_APP_IMG_URL}${item.image}`
                                : URL.createObjectURL(item)
                            }
                            alt="img"
                            style={{
                              borderBottomLeftRadius: 4,
                              borderBottomRightRadius: 4,
                              borderRadius: "12px",
                              display: "block",
                              width: "100%",
                              border: "5px solid transparent",
                              transition: "border-color 0.3s ease", // Smooth hover transition
                              "&:hover": {
                                borderColor: "rgb(60, 155, 234)",
                              },
                            }}
                            loading="lazy"
                          />

                        </Paper>
                      ))}

                      {/* Upload Section */}
                      {(user?.role === "ADMIN" ||
                        user?.settings.upload_pictures) && (
                          <Box
                            {...getRootProps()}
                            component={"div"}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "300px",
                              borderRadius: "12px",
                              padding: 2,
                              backgroundColor: "#f5f5f5", // Light background to distinguish
                              border: "1px solid #ddd",
                            }}
                          >
                            <Stack
                              sx={{
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                              }}
                            >
                              <input
                                {...getInputProps()}
                                onChange={handleInputImageFileChange}
                              />
                              <Button
                                sx={{
                                  height: "80px",
                                  width: "80px",
                                  backgroundColor: "#fff",

                                  borderRadius: "58px",
                                  textTransform: "none",
                                  "&:hover": {
                                    backgroundColor: "#6F6F6F50",
                                  },
                                }}
                              >
                                <Add
                                  sx={{
                                    height: "48px",
                                    width: "48px",
                                    color: "#A79C92",
                                  }}
                                />
                              </Button>
                            </Stack>
                          </Box>
                        )}
                    </Masonry>
                  </Box>

                </Box>
              </Box>}
          </Stack>
        </Stack>
      </form >
    </>
  );
};

export default GeneralSettingsOption;

const CommonTypoGraphy = ({ text }) => {
  return (
    <Typography variant="body1" sx={{ fontWeight: 700, color: "#1F1F1F" }}>
      {text}
    </Typography>
  );
};
