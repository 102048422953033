import React, { useState } from 'react';
import { Dialog, Stack, DialogContent, AppBar, Box, Typography } from '@mui/material';

import CreateBookingTab from '../../components/calanderComponents/booking/createBookingTab';
import PauseCalendarTab from '../../components/calanderComponents/booking/pauseCalendarTab';
import { t } from 'i18next';

const commonStyle = {
    padding: "10px 15px",
    cursor: "pointer",
    fontWeight: 500,
    color: "#BBB0A4",
};

export default function NewBookingForm({ initialData, open, closeForm, rescheduleProps, validatePause, pauseProps, setEvents, tempId }) {



    const [selectedTab, setSelectedTab] = useState(pauseProps ? 1 : 0);

    return (
        <Dialog
            PaperProps={{ sx: { borderRadius: "25px" } }}
            keepMounted
            fullWidth
            maxWidth="md"
            open={open}
            onClose={closeForm}
        // onClose={() => console.log('here')}
        >
            <DialogContent sx={{ px: 0, py: 0, mt: 0, mb: 0 }}>

                <Stack sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                    <AppBar position="sticky" sx={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            px:10,
                            gap: 10,
                            maxWidth: "100%",
                            scrollbarWidth: "none",
                            overflowX: "scroll",
                        }}>
                            {!pauseProps &&
                                <Typography
                                    noWrap
                                    variant="body1"
                                    sx={{
                                        ...commonStyle,
                                        whiteSpace: "nowrap",
                                        flexShrink: 0,
                                        ...(selectedTab === 0 ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                                    }}
                                    onClick={() => setSelectedTab(0)}
                                >
                                    {rescheduleProps ?
                                        t("Calendar.EditBk")
                                        :
                                        t("Calendar.CreBook")
                                    }
                                </Typography>
                            }

                            {!rescheduleProps &&
                                <Typography
                                    noWrap
                                    variant="body1"
                                    sx={{
                                        ...commonStyle,
                                        whiteSpace: "nowrap",
                                        flexShrink: 0,
                                        ...(selectedTab === 1 ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 } : {}),
                                    }}
                                    onClick={() => setSelectedTab(1)}
                                >
                                    {t("Calendar.CrePause")}
                                </Typography>}
                        </Box>
                    </AppBar>
                </Stack>

                <Box>
                    {(selectedTab == 0) &&
                        <CreateBookingTab
                            initialData={initialData}
                            rescheduleProps={rescheduleProps}
                            closeForm={closeForm}
                            setEvents={setEvents}
                            tempId={tempId}
                        />
                    }

                    {selectedTab == 1 &&
                        <PauseCalendarTab
                            pauseProps={pauseProps}
                            validatePause={validatePause}
                            initialData={initialData}
                            closeForm={closeForm}
                            setEvents={setEvents}
                        />
                    }
                </Box>
            </DialogContent>

        </Dialog>
    )
};