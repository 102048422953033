import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Button,
  IconButton,
  Stack,
  Avatar,
  
} from "@mui/material";

import backButton from "../../assets/less.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../index.css";
import tickImg from "../../assets/Vector (1).png";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { user } from "../../context/permissionSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import call from "../../assets/call.jpg";
import logo from "../../assets/logo.png";
import lock from "../../assets/lock.png";

import VerificationCode from "./Otpfprm";
import ConfirmPass from "./confirmPass";
import FinalresetpassbookingModel from "./FinalresetpassbookingModel";
import ResNumberInForm from "./ResNumberInForm";
import { t } from "i18next";
import { settings } from "../../context/settingsSlice";

const LoginFlow = () => {
  const [step, setStep] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [passcode, setPasscode] = useState(["", "", "", "", "", ""]);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [Index, setIndex] = useState(0);
  const [resetPassToken , setResPassToken ] = useState("");
  const [locations, setLocations] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [phone_number,setPhoneNumber] = useState("");
  const [imageUrl, setImageUrl] = useState([]);
  const auth_token = localStorage.getItem("auth_token");

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState([]);


useEffect(() => {
  if (auth_token) {
    navigate("/calendar", { replace: true }); // Prevents adding duplicate history entries
  }
},[])
 

  const OTPSending = async ({setter,phoneNumber }) => {
    try {
      setter(true)
      
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/v1/store/auth/requestOTP`,
        {
          country_code: "+45",
          phone_number:phoneNumber ? phoneNumber : phone_number,
        
        }
      );
  
  
     if(response){
      const {success} = response?.data;
      if (success) {
       
        toast.success("OTP sent successfully");
        return success;
      }
  
     }
      
    } catch (error) {
      
      
    
       toast.error(error.response?.data.detail || "Something went wrong") 
    }

  }

  

  function aftersuccessfulReset () {
    setPhone("");
    setStep(1)
    setResStep(0);
    
  }

  const handleLogin = async () => {
    try {
      const sanitizedPhone = phone.replace(/\s/g, "");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/v1/store/auth/login`,
        {
          country_code: "+45",
          phone_number: sanitizedPhone,
          password: password,
       
        }
      );

      const { success, data } = response.data;
      if (success) {
        const locationData = data.map((location) => ({
          name: location.profile.name,
          email: location.profile.email,
          image: location.profile.profile_image
            ? `${location.image_base_url}${location.profile.profile_image}`
            : "/default-profile.jpg",
          image_base_url: location.image_base_url,
          employees: location.profile.employees.map((employee) => ({
            ...employee,
            image: employee.image
              ? `${process.env.REACT_APP_IMG_URL}${employee.image}`
              : null,
          })),
          schedules: location.profile.schedules.map((schedule) => ({
            ...schedule,
          })),
          is_active: location.is_active,
        }));

        setLocations(locationData);
        setLoginData(data);
        // setStep(2);
        // if (locationData.length === 1) {
        //   handleSelectLocation(0);
        // } else {
        //   setStep(2);
        // }
      } else {
        toast.error("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login failed. Please try again.");
    }
  };

  useEffect(() => {
    if (locations.length === 1) {
      handleSelectLocation(0);
    } else if (locations.length > 1) {
      setStep(2);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ locations]);



  const handleSelectLocation = (index) => {
    if (locations.length === 1) {
      index = 0;
      setStep(3);
    }
    setSelectedLocation(index);
    setEmployees(locations[index].employees);
    setImageUrl(locations[index].image_base_url);
    setIndex(index);
  };

  const handleSelectEmployee = (index) => {
    setSelectedEmployee(index);
  };

  const handleNext = async () => {
    const employee = employees[selectedEmployee];

    const combinedPasscode = passcode.join("");

    if (step === 2 && selectedLocation !== null) {
      setStep(3);
    } else if (step === 3 && selectedEmployee !== null) {
      setStep(4);
    } else if (step === 4 && combinedPasscode.length === 6) {
      // Getting selected employee data

      if (!employee) {
        toast.error("Please select an employee."); // Alert if no employee is selected
        return;
      }

      const selectedLocationToken = loginData[Index].access_token;
      // localStorage.setItem("auth_token", selectedLocationToken);

      // const res = await apiFetcher.get(
      //   `https://api.fiind.app/api/v1/store/employee/get/${employee.id}`
      // );

      if (employee.access_code === combinedPasscode) {
        // Validate only the access code
        toast.success("Access Granted!"); // Success notification


        localStorage.setItem("auth_token", selectedLocationToken);

      

        let userWithPermissions = {...employee,"settings":employee.settings}
        // delete userWithPermissions.employee_setting

        

        dispatch(user(userWithPermissions));
        dispatch(settings({"from_dashboard" : false}));

        localStorage.setItem("employee_role", employee.role); // Store selected employee ID
        localStorage.setItem("employee_id", employee.id); // Store selected employee ID
        localStorage.setItem("employees", JSON.stringify(employees)); // Store the full employees list
        localStorage.setItem("image_url", imageUrl);
        navigate("/calendar", { replace: true }); // Redirect to calendar page
      } else {
        toast.error("Invalid passcode."); // Error notification if access code is incorrect
      }
    }
  };

  const [resStep, setResStep] = useState(0);
  const handleResetPass = () => {
    if (resStep === 0) {
           setStep(0);
      setResStep(1);
    }

    if (resStep === 1) {
      setResStep(2);
    }

    if (resStep === 2) {
      setResStep(3);
    }

    if (resStep === 3) {
      setResStep(4);
    }
  };

  const handleResBack = () => {
    setResStep((prev) => prev - 1);

    if (resStep <= 1 && resStep !== 0) {
      setStep(1);
    }
  };



  const handlePasscodeChange = (e, index) => {
    const newPasscode = [...passcode];
    newPasscode[index] = e.target.value.slice(-1);
    setPasscode(newPasscode);

    if (e.target.value && index < passcode.length - 1) {
      document.getElementById(`passcode-${index + 1}`).focus();
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && !passcode[index] && index > 0) {
      document.getElementById(`passcode-${index - 1}`).focus();
    }
  };
  const formatPhoneNumber = (number) => {
    if (number !== "") {
      return number.replace(/(\d{2})(?=\d)/g, "$1 ");
    } else {
      return "";
    }
  };

  const handlePhoneInput = (e) => {
    let input = e.target.value.replace(/[^0-9]/g, "");

    setPhone(input);
  };

  return (
    <Box
      component={"div"}
      sx={{
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        padding: "30px",
        // marginTop: "-10px",
        // marginLeft: "-10px",
        backgroundColor: "#BBB0A4",
      }}
    >
      <Stack
        component={"img"}
        src={logo}
        height={"60px"}
        sx={{ marginRight: "auto", marginLeft: "auto", marginTop: "20px" }}
      />

      <Box
        component={"div"}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "-50px",
        }}
      >
        <ToastContainer />

        {step === 1 && (
          <Box
            sx={{
              width: "90%", // Makes it responsive
              maxWidth: "500px", // Limits max width for larger screens
              minWidth: "320px", // Ensures it doesn't shrink too much
              height: "auto",
              backgroundColor: "white",
              borderRadius: "25px",
              display: "flex",
              flexDirection: "column",
              padding: "20px 30px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adds subtle shadow
              alignItems: "center", // Centers content
            }}
          >
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "400",
                color: "#6F6F6F",
                textAlign: "center",
              }}
            >
             {t("Common.LoginMT")}
            </Typography>

            {/* Phone Number Input */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginBottom: "5px",
                }}
              >
                <Stack
                  component="img"
                  src={call}
                  alt="call"
                  sx={{ height: "17px" }}
                />
                <Typography fontSize="17px" fontWeight="400" color="#6F6F6F">
                {t("Common.LoginMNummEnt")}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  border: "2px solid #BBB0A4",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <span
                  style={{
                    padding: "10px",
                    fontSize: "17px",
                  }}
                >
                  +45
                </span>
                <TextField
                  type="tel"
                  variant="standard"
                  value={formatPhoneNumber(phone)}
                  onChange={handlePhoneInput}
                  placeholder="00 00 00 00"
                  InputProps={{
                   
                    disableUnderline: true,
                    style: {
                      width: "100%",
                      backgroundColor: "transparent",
                      paddingLeft: "10px",
                      fontSize: "19px",
                      fontWeight: "400",
                    },
                  }}

                  inputProps={{ maxLength:11}}
                  sx={{
                    width: "100%",
                    "& input": { border: "none", outline: "none" },
                  }}
                />
              </Box>
            </Box>

            {/* Password Input */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginBottom: "5px",
                }}
              >
                <Stack
                  component="img"
                  src={lock}
                  alt="lock"
                  sx={{ height: "17px" }}
                />
                <Typography fontSize="16px" fontWeight="400" color="#6F6F6F">
                {t("Common.LoginPass")}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  border: "2px solid #BBB0A4",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <TextField
                  type={showPassword ? "text" : "password"}
                  variant="standard"
                  placeholder= {t("Common.LoginPass")}
                  value={password.replace(/\s/g, "")}
                  onChange={(e) =>
                    setPassword(e.target.value.replace(/\s/g, ""))
                  }
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      width: "100%",
                      backgroundColor: "transparent",
                      paddingLeft: "10px",
                      fontSize: "16px",
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ marginRight: "10px" }}
                      >
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    "& input": { border: "none", outline: "none" },
                  }}
                />
              </Box>
            </Box>

            {/* Login Button */}
            <Button
              sx={{
                width: "100%",
                maxWidth: "210px",
                borderRadius: "25px",
                height: "40px",
                marginTop: "20px",
                fontSize: "17px",
                fontWeight: "400",
                color: "white",
                backgroundColor: "#BBB0A4",
                textTransform: "capitalize",
                "&:hover": { backgroundColor: "#9C968B" },
              }}
              onClick={handleLogin}
            >
              {t("Common.LogBtnT")}
            </Button>

            {/* Forgot Password */}
            <Typography
              sx={{
                marginTop: "10px",
                fontSize: "17px",
                fontWeight: "400",
                color: "#6F6F6F",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <span onClick={() => handleResetPass()}>{t("Common.LogForPass")}</span>
            </Typography>
          </Box>
        )}
        {step === 2 && (
          <Box
            component={"div"}
            className="step active "
            sx={{ height: "65%", width: "25%" }}
          >
            <Box
              component={"div"}
              className="step3"
              sx={{ height: "100% ", width: "80%" }}
            >
              <Typography
                variant="h2"
                style={{ textAlign: "center", marginTop: "-5px" }}
              >
                {t("Common.LogChooseDepartment")}
              </Typography>
              {locations.map((location, index) => (
                <Box
                  component={"div"}
                  key={index}
                  className={`select-item ${
                    selectedLocation === index ? "" : ""
                  } ${location.is_active ? "" : "inactive"}`} // Add 'inactive' class if not active
                  onClick={() =>
                    location.is_active && handleSelectLocation(index)
                  } // Only select if active
                  sx={{
                    marginTop: "20px",
                    height: "18%",

                    position: "relative",
                    cursor: location.is_active ? "pointer" : "not-allowed",
                  }} // Add 'not-allowed' cursor for inactive
                >
                  <img src={location.image} alt="Profile" />
                  <Stack flex={1} display={"table-column"} sx={{ p: 0 }}>
                    <Typography
                      variant="small"
                      sx={{ fontSize: "1rem", color: "#545454" }}
                    >
                      {location.name}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "0.85rem" }}>
                      {location.email}
                    </Typography>
                  </Stack>
                  {location.is_active && selectedLocation === index && (
                    <img
                      src={tickImg}
                      alt="Selected"
                      style={{
                        position: "absolute",

                        right: "10px",
                        width: "20px",
                        height: "17px",

                        marginTop: "auto",
                      }}
                    />
                  )}
                </Box>
              ))}
            </Box>
            <button
              className="button"
              style={{ width: "40%", te: "15px" }}
              disabled={selectedLocation === null}
              onClick={handleNext}
            >
              {t("Common.LogNext")}
            </button>
          </Box>
        )}
        {step === 3 && (
          <Box
            component={"div"}
            className="step active"
            sx={{ height: "65%", width: "25%" }}
          >
            <Box commponent={"div"} className="step3">
              <Typography
                variant="h2"
                style={{
                  marginTop: "-5px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
              {t("Common.ChooseEmp")}
              </Typography>
              {employees.map((employee, index) => (
              <>
          
                <Box
                  component={"div"}
                  key={index}
                  className={`select-item`}
                  onClick={() => handleSelectEmployee(index)}
                  style={{
                    marginTop: "5px",
                    display: "flex",
                    alignItems: "center",
                  
                  }}
                >
                
                   <img src={employee?.image} alt={"Dev"} style={{
                    opacity : employee.image ? "1" : "0"}}/>
              
                  <Box
                    component={"div"}
                  >
                    <Typography
                    // ml={employee.image ? 0 :1}
                      variant="small"
                      sx={{ fontSize: "0.9rem", color: "#545454" }}
                    >
                      {employee.name}
                    </Typography>
                  </Box>
                  {selectedEmployee === index && (
                    <img
                      src={tickImg}
                      alt="Selected"
                      style={{
                        marginLeft: "auto",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  )}
                </Box>
                </>
              ))}
            </Box>
            <button
              className="button"
              disabled={selectedEmployee === null}
              style={{
                width: "40%",
                fontSize: "18px",
              }}
              onClick={handleNext}
            >
              {t("Common.LogNext")}
            </button>
          </Box>
        )}
        {step === 4 && (
          <Box component={"div"} className="step active pading">
            <Typography
              variant="h2"
              style={{ marginBottom: "75px", color: "#6F6F6F" }}
            >
                {t("Common.OtpT")}
            </Typography>
            {selectedEmployee !== null && (
              <Typography
                variant="h6"
                sx={{ fontSize: "1.3rem", color: "#545454" }}
              >
               {t("Common.OTPD")}{" "}
                {employees[selectedEmployee].name}
              </Typography>
            )}
            <Box
              commponent={"div"}
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {passcode.map((digit, index) => (
                <input
                  key={index}
                  id={`passcode-${index}`}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handlePasscodeChange(e, index)}
                  onKeyDown={(e) => handleBackspace(e, index)}
                  style={{
                    width: "55px",
                    height: "55px",
                    fontSize: "22px",
                    textAlign: "center",
                    marginTop: "33px",
                    marginBottom: "50px",
                    border: "1px solid #A79C92",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                />
              ))}
            </Box>

            <button
              className="button"
              onClick={handleNext}
              disabled={passcode.join("").length < 6}
              style={{
                width: "40%",
                marginBottom: "50px",
              }}
            >
              {t("Common.LogNext")}
            </button>
          </Box>
        )}

        {resStep === 1 && step === 0 && (
         <ResNumberInForm OTPSending={OTPSending} phone={phone} handleResetPass={handleResetPass} setPhoneNumber={setPhoneNumber} backButton={backButton} handleResBack={handleResBack}/>
        )}
        {resStep === 2 && step === 0 && (
          <VerificationCode
          OTPSending={OTPSending}
          phone_number={phone_number}
            handleResBack={handleResBack}
            handleResetPass={handleResetPass}
            setResPassToken={setResPassToken}
          />
        )}

        {resStep === 3 && step === 0 && (
          <ConfirmPass
            backButton={backButton}
            handleResBack={handleResBack}
            handleResetPass={handleResetPass}
            token ={resetPassToken}
          />
        )}

        {resStep ===4 && step ===0 && (
         <FinalresetpassbookingModel handleResBack={handleResBack} backButton={backButton} onSubmit={aftersuccessfulReset}/>
        )}
      </Box>
    </Box>
  );
};

export default LoginFlow;
