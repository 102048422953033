import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Stack } from "@mui/material";
import AppbarComponent from "../../../components/AppBar";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import apiFetcher from "../../../utils/interCeptor";
import { HttpStatusCode } from "axios";
import { useSelector } from "react-redux";
import { route } from "../../../context/routeSlice";
import { useDispatch } from "react-redux";

export default function CustomerDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(t("Customer.CustomerInformation"));

  const [customer, setCustomer] = useState();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state?.settings.data);
  const { id } = useParams();

  useEffect(() => {
    const pathname = location?.pathname.split("/")[3];
    if (pathname === "journalgroups") {
      setSelectedOption(t("Common.JournalGroups"));
    } else if (pathname === "customerinformation" || !pathname) {
      setSelectedOption(t("Customer.CustomerInformation"));
    } else if (pathname === "bookings") {
      setSelectedOption(t("Common.Bookings"));
    }
    else if (pathname === "advancedjournal") {
      setSelectedOption(t("Customer.AdvancedJournal"));
    }
  }, [location.pathname]);

  let labels = [
    t("Common.Bookings"),
    t("Customer.CustomerInformation"),
    settings?.profile?.allow_journal && t("Common.JournalGroups"),
    settings?.profile?.allow_advance_journal && t("Customer.AdvancedJournal"),
  ];

  labels = labels.filter(Boolean);


  const fetchCustomerDetails = async () => {
    try {
      const response = await apiFetcher.get(`api/v1/store/customer/${id}`);
      if (response.status === HttpStatusCode.Ok) {
        setCustomer(response?.data?.data);
      }
    } catch (error) {
      toast.error("Failed to fetch customer details");
      navigate("/customers");
    }
  };

  useEffect(() => {
    fetchCustomerDetails();
  }, []);

  const handleClick = (option) => {

    if (option === t("Common.Bookings")) {
      navigate(`bookings`, { replace: true });
      dispatch(route(`customers/${id}/bookings`));
    } else if (option === t("Customer.CustomerInformation")) {
      navigate(`customerinformation`, {
        state: { data: customer, isEdit: true },
        replace: true,
      });
      dispatch(route(`customers/${id}/customerinformation`));
    } else if (option === t("Common.JournalGroups")) {
      navigate(`journalgroups`, { replace: true });
      dispatch(route(`journalgroups`));
    } else if (option === t("Customer.AdvancedJournal")) {
      navigate(`advancedjournal`, { state: { data: customer }, replace: true });
      dispatch(route(`customers/${id}/advancedjournal`));
    }

    // setSelectedOption(option);
  };

  useEffect(() => {
    fetchCustomerDetails();
  }, [selectedOption, location]);

  return (
    <>
      <Stack id="top-here" sx={{ maxHeight: "100%", overflowY: "scroll" }}>
        <>
          <AppbarComponent
            labels={
              location?.pathname === "/customers/create"
                ? [t("Customer.Bookings")]
                : labels
            }
            selectedButton={selectedOption}
            handleClick={handleClick}
            ShowUser={location?.pathname !== "/customers/create"}
            userName={customer?.name}
            UserImage={customer?.image}
          />
          <Stack sx={{ px: 4, pb: 4, pt: 0 }}>
            <Outlet />
          </Stack>
        </>
      </Stack>
    </>
  );
}