import React, { useEffect, useState } from 'react'
import TableView from './TableView';
import AdvancedJournalView from './AdvancedView';
import { HttpStatusCode } from 'axios';
import apiFetcher from '../../../../utils/interCeptor';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default function AdvancedJournal({ }) {

    const location = useLocation()
    const [customerAdvancedTemplates, setCustomerAdvancedTemplates] = useState([])
    const [templates, setTemplates] = useState([])
    const [selectedAdvancedJournalId, setSelectedAdvancedJournalId] = useState({})
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState("Table")
    const customer = location?.state?.data;
    const { id } = useParams()
    const settings = useSelector((state) => state?.settings.data);
    const navigate = useNavigate();

    const getAdvancedTemplates = async () => {
        try {
            setLoading(true)
            const listResponse = await apiFetcher.get(`api/v1/store/advance_journal?outlet_customer_id=${id}`)
            if (listResponse.status === HttpStatusCode.Ok) {
                setCustomerAdvancedTemplates(listResponse?.data?.data)
            }
        } catch (error) {
            console.error("Error fetching templates:", error);
        } finally {
            setLoading(false)
        }
    }

    const getSelectors = async () => {
        try {
            const selectResponse = await apiFetcher.get(`api/v1/store/journal/template/list?advance=true`);
            if (selectResponse.status === HttpStatusCode.Ok) {
                setTemplates(selectResponse?.data?.data)
            }
        } catch (error) {
            console.error("Error fetching templates:", error);
        }
    }

    useEffect(() => {
        if (!settings?.profile?.allow_advance_journal) {
            navigate("/customers")
        }
        getSelectors()
        if (id) {
            getAdvancedTemplates(id)
        }
    }, [])


    return (
        <React.Fragment>
            {show === "Table" && <TableView templates={templates} getAdvancedTemplates={getAdvancedTemplates} customerAdvancedTemplates={customerAdvancedTemplates} setSelectedAdvancedJournalId={setSelectedAdvancedJournalId} loading={loading} customer={customer} setShow={setShow} />}
            {(show === "Advanced" && selectedAdvancedJournalId && !loading?.initialJournal) &&
                <AdvancedJournalView
                    customer={customer}
                    recallApi={getAdvancedTemplates}
                    selectedAdvancedJournalId={selectedAdvancedJournalId}
                    setShow={setShow}
                    templates={templates}
                />}
        </React.Fragment>
    )
}
