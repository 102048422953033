import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, IconButton, Stack, Typography, Tooltip, CircularProgress } from '@mui/material';
import { ArrowDropDownRounded, ArrowDropUpRounded, ArrowForward } from '@mui/icons-material';
import { t } from 'i18next';
import moment from 'moment';

const FCommonTable = ({ columns, data, loading = false, onRowClick = () => { }, columnWidths, headerColor = "#e6e6e6", bodyColor = '#fff', defaultOrder = '', visibleColumns = [] }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(defaultOrder);
    const [sortedData, setSortedData] = useState(data);

    useEffect(() => {
        handleSortRequest(orderBy, order);
    }, [data]);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        handleSortRequest(property, isAsc ? 'desc' : 'asc');
    };

    const handleSortRequest = (property, order) => {
        const sorted = [...data].sort((a, b) => {
            const valueA = a[property];
            const valueB = b[property];

            let comparison = 0;

            if (typeof valueA === 'string' && typeof valueB === 'string') {
                // Handle date string in DD/MM-YYYY format using moment
                if (isValidDate(valueA) && isValidDate(valueB)) {
                    comparison = moment(valueA, 'DD/MM-YYYY').valueOf() - moment(valueB, 'DD/MM-YYYY').valueOf();
                } else {
                    comparison = valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
                }
            } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                comparison = valueA - valueB;
            } else if (moment(valueA).isValid() && moment(valueB).isValid()) {
                comparison = moment(valueA).valueOf() - moment(valueB).valueOf();
            } else {
                comparison = String(valueA).localeCompare(String(valueB), undefined, { sensitivity: 'base' });
            }

            return order === 'asc' ? comparison : -comparison;
        });

        setSortedData(sorted);
    };

    // Function to check if a string is a valid date in DD/MM-YYYY format
    const isValidDate = (dateString) => {
        return moment(dateString, 'DD/MM-YYYY', true).isValid();
    };



    const filteredColumns = columns.filter(column => visibleColumns.includes(column.id));
    const filteredData = sortedData.map(row => {
        const filteredRow = {};
        filteredColumns.forEach(column => {
            filteredRow[column.id] = row[column.id];
        });
        return filteredRow;
    });

    return (
        <Stack sx={{ height: 'auto', width: '100%', borderRadius: 5, overflow: 'hidden', position: 'relative', filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))' }}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, tableLayout: 'fixed' }} aria-labelledby="tableTitle">
                    <TableHead sx={{ bgcolor: headerColor }}>
                        <TableRow>
                            {filteredColumns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id ? order : false}
                                    sx={{
                                        width: columnWidths ? columnWidths[column.id] : 'auto',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        py: 1,
                                        px: 2,
                                    }}
                                >
                                    {column.sortable ? (
                                        <IconButton
                                            disableRipple
                                            sx={{ pl: 0 }}
                                            onClick={() => handleRequestSort(column.id)}
                                        >
                                            <Typography variant="body1" sx={{ fontWeight: 700, color: '#1F1F1F' }}>
                                                {column.label}
                                            </Typography>
                                            {orderBy === column.id && order === 'asc' ? <ArrowDropDownRounded /> : <ArrowDropUpRounded />}
                                        </IconButton>
                                    ) : column.label === "Edit" ? (
                                        <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 700, color: '#1F1F1F' }}>
                                            {column.label}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1" sx={{ fontWeight: 700, color: '#1F1F1F' }}>
                                            {column.label}
                                        </Typography>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ bgcolor: bodyColor }}>
                        {loading ?
                            <TableRow>
                                <TableCell colSpan={filteredColumns.length} sx={{ textAlign: 'center', py: 2 }}>
                                    <CircularProgress size={22} sx={{ color: '#6f6f6f' }} />
                                </TableCell>
                            </TableRow>
                            : filteredData.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={filteredColumns.length} sx={{ textAlign: 'center', py: 2 }}>
                                        <Typography sx={{ color: '#1F1F1F' }} variant="body1">
                                            {t("Components.NoData")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredData.map((row) => (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.id}
                                        onClick={() => onRowClick(data.find(item => item.id === row.GoTo))}
                                        sx={{ cursor: row.GoTo ? 'pointer' : 'default' }}
                                    >
                                        {filteredColumns.map((column) => (
                                            <TableCell
                                                id={"column-" + column.id}
                                                sx={{
                                                    width: columnWidths ? columnWidths[column.id] : 'auto',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    py: 1,
                                                    px: 2,
                                                }}
                                            >
                                                {column.id === 'GoToComponent' ?
                                                    (<div>{row.GoToComponent}</div>)
                                                    : column.id === 'GoTo' ? (
                                                        <Stack>
                                                            <IconButton
                                                                size="small"
                                                                disableRipple
                                                                disableFocusRipple
                                                                sx={{ color: '#44B904' }}
                                                                onClick={(event) => {
                                                                    event.stopPropagation(); // Prevent the row click from firing
                                                                    onRowClick(data.find(item => item.id === row.GoTo));
                                                                }}
                                                            >
                                                                <ArrowForward />
                                                            </IconButton>

                                                        </Stack>
                                                    ) : column.id === 'status' ? (
                                                        <Typography
                                                            color={'#1F1F1F'}
                                                            variant='body1'
                                                            sx={{
                                                                color: row[column.id] === t("Common.Completed") ? '#367B3D' :
                                                                    row[column.id] === t("Common.Pending") ? '#E19957' :
                                                                        row[column.id] === t("Common.Cancelled") ? '#C74141' :
                                                                            row[column.id] === t("Common.Absence") ? '#A36437' :
                                                                                row[column.id] === t("Common.Open") ? '#E19957' :
                                                                                    '#1F1F1F',
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            {row[column.id]}
                                                        </Typography>
                                                    ) : column.id === 'total' ? (
                                                        <Typography color={'#1F1F1F'} variant='body1'>
                                                            {row[column.id]} Kr.
                                                        </Typography>
                                                    ) : column.id === "journal_id" ? (
                                                        <Typography color={'#1F1F1F'} fontWeight={700} variant='body1'>
                                                            {row[column.id]}
                                                        </Typography>
                                                    ) : (
                                                        <Typography sx={{
                                                            maxWidth: '200px', // Set the max width as needed
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        }} color={'#1F1F1F'} variant='body1'>
                                                            {row[column.id]}
                                                        </Typography>
                                                    )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            )}
                    </TableBody>

                </Table>
            </TableContainer >
        </Stack >
    );
};

export default FCommonTable;