import moment from "moment";

export function generateTimeSlots() {
    const timeSlots = [];
    const startTime = moment('00:00', 'HH:mm');
    const endTime = moment('23:55', 'HH:mm');

    while (startTime <= endTime) {
        const nextTime = moment(startTime).add(30, 'minutes');
        timeSlots.push(`${startTime.format('HH:mm')} - ${nextTime.format('HH:mm')}`);
        startTime.add(5, 'minutes');
    }

    return timeSlots;
}

export const transformServiceList = (data, fields , t) => {

    const filteredData = data.map(group => ({
        ...group,
        services: group.services.filter(service => {
            return !fields.some(field => field.serviceId === service.id);
        })
    }));

    const groupedServices = filteredData
        .filter(group => group.id !== 0)
        .map(group => ({
            title: group.group,
            groupId: group.id,
            data: group.services.length > 0 ? group.services : [{ name: t("SpOffers.NoServ"), groupId: group.id }],
        }));

    const ungroupedServices = filteredData
        .filter(group => group.id === 0)
        .flatMap(group => group.services)
        .map(service => ({
            ...service,
            groupId: 0,
        }));

    return [
        {
            title: t("Calendar.ServicesWithoutGroup"),
            groupId: 0,
            data: ungroupedServices.length > 0 ? ungroupedServices : [{ name: t("SpOffers.NoServ"), groupId: 0 }],
        },
        ...groupedServices,
    ];
};


export function getItems(duration) {

    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    if (hours > 0) {
        return `${hours}t. ${minutes}min.`;
    } else {
        return `${minutes}min.`;
    }
}

export const formatPhoneNumber = (number) => {
    if (number != "") {
        return number?.replace(/(\d{2})(?=\d)/g, "$1 ");
    } else {
        return "";
    }
};


export const validateBooking = (cardValues) => {

    for (let i = 0; i < cardValues.length; i++) {
        const { selectedEmployee, available_time, duration_min } = cardValues[i];
        const startTime = moment(available_time, 'HH:mm'); // Parse available time
        const endTime = moment(startTime).add(duration_min, 'minutes'); // Add duration to get end time

        for (let j = 0; j < cardValues.length; j++) {
            if (i !== j) {
                const { selectedEmployee: compareEmployee, available_time: compareTime, duration_min: compareDuration } = cardValues[j];

                if (selectedEmployee.id === compareEmployee.id) {
                    const compareStartTime = moment(compareTime, 'HH:mm');
                    const compareEndTime = moment(compareStartTime).add(compareDuration, 'minutes');

                    if (
                        (startTime.isBefore(compareEndTime) && endTime.isAfter(compareStartTime)) || // If times overlap
                        (compareStartTime.isBefore(endTime) && compareEndTime.isAfter(startTime)) // If other booking times overlap this
                    ) {
                        return false; // If overlap found, return false
                    }
                }
            }
        }
    }

    return true; // No overlaps, return true
};