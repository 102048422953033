import React, { useState, useEffect, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";


import "../../index.css";
import greaterImg from "../../assets/greter.png";
import lessImg from "../../assets/less.png";
import closeImg from "../../assets/X.png";
import BookingForm from "./BookingForm";
import { Modal, Button, FormControl } from "react-bootstrap";
import axios, { HttpStatusCode } from "axios";
// import "moment/locale/nb"; // Import Norwegian Bokmål locale

// import "moment/locale/nb"; // Import Norwegian Bokmål locale
// import CustomDateHeader from "./calenderHeaders"; // Import the custom date header
import BookingDetailModal from "./BookingDetailModal";
// import "moment/locale/da"

import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";

import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import apiFetcher from "../../utils/interCeptor";
import { isEmpAvailable } from '../../components/settings/opningHours/utils/Functions';
import CustomSelect from "../../components/settings/commonCustomSelect";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FSelect from "../../components/commonComponents/F_Select";
import PopupForEmployee from "../../components/calanderPopups/PopUpForEmployee";
import PopUpForOTP from "../../components/calanderPopups/PopUpForOTP";
import { useDispatch } from "react-redux";
import { settings } from "../../context/settingsSlice";
import { t } from "i18next";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import "moment/locale/en-gb";
import "moment/locale/da";

const CustomSVGIcon = (props) => (
  <img
    {...props}
    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Cpath d='M18.2583 8.20312L12.2816 14.1798C11.5758 14.8856 10.4208 14.8856 9.71495 14.1798L3.73828 8.20312' stroke='%23545454' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E"
    alt="Custom icon"
    style={{ width: '22px', height: '22px', color: '#545454', transform: 'translateY(-20%)' }}
  />
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 550,
    },
  },
};

const CustomToolbar = ({
  label,
  onNavigate,
  openForm,
  view,
  setView,
  employeeId,
  setEmployeeId,
  showAllEmployeesCalendar,
  employees,
  isToday,
  selectedDate,
  setSelectedDate,
  CustomTimeHeader,
  setCurrentWeek,
  setDropDownEmployees,
  selectedLanguage
}) => {

  const user = useSelector((state) => state.user.data);

  const setting = useSelector((state) => state?.settings?.data);

  const dispatch = useDispatch();


  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarValue, setCalendarValue] = useState(moment(selectedDate));
  const role = localStorage.getItem("employee_role");
  // const currentYear = dayjs(selectedDate) && dayjs(selectedDate).year ? dayjs(selectedDate).year() : moment().year();
  const currentYear = moment(selectedDate) && moment(selectedDate).year ? moment(selectedDate).year() : moment().year();
  const [selectedEmployee, setSelectedEmployee] = useState([]);


  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(0);

  const [showEmployeePopup, setShowEmployeePopup] = useState(false);
  const [employeeObjectForPopUp, setEmployeeObjectForPopUp] = useState([])
  const [selectedPopUpEmployee, setSelectedPopUpEmployee] = useState(null);

  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [passcode, setPasscode] = useState(["", "", "", "", "", ""]);

  const handleToday = () => onNavigate("DATE", moment());



  useEffect(() => {
    const extractAndTranslateDate = () => {
    
      let parsedDate = null;
      if (view === "week") {
        const words = label.split(/\s+/);
        const [startDateStr, endDateStr] = label.split(" – ");
        const startDate = moment(startDateStr, "MMMM DD", true).year(currentYear);
        let endDate = moment(endDateStr, "MMMM DD", true).year(currentYear);
        const daysInStartMonth = startDate.daysInMonth();
        const startMonth = startDate.month();
        const endMonth = endDate.month();

        if (endDate.isBefore(startDate, 'day')) {
          endDate = endDate.year(currentYear);
        }
        if ((startMonth === 11 && endMonth === 0) || (startMonth === 0 && endMonth === 11)) {
          const decemberContribution = daysInStartMonth - startDate.date() + 1;
          const januaryContribution = endDate.date();

          if (januaryContribution > decemberContribution) {
            parsedDate = moment(`${endDateStr} ${currentYear}`, "MMMM DD YYYY", true);
          } else {
            parsedDate = moment(`${startDateStr} ${currentYear}`, "MMMM DD YYYY", true);
          }
        } else {
          parsedDate = moment(`${startDateStr} ${currentYear}`, "MMMM DD YYYY", true);
        }
      } else if (view === "day") {
        const match = label.match(/([A-Za-zæøåÆØÅ]+ \d{2})/);
        if (match) {
          const [_, dateStr] = match;
          const tempDate = moment(`${dateStr} ${currentYear}`, "MMM DD YYYY", true).locale(selectedLanguage);

          if (tempDate.isValid()) {
            const fullDateStr = tempDate.format("MMMM DD"); // e.g., "december 13"
            parsedDate = moment(`${fullDateStr} ${currentYear}`, "MMMM DD YYYY", true); // Parse the updated string
          }
        }
      }

      if (parsedDate && parsedDate.isValid()) {
        CustomTimeHeader({ date: parsedDate });
      } else {
        console.error("Invalid label format or parsing failed:", label);
      }
    };

    extractAndTranslateDate();
  }, [label]);

  const handleNavigate = (direction) => {
    onNavigate(direction);
  };

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };

  const handleDateChange = (date) => {
    // if (!dayjs.isDayjs(date)) {
    //   date = dayjs(date);
    // }
    setCalendarValue(date);
    setSelectedDate(date);
    onNavigate("DATE", date.toDate());
    setView('day');
  };


  useEffect(() => {
    const storedEmployeeId = localStorage.getItem("selectedEmployeeId");
    if (!storedEmployeeId) {
      const storedEmployeeIdFromLocalStorage = localStorage.getItem("employee_id");
      setEmployeeId(storedEmployeeIdFromLocalStorage || "0");
    } else {
      setEmployeeId(storedEmployeeId);
    }
  }, []);


  const handleEmployeeSelect = (e) => {
    const currentEmployeeId = e.target.value;
    if (currentEmployeeId === "0") {
      setEmployeeId("0");
      localStorage.setItem("selectedEmployeeId", "0");
    } else {
      setEmployeeId(currentEmployeeId);
      localStorage.setItem("selectedEmployeeId", currentEmployeeId);
    }
  };

  const formatDateRange = (label) => {
    const [startDateStr, endDateStr] = label.split(" – ");
    let endDate;

    if (view === "week") {
      const startMonthMatch = startDateStr.match(/([A-Za-zæøåÆØÅ]+)/);
      const endMonthMatch = endDateStr ? endDateStr.match(/([A-Za-zæøåÆØÅ]+)/) : startMonthMatch;
      const dayMatch = endDateStr ? endDateStr.match(/(\d{2})/) : startDateStr.match(/(\d{2})/);

      if (endMonthMatch) {
        endDate = endDateStr;
      } else if (startMonthMatch && dayMatch) {
        endDate = `${startMonthMatch[0]} ${dayMatch[0]}`;
      } else {
        console.error("Unable to parse date range");
        return "";
      }
      const formattedStartDate = moment(startDateStr, "MMMM DD").locale(selectedLanguage).format("DD MMMM");
      const formattedEndDate = moment(endDate, "MMMM DD").locale(selectedLanguage).format("D MMMM");

      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    else {
      const formattedSelectedDate = moment(selectedDate).locale(selectedLanguage).format("ddd. DD MMM YYYY");
      return formattedSelectedDate;
    }
  };



  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };


  const handleSelectEmployee = (e) => {
    const selectedValues = e.target.value;

    let allID = employees?.map((data) => data.value);

    if ((selectedValues === 0 || selectedValues.includes(0)) && allID.length === selectedEmployee.length) {
      setSelectedEmployee([]);
    }
    else if (selectedValues === 0 || selectedValues.includes(0)) {
      setSelectedEmployee(allID);
    }
    else if (selectedValues.length === 0) {
      setSelectedEmployee([]);
    }
    else {
      setSelectedEmployee(selectedValues);
    }
  };



  const getLocations = async () => {
    try {
      const response = await apiFetcher.get('api/v1/store/get_accounts')
      if (response.status === HttpStatusCode.Ok) {
        setLocations(response?.data?.data);
      }
    } catch (error) {
      toast.error(t("Calendar.ToastErrAcc"));
    }
  }

  useEffect(() => {
    getLocations();
  }, [])

  const resetStates = (e, reason) => {

    if (reason === 'backdropClick') {
      return;
    }
    setSelectedLocation(0);
    setEmployeeObjectForPopUp();
    setShowEmployeePopup(false);
    setSelectedPopUpEmployee();
    setShowOTPPopup(false);
    setPasscode(["", "", "", "", "", ""]);
  }


  const handleSelectLocation = (e) => {
    const selectedLocationId = e?.target?.value;

    // Update the local state with the selected location's ID
    setSelectedLocation(selectedLocationId);


    // Find the employee object corresponding to the selected location
    const employee = locations.find((location) => location?.profile?.id === selectedLocationId);

    // Update the employee object for the popup
    setEmployeeObjectForPopUp(employee);

    // Show the employee popup
    setShowEmployeePopup(true);

    // Modify the profile.id directly in the setting data object (functionally)
    // const updatedData = {
    //   ...setting, // Spread the existing settings
    //   profile: {
    //     ...setting.profile,
    //     id: selectedLocationId,
    //   },
    // };

    // Now, `updatedData` has the modified `profile.id` but other values remain unchanged.
  };


  const handleSelectPopUpEmployee = (emp) => {
    setSelectedPopUpEmployee(emp);
  }


  const handleTransferToOTP = () => {



    if (selectedPopUpEmployee) {

      setShowEmployeePopup(false);
      setShowOTPPopup(true);
    } else {
      toast.error(t("Calendar.ToastErrEmpSelect"));
      return

    }
  }
  const handlePasscodeChange = (e, index) => {
    const newPasscode = [...passcode];
    newPasscode[index] = e.target.value.slice(-1);
    setPasscode(newPasscode);

    if (e.target.value && index < passcode.length - 1) {
      document.getElementById(`passcode-${index + 1}`).focus();
    }
  };


  const handleFinalSavePopuUs = () => {
    const combinedPasscode = passcode.join("");
    if (combinedPasscode.length === 6 && combinedPasscode === selectedPopUpEmployee?.access_code) {
      localStorage.setItem("auth_token", employeeObjectForPopUp?.access_token);
      localStorage.setItem("employee_id", selectedPopUpEmployee?.id);
      localStorage.setItem("employee_role", selectedPopUpEmployee?.role);
      localStorage.setItem("employees", JSON.stringify(employeeObjectForPopUp?.profile?.employees));

      setShowOTPPopup(false);
      toast.success(t("Calendar.ToastSuccessDepartmentChange"));
      setTimeout(() => {
        resetStates();
        window.location.reload();
      }, 800);

    } else if (combinedPasscode.length !== 6) {
      toast.error(t("Calendar.ToastErrPasscode"));
      setPasscode(["", "", "", "", "", ""]);
    } else {
      toast.error(t("Calendar.ToastErrPasscode"));
      setPasscode(["", "", "", "", "", ""]);

      setSelectedLocation(localStorage.getItem("employee_id"));
    }
  }




  const options = [
    { label: t("Calendar.SelectLocation"), value: 0, disabled: true },
    ...locations
      .filter(({ profile, is_active }) => profile?.id !== setting?.profile?.id && is_active)
      .map(({ profile }) => ({
        value: profile?.id,
        label: profile?.name
      }))
  ];




  return (
    <div className="toolbar-container">
      <div className="right-section">
        <div className="lot">
          {(user?.role === "ADMIN" || (user?.settings.change_department)) && (
            <>
              <FSelect
                fontWeight={700}
                IconComponent={CustomSVGIcon}
                options={options}
                value={selectedLocation}
                onChange={handleSelectLocation}
                showPlaceHolder={false}
                backgroundColor="#fff"
                borderColor="#ccc"
                borderRadius={12}
                sx={{ marginLeft: 2 }}
              />
              {/* <div className="dropdown-container">
                <select
                  className="dropdown"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                  defaultValue={350}
                >
                  <option value="Select Location">Select Location</option>
                  {locations.map((location) => (
                    <option key={location?.profile?.id} value={location?.profile?.id}>
                      {location?.profile?.name}
                    </option>
                  ))}
                </select>
              </div> */}
            </>
          )}
        </div>
        <div className="lot">
          <div className="toolbar">
            <button
              onClick={() => handleNavigate("PREV")}
              className="rotated-button"
            >
              <img src={lessImg} alt="Less" height={12} />
            </button>
            <button
              onClick={handleToday}
              className="today-button"
              style={{
                backgroundColor: !isToday ? "#ffffff" : "#BBB0A4",
                color: !isToday ? "#BBB0A4" : "#ffffff",
              }}
            >
              {t("Common.Today")}
            </button>

            <span className="toolbar-label" onClick={toggleCalendar}>
              {formatDateRange(label)}
            </span>

            <button
              onClick={() => handleNavigate("NEXT")}
              className="rotated-button"
            >
              <img src={greaterImg} alt="Greater" height={12} />
            </button>
          </div>

          {calendarOpen && (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={selectedLanguage == 'da'?'da':'en-gb'}>
              <Box className="index-calendar" sx={{ left: role === "ADMIN" ? '63%' : '73%', }}>
                <DateCalendar
                  defaultValue={moment()}
                  
                  value={calendarValue}
                  onChange={(newValue) => handleDateChange(newValue)}

                  sx={{
                    width: '100%',
                    '& .MuiPickersDay-root': {
                      color: 'black',
                      fontSize: '18px',
                    },
                    '& .Mui-selected': {
                      backgroundColor: '#BBB0A4 !important',
                      color: 'white !important',
                      fontSize: '18px',
                    },
                    '& .MuiPickersCalendarHeader-label': {
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: '#000',
                      marginLeft: '20px',
                    },
                    //     '& .css-1nxbkmn-MuiPickersCalendarHeader-root' : {
                    // border:'2px solid red',
                    // display:'flex',
                    // justifyContent:'center'
                    //     },
                    '& .MuiTypography-root': {
                      color: '#5b6980',
                      fontSize: '18px',
                    },
                    '& .MuiIconButton-root': {
                      color: 'black',
                      fontSize: '18px',

                    },
                  }}
                />
              </Box>
            </LocalizationProvider>
          )}

          {(role === "ADMIN" || user?.settings?.view_all_employees) && (
            <div className="dropdown-container">
              <select
                className="dropdown"
                value={employeeId}
                onChange={(e) => handleEmployeeSelect(e)}
              >
                <option value="0" >{t("Common.AllEmployees")}</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* <FSelect
                    backgroundColor='#fff'
                    isMultiSelect={true}
                    value={selectedEmployee}
                    TextToDisplayWithCount={'employees'}
                    sx={{ width: 170 }}
                    onChange={handleSelectEmployee}

                    options={employees}
                    borderRadius={50}
                    padding={0}
                    onClose={() => {
                      setDropDownEmployees(selectedEmployee);
                    }}
                /> */}



          {(user?.settings.create_customers || user?.role === "ADMIN") && (
            <button onClick={openForm} className="new-booking-button">
              {t("Customer.NewBooking")}
            </button>
          )}



        </div>
      </div>


      {showEmployeePopup &&
        <PopupForEmployee
          open={showEmployeePopup}
          onClose={resetStates}
          selectedPopUpEmployee={selectedPopUpEmployee}
          handleSelectPopUpEmployee={handleSelectPopUpEmployee}
          employeeData={employeeObjectForPopUp}
          handleTransferToOTP={handleTransferToOTP}

        />

      }

      {showOTPPopup &&
        <PopUpForOTP
          open={showOTPPopup}
          onClose={resetStates}
          passcode={passcode}
          handlePasscodeChange={handlePasscodeChange}
          selectedPopUpEmployee={selectedPopUpEmployee}
          handleFinalSavePopuUs={handleFinalSavePopuUs} />
      }

    </div>
  );
};

export default CustomToolbar