import React, { useState } from 'react';
import { Dialog, Stack, DialogContent, Typography } from '@mui/material';

import CommonButton from '../../components/settings/commonButton';
import CustomDatePicker from '../../components/settings/commonDatePicker';
import CustomTimePicker from '../../components/settings/commonTimePicker';


export default function NewRescheduleBookingModal({ open, closeForm }) {

    const [selectDate, setSelectDate] = useState(null);
    const [selectTime, setSelectTime] = useState(null);

    return (
        <Dialog
            PaperProps={{ sx: { borderRadius: "25px" } }}
            keepMounted
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => { }}
        >
            <DialogContent sx={{ px: 0, py: 3, mt: 0, mb: 0 }}>

                <Stack sx={{ display: 'flex', width: '100%', px: 5 }}>

                    <Typography variant='h5' sx={{ color: '#1f1f1f', fontWeight: 700, textAlign: 'left' }}>
                        {'Reschedule booking'}
                    </Typography>

                    <Stack sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
                        <CustomDatePicker
                            value={selectDate}
                            onChange={(e) => setSelectDate(e)}
                            sx={{ width: '70%' }}
                            size="small"
                            borderColor="#D9D9D9"
                            format="DD/MM-YYYY"
                            padding={1}
                            borderThickness="2px"
                            inputColor="#A0A0A0"
                            iconVisibility={false}
                        />

                        <CustomTimePicker
                            value={selectTime}
                            onChange={(time) => setSelectTime(time)}
                            borderRadius={{ topLeft: '13px', topRight: '13px', bottomLeft: '13px', bottomRight: '13px' }}
                            sx={{ width: '70%' }}
                        />
                    </Stack>

                </Stack>

                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 3, px: 5 }}>
                    <CommonButton
                        height={40}
                        title={'Cancel'}
                        backgroundColor={'#D9D9D9'}
                        width={'240px'}
                        onClick={() => closeForm()}
                    />

                    <CommonButton
                        height={40}
                        title={'Reschedule'}
                        backgroundColor={'#44B904'}
                        width={'240px'}
                        onClick={() => closeForm()}
                    />

                </Stack>

            </DialogContent>

        </Dialog >
    )
};