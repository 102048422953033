import React, { useState, useEffect } from 'react';
import { Stack, Autocomplete, Box, Grid2, Typography, Divider, ListSubheader, TextField, Card, CardContent, InputAdornment, MenuItem, IconButton, CircularProgress, Tooltip } from '@mui/material';
import * as Yup from 'yup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useFormik } from "formik";
import FSwitch from '../../commonComponents/f-switch';
import CustomSelect from '../../settings/commonCustomSelect';
import CustomDatePicker from '../../settings/commonDatePicker';
import CommonButton from '../../settings/commonButton';
import CreateCustomerForm from './createCustomerModal';
import AddCustomerIcon from '../../../assets/newAddCustomer.png';
import CustomTextField from '../../settings/commonTextinput';
import FButton from '../../commonComponents/F_Button';
import ClockIcon from '../../../assets/newClockDesign.svg';
import SearchNewGold from '../../../assets/searchNewGold.png';
import { t } from 'i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import moment from 'moment';
import FSelect from '../../commonComponents/F_Select';
import { generateTimeSlots, getItems } from './utils/functions';
import { apiMangerBooking } from './utils/api';
import { HandleBooking } from './utils/handlers';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';



export default function CreateBookingTab({ initialData, closeForm, rescheduleProps, setEvents, tempId }) {
    const user = useSelector((state) => state.user.data);
    const setting = useSelector((state) => state.settings.data);

    const [loading, setLoading] = useState({
        serviceApi: false,
        customerApi: false,
        timeSlotApi: [false],
    });
    const [serviceList, setServiceList] = useState([]);
    const [customer, setCustomer] = useState();
    const [customers, setCustomers] = useState([{ label: t("Customer.AddNewCustomer"), id: 0 }]);
    const [showCreateCustomerDialog, setShowCreateCustomerDialoge] = useState(false);
    const [cancelToken, setCancelToken] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const { control, getValues, setValue } = useForm({
        defaultValues: {
            cards: [{ booking_id: null, selectedService: '', employeeList: [], selectedEmployee: '', availableTimeSlots: [], available_time: '', durationList: [], duration_min: '', price: '' }]
        }
    });

    const [cardValues, setCardValues] = useState(getValues('cards'));
    const { fields, append, remove } = useFieldArray({
        control,
        name: "cards"
    });

    const [errors, setErrors] = useState({})

    useEffect(() => {
        setTimeSlots(generateTimeSlots());
        apiMangerBooking.fetchServiceApi({ setServiceList, setLoading, fields, t, setValue, getValues, rescheduleProps, setCardValues });
        if (rescheduleProps) {

            setLoading((prev) => ({ ...prev, timeSlotApi: Array(rescheduleProps?.booking?.length).fill(false) }));
            setValue('cards', rescheduleProps?.booking && rescheduleProps?.booking.map((item) => ({ ...item, employeeList: [], availableTimeSlots: [] })));
            setCustomers([{ label: rescheduleProps?.customer_name, id: rescheduleProps?.id }]);
            apiMangerBooking.availableTimeSlots(rescheduleProps, setValue, setCardValues, setLoading, setting);


            formik.setFieldValue('selectedCustomer', rescheduleProps?.selectedCustomer?.id ? rescheduleProps?.selectedCustomer : null);
            formik.setFieldValue('walk_in', rescheduleProps?.walk_in);
            formik.setFieldValue('sendEmail', rescheduleProps?.send_email);
            formik.setFieldValue('sendSms', rescheduleProps?.send_sms);
            formik.setFieldValue('date', moment(rescheduleProps?.date));
            formik.setFieldValue('note', rescheduleProps?.note);
        } else if (initialData && !rescheduleProps) {
            formik.setFieldValue('date', moment(initialData?.date));

        } else {
            formik.setFieldValue('date', moment());
        }
    }, [rescheduleProps, initialData]);

    useEffect(() => {
        if (customer) {
            setCustomers(customer)
            formik.setFieldValue('selectedCustomer', customer[1])
        }
    }, [customer])

    const allServices = serviceList.flatMap((groupData) =>
        groupData.data.map((service) => ({
            ...service,
            group: groupData.title,
        }))
    );


    const initialValues = {
        selectedCustomer: null,
        walk_in: false,
        sendEmail: false,
        sendSms: false,
        date: null,
        note: '',
        disable: false
    };

    const validationSchema = Yup.object().shape({
        selectedCustomer: Yup.object()
            .when('walk_in', {
                is: false, // When walk_in is false
                then: Yup.object().required(t("Customer.CustomerNameError")).nullable(),
                otherwise: Yup.object().nullable() // If walk_in is true, it's not required
            }),

        date: Yup.date()
            .required(t("Calendar.YupErrDateReq"))
            .nullable(),

        walk_in: Yup.boolean(),
    });


    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values) => {


            const formatValues = {
                booking_id: rescheduleProps ? rescheduleProps?.booking_id : null,
                customer_name: values?.walk_in ? '' : values?.selectedCustomer?.name,
                customer_phone_number: values?.walk_in ? '' : values?.selectedCustomer?.phone_number,
                booking_date: values?.date.format('YYYY-MM-DD'),
                note: values?.note,
                send_email: values?.walk_in ? false : values?.sendEmail,
                send_sms: values?.walk_in ? false : values?.sendSms,
                walk_in: values?.walk_in,
                created_by_emp_id: user?.id,
                created_by_emp_name: user?.name
            }

            let cards = getValues('cards');
            let validationErrors = {}; // To collect the errors

            let format = cards && cards.map((item, index) => {
                let errorsForItem = {}; // Errors for the current item


                if (!item?.selectedService) {
                    errorsForItem.selectedService = t("Calendar.PlSelServ");
                }

                if (!item?.selectedEmployee) {
                    errorsForItem.selectedEmployee = t("Calendar.PlSelEmp");
                }

                if (!item?.available_time || !item?.available_time.includes('-')) {
                    errorsForItem.available_time = t("Calendar.PlSelTime");
                }

                if (!item?.duration_min) {
                    errorsForItem.duration_min = t("Calendar.PlSelDur");
                }

                if (!item?.price) {
                    errorsForItem.price = t("Calendar.PlEnterPr");
                }

                if (Object.keys(errorsForItem).length > 0) {
                    validationErrors[index] = errorsForItem; // Save the errors for this index
                }

                let [startTime, endTime] = item?.available_time.split('-');

                let startMoment = moment(startTime.trim(), 'HH:mm'); // Parse the start time in "HH:mm" format
                let endMoment = startMoment.clone().add(item?.duration_min, 'minutes');
                let formattedEndTime = endMoment.format('HH:mm');

                let updatedAvailableTime = `${startTime.trim()} - ${formattedEndTime}`;

                let formattedItem = {
                    service_id: item?.selectedService?.id,
                    time_slot: updatedAvailableTime,
                    employee_id: item?.selectedEmployee?.id,
                    duration: item?.duration_min,
                    total_amount: item?.price,
                    booking_id: item?.booking_id
                };

                return formattedItem;
            });

            // If there are any errors, update the state
            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                return;
            }


            const payload = {
                ...formatValues,
                services: format
            }

            const editPayload = {
                booking_id: rescheduleProps?.booking_id,
                note: values?.note,
                services: format
            }

            let isEdit = rescheduleProps ? true : false

            if (payload.services.length === 0) return;

            apiMangerBooking.handleSubmit(isEdit ? editPayload : payload, closeForm, toast, t, isEdit, setEvents);

        }
    });

    const addCard = () => {
        append({
            booking_id: null,
            selectedService: '',
            employeeList: [],
            selectedEmployee: '',
            availableTimeSlots: [],
            available_time: '',
            durationList: [],
            duration_min: '',
            price: ''
        });

        setCardValues((prevValues) => [...prevValues, { selectedService: '', employeeList: [], selectedEmployee: '', availableTimeSlots: [], available_time: '', durationList: [], duration_min: '', price: '' }]);

        setLoading((prev) => ({ ...prev, timeSlotApi: Array(cardValues?.length + 1).fill(false) }));
    };


    const formattedTotalAmount = new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK',
        minimumFractionDigits: 2,
        currencyDisplay: 'symbol',
    }).format(cardValues?.reduce((acc, card) => acc + Number(card.price), 0));

    const formattedString = getItems(cardValues?.reduce((acc, card) => acc + card.duration_min, 0));

    return (
        <>
            <Grid2 marginTop={1} paddingBottom={5} container spacing={2} sx={{ px: 5 }}>

                <Grid2 item size={{ xs: 12, md: 4 }}>

                    <Typography variant="body1" sx={{ mt: 1 }}>
                        {t("Calendar.SearchCus")}
                    </Typography>


                    <Autocomplete
                        disabled={rescheduleProps || formik?.values?.disable}
                        value={formik.values.selectedCustomer}
                        onChange={(event, obj) => {
                            if (obj && obj.id == 0) {
                                setShowCreateCustomerDialoge(true);
                            } else {
                                formik.setFieldValue('selectedCustomer', obj);
                            }
                        }}

                        options={customers}
                        getOptionLabel={(option) => option && option.label}
                        fullWidth
                        filterOptions={(option) => option}
                        popupIcon={<KeyboardArrowDownIcon style={{ color: 'black' }} />}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e) => apiMangerBooking.fetchSuggestions({ cus: e?.target?.value, cancelToken, setCancelToken, setCustomers, setLoading, t })}
                                placeholder={loading.customerApi ? `${t("Common.Loading")}` : t("Calendar.SearchCus")}
                                variant="outlined"
                                size="small"
                                sx={{
                                    mt: 1,
                                    // bgcolor: '#E9E9E9',
                                    height: 40,
                                    fontSize: '0.85rem',
                                    borderRadius: '13px',
                                    border: `1px solid #D9D9D9`,
                                    '& .MuiInputBase-root': { padding: '5px' },
                                    '& .MuiInputBase-input': {
                                        color: '#545454',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                    },
                                    '& .MuiInputBase-input::placeholder': { fontSize: '14px' },
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                                    '& .MuiAutocomplete-popupIndicator': { color: '#000' },
                                }}
                                onFocus={(event) => { event.stopPropagation(); }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchNewGold} alt="search" style={{ height: '20px', width: '20px' }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <React.Fragment>
                                <MenuItem
                                    {...props}
                                    value={option.id}
                                    sx={{
                                        color: '#545454',
                                        fontSize: '15px',
                                        fontWeight: 400,
                                        '&:hover': { backgroundColor: '#f5f5f5' },
                                        padding: 0
                                    }}
                                >
                                    {option.id === 0 && (
                                        <img
                                            src={AddCustomerIcon}
                                            alt="Add customer"
                                            style={{ height: '20px', width: '22px', paddingRight: '2px' }}
                                        />
                                    )}
                                    {option.label}
                                </MenuItem>

                                <Divider />
                            </React.Fragment>
                        )}
                        noOptionsText={t("Calendar.NoCusFound")}
                        disableClearable
                    />
                    {formik.errors.selectedCustomer && formik.touched.selectedCustomer && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="caption" color="red">{formik.errors.selectedCustomer}</Typography>
                        </Box>
                    )}

                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 10,
                        }}
                    >
                        <FSwitch
                            disabled={rescheduleProps}
                            checked={formik.values.walk_in}
                            onChange={() => {
                                HandleBooking.handleWalkInChange(formik);
                                formik.setFieldValue('walk_in', !formik.values.walk_in)
                            }}

                        />
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 400, color: "#1F1F1F" }}
                        >
                            {t("Calendar.Dropin")}
                        </Typography>
                    </Box>

                    <Typography variant="body1" sx={{ mt: 2 }}>
                        {t("Common.Date")}
                    </Typography>

                    <CustomDatePicker
                        disabled={rescheduleProps}
                        value={formik.values.date}
                        onChange={(e) => formik.setFieldValue('date', e)}
                        sx={{ width: '100%', mt: 1 }}
                        onBlur={formik.handleBlur}
                        size="small"
                        borderColor="#D9D9D9"
                        format="DD/MM-YYYY"
                        padding={1}
                        borderThickness="2px"
                        inputColor="#A0A0A0"
                        iconVisibility={false}
                    />
                    {formik.errors.date && formik.touched.date && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="caption" color="red">{formik.errors.date}</Typography>
                        </Box>
                    )}

                    <Typography variant="body1" sx={{ mt: 2 }}>
                        {t("Common.Note")}
                    </Typography>

                    <TextField
                        placeholder={t("Calendar.NoteDes")}
                        multiline
                        rows={3}
                        fullWidth
                        value={formik.values.note}
                        onChange={(e) => formik.setFieldValue('note', e?.target?.value)}
                        sx={{
                            border: `1px solid #D9D9D9`,
                            mt: 1,
                            borderRadius: '15px',
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                        }}
                    />

                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 10,
                        }}
                    >
                        <FSwitch
                            disabled={rescheduleProps || formik?.values?.disable || !setting?.profile?.enable_email}
                            checked={formik.values.sendEmail}
                            onChange={() => formik.setFieldValue('sendEmail', !formik.values.sendEmail)}

                        />
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 400, color: "#1F1F1F" }}
                        >
                            {t("Calendar.EmailConf")}
                        </Typography>
                    </Box>

                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 3,
                        }}
                    >
                        <FSwitch
                            disabled={rescheduleProps || formik?.values?.disable || !setting?.profile?.enable_sms}
                            checked={formik.values.sendSms}
                            onChange={() => formik.setFieldValue('sendSms', !formik.values.sendSms)}

                        />
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 400, color: "#1F1F1F" }}
                        >
                            {t("Calendar.SmsConf")}
                        </Typography>
                    </Box>

                </Grid2>

                <Grid2 item size={{ xs: 0.5 }}>
                    <Divider
                        orientation="vertical"
                        sx={{
                            color: '#D9D9D9',
                        }}
                    />
                </Grid2>

                <Grid2 item size={{ xs: 12, md: 7.5 }}>


                    <Stack sx={{ display: 'flex', flexDirection: 'row', textAlign: 'left', alignItems: 'center', mb: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: 700 }}>
                            {t("Common.SelectService")}
                        </Typography>
                    </Stack>

                    {/* <Box sx={{ height: '400px', overflowY: 'auto', overflowX: 'visible', position: 'relative' }}>  */}
                    {fields.map((card, index) => (
                        <Card
                            key={card.id || index}
                            sx={{ width: '100%', borderRadius: '15px', mb: 2, position: 'relative', overflow: 'visible', zIndex: 1000, filter: 'drop-shadow(0px 1px 7px #00000040)' }}
                        >
                            {index !== 0 && <Box sx={{ position: 'absolute', top: -10, right: -10, zIndex: 1000 }}>
                                <IconButton
                                    disableFocusRipple disableTouchRipple disableRipple
                                    onClick={() => {
                                        setCardValues((prevValues) => prevValues.filter((_, i) => i !== index));
                                        remove(index)
                                    }}
                                    aria-label="remove card"
                                    sx={{
                                        padding: '4px',
                                        borderRadius: '50%',
                                        bgcolor: '#DC0000',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                    }}
                                >
                                    <Close sx={{ color: '#fff', fontSize: 20 }} />
                                </IconButton>
                            </Box>}

                            <CardContent>

                                <Grid2 container spacing={1}>

                                    <Grid2 item size={{ xs: 8 }}>
                                        <Autocomplete
                                            disabled={!formik.values.date}
                                            popupIcon={<KeyboardArrowDownIcon style={{ color: !formik.values.date ? '#d0d0d0' : 'black' }} />}
                                            value={cardValues[index]?.selectedService || null}
                                            options={allServices} // Ensure options are correctly passed
                                            getOptionLabel={(option) => option.name}
                                            groupBy={(option) => option.group}
                                            fullWidth
                                            onChange={(event, newValue) => HandleBooking.handleServiceChange(index, newValue, setValue, setCardValues, initialData, cardValues, setLoading, formik, timeSlots, setting)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    placeholder={loading.serviceApi ? `${t("Common.Loading")}...` : `${t("Calendar.SearchSer")}...`}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        height: 40,
                                                        fontSize: '0.85rem',
                                                        borderRadius: '13px',
                                                        border: `1px solid #D9D9D9`,
                                                        '& .MuiInputBase-root': {
                                                            padding: '5px',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            color: '#545454',
                                                            fontSize: '15px',
                                                            fontWeight: 400,
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D9D9D9',
                                                        },
                                                        '& .MuiAutocomplete-popupIndicator': {
                                                            color: '#000',
                                                        },
                                                        width: '100%',
                                                        '& .MuiInputBase-input::placeholder': {
                                                            color: '#A0A0A0',
                                                            fontSize: '14px',
                                                            fontWeight: 400
                                                        },
                                                    }}
                                                />
                                            }
                                            renderOption={(props, option) => (
                                                <>
                                                    <li
                                                        {...props}
                                                        key={option.id || index}
                                                        value={option.id}
                                                        style={{ padding: '4px 12px', lineHeight: '1.2' }}
                                                    >
                                                        <Typography variant="body1" color='#545454'>
                                                            {option.name}
                                                        </Typography>
                                                    </li>
                                                    <Divider sx={{ margin: '4px 0' }} />
                                                </>
                                            )}
                                            renderNoOptions={(props) => (
                                                <Box {...props} sx={{ padding: 1 }}>
                                                    <Typography variant="body1" color="error" align="center">
                                                        {t("SpOffers.NoRes")}
                                                    </Typography>
                                                </Box>
                                            )}
                                            renderGroup={(params) => (
                                                <li>
                                                    <ListSubheader
                                                        component="div"
                                                        sx={{
                                                            fontWeight: '700',
                                                            fontSize: '18px',
                                                            color: '#1F1F1F',
                                                            '&.Mui-disabled': {
                                                                color: '#1F1F1F',
                                                            },
                                                            position: 'relative',
                                                            padding: '4px 12px',
                                                            lineHeight: '1.2',
                                                        }}
                                                    >
                                                        {params.group}
                                                    </ListSubheader>
                                                    <Divider sx={{ margin: '4px 0' }} />
                                                    {params.children}
                                                </li>
                                            )}
                                            clearIcon={false}
                                        />
                                        {errors[index]?.selectedService && <Tooltip title={errors[index]?.selectedService} placement="top" arrow> <Typography noWrap style={{ color: 'red', variant: 'caption', fontSize: '11px' }}>{errors[index]?.selectedService}</Typography></Tooltip>}
                                    </Grid2>

                                    <Grid2 item size={{ xs: 4 }}>
                                        <CustomSelect
                                            id={`duration_min_${index}`}
                                            options={cardValues[index]?.durationList || []}
                                            value={cardValues[index]?.duration_min || ''}
                                            onChange={(e) => HandleBooking.handleDurationChange(index, e?.target?.value, setValue, setCardValues, cardValues)}
                                            placeholder={t("Common.Duration")}
                                            sx={{ width: '100%' }}
                                            borderColor='#D9D9D9'
                                            borderThickness="1px"
                                            noLabel
                                            disabled={!cardValues[index]?.selectedService}
                                        />
                                        {errors[index]?.duration_min && <Tooltip title={errors[index]?.duration_min} placement="top" arrow> <Typography noWrap style={{ color: 'red', variant: 'caption', fontSize: '11px' }}>{errors[index]?.duration_min}</Typography> </Tooltip>}
                                    </Grid2>

                                </Grid2>

                                <Grid2 container spacing={1}>

                                    <Grid2 item size={{ xs: 3.5 }}>
                                        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                            <CustomTextField
                                                value={cardValues[index]?.price || ''}
                                                disabled={!cardValues[index]?.selectedService || !cardValues[index]?.duration_min}
                                                onChange={(e) => { HandleBooking.handlePriceChange(index, e.target.value, setValue, setCardValues, cardValues) }}
                                                placeholder={'0.00'}
                                                id={`price_${index}`}
                                                name={`price_${index}`}
                                                width={'60%'}
                                                borderRadius={{
                                                    topLeft: "15px",
                                                    topRight: "0px",
                                                    bottomLeft: "15px",
                                                    bottomRight: "0px",
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    width: "40%",
                                                    height: 42,
                                                    backgroundColor: "#D9D9D9",
                                                    color: "black",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "0px 15px 15px 0px",
                                                    mt: 1.5
                                                }}
                                            >
                                                <Typography>kr.</Typography>
                                            </Box>
                                        </Stack>
                                        {errors[index]?.price && <Tooltip title={errors[index]?.price} placement="top" arrow> <Typography noWrap style={{ color: 'red', variant: 'caption', fontSize: '11px' }}>{errors[index]?.price}</Typography> </Tooltip>}
                                    </Grid2>

                                    <Grid2 item size={{ xs: 5 }}>
                                        <Autocomplete
                                            disabled={!cardValues[index]?.selectedService || !cardValues[index]?.duration_min}
                                            options={cardValues[index]?.employeeList || []}
                                            value={cardValues[index]?.selectedEmployee || null}
                                            onChange={(event, newValue) => HandleBooking.handleEmployeeChange(newValue, index, setValue, setLoading, setCardValues, cardValues, timeSlots, formik, setting)}
                                            getOptionLabel={(option) => option.name || ''}
                                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                                            fullWidth
                                            popupIcon={<KeyboardArrowDownIcon style={{ color: !cardValues[index]?.selectedService || !cardValues[index]?.duration_min ? '#d0d0d0' : 'black' }} />}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={t("Common.SelectEmployee")}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        mt: 1.7,
                                                        height: 40,
                                                        fontSize: '0.85rem',
                                                        borderRadius: '13px',
                                                        border: `1px solid #D9D9D9`,
                                                        '& .MuiInputBase-root': { padding: '5px' },
                                                        '& .MuiInputBase-input': {
                                                            color: '#545454',
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                        },
                                                        '& .MuiInputBase-input::placeholder': { fontSize: '14px' },
                                                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                                                        '& .MuiAutocomplete-popupIndicator': { color: '#000' },
                                                    }}
                                                    onFocus={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <MenuItem
                                                    {...props}
                                                    value={option.id}
                                                    sx={{
                                                        color: '#545454',
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        '&:hover': { backgroundColor: '#f5f5f5' },
                                                    }}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            )}
                                            noOptionsText={t("Calendar.NOemp")}
                                            disableClearable
                                        />
                                        {errors[index]?.selectedEmployee && < Tooltip title={errors[index]?.selectedEmployee} placement="top" arrow> <Typography noWrap style={{ color: 'red', variant: 'caption', fontSize: '11px' }}>{errors[index]?.selectedEmployee}</Typography> </Tooltip>}
                                    </Grid2>

                                    <Grid2 item size={{ xs: 3.5 }}>
                                        <FSelect
                                            disabled={!cardValues[index]?.selectedService || !cardValues[index]?.duration_min || !cardValues[index]?.selectedEmployee}
                                            value={cardValues[index]?.available_time}
                                            onChange={(e) => HandleBooking.handleBookingTimeChange(index, e?.target?.value, setValue, setCardValues, cardValues, toast, t, tempId)}
                                            placeholderText={loading.timeSlotApi[index] ? <CircularProgress size={20} /> : <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} > {t("Calendar.BkTime")}</Typography>}
                                            options={cardValues[index]?.availableTimeSlots}
                                            sx={{ width: '100%', mt: 1.7 }}
                                            borderColor="#D9D9D9"
                                            borderThickness="1px"
                                            autoScrollToValue
                                        />
                                        {errors[index]?.available_time && <Tooltip title={errors[index]?.available_time} placement="top" arrow> <Typography noWrap style={{ color: 'red', variant: 'caption', fontSize: '11px' }}>{errors[index]?.available_time}</Typography> </Tooltip>}
                                    </Grid2>

                                </Grid2>

                            </CardContent>

                        </Card>
                    ))}

                    <FButton
                        onClick={() => addCard()}
                        title={`+ ${t("Calendar.AddExServ")}`}
                        titleColor={'black'}
                        titleWeight={1}
                        variant={'save'}
                        border={'1px dashed black'}
                        sx={{
                            width: "100%",
                            color: "white",
                            borderRadius: 2,
                            backgroundColor: "white",
                            mb: 2
                        }}
                    />

                    {/* </Box> */}

                    <Box sx={{ marginTop: 'auto' }}>
                        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', px: 2, mb: 1 }}>
                            <Typography variant="body1">
                                {`${t("Common.Total")} : ${formattedTotalAmount}`}
                            </Typography>

                            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <img
                                    src={ClockIcon}
                                    alt="clock"
                                    style={{ height: '20px', width: '20px', marginRight: 5 }}
                                />
                                <Typography variant="body1">
                                    {formattedString}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Divider sx={{ borderWidth: 0.5, borderColor: '#696969', ml: 2, mr: 2, mb: 2 }} />

                        <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'space-between', gap: 3, px: 3 }}>
                            <CommonButton
                                height={40}
                                title={t("Setting.Cancel")}
                                backgroundColor={'#D9D9D9'}
                                style={{ minWidth: 150 }}
                                onClick={() => closeForm()}
                            />

                            <CommonButton
                                type="submit"
                                height={40}
                                title={rescheduleProps ? t("Customer.SaveCh") : t("Calendar.CreBook")}
                                // backgroundColor={(cardValues.some(card => !card.selectedService || !card.duration_min || !card.selectedEmployee || !card.available_time || !card.price) || cardValues.length === 0) ? '#D9D9D9' : '#44B904'}
                                style={{ minWidth: 180 }}
                                onClick={formik.handleSubmit}
                            // disabled={(cardValues.some(card => !card.selectedService || !card.duration_min || !card.selectedEmployee || !card.available_time || !card.price) || cardValues.length === 0)}
                            />
                        </Box>
                    </Box>


                </Grid2>

            </Grid2>

            {showCreateCustomerDialog &&
                <CreateCustomerForm
                    open={showCreateCustomerDialog}
                    closeForm={() => setShowCreateCustomerDialoge(false)}
                    setCustomer={setCustomer}
                />
            }

        </>
    )
};