import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from "formik";

import CustomTextField from '../../settings/commonTextinput';
import CommonButton from '../../settings/commonButton';

import { t } from 'i18next';
import { apiMangerBooking } from './utils/api';
import { toast } from 'react-toastify';
import { formatPhoneNumber } from './utils/functions';

export default function CreateCustomerForm({ open, closeForm, setCustomer }) {


    const initialValues = {
        customerName: '',
        phoneNumber: '',
        email: '',
    };

    const validationSchema = Yup.object().shape({

        customerName: Yup.string()
            .required(t("Customer.CustomerNameError")),

        phoneNumber: Yup.string()
            .required(t("Customer.PhoneNumberError"))
            .matches(/^\d{8}$/, t("Customer.PhoneNumberInvalid"))
            .typeError(t("Customer.PhoneNumberTypeError"))
            .required(t("Customer.PhoneNumberError")),

        email: Yup.string()
            .nullable()
            .test('is-valid-email', t("Customer.EmailError"), (value) => {
                if (value && value.trim() !== '') {
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    return emailRegex.test(value);
                }
                return true;
            }),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            let payload = {
                name: values.customerName,
                phone_number: values.phoneNumber,
                email: values.email
            }
            apiMangerBooking.createCustomer(payload, closeForm, toast, t, setCustomer);
        }
    });

    return (
        <Dialog
            PaperProps={{ sx: { borderRadius: "25px" } }}
            keepMounted
            fullWidth
            maxWidth="sm"
            open={open}
           
        >
            <DialogTitle>
                <Typography variant='h5' sx={{ color: '#1f1f1f', fontWeight: 700, px: 3 }}>
                    {t("Calendar.CreateCus")}
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ pl: 6, pr: 6, pt: 0, pb: 0, mt: 0, mb: 0 }}>

                <Typography variant="body1">
                    {t("Common.Name")}
                </Typography>

                <CustomTextField
                    value={formik.values.customerName}
                    onChange={(e) => formik.setFieldValue("customerName", e.target.value)}
                    placeholder={t("Customer.CustomerName")}
                    id={"customerName"}
                    name={"customerName"}
                    mt={1}
                    borderRadius={'15px'}
                />
                {formik.touched.customerName && formik.errors.customerName &&
                    <Typography variant='caption' color='red'>
                        {formik.errors.customerName}
                    </Typography>
                }

                <Typography variant="body1" sx={{ mt: 2 }}>
                    {t("Common.Phone")}
                </Typography>

                <CustomTextField
                    value={formik.values.phoneNumber ? formatPhoneNumber(formik.values.phoneNumber) : ''}
                    onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, ''); // This ensures only numbers are allowed
                        formik.setFieldValue("phoneNumber", value);
                    }}
                    inputProps={{ maxLength: 11 }}
                    placeholder={t("Calendar.PhoneNumber")}
                    id={"phoneNumber"}
                    name={"phoneNumber"}
                    mt={1}
                    borderRadius={'15px'}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber &&
                    <Typography variant='caption' color='red'>
                        {formik.errors.phoneNumber}
                    </Typography>
                }


                <Typography variant="body1" sx={{ mt: 2 }}>
                    {t("Setting.Email") + ` (${t("Common.Optional")})`}
                </Typography>

                <CustomTextField
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    placeholder={t("Setting.Email")}
                    id={"email"}
                    name={"email"}
                    mt={1}
                    borderRadius={'15px'}
                />
                {formik.touched.email && formik.errors.email &&
                    <Typography variant='caption' color='red'>
                        {formik.errors.email}
                    </Typography>
                }

            </DialogContent>

            <DialogActions sx={{ justifyContent: 'center', mb: 2, mt: 2, ml: 3, mr: 3, gap: 2 }} >
                <CommonButton
                    height={40}
                    title={t("Setting.Cancel")}
                    backgroundColor={'#D9D9D9'}
                    style={{ minWidth: 50 }}
                    onClick={closeForm}
                />

                <CommonButton
                    type="submit"
                    height={40}
                    title={t("Calendar.CreateCus")}
                    backgroundColor={'#44B904'}
                    style={{ minWidth: 50 }}
                    onClick={formik.handleSubmit}
                />
            </DialogActions>

        </Dialog >
    )
};