import { Stack, Typography } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { t } from "i18next";
import { Circle } from "@mui/icons-material";
import FSelect from "../../../commonComponents/F_Select";
import FButton from "../../../commonComponents/F_Button";
import FPrimaryHeading from "../../../commonComponents/F_PrimaryHeading";
import FCommonTable from "../../../commonComponents/F_commonTable";
import apiFetcher from "../../../../utils/interCeptor";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { HttpStatusCode } from "axios";
import { DropDown } from "../../../insight/customDropDown";
import dayjs from "dayjs";
import BookingForm from "../../../../scenes/Calendar/BookingForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function Bookings({
  asComponent = false,
  selectedEmployee,
  selectEmpObj,
  handleEmployeeSelect,
  handleClose,
  stDate = dayjs(),
  setStDate,
  enDate = dayjs(),
  setEnDate,
  showCalander = false,

  compFutureData,
  compPrevData,
  loadingAsComp = false,
}) {
  const [apiDataBookings, setApiDataBookings] = useState({
    futureData: [],
    previosData: [],
  });
  const  navigate = useNavigate();
  const location = useLocation();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [customer, setCustomer] = useState(location?.state?.data);
  const [bookingFlowModal, setBookingFlowModal] = useState(false);
  const {id} = useParams()

  moment.locale("en-gb");

  const bookigsColumns = [
    { id: "id", label: `${t("Common.ID")}`, sortable: false },
    { id: "date", label: `${t("Common.Date")}`, sortable: true },
    { id: "name", label: `${t("Common.Name")}`, sortable: true },
    { id: "time", label: `${t("Common.Time")}`, sortable: false },
    { id: "service", label: `${t("Common.Service")}`, sortable: false },
    { id: "employee", label: `${t("Common.CapsEmployee")}`, sortable: false },
    { id: "status", label: `${t("Common.Status")}`, sortable: false },
    { id: "total", label: `${t("Common.Total")}`, sortable: true },
  ];

useEffect(() => {
      
  const fetchCustomerDetails = async () => {
   if(id){ try {
      const response = await apiFetcher.get(
        `api/v1/store/customer/${id}`
      );
      if (response.status === HttpStatusCode.Ok) {
        setCustomer(response?.data?.data);
      }
    } catch (error) {
      toast.error("Failed to fetch customer details");
      navigate("/customers");
    }
}
  };

  fetchCustomerDetails();
},[])


  const fetchBookings = async () => {

    try {
      const futureResponse = await apiFetcher.get(
        `api/v1/store/customer/booking/${id}?date=${moment().format(
          "YYYY-MM-DD"
        )}`
      );
      const previousResponse = await apiFetcher.get(
        `api/v1/store/customer/booking/${id}?to_date=${moment().format(
          "YYYY-MM-DD"
        )}`
      );

      if (futureResponse.status === HttpStatusCode.Ok) {
        setApiDataBookings((prev) => ({
          ...prev,
          futureData: futureResponse.data.data,
        }));
      }
      if (previousResponse.status === HttpStatusCode.Ok) {
        setApiDataBookings((prev) => ({
          ...prev,
          previosData: previousResponse.data.data,
        }));
      }
    } catch (error) {
      toast.error("Failed to fetch bookings");
    }
  };

  useEffect(() => {
    if (customer?.id && !asComponent) {
      fetchBookings();
    }
  }, [customer?.id, startDate, endDate]);

  const refreshBookings = () => {
    fetchBookings();
  };

  const statusObj = {
    BOOKED: t("Common.Pending"),
    RESCHEDULED: t("Common.Pending"),
    OFFER_ACCEPTED: t("Common.Pending"),
    NOSHOW: t("Common.Absence"),
    OFFERED: t("Common.Cancelled"),
    CANCELLED: t("Common.Cancelled"),
    COMPLETED: t("Common.Completed"),
    Pending: t("Common.Pending"),
    Completed: t("Common.Completed"),
    Cancelled: t("Common.Cancelled"),
    Absence: t("Common.Absence"),
  };

  // Function to count statuses
  const countStatuses = (bookings) => {
    let counts = {
      Pending: 0,
      Completed: 0,
      Cancelled: 0,
      Absence: 0,
    };

    bookings.forEach((booking) => {
      counts[statusObj[booking.status]]++;
    });

    return [
      { label: `${counts.Pending} ${t("Common.Pending")}`, color: "#E19957" },
      {
        label: `${counts.Completed} ${t("Common.Completed")}`,
        color: "#367B3D",
      },
      {
        label: `${counts.Cancelled} ${t("Common.Cancelled")}`,
        color: "#C74141",
      },
      { label: `${counts.Absence} ${t("Common.Absence")}`, color: "#A36437" },
    ];
  };

  // Preparing data for future and previous bookings
  const dataForFutureBookings = apiDataBookings.futureData.map((book) => ({
    id: book?.id,
    date: moment(book?.booking_datetime_start, "YYYY-MM-DDTHH:mm:ss").format(
      "DD/MM-YYYY"
    ),
    name: book?.booking_details?.customer_name,
    time: `${moment(book?.booking_datetime_start, "YYYY-MM-DDTHH:mm:ss").format(
      "HH:mm"
    )} - ${moment(book?.booking_datetime_end, "YYYY-MM-DDTHH:mm:ss").format(
      "HH:mm"
    )} `,
    service: book?.booking_details?.service_name,
    employee: book?.booking_details?.employee_name,
    status: statusObj[book?.status],
    total: parseFloat(book?.total_amount),
  }));

  const dataForPreviousBookings = apiDataBookings.previosData.map((book) => ({
    id: book?.id,
    date: moment(book?.booking_datetime_start, "YYYY-MM-DDTHH:mm:ss").format(
      "DD/MM-YYYY"
    ),
    name: book?.booking_details?.customer_name,
    time: `${moment(book?.booking_datetime_start, "YYYY-MM-DDTHH:mm:ss").format(
      "HH:mm"
    )} - ${moment(book?.booking_datetime_end, "YYYY-MM-DDTHH:mm:ss").format(
      "HH:mm"
    )} `,
    service: book?.booking_details?.service_name,
    employee: book?.booking_details?.employee_name,
    status: statusObj[book?.status],
    total: parseFloat(book?.total_amount),
  }));

  // Count statuses for future and previous bookings
  // const rawDataFuture = countStatuses(apiDataBookings.futureData);
  // const rawDataPrevious = countStatuses(apiDataBookings.previosData);

  const rawDataPrevious = useMemo(() => {
    return countStatuses(
      asComponent ? compPrevData : apiDataBookings?.previosData
    );
  }, [asComponent ? compPrevData : apiDataBookings?.previosData]);

  const filterdColumns = bookigsColumns.filter((column) => column.id !== "id");


  return (
    <Stack sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <ToastContainer />
      <Stack
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <FPrimaryHeading
          fontColor={"#545454"}
          text={t("Customer.FutureBookings")}
        />
        {asComponent ? (
          <FSelect
            sx={{ minWidth: "20%" }}
            placeholderText={t("Common.SelectEmployee")}
            isMultiSelect
            padding="0"
            options={selectEmpObj}
            value={selectedEmployee}
            selectAllRenderText={t("Common.AllEmployees")}
            TextToDisplayWithCount={t("Common.Employees")}
            onChange={handleEmployeeSelect}
            onClose={handleClose}
          />
        ) : (
          <Stack flex={1} flexDirection={"row"} justifyContent={"flex-end"}>
            <FButton
              variant={"save"}
              title={`+ ${t("Customer.NewBooking")}`}
              sx={{ py: 1 }}
              onClick={() => {
                setBookingFlowModal(true);
              }}
            />
          </Stack>
        )}
      </Stack>
      <FCommonTable
        visibleColumns={[
          "date",
          "name",
          "time",
          "service",
          "employee",
          "status",
          "total",
        ]}
        columnWidths={{
          date: "10%",
          name: "15%",
          time: "10%",
          service: "35%",
          employee: "15%",
          status: "10%",
          total: "10%",
        }}
        columns={filterdColumns}
        data={asComponent ? compFutureData : dataForFutureBookings}
        loading={asComponent ? loadingAsComp.future : false}
        onRowClick={() => {}}
      />

      <Stack
        mt={4}
        gap={1}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        {showCalander && (
          <Stack width={"20%"} ml={"auto"}>
            <DropDown
              show={showCalander}
              setStartDate={asComponent ? setStDate : setStartDate}
              setEndDate={asComponent ? setEnDate : setEndDate}
              startdate={asComponent ? stDate : startDate}
              endDate={asComponent ? enDate : endDate}
            />
          </Stack>
        )}
        <Stack
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <FPrimaryHeading
            fontColor={"#545454"}
            text={t("Customer.PreviousBookings")}
          />
          <Stack
            ml={"auto"}
            display={"flex"}
            flexDirection={"row"}
            gap={8}
            alignItems={"center"}
          >
            {rawDataPrevious.length > 0 &&
              rawDataPrevious.map((data, i) => (
                <Typography
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  variant="body1"
                  sx={{ fontWeight: 500, color: "#545454" }}
                >
                  <Circle fontSize="10px" sx={{ color: data.color }} />{" "}
                  {data.label}
                </Typography>
              ))}
          </Stack>
        </Stack>
      </Stack>

      <FCommonTable
        visibleColumns={[
          "date",
          "name",
          "time",
          "service",
          "employee",
          "status",
          "total",
        ]}
        columnWidths={{
          date: "10%",
          name: "15%",
          time: "10%",
          service: "35%",
          employee: "15%",
          status: "10%",
          total: "10%",
        }}
        columns={filterdColumns}
        data={asComponent ? compPrevData : dataForPreviousBookings}
        loading={asComponent ? loadingAsComp.previous : false}
        onRowClick={() => { }}
      />

      {bookingFlowModal && (
        <BookingForm
          closeForm={() => {
            setBookingFlowModal(false);
          }}
          open={bookingFlowModal}
          asComponetOFBooking={true}
          customer={customer}
          refreshBookings={refreshBookings}
        />
      )}
    </Stack>
  );
}
