import { Grid2, IconButton, Modal, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { t } from 'i18next'
import FDatePicker from '../commonComponents/F_DatePicker'
import FSwitch from '../commonComponents/f-switch'
import FPrimaryHeading from '../commonComponents/F_PrimaryHeading'
import { Close } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FButton from '../commonComponents/F_Button'
import moment from 'moment'
import CustomTimePicker from '../settings/commonTimePicker'
import { useSelector } from 'react-redux'

export default function Reschedule({ open, onClose, handleEventUpdate, scheduleChange }) {

    const setting = useSelector((state) => state.settings.data);

    useEffect(() => {
        if (scheduleChange) {
            formik.setValues({
                date: moment(scheduleChange?.data?.start),
                start_time: moment(scheduleChange?.data?.start),
                end_time: moment(scheduleChange?.data?.end),
            })
        }
    }, [scheduleChange, handleEventUpdate])

    const validationSchema = Yup.object().shape({
        date: Yup.string()
            .typeError(t('Calendar.YupErrDateReq'))
            .required(t('Calendar.YupErrDateReq')),
        start_time: Yup.string()
            .typeError(t('SpOffers.YupErrStartTimeRequired'))
            .required(t('SpOffers.YupErrStartTimeRequired')),
        end_time: Yup.string()
            .required(t('SpOffers.YupErrEndingTimeRequired'))
            .typeError(t("SpOffers.YupErrEndingTimeRequired"))
            .test('isAfterStartTime', t("Setting.YupEnTimeAftr"), function (value) {
                const { start_time } = this.parent;
                return !value || !start_time || moment(value).isAfter(moment(start_time));
            }),
        email_conf: Yup.bool(),
        sms_conf: Yup.bool(),
    })

    const formik = useFormik({
        initialValues: { date: null, start_time: null, end_time: null, email_conf: false, sms_conf: false },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let finalValues = {
                ...scheduleChange?.data,
                date: values.date.toDate(),
                start: values.start_time.toDate(),
                end: values.end_time.toDate(),
                send_email: values.email_conf,
                send_sms: values.sms_conf
            }

            handleEventUpdate(finalValues, scheduleChange?.isResizing)
            onClose()
        }
    })





    return (
        <Modal
            open={open}
            onClose={onClose}
            keepMounted
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >

            <Paper sx={{ position: "relative", maxWidth: 600, maxHeight: "80%", overflow: 'auto', display: 'flex', flexDirection: 'column', borderRadius: 8, py: 4, px: 6, minWidth: "20%", minHeight: "10%" }}>

                <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={onClose}>
                    <Close />
                </IconButton>


                <FPrimaryHeading text={t("Calendar.Reschedule")} />

                <Grid2 container spacing={2} mt={4} >

                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <FDatePicker
                            format={'DD/MM-YYYY'}
                            value={formik.values.date}
                            onChange={(value) => formik.setFieldValue('date', value)}
                            sx={{ width: "100%" }}
                        />
                        {formik?.touched?.date && formik?.errors?.date && <Typography style={{ color: 'red' }}>{formik?.errors?.date}</Typography>}
                    </Grid2>

                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Stack display={"flex"} flexDirection="row" alignItems="center" m={0} >
                            <Stack sx={{ width: "47.5%", }}>
                                <CustomTimePicker
                                    value={formik.values.start_time}
                                    onChange={(time) => formik.setFieldValue('start_time', time)}
                                    borderRadius={{ topLeft: '13px', topRight: '0px', bottomLeft: '0px', bottomRight: '13px' }}
                                    sx={{ width: '100%' }}
                                />
                            </Stack>

                            <Stack sx={{ width: "15%", display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", borderTop: '1px solid #d1d1d1', borderBottom: '1px solid #d1d1d1', height: 40 }}>
                                <Typography sx={{ width: 'full', color: '#1F1F1F' }}>
                                    {t("Common.To")}
                                </Typography>
                            </Stack>

                            <Stack sx={{ width: "47.5%" }}>
                                <CustomTimePicker
                                    value={formik.values.end_time}
                                    onChange={(time) => formik.setFieldValue('end_time', time)}
                                    borderRadius={{ topLeft: '0px', topRight: '13px', bottomLeft: '13px', bottomRight: '0px' }}
                                    sx={{ width: '100%' }}
                                />
                            </Stack>
                        </Stack>
                        {formik.errors.start_time && formik.touched.start_time && <Typography variant="body2" sx={{ color: 'red' }}>{formik.errors.start_time}</Typography>}
                        {formik.errors.end_time && formik.touched.end_time && <Typography variant="body2" sx={{ color: 'red' }}>{formik.errors.end_time}</Typography>}

                    </Grid2>

                </Grid2>

                <FPrimaryHeading sx={{ mt: 4 }} text={t("Calendar.Confirmation")} />

                <Stack width={'50%'} mt={2} gap={1}>
                    <FSwitch
                        disabled={!setting?.profile?.enable_email}
                        checked={formik.values.email_conf}
                        onChange={(e) => formik.setFieldValue('email_conf', !formik.values.email_conf)}
                        label={t("Calendar.EmailConf")}
                    />

                    <FSwitch
                        disabled={!setting?.profile?.enable_sms}
                        checked={formik.values.sms_conf}
                        onChange={(e) => formik.setFieldValue('sms_conf', !formik.values.sms_conf)}
                        label={t("Calendar.SmsConf")}
                        sx={{ width: 'auto' }}
                    />
                </Stack>

                <Stack sx={{ mt: 4, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 2 }}>


                    <FButton variant={"save"} sx={{ backgroundColor: "#d0d0d0", minWidth: '25%' }} title={t("Setting.Cancel")} onClick={onClose} />
                    <FButton variant={"save"} sx={{ minWidth: '25%' }} title={t("Calendar.Reschedule")} onClick={formik.handleSubmit} />
                </Stack>
            </Paper>
        </Modal>
    )
}
