import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FDialogTitle } from "../../components/commonComponents/F_DialogTItle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import arrowDown from "../../assets/vector2.png";
import { useState } from "react";
import { t } from "i18next";

export default function SelectServiceDialog({
  handleServiceSelect,
  showServiceModal,
  setShowServiceModal,
  closeForm,
  serviceGroups,
}) {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleToggleAccordion = (groupId) => {
    setActiveAccordion(activeAccordion === groupId ? null : groupId);
  };

  return (
    <Dialog
      open={showServiceModal}
      onClose={() => setShowServiceModal(false)}
      PaperProps={{
        style: {
          width: "45%",
          height: "72%",
          bgcolor: "background.paper",
          margin: "auto",
          borderRadius: "10px",
          marginTop: "7%",
          marginBottom: "10%",
          overflowY: "auto",
          padding: "0px 10px",
          /* Add scrollbar styling */
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "5px",
          },
        },
      }}
    >
      <DialogContent
        sx={{
          position: "relative",
        }}
      >
        <Stack sx={{}}>
          <FDialogTitle
            onClose={closeForm}
            height={3}
            onBack={() => setShowServiceModal(false)}
            sx={{ marginTop: -1 }}


          >
            {t("Calendar.ManualBooking")}
          </FDialogTitle>
        </Stack>

        <Stack sx={{ overflowY: "auto", height: "85%", mt: 1, scrollbarWidth: 'none' }}>
          <Typography
            variant="h4"
            sx={{ mb: 2, fontWeight: 700, color: "#6F6F6F" }}
          >
            {t("Calendar.ChooseTreatment")}
          </Typography>

          {serviceGroups.length > 0 ? (
            serviceGroups.map((group, index) => (
              <Box key={index} mb={2}>
                <Card
                  key={index}
                  sx={{
                    border: "3px solid #BBB0A4",
                    borderRadius: "20px",
                    height: "100%",
                  }}
                >
                  <CardHeader
                    titleTypographyProps={{ variant: "body1" }}
                    title={`${group.group === "" ? t("Calendar.ServicesWithoutGroup") : group.group}(${group.services.length} ${t("Common.Services")})`}
                    onClick={() => handleToggleAccordion(group.id)}
                    sx={{ cursor: "pointer", boxShadow: "none" }}
                    action={
                      <IconButton>
                        <img src={arrowDown} alt="Arrow" />
                      </IconButton>
                    }
                  />
                  <CardContent
                    sx={{
                      display: activeAccordion === group.id ? "block" : "none",
                      boxShadow: "none",
                    }}
                  >
                    {group.services.length > 0 ? (
                      group.services.map((service) => (
                        <Stack
                          key={service.id}
                          sx={{
                            border: "none",
                            boxShadow:
                              "0 4px 8px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.15)",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "space-between",

                            cursor: "pointer",
                            height: "auto",
                            paddingLeft: "15px",
                            mb: 2,
                            p: 1,
                            borderRadius: "10px",
                            "&:hover": { bgcolor: "grey.100" },
                          }}
                          onClick={() => handleServiceSelect(service)}
                        >
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "column",

                              width: "100%",
                              height: "auto",
                            }}
                          >
                            <Stack
                              container
                              alignItems="center"
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography variant="body1" fontWeight={600}>
                                {service.name}
                              </Typography>

                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <AccessTimeIcon
                                  fontSize="small"
                                  sx={{ mr: 1 }}
                                />
                                <Typography variant="body1">{service.duration_text}</Typography>
                              </Stack>
                            </Stack>
                            <Typography variant="caption">
                              {service.description ||
                               t("Calendar.NoDescriptionMsg")}
                            </Typography>
                          </Stack>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            sx={{ mt: 1.5 }}
                          >
                            {service.price} kr.
                          </Typography>
                        </Stack>
                      ))
                    ) : (
                      <Typography>{t("Calendar.NoServeiceMsg")}</Typography>
                    )}
                  </CardContent>
                </Card>
              </Box>
            ))
          ) : (
            <Typography>
              {t("Calendar.NoGroupMsg")}
            </Typography>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
