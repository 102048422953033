import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    ListItem, Box, Modal, TextareaAutosize, Divider, List, IconButton, Grid,
    Typography, Checkbox, FormControlLabel, Paper, ListItemText, CircularProgress,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import F_Checkbox from "../commonComponents/F_Checkbox";

import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";

import CustomTextField from '../settings/commonTextinput';
import SecondaryHeading from '../settings/commonSecondaryHeading';
import serviceNameIcon from "../../assets/nameOfServiceIcon.png";
import cameraIcon from "../../assets/cameraIcon.png";
import cashIcon from "../../assets/priceicon.png";
import CustomSearchBar from '../settings/commonSearchBar';
import ServiceTypeIcon from "../../assets/servicetypeiconNew.png";
import DescriptionIcon from "../../assets/edit.png";
import DurationIcon from "../../assets/durationClock.png";
import CustomSelect from '../settings/commonCustomSelect';
import DeleteIcon from "../../assets/DeleteIcon.png";
import AddEmployeeicon from "../../assets/whoOffersServiceIcon.png";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import PrimaryHeading from '../settings/commonPrimaryHeading';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import FSelect from '../commonComponents/F_Select';

const durationList = [
    {
        value: 5,
        label: `5 min`,
        minutes: 5,
    },
    {
        value: 10,
        label: `10 min`,
        minutes: 10,
    },
    {
        value: 15,
        label: `15 min`,
        minutes: 15,
    }, {
        value: 20,
        label: `20 min`,
        minutes: 20,
    }, {
        value: 25,
        label: `25 min`,
        minutes: 25,
    },
    {
        value: 30,
        label: `30 min`,
        minutes: 30,
    },
    {
        value: 35,
        label: `35 min`,
        minutes: 35,
    },
    {
        value: 40,
        label: `40 min`,
        minutes: 40,
    },
    {
        value: 45,
        label: `45 min`,
        minutes: 45,
    },
    {
        value: 50,
        label: `50 min`,
        minutes: 50,
    }, {
        value: 55,
        label: `55 min`,
        minutes: 55,
    },
    {
        value: 60,
        label: `1 h`,
        minutes: 60,
    },
    {
        value: 65,
        label: `1 h. 5 min`,
        minutes: 65,
    },
    {
        value: 70,
        label: `1 h. 10 min`,
        minutes: 70,
    },
    {
        value: 75,
        label: `1 h. 15 min`,
        minutes: 75,
    },
    {
        value: 80,
        label: `1 h. 20 min`,
        minutes: 80,
    },
    {
        value: 85,
        label: `1 h. 25 min`,
        minutes: 85,
    },
    {
        value: 90,
        label: `1 h. 30 min`,
        minutes: 90,
    },
    {
        value: 95,
        label: `1 h. 35 min`,
        minutes: 95,
    },
    {
        value: 100,
        label: `1 h. 40 min`,
        minutes: 100,
    },
    {
        value: 105,
        label: `1 h. 45 min`,
        minutes: 105,
    },
    {
        value: 110,
        label: `1 h. 50 min`,
        minutes: 110,
    },
    {
        value: 115,
        label: `1 h. 55 min`,
        minutes: 115,
    },
    {
        value: 120,
        label: `2 h`,
        minutes: 120,
    },
    //////////
    {
        value: 125,
        label: `2 h. 5 min`,
        minutes: 125,
    },
    {
        value: 130,
        label: `2 h. 10 min`,
        minutes: 130,
    },
    {
        value: 135,
        label: `2 h. 15 min`,
        minutes: 135,
    },
    {
        value: 140,
        label: `2 h. 20 min`,
        minutes: 140,
    },
    {
        value: 145,
        label: `2 h. 25 min`,
        minutes: 145,
    },
    {
        value: 150,
        label: `2 h. 30 min`,
        minutes: 150,
    },
    {
        value: 155,
        label: `2 h. 35 min`,
        minutes: 155,
    },
    {
        value: 160,
        label: `2 h. 40 min`,
        minutes: 160,
    },
    {
        value: 165,
        label: `2 h. 45 min`,
        minutes: 165,
    },
    {
        value: 170,
        label: `2 h. 50 min`,
        minutes: 170,
    },
    {
        value: 175,
        label: `2 h. 55 min`,
        minutes: 175,
    },
    {
        value: 180,
        label: `3 h`,
        minutes: 180,
    },
    //////////
    {
        value: 185,
        label: `3 h. 5 min`,
        minutes: 185,
    },
    {
        value: 190,
        label: `3 h. 10 min`,
        minutes: 190,
    },
    {
        value: 195,
        label: `3 h. 15 min`,
        minutes: 195,
    },
    {
        value: 200,
        label: `3 h. 20 min`,
        minutes: 200,
    },
    {
        value: 205,
        label: `3 h. 25 min`,
        minutes: 205,
    },
    {
        value: 210,
        label: `3 h. 30 min`,
        minutes: 210,
    },
    {
        value: 215,
        label: `3 h. 35 min`,
        minutes: 215,
    },
    {
        value: 220,
        label: `3 h. 40 min`,
        minutes: 220,
    },
    {
        value: 225,
        label: `3 h. 45 min`,
        minutes: 225,
    },
    {
        value: 230,
        label: `3 h. 50 min`,
        minutes: 230,
    },
    {
        value: 235,
        label: `3 h. 55 min`,
        minutes: 235,
    },
    {
        value: 240,
        label: `4 h`,
        minutes: 240,
    },
    //////////
    {
        value: 245,
        label: `4 h. 5 min`,
        minutes: 245,
    },
    {
        value: 250,
        label: `4 h. 10 min`,
        minutes: 250,
    },
    {
        value: 255,
        label: `4 h. 15 min`,
        minutes: 255,
    },
    {
        value: 260,
        label: `4 h. 20 min`,
        minutes: 260,
    },
    {
        value: 265,
        label: `4 h. 25 min`,
        minutes: 265,
    },
    {
        value: 270,
        label: `4 h. 30 min`,
        minutes: 270,
    },
    {
        value: 275,
        label: `4 h. 35 min`,
        minutes: 275,
    },
    {
        value: 280,
        label: `4 h. 40 min`,
        minutes: 280,
    },
    {
        value: 285,
        label: `4 h. 45 min`,
        minutes: 285,
    },
    {
        value: 290,
        label: `4 h. 50 min`,
        minutes: 290,
    },
    {
        value: 295,
        label: `4 h. 55 min`,
        minutes: 295,
    },
    {
        value: 300,
        label: `5 h`,
        minutes: 300,
    },
    //////////
    {
        value: 305,
        label: `5 h. 5 min`,
        minutes: 305,
    },
    {
        value: 310,
        label: `5 h. 10 min`,
        minutes: 310,
    },
    {
        value: 315,
        label: `5 h. 15 min`,
        minutes: 315,
    },
    {
        value: 320,
        label: `5 h. 20 min`,
        minutes: 320,
    },
    {
        value: 325,
        label: `5 h. 25 min`,
        minutes: 325,
    },
    {
        value: 330,
        label: `5 h. 30 min`,
        minutes: 330,
    },
    {
        value: 335,
        label: `5 h. 35 min`,
        minutes: 335,
    },
    {
        value: 340,
        label: `5 h. 40 min`,
        minutes: 340,
    },
    {
        value: 345,
        label: `5 h. 45 min`,
        minutes: 345,
    },
    {
        value: 350,
        label: `5 h. 50 min`,
        minutes: 350,
    },
    {
        value: 355,
        label: `5 h. 55 min`,
        minutes: 355,
    },
    {
        value: 360,
        label: `6 h`,
        minutes: 360,
    },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: '#FFFFFF',
    borderRadius: 3,
    boxShadow: 0,
    p: 3.5,
};


function ServiceModal(props) {
    const { open, handleClose, selectedItem, type, rooms, serviceData } = props;

    const formikRef = useRef(null);
    const user = useSelector((state) => state.user.data)

    const authTokenUser = localStorage.getItem('auth_token');

    const [isLoading, setLoading] = useState(false);
    const [selectedServiceTypeButtonsOption, setSelectedServiceTypeButtonsOption] = useState('is_new_service');
    // const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
    const [file, setFile] = useState(null);

    const [serviceTypeList, setServiceTypeList] = useState([]);
    const [serviceTypeFilterList, setServiceTypeFilterList] = useState([]);
    const [serviceTypeSearch, setServiceTypeSearch] = useState('');

    const [employeesList, setEmployeesList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    // const [selectedEmplpoyeeList, setSelectedEmplpoyeeList] = useState([]);
    // const [employeeSearch, setEmployeeSearch] = useState('');
    const [showEmployeeList, setShowEmployeeList] = useState(false)

    const [group_id, setGroup_id] = useState(null);

    const [showCetification, setShowCetification] = useState(false)



    const [initialValues, setInitialValues] = useState({
        id: selectedItem?.id ?? null,
        groupId: selectedItem?.groupId ?? 0,
        group_id: selectedItem?.group_id ?? null,
        name: type == 'create' ? '' : selectedItem?.name || '',
        selectedServiceTypeButtonsOption: 'is_new_service',
        price: type == 'create' ? '' : selectedItem?.price || '',
        selectedServiceTypes: type == 'create' ? [] : selectedItem?.service_type_ids || [],
        is_equipment_service: selectedItem?.is_equipment_service ?? false,
        description: selectedItem?.description ?? '',
        duration_min: selectedItem?.duration_min ?? 5,
        duration_text: selectedItem?.duration_text ?? '',
        is_price_starts_with: selectedItem?.is_price_starts_with ?? false,
        price: selectedItem?.price ?? null,
        no_of_equipments: selectedItem?.no_of_equipments ?? null,
        available_to_multiple_clients: selectedItem?.no_of_equipments ? (selectedItem?.no_of_equipments > 1 ? true : false) : false,
        selectedEmplpoyeeList: [],
        // disableBtn: false,
        is_certified: false,
        room_id: selectedItem?.room_id ?? null,

        is_addon: selectedItem?.is_addon ?? false,
        main_service_ids: selectedItem?.main_service_ids ? selectedItem?.main_service_ids : [],
        can_book_alone: selectedItem?.can_book_alone ?? false
    })

    const serviceTypeButtonsOption = [
        { id: 'is_new_service', name: t("Services.NewService") },
        { id: 'is_bundle_offer', name: t("Services.BundleOffer") },
        { id: 'is_consultation', name: t("Services.Consultation") },
        { id: 'is_offer_to_new_clients', name: t("Services.OfferToNewClients") },
        { id: 'is_follow_up_treatment', name: t("Services.FollowUPTreat") },
        { id: 'is_offer_to_regular_clients', name: t("Services.OfferToRegClient") },
    ];

    // let validationSchema = Yup.object({
    //     name: Yup.string()
    //         .required('Service name is required')
    //         .min(3, 'Service name must be at least 3 characters long')
    //         .max(50, 'Service name must be less than 50 characters'),
    //     price: Yup.number()
    //         .typeError('Price must be a valid number')
    //         .required('Price is required')
    //         .positive('Price must be a positive number')
    //         .min(0, 'Price must be a valid number'),
    //     selectedServiceTypes: Yup.array()
    //         .min(1, 'At least one service type must be selected')
    //         .required('Service type selection is required'),

    //     is_equipment_service: Yup.boolean().nullable(),
    //     available_to_multiple_clients: Yup.boolean().typeError('invalid is equipment service').nullable(),
    //     no_of_equipments: Yup.number().when('available_to_multiple_clients', {
    //         is: true,
    //         then: Yup.number().required('Please enter machine count').min(1, 'Machine count should be at least 1').typeError('Please enter machine count')
    //     }).nullable(),

    //     selectedEmplpoyeeList: Yup.array().when('is_equipment_service', {
    //         is: false,
    //         then: Yup.array().min(1, 'Employee selection is required')
    //             .required('Employee selection is required')
    //     }),

    //     // .min(1, 'Enter price to every employee')
    //     // .required('Employee selection is required')


    // });

    let validationSchema = Yup.object({
        name: Yup.string()
            .required(t('Services.YupErrNameRequired'))
            .min(3, t('Services.YupErrNameMin'))
            .max(100, t('Services.YupErrNameMax')),
        price: Yup.number()
            .typeError(t('Services.YupErrPriceTypeError'))
            .required(t('Services.YupErrPriceRequired'))
            .positive(t('Services.YupErrPricePositive'))
            .min(0, t('Services.YupErrPriceMin')),
        selectedServiceTypes: Yup.array()
            .min(1, t('Services.YupErrSelectedServiceTypesMin'))
            .required(t('Services.YupErrSelectedServiceTypesRequired')),

        is_equipment_service: Yup.boolean().nullable(),
        available_to_multiple_clients: Yup.boolean().typeError(t('Services.YupErrAvailableToMultipleClientsTypeError')).nullable(),
        no_of_equipments: Yup.number().when('available_to_multiple_clients', {
            is: true,
            then: Yup.number().required(t('Services.YupErrNoOfEquipmentsRequired'))
                .min(1, t('Services.YupErrNoOfEquipmentsMin'))
                .typeError(t('Services.YupErrNoOfEquipmentsTypeError'))
        }).nullable(),

        selectedEmplpoyeeList: Yup.array().when('is_equipment_service', {
            is: false,
            then: Yup.array().min(1, t('Services.YupErrSelectedEmployeeListMin'))
                .required(t('Services.YupErrSelectedEmployeeListRequired'))
        }),

        main_service_ids: Yup.array().when('is_addon', {
            is: true,
            then: Yup.array().min(1, t('Services.YupErrSelectedAddOnServiceMin'))
                .required(t('Services.YupErrSelectedAddOnServiceRequired'))
        }),

        // room_id: Yup.number().typeError().nullable().notRequired(),
    });


    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: values => {
            onSavePress(values)

        },
    });


    // useEffect(() => {

    //     if (formik?.values?.selectedServiceTypes?.length > 0) {
    //         const

    //         if(needCertification){

    //             formik.setFieldValue('is_certified', false)

    //             setShowCetification(true);
    //         }else{
    //             formik.setFieldValue('is_certified', false)
    //             setShowCetification(false);
    //         }
    //     } else {
    //         formik.setFieldValue('is_certified', false)
    //         setShowCetification(false);
    //         // setShowCetification(false);
    //     }

    // }, [formik?.values?.selectedServiceTypes]);


    useEffect(() => {




        if (selectedItem && type === 'edit') {
            // setGroup_id(selectedItem?.group_id || null);
            // setSelectedServiceTypes(selectedItem?.service_type_ids || []);
            // setServiceDesc(selectedItem?.description || '');
            // const matchedDuration = durationList.find(duration => duration.minutes === selectedItem?.duration_min);
            // setDuration(matchedDuration?.value || null);
            // setIsPriceStartFrom(selectedItem?.is_price_starts_with || false);

            if (selectedItem?.image) {
                const fetchImage = async () => {
                    try {
                        const response = await fetch(selectedItem.image);
                        const blob = await response.blob();
                        const fileFromImage = new File([blob], 'reuploaded-image.png', { type: blob.type });
                        setFile(fileFromImage);
                    } catch (error) {
                        console.error('Error fetching the image:', error);
                    }
                };
                fetchImage();
            } else {
                setFile(null);
            };

            // setisMachine(selectedItem?.is_equipment_service || false);
            // setHowManyMachine(selectedItem?.no_of_equipments.toString() || '');
            // setIsChecked(selectedItem?.is_consultation || false);
            formik.setValues(selectedItem)

            if (selectedItem?.is_bundle_offer) {
                setSelectedServiceTypeButtonsOption('is_bundle_offer');
                formik.setFieldValue('selectedServiceTypeButtonsOption', 'is_bundle_offer')
            }

            if (selectedItem?.is_new_service) {
                formik.setFieldValue('selectedServiceTypeButtonsOption', 'is_new_service');
            }

            if (selectedItem?.is_consultation) {
                formik.setFieldValue('selectedServiceTypeButtonsOption', 'is_consultation');
            }

            if (selectedItem?.is_offer_to_new_clients) {
                formik.setFieldValue('selectedServiceTypeButtonsOption', 'is_offer_to_new_clients');
            }

            if (selectedItem?.is_offer_to_regular_clients) {
                formik.setFieldValue('selectedServiceTypeButtonsOption', 'is_offer_to_regular_clients');
            }

            if (selectedItem?.is_offer_to_new_clients) {
                formik.setFieldValue('selectedServiceTypeButtonsOption', 'is_offer_to_new_clients');
            }

            // compair emp sequece form main api and from props and sort it 

            formik.setFieldValue('selectedEmplpoyeeList', selectedItem?.employees || [])
            formik.setFieldValue('selectedServiceTypes', selectedItem?.service_type_ids || [])

            if (selectedItem?.no_of_equipments > 1 && selectedItem?.is_equipment_service) {
                formik.setFieldValue('available_to_multiple_clients', true)
            }

            if (selectedItem?.no_of_equipments == 1 && selectedItem?.is_equipment_service) {
                formik.setFieldValue('no_of_equipments', null)
            }




            if (selectedItem?.is_certified) {
                // formik.setFieldValue('is_certified', is_certified)
                setShowCetification(selectedItem?.is_certified)
            }

            if (selectedItem?.room_id) {
                formik.setFieldValue('room_id', selectedItem?.room_id)
            }
            

            // if(serviceTypeList.length && (selectedItem && type === 'edit')){



            //     let serviceTypeObjList = []
            //     serviceTypeList.map((serviceTypeObj)=>{
            //         if(selectedItem.service_type_ids.includes(serviceTypeObj?.id)){
            //             serviceTypeObjList.push(serviceTypeObj)
            //         }

            //     })


            //     formik.setFieldValue('selectedServiceTypes', serviceTypeObjList)
            // }


        }
    }, [selectedItem]);





    useEffect(() => {
        fetchCategoryApi();
    }, []);

    async function fetchCategoryApi() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/service/service_types`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response && response.data && response.data.data) {
                const arr = response.data.data.map(element => ({
                    ...element,
                    value: element.id,
                    label: element.service_type,
                }));

                setServiceTypeList(arr);
                setServiceTypeFilterList(arr);

                const itemEquipment = arr.find((obj) => obj.id === selectedItem?.service_type_id);
                if (itemEquipment && itemEquipment.is_equipment_based) {
                    formik.setFieldValue('is_equipment_service', true)
                    // setisMachineVisible(true);
                    // setisMachine(true);
                } else {
                    formik.setFieldValue('is_equipment_service', false)
                    // setisMachineVisible(false);
                    // setisMachine(false);
                }
            } else {
                setServiceTypeList([]);
                setServiceTypeFilterList([]);
            }

            fetchEmployees();
        } catch (error) {
            console.error("Error fetching service type data:", error);
            setServiceTypeList([]);
            setServiceTypeFilterList([]);
        } finally {
            setLoading(false);
        }
    }

    async function fetchEmployees() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/employee/get`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response) {
                setEmployeesList(response.data.data);
                setFilterList(response.data.data);

                if (type == 'edit' && selectedItem && selectedItem.employees.length > 0) {
                    var arr = [];
                    for (let i = 0; i < response?.data.data.length; i++) {
                        const e = response?.data.data[i];
                        for (
                            let j = 0;
                            j < selectedItem.employees.length;
                            j++
                        ) {
                            const element = selectedItem.employees[j];
                            if (e.id == element.id)
                                arr.push({ ...e, price: element.price });
                        }
                    }
                    // setSelectedEmplpoyeeList(arr);
                    // formik.setFieldValue('selectedEmplpoyeeList', arr)
                    // if (formikRef.current) {
                    //     formikRef.current.setFieldValue('selectedEmplpoyeeList', arr);
                    // }
                };

            } else {
                setServiceTypeList([]);
                setServiceTypeFilterList([]);
            }
        } catch (error) {
            console.error("Error fetching employee data:", error);
            setFilterList([]);
            setEmployeesList([]);
        } finally {
            setLoading(false);
        }
    }

    const handleRowClick = (name) => {
        // setSelectedServiceTypeButtonsOption(name);
        formik.setFieldValue('selectedServiceTypeButtonsOption', name)
    };

    // const handleToggle = (service) => {
    //     setSelectedServiceTypes((prev) => {
    //         const isAlreadySelected = prev.some((el) => el.id === service.id) || prev.includes(service.id);
    //         let updatedList;

    //         if (isAlreadySelected) {
    //             updatedList = prev.filter((el) => el.id !== service.id && el !== service.id);
    //         } else {
    //             updatedList = [...prev, service];
    //         }

    //         setIsGovtApproved(
    //             updatedList.some((selectedItem) => selectedItem.need_certification === true)
    //         );

    //         const allSelectedRequireEquipment = updatedList.length > 0 && updatedList.every((selectedItem) => selectedItem.is_equipment_based === true);
    //         setisMachineVisible(allSelectedRequireEquipment);

    //         return updatedList;
    //     });
    // };

    // const handleToggle = (service) => {

    //     const isAlreadySelected = formik.values.selectedServiceTypes.some((el) => el.id === service.id) || formik.values.selectedServiceTypes.includes(service.id);

    //     let updatedList;

    //     if (isAlreadySelected) {
    //         updatedList = formik.values.selectedServiceTypes.filter((el) => el.id !== service.id && el !== service.id);
    //     } else {
    //         updatedList = [...formik.values.selectedServiceTypes, service];
    //     }

    //     formik.setFieldValue('selectedServiceTypes', updatedList);

    //     // const need_certification = updatedList.some((selectedItem) => selectedItem.need_certification);


    //     const allSelectedRequireEquipment = updatedList.length > 0 && updatedList.every((selectedItem) => selectedItem.is_equipment_based === true);



    //    const  needCertification = updatedList.some((item) => item.need_certification);
    //     setShowCetification(needCertification)


    //     // setisMachineVisible(allSelectedRequireEquipment);
    //     // formik.setFieldValue('need_certification', need_certification)
    //     formik.setFieldValue('is_equipment_service', allSelectedRequireEquipment)
    //     // formik.setFieldValue('disableBtn', true)
    // };


    const handleToggle = (service) => {

        const isAlreadySelected = formik.values.selectedServiceTypes.includes(service.id);

        let updatedList;

        if (isAlreadySelected) {
            updatedList = formik.values.selectedServiceTypes.filter((el) => el !== service.id);
        } else {
            updatedList = [...formik.values.selectedServiceTypes, service.id];
        }


        formik.setFieldValue('selectedServiceTypes', updatedList);

        // const need_certification = updatedList.some((selectedItem) => selectedItem.need_certification);


        // const allSelectedRequireEquipment = updatedList.length > 0 && updatedList.every((selectedItem) => selectedItem.is_equipment_based === true);



        //    const  needCertification = updatedList.some((item) => item.need_certification);
        //     setShowCetification(needCertification)



        // formik.setFieldValue('is_equipment_service', allSelectedRequireEquipment)
    };



    useEffect(() => {

        let updatedList = []
        serviceTypeList.map((serviceTypeObj) => {
            if (formik.values.selectedServiceTypes.includes(serviceTypeObj?.id)) {
                updatedList.push(serviceTypeObj)
            }
        })


        // const need_certification = updatedList.some((selectedItem) => selectedItem.need_certification);


        const allSelectedRequireEquipment = updatedList.length > 0 && updatedList.every((selectedItem) => selectedItem.is_equipment_based === true);



        const needCertification = updatedList.some((item) => item.need_certification);
        setShowCetification(needCertification)



        formik.setFieldValue('is_equipment_service', allSelectedRequireEquipment)

    }, [formik.values.selectedServiceTypes, serviceTypeList])



    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            setFile(uploadedFile);
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
    };

    const updateServiceTypeSearch = useCallback((text) => {
        if (text) {
            const searchTerm = text.trim().toUpperCase();
            const newData = serviceTypeList.filter((item) => {
                const itemLabel = item.label ? item.label.trim().toUpperCase() : '';
                return itemLabel.includes(searchTerm);
            });
            setServiceTypeFilterList(newData);
        } else {
            setServiceTypeFilterList(serviceTypeList);
        }
        setServiceTypeSearch(text);
    }, [serviceTypeList]);



    // const handleChangePriceStartFrom = (event) => {
    //     setIsPriceStartFrom(event.target.checked);
    // };

    function addEmployee(item) {

        let employeesListData = [...formik.values.selectedEmplpoyeeList]
        if (employeesListData.some((el) => el.id == item.id)) {
            const index = employeesListData.findIndex(
                (e) => e.id == item.id,
            );
            if (index !== undefined) employeesListData.splice(index, 1);
        } else {


            employeesListData.push({
                ...item,
                price: formik.values.price != '' ? formik.values.price : '',
            });
        }

        formik.setFieldValue('selectedEmplpoyeeList', employeesListData)
        // setSelectedEmplpoyeeList((prev) => [...prev]);
    };


    const handlePriceChange = (id, value) => {
        formik.setFieldValue('selectedEmplpoyeeList',
            formik.values.selectedEmplpoyeeList.map((employee) =>
                employee.id === id ? { ...employee, price: value } : employee
            )
        );
    };




    const onSavePress = async (values) => {


        let selectedServiceType = {
            'is_new_service': false,
            'is_bundle_offer': false,
            'is_consultation': false,
            'is_offer_to_new_clients': false,
            'is_follow_up_treatment': false,
            'is_offer_to_regular_clients': false
        }
        selectedServiceType[selectedServiceTypeButtonsOption] = true
        setLoading(true);

        const formData = new FormData();


        var objDuration = null;
        try {
            objDuration = durationList.find(item => item.value === values.duration_min);
        } catch (e) {
            objDuration = null;
        };




        var serviceTypesId = [];
        if (values.selectedServiceTypes.length > 0) {

            serviceTypesId = values.selectedServiceTypes.map((item) =>
                typeof item === 'object' ? item.id : item
            );
        };



        var employeeListForSubmit = [];

        if (formik.values.selectedEmplpoyeeList.length > 0 && formik.values.selectedEmplpoyeeList.some((el) => el.price === '' || !el.price)) {
            formik.setErrors({ selectedEmplpoyeeList: t("Services.YupErrEnterAll") });
            setLoading(false);
            return
        } else if (formik.values.selectedEmplpoyeeList.length > 0) {
            employeeListForSubmit = formik.values.selectedEmplpoyeeList.map(({ id, price }) => ({
                employee_id: id,
                price,
            }));
        };



        const sendData = {
            name: values.name,
            group_id: values.group_id,
            description: values.description,
            duration_text: objDuration.label,
            duration_min: objDuration.minutes,
            price: values.price,
            is_equipment_service: values.is_equipment_service,
            no_of_equipments: values?.available_to_multiple_clients ? values?.no_of_equipments : 1,
            is_special: false,
            special_price: 0,
            service_type_id: typeof values.selectedServiceTypes[0] === 'object' ? values.selectedServiceTypes[0].id : values.selectedServiceTypes[0],
            service_type_ids: serviceTypesId,
            employees: !values.is_equipment_service ? employeeListForSubmit : [],
            group: 'abc',
            is_price_starts_with: values.is_price_starts_with,
            is_certified: values?.is_certified,
            room_id: values?.room_id === 'default' ? null : values?.room_id,
            is_addon: values?.is_addon,
            main_service_ids: values?.main_service_ids,
            can_book_alone: values?.can_book_alone,
            // is_consultation: isChecked,
            // is_bundle_offer: isCheckedBundelOffer,
            ...selectedServiceType

        };

        if (selectedItem) {
            sendData.remove_image = file === null ? true : false;
        };

        formData.append('req_body', JSON.stringify(sendData));

        if (file) {
            formData.append('image', file);
        };



        let API = null;
        let METHOD = '';
        if (selectedItem) {
            API = `${process.env.REACT_APP_URL}/api/v1/store/service/${selectedItem?.id}`;
            METHOD = 'PATCH';
        } else {
            API = `${process.env.REACT_APP_URL}/api/v1/store/service`;
            METHOD = 'POST'
        }

        try {
            const response = await axios({
                method: METHOD,
                url: API,
                headers: {
                    Authorization: `Bearer ${authTokenUser}`,
                },
                data: formData,
            });

            if (response) {
                if (type == 'create') {
                    toast.success(t("Services.ToastSuccessServ"));
                } else {
                    toast.success(t("Services.ToastUSuccessServ"));
                };

                if (formikRef.current) {
                    formikRef.current.resetForm()
                };
                resetFields('Callapi');
                // setTimeout(() => {
                //     handleClose();
                //     if (formikRef.current) {
                //         formikRef.current.resetForm()
                //     };
                //     resetFields('Callapi');
                // }, 2000);
            } 
        } catch (error) {
            setLoading(false);
            console.error('Error during API call:', error);
        }
    };

    const resetFields = (command) => {
        // setGroup_id(null);
        // // setSelectedServiceTypes([]);
        // setServiceDesc('');
        // // setDuration(null);
        // setIsPriceStartFrom(false);
        // setFile(null);
        // // setSelectedEmplpoyeeList([]);
        // setisMachine(false);
        // setHowManyMachine('');
        // setIsChecked(false);
        // setIsCheckedBundelOffer(false);
        // setLoading(false);

        formik.resetForm()
        handleClose(command);
    };


    const handleCloseModal = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
    };

    const handleNoOfPeople = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        // setHowManyMachine(value)
        formik.setFieldValue('no_of_equipments', value)
    };



    useEffect(() => {


    }, [formik.errors])

    let roomOption = [
        { value: 'default', label: t("Services.NoRoom") },
        ...rooms.map((room) => ({
            value: room.id,
            label: room.name
        }))
    ];


    const handleAddonService = (e) => {
        const selectedValues = e.target.value;
        let allID = serviceData?.map((data) => data.value);

        if ((selectedValues === 0 || selectedValues.includes(0)) && allID.length === formik?.values?.main_service_ids?.length) {
            formik.setFieldValue('main_service_ids', []);
        }
        else if (selectedValues === 0 || selectedValues.includes(0)) {
            formik.setFieldValue('main_service_ids', allID)
        }
        else if (selectedValues.length === 0) {
            formik.setFieldValue('main_service_ids', []);
        }
        else {
            formik.setFieldValue('main_service_ids', selectedValues);
        }
    };



    return (

        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{ scrollbarWidth: 'none' }}
            PaperProps={{ sx: { borderRadius: "25px" } }}
        >
            <DialogTitle id="scroll-dialog-title"></DialogTitle>
            <DialogContent sx={{ scrollbarWidth: 'none' }}>



                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => resetFields()}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '30px',
                            zIndex: 10,
                            color: 'black'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>


                <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row', marginTop: '20px' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', width: '48%' }} rowGap={0}>
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', }}>
                            <img
                                src={serviceNameIcon}
                                style={{ width: 22, height: 22, marginRight: 10 }}
                                alt=""
                            />

                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.SrvName")}</Typography>
                        </Box>
                        <CustomTextField
                            id="name"
                            name="name"
                            value={formik.values.name}
                            mt={1}
                            onChange={formik.handleChange}
                            placeholder={t("Services.PlaceHLDSrv")}
                            onBlur={formikRef.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <div style={{ color: 'red', fontSize: '12px' }}>
                                {formik.errors.name}
                            </div>
                        )}

                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 20 }}>
                            <img
                                src={ServiceTypeIcon}
                                style={{ width: 20, height: 20, marginRight: 10 }}
                                alt=""
                            />

                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.SrvType")}</Typography>

                        </Box>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1, width: '100%', justifyContent: 'space-between' }}>
                            {serviceTypeButtonsOption.slice(0, 6).map(item => (
                                <Paper
                                    key={item.id}
                                    sx={{
                                        cursor: 'pointer',
                                        padding: 1,
                                        borderRadius: '12px',
                                        height: 30,
                                        width: '50%',
                                        backgroundColor: formik.values.selectedServiceTypeButtonsOption === item.id ? '#44B904' : 'white',
                                        color: formik.values.selectedServiceTypeButtonsOption === item.id ? 'white' : 'black',
                                        flex: '1 1 calc(50% - 10px)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: formik.values.selectedServiceTypeButtonsOption === item.id ? `1px solid #44B904` : `1px solid #A79C92`,
                                    }}
                                    elevation={0}
                                    onClick={() => handleRowClick(item.id)}
                                >
                                    <Typography variant="body1" textAlign="center"
                                        sx={{
                                            fontWeight: 500, fontSize: 13, color: formik.values.selectedServiceTypeButtonsOption === item.id ? 'white' : '#A79C92',
                                            lineHeight: 1.2,
                                        }}>
                                        {item.name}
                                    </Typography>
                                </Paper>
                            ))}
                        </Box>

                        <Stack>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 20 }}>
                                <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.SelectRoom")}</Typography>
                            </Box>
                            <FSelect
                                value={formik.values.room_id === null ? 'default' : formik.values.room_id}
                                onChange={formik.handleChange}
                                defaultValue={'default'}
                                name="room_id"
                                placeholderText={t("Services.SelectRoom")}
                                options={roomOption}
                                disabledDefaultValue={false}
                                showPlaceHolder
                            />
                            {formik.touched.room_id && formik.errors.room_id && (
                                <div style={{ color: 'red', fontSize: '12px' }}>
                                    {formik.errors.room_id}
                                </div>
                            )}
                        </Stack>

                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 20 }}>
                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.SrvTreatment")}</Typography>


                        </Box>

                        <Box style={{
                            display: 'flex', flexDirection: "column", overflowY: 'auto',
                            justifyContent: "flex-start", alignItems: 'center', width: '100%', height: '320px',
                            marginTop: 10, borderRadius: '12px', border: '1px solid #A79C92',
                        }}>

                            <Box
                                style={{
                                    display: 'flex',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                    backgroundColor: 'white',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <CustomSearchBar
                                    width={'95%'}
                                    height={40}
                                    value={serviceTypeSearch}
                                    onChange={(e) => updateServiceTypeSearch(e.target.value)}
                                />
                            </Box>

                            <List
                                style={{
                                    width: '100%',
                                    overflowY: 'scroll',
                                    scrollbarWidth: 'none',
                                }}
                            >
                                {serviceTypeFilterList.map((service) => (
                                    <React.Fragment key={service.id} >
                                        <ListItem style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleToggle(service)}>

                                            <Typography sx={{ color: '#545454', fontWeight: 400, fontSize: '15px' }}>
                                                {service.label}
                                            </Typography>


                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disableRipple
                                                        // checked={formik.values.selectedServiceTypes.some((selectedItem) => {
                                                        //     if (typeof selectedItem === 'object' && selectedItem !== null) {
                                                        //         return selectedItem.id === service.id;
                                                        //     }
                                                        //     return selectedItem === service.id;
                                                        // })}

                                                        checked={formik.values.selectedServiceTypes.some((selectedItem) => {
                                                            if (typeof selectedItem === 'object' && selectedItem !== null) {
                                                                return selectedItem.id === service.id;
                                                            }
                                                            return selectedItem === service.id;
                                                        })}

                                                        onChange={() => handleToggle(service)}
                                                        sx={{
                                                            '&.Mui-checked .MuiSvgIcon-root': {
                                                                backgroundColor: 'transparent',
                                                                borderColor: '#A79C92',
                                                                color: 'green',
                                                            },
                                                            mt: 0, mb: 0
                                                        }}
                                                    />
                                                }
                                                label=""
                                            />
                                        </ListItem>
                                        <Divider sx={{ borderColor: '#A79C92', margin: '3px 0', borderBottomWidth: 1, marginLeft: 2, marginRight: 2 }} />
                                    </React.Fragment>
                                ))}
                            </List>

                        </Box>

                        {formik.touched.selectedServiceTypes && formik.errors.selectedServiceTypes && (
                            <div style={{ color: 'red', fontSize: '12px' }}>
                                {formik.errors.selectedServiceTypes}
                            </div>
                        )}

                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'column', width: '48%', justifyContent: 'flex-start', alignItems: 'flex-start' }} sx={{ mt: 0, pt: 0 }} rowGap={0}>

                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center' }}>
                            <img
                                src={DescriptionIcon}
                                style={{ width: 20, height: 20, marginRight: 10 }}
                                alt=""
                            />
                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.SrvDesc")} {`(${t("Common.Optional")})`}</Typography>

                        </Box>
                        <TextareaAutosize
                            id='description'
                            minRows={3}
                            maxRows={6}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            style={{
                                width: '100%',
                                padding: '10px',
                                fontSize: '16px',
                                borderRadius: '10px',
                                border: '1px solid #A79C92',
                                outline: 'none',
                                resize: 'none',
                                marginTop: 5
                            }}

                            maxLength={1000}

                        />
                        <Typography variant="caption" sx={{ color: '#6F6F6F', width: '100%', textAlign: 'right' }}> {formik.values.description.length}/1000</Typography>

                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 1 }}>
                            <img
                                src={DurationIcon}
                                style={{ width: 20, height: 20, marginRight: 10 }}
                                alt=""
                            />

                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Common.Duration")}</Typography>

                        </Box>
                        <CustomSelect
                            id='duration_min'
                            value={formik.values.duration_min}
                            onChange={e => { formik.setFieldValue('duration_min', e.target.value) }}
                            options={durationList}
                            sx={{ width: '100%', mt: 0.5, height: 40 }}
                            borderColor="#A79C92"
                            borderThickness="1px"
                            noLabel
                        />

                        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%', mt: 3, mb: 0 }}>
                            <Stack flex={0.9} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                <img
                                    src={cashIcon}
                                    style={{ width: 20, height: 20, marginRight: 10 }}
                                    alt=""
                                />

                                <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Common.Price")} (kr.)</Typography>
                            </Stack>

                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} onClick={() => formik.setFieldValue('is_price_starts_with', !formik.values.is_price_starts_with)} sx={{ cursor: 'pointer' }}>
                                <Checkbox
                                    checked={formik.values.is_price_starts_with}
                                    onChange={formik.handleChange}
                                    sx={{

                                        '&.Mui-checked .MuiSvgIcon-root': {
                                            backgroundColor: 'transparent',
                                            borderColor: '#A79C92',
                                            color: 'green',
                                        },
                                        p: 0

                                    }}
                                />

                                <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.StartingFr")}</Typography>

                            </Stack>
                        </Stack>
                        <CustomTextField
                            id='price'
                            name="price"
                            type="text"
                            placeholder={t("Services.EnterPrice")}
                            inputMode="decimal"
                            onKeyPress={(e) => {
                                const regex = /^[0-9]*\.?[0-9]*$/;
                                if (!regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                const newPrice = e.target.value;
                                formik.setFieldValue('price', newPrice);
                                // setStandardPrice(newPrice);
                            }}
                            value={formik.values.price}

                        />
                        {formik.touched.price && formik.errors.price && (
                            <div style={{ color: 'red', fontSize: '12px' }}>
                                {formik.errors.price}
                            </div>
                        )}


                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 15 }}>
                            <img
                                src={cameraIcon}
                                style={{ width: 20, height: 20, marginRight: 10 }}
                                alt=""
                            />
                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.SrvPic")}{`(${t("Common.Optional")})`}</Typography>

                        </Box>

                        {!file && (
                            <label
                                htmlFor="file-upload"
                                style={{
                                    display: 'inline-block',
                                    padding: '10px',
                                    border: '1px solid #6F6F6F',
                                    width: '137px',
                                    height: 40,
                                    borderRadius: '12px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#f5f5f5',
                                    cursor: 'pointer',
                                    transition: 'border-color 0.3s',
                                    marginTop: '6px'
                                }}
                            >
                                <span style={{ fontWeight: 400, fontSize: '15px', color: '#6F6F6F' }}>{t("Services.SrvUpl")}</span>

                                <input
                                    type="file"
                                    accept="image/*"
                                    id="file-upload"
                                    onChange={handleFileChange}
                                    style={{
                                        display: 'none',
                                    }}
                                />
                            </label>
                        )}

                        {file && (
                            <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
                                <>
                                    <Paper
                                        elevation={0}
                                        style={{
                                            width: '137px',
                                            height: 40,
                                            borderRadius: '12px',
                                            border: '1px solid #367B3D',
                                            opacity: 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '0 10px',
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        <span style={{ fontWeight: 400, fontSize: '15px', color: '#367B3D' }}>{t("Common.Uploaded")}!</span>
                                    </Paper>

                                    <IconButton onClick={handleRemoveFile} style={{ padding: 0 }} disableRipple>
                                        <img
                                            src={DeleteIcon}
                                            alt="Delete"
                                            style={{ width: '17px', height: '19px', marginLeft: 20 }}
                                        />
                                    </IconButton>
                                </>
                            </Stack>
                        )}

                        {formik.values.is_equipment_service && (
                            <>
                                <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '22px', paddingLeft: 0, marginLeft: 0, cursor: 'pointer' }} onClick={() => formik.setFieldValue('available_to_multiple_clients', !formik.values.available_to_multiple_clients)}>
                                    <Checkbox
                                        disableRipple
                                        checked={formik.values.available_to_multiple_clients}
                                        onChange={() => formik.setFieldValue('available_to_multiple_clients', !formik.values.available_to_multiple_clients)}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                border: '0.1px solid #A79C92',
                                                borderRadius: '4px',
                                            },
                                            '&.Mui-checked .MuiSvgIcon-root': {
                                                backgroundColor: 'transparent',
                                                borderColor: '#A79C92',
                                                color: 'green',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                            paddingLeft: 0
                                        }}
                                    />

                                    <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.SrvAtMulti")}</Typography>
                                </Stack>


                                {!formik.values.available_to_multiple_clients && (
                                    <div style={{ marginTop: '10px' }}>
                                        <SecondaryHeading fontColor='#6F6F6F' size={'15px'} text={t("Services.SrvAtMultiDes")} />
                                    </div>
                                )}

                                {formik.values.available_to_multiple_clients && (
                                    <div style={{ marginTop: '10px', width: '100%' }}>
                                        <CustomTextField
                                            id='no_of_equipments'
                                            value={formik.values.no_of_equipments}
                                            onChange={(event) => handleNoOfPeople(event)}
                                            placeholder={"Number of clients"}
                                        />
                                        {formik.touched.no_of_equipments && formik.errors.no_of_equipments && (
                                            <div style={{ color: 'red', fontSize: '12px' }}>
                                                {formik.errors.no_of_equipments}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}


                        {showCetification && (
                            <Stack>
                                <Stack onClick={() => formik.setFieldValue('is_certified', !formik.values.is_certified)} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '22px', paddingLeft: 0, marginLeft: 0, cursor: 'pointer' }} >
                                    <Checkbox
                                        disableRipple
                                        checked={formik.values.is_certified}
                                        sx={{
                                            // '& .MuiSvgIcon-root': {
                                            //     border: '0.1px solid #A79C92',
                                            //     borderRadius: '4px',
                                            // },
                                            '&.Mui-checked .MuiSvgIcon-root': {
                                                backgroundColor: 'transparent',
                                                borderColor: '#A79C92',
                                                color: 'green',
                                            },
                                            // '&:hover': {
                                            //     backgroundColor: 'transparent',
                                            // },
                                            paddingLeft: 0
                                        }}
                                    />

                                    <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.SrvGov")}</Typography>
                                </Stack>

                                {formik.touched.is_certified && formik.errors.is_certified && (
                                    <Typography style={{ color: 'red', fontSize: '12px' }}>
                                        {formik.errors.is_certified}
                                    </Typography>
                                )}
                            </Stack>
                        )}

                        {!formik.values.is_equipment_service && (
                            <>
                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 15 }}>

                                    <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.SrvWho")}?</Typography>
                                </Box>

                                <Box
                                    style={{
                                        display: 'flex', flexDirection: "column", scrollbarWidth: 'none',
                                        justifyContent: "flex-start", alignItems: 'center', width: '100%',
                                        marginTop: '10px', borderRadius: '12px', boxShadow: '0px 4px 55px 0px #0000001A',
                                        paddingBottom: 10
                                    }}>

                                    <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>

                                        <Box style={{ display: 'flex', justifyContent: 'flex-start', width: '15%', height: '155px', marginRight: 8 }}>
                                            <img
                                                src={AddEmployeeicon}
                                                style={{ width: 35, height: 35, margin: 20, cursor: 'pointer' }}
                                                alt=""
                                                onClick={() => setShowEmployeeList(true)}
                                            />
                                        </Box>

                                        <Stack flex={1} flexDirection={'row'} justifyContent={'center'} alignItems={'flex-start'} >
                                            <Grid container spacing={0}>
                                                {formik.values.selectedEmplpoyeeList.map((employee) => (
                                                    <Grid item xs={12} sm={4} key={employee.id} sx={{ mt: 1 }}>
                                                        <Stack

                                                            flex={1} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'}
                                                            sx={{ m: 0, p: 0 }}

                                                        >

                                                            <Typography variant="caption" sx={{
                                                                color: '#1f1f1f', width: '100%', textAlign: 'center', fontSize: 14, fontWeight: 600, overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box",
                                                                WebkitLineClamp: "2",
                                                                WebkitBoxOrient: "vertical", lineHeight: 1.3
                                                            }}>
                                                                {employee.name}
                                                            </Typography>

                                                            <CustomTextField
                                                                value={employee.price}
                                                                onChange={(e) => handlePriceChange(employee.id, e.target.value)}
                                                                width={'80%'}
                                                                height={40}
                                                                onKeyPress={(e) => {
                                                                    const regex = /^[0-9]*\.?[0-9]*$/;
                                                                    if (!regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                lineHeight={0.2}
                                                                mt={0.4}
                                                                pt={0}
                                                                // placeholder={'Price'}
                                                                placeholderFontSize={14}
                                                                inputFontSize={14}
                                                            // sx={{ pl:1,pt:0 }}

                                                            />
                                                        </Stack>
                                                    </Grid>
                                                ))}
                                            </Grid>

                                        </Stack>

                                    </Box>

                                </Box>

                                {formik.touched.selectedEmplpoyeeList && formik.errors.selectedEmplpoyeeList && (
                                    <div style={{ color: 'red', fontSize: '12px' }}>
                                        {formik.errors.selectedEmplpoyeeList}
                                    </div>
                                )}


                            </>
                        )}




                        <Stack display={'flex'} flexDirection={'row'} mt={2} width={'100%'} alignItems={'center'}>
                            <Stack sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>
                                <Stack onClick={() => formik.setFieldValue('is_addon', !formik.values.is_addon)} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 0, marginLeft: 0, cursor: 'pointer' }} >
                                    <F_Checkbox checked={formik.values.is_addon} />
                                    <Typography variant="body1" sx={{ color: '#6F6F6F', mr: 1 }}> {t("Services.AddOn")}</Typography>
                                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9167 15.5833V11.4167M11.9167 7.25H11.9271M22.3333 11.4167C22.3333 17.1696 17.6696 21.8333 11.9167 21.8333C6.1637 21.8333 1.5 17.1696 1.5 11.4167C1.5 5.6637 6.1637 1 11.9167 1C17.6696 1 22.3333 5.6637 22.3333 11.4167Z" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </Stack>

                                {formik.touched.is_addon && formik.errors.is_addon && (
                                    <Typography style={{ color: 'red', fontSize: '12px' }}>
                                        {formik.errors.is_addon}
                                    </Typography>
                                )}
                            </Stack>


                            {formik?.values?.is_addon &&
                                <Stack sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>
                                    <FSelect
                                        isMultiSelect={true}
                                        value={formik?.values?.main_service_ids || []}
                                        options={serviceData || []}
                                        onChange={(e) => handleAddonService(e)}
                                        selectAllRenderCheckBoxText={t("Services.AllServ")}
                                        selectAllRenderText={t("Services.AllServ")}
                                        placeholderText={t("Services.SelectServ")}
                                        TextToDisplayWithCount={t("Common.Services")}
                                    />

                                    {(formik.touched.main_service_ids && formik.errors.main_service_ids) &&
                                        <Typography style={{ color: 'red', fontSize: '12px' }}>
                                            {formik.errors.main_service_ids}
                                        </Typography>}
                                </Stack>
                            }

                        </Stack>

                        {formik?.values?.is_addon &&
                            <Stack>
                                <Stack onClick={() => formik.setFieldValue('can_book_alone', !formik.values.can_book_alone)} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '22px', paddingLeft: 0, marginLeft: 0, cursor: 'pointer' }} >
                                    <F_Checkbox checked={formik.values.can_book_alone} />

                                    <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> {t("Services.BookedAlone")}</Typography>
                                </Stack>

                                {formik.touched.can_book_alone && formik.errors.can_book_alone && (
                                    <Typography style={{ color: 'red', fontSize: '12px' }}>
                                        {formik.errors.can_book_alone}
                                    </Typography>
                                )}
                            </Stack>
                        }

                        {(user?.settings.crud_services || user?.role === "ADMIN") && (


                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                                <button
                                    type="button"
                                    onClick={formik.handleSubmit}

                                    disabled={isLoading || ((showCetification && !formik.values.is_certified))}
                                    style={{
                                        backgroundColor: ((showCetification && !formik.values.is_certified)) ? '#D9D9D9' : '#44B904',
                                        color: 'white',
                                        border: 0,
                                        borderRadius: '12px',
                                        width: '100%',
                                        height: '40px',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        position: 'relative',
                                    }}
                                >
                                    {isLoading ? (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: 'white',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                                zIndex: 1,
                                            }}
                                        />
                                    ) : (
                                        <span style={{ zIndex: 2 }}>
                                            {(showCetification && !formik.values.is_certified) ? t("Services.SrvGovErr") : type === 'create' ? t("Services.CreateSrv") : t("Services.UpdateSrv")}
                                        </span>
                                    )}
                                </button>
                            </Box>)}
                    </Box>


                </Box>


                {/* 
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        serviceName: type == 'create' ? '' : selectedItem?.name || '',
                        price: type == 'create' ? '' : selectedItem?.price || '',
                        selectedServiceTypes: type == 'create' ? [] : selectedItem?.service_type_ids || [],
                        is_equipment_service: selectedItem?.is_equipment_service
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        onSavePress(values);
                    }}
                >
                    {({ touched, errors, handleSubmit, setFieldValue, values }) => (

                        <Form onSubmit={handleSubmit}>

                            <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row', marginTop: '20px' }}>

                                <Box style={{ display: 'flex', flexDirection: 'column', width: '48%' }} rowGap={0}>

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', }}>
                                        <img
                                            src={serviceNameIcon}
                                            style={{ width: 22, height: 22, marginRight: 10 }}
                                            alt=""
                                        />
                                       
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Name of service</Typography>
                                    </Box>
                                    <Field
                                        name="serviceName"
                                        type="text"
                                        placeholder="Enter service name"
                                        style={{
                                            height: 40,
                                            padding: '10px',
                                            fontSize: '16px',
                                            borderRadius: '10px',
                                            border: '1px solid #A79C92',
                                            outline: 'none',
                                            marginTop: 5
                                        }}
                                    />
                                    {touched.serviceName && errors.serviceName && (
                                        <div style={{ color: 'red', fontSize: '12px' }}>
                                            {errors.serviceName}
                                        </div>
                                    )}

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 20 }}>
                                        <img
                                            src={ServiceTypeIcon}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            alt=""
                                        />
                                     
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Service type</Typography>

                                    </Box>

                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1, width: '100%', justifyContent: 'space-between' }}>
                                        {serviceTypeButtonsOption.slice(0, 6).map(item => (
                                            <Paper
                                                key={item.id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    padding: 1,
                                                    borderRadius: '12px',
                                                    height: 30,
                                                    width: '50%',
                                                    backgroundColor: selectedServiceTypeButtonsOption === item.id ? '#44B904' : 'white',
                                                    color: selectedServiceTypeButtonsOption === item.id ? 'white' : 'black',
                                                    flex: '1 1 calc(50% - 10px)',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: selectedServiceTypeButtonsOption === item.id ? `1px solid #44B904` : `1px solid #A79C92`,
                                                }}
                                                elevation={0}
                                                onClick={() => handleRowClick(item.id)}
                                            >
                                                <Typography variant="body1" textAlign="center"
                                                    sx={{
                                                        fontWeight: 500, fontSize: 13, color: selectedServiceTypeButtonsOption === item.id ? 'white' : '#A79C92',
                                                        lineHeight: 1.2,
                                                    }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        ))}
                                    </Box>

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 20 }}>
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Select the different service types your treatment includes.</Typography>

                                       
                                    </Box>

                                    <Box style={{
                                        display: 'flex', flexDirection: "column", overflowY: 'auto',
                                        justifyContent: "flex-start", alignItems: 'center', width: '100%', height: '320px',
                                        marginTop: 10, borderRadius: '12px', border: '1px solid #A79C92',
                                    }}>

                                        <Box
                                            style={{
                                                display: 'flex',
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                                backgroundColor: 'white',
                                                width: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CustomSearchBar
                                                width={'95%'}
                                                height={40}
                                                value={serviceTypeSearch}
                                                onChange={(e) => updateServiceTypeSearch(e.target.value)}
                                            />
                                        </Box>

                                        <List
                                            style={{
                                                width: '100%',
                                                overflowY: 'scroll',
                                                scrollbarWidth: 'none',
                                            }}
                                        >
                                            {serviceTypeFilterList.map((service) => (
                                                <React.Fragment key={service.id} >
                                                    <ListItem style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', paddingTop: 0, paddingBottom: 0 }} onClick={() => handleToggle(service, setFieldValue, values)}>
                                                        
                                                        <Typography sx={{ color: '#545454', fontWeight: 400, fontSize: '15px' }}>
                                                            {service.label}
                                                        </Typography>
                                                      

                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    checked={values.selectedServiceTypes.some((selectedItem) => {
                                                                        if (typeof selectedItem === 'object' && selectedItem !== null) {
                                                                            return selectedItem.id === service.id;
                                                                        }
                                                                        return selectedItem === service.id;
                                                                    })}
                                                                    // onChange={() => handleToggle(service)}
                                                                    onChange={() => handleToggle(service, setFieldValue, values)}
                                                                    sx={{
                                                                        '&.Mui-checked .MuiSvgIcon-root': {
                                                                            backgroundColor: 'transparent',
                                                                            borderColor: '#A79C92',
                                                                            color: 'green',
                                                                        },
                                                                        mt: 0, mb: 0
                                                                    }}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    </ListItem>
                                                    <Divider sx={{ borderColor: '#A79C92', margin: '3px 0', borderBottomWidth: 1, marginLeft: 2, marginRight: 2 }} />
                                                </React.Fragment>
                                            ))}
                                        </List>

                                    </Box>

                                    {touched.selectedServiceTypes && errors.selectedServiceTypes && (
                                        <div style={{ color: 'red', fontSize: '12px' }}>
                                            {errors.selectedServiceTypes}
                                        </div>
                                    )}

                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column', width: '48%', justifyContent: 'flex-start', alignItems: 'flex-start' }} sx={{ mt: 0, pt: 0 }} rowGap={0}>

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center' }}>
                                        <img
                                            src={DescriptionIcon}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            alt=""
                                        />
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Service description (optional)</Typography>

                                    </Box>
                                    <TextareaAutosize
                                        minRows={3}
                                        maxRows={6}
                                        value={serviceDesc}
                                        onChange={(e) => setServiceDesc(e.target.value)}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            fontSize: '16px',
                                            borderRadius: '10px',
                                            border: '1px solid #A79C92',
                                            outline: 'none',
                                            resize: 'none',
                                            marginTop: 5
                                        }}

                                        maxLength={1000}
                                     
                                    />
                                    <Typography variant="caption" sx={{ color: '#6F6F6F', width: '100%', textAlign:'right' }}> {serviceDesc.length}/1000</Typography>

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 15 }}>
                                        <img
                                            src={DurationIcon}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            alt=""
                                        />

                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Duration</Typography>

                                    </Box>
                                    <CustomSelect
                                        value={duration}
                                        onChange={e => setDuration(e.target.value)}
                                        options={durationList}
                                        sx={{ width: '100%', mt: 0.5, height: 40 }}
                                        borderColor="#A79C92"
                                        borderThickness="1px"
                                        noLabel
                                    />

                                    <Stack flex={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%', mt: 2, mb: 0.8 }}>
                                        <Stack flex={0.9} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                            <img
                                                src={cashIcon}
                                                style={{ width: 20, height: 20, marginRight: 10 }}
                                                alt=""
                                            />

                                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Price (kr.)</Typography>
                                        </Stack>

                                        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} onClick={()=>setIsPriceStartFrom(!isPriceStartFrom)} sx={{cursor:'pointer'}}>
                                            <Checkbox
                                                checked={isPriceStartFrom}
                                                onChange={handleChangePriceStartFrom}
                                                sx={{
                                                   
                                                    '&.Mui-checked .MuiSvgIcon-root': {
                                                        backgroundColor: 'transparent',
                                                        borderColor: '#A79C92',
                                                        color: 'green',
                                                    },
                                                    p: 0
                                                   
                                                }}
                                            />

                                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Starting from</Typography>

                                        </Stack>
                                    </Stack>
                                    <Field
                                        name="price"
                                        type="text"
                                        placeholder="Enter price"
                                        inputMode="decimal"
                                        onKeyPress={(e) => {
                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                            if (!regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const newPrice = e.target.value;
                                            setFieldValue('price', newPrice);
                                            setStandardPrice(newPrice);
                                        }}
                                        value={values.price}
                                        style={{
                                            padding: '10px',
                                            fontSize: '16px',
                                            borderRadius: '10px',
                                            border: '1px solid #A79C92',
                                            outline: 'none',

                                            height: 40,
                                            width: '100%'
                                        }}
                                    />
                                    {touched.price && errors.price && (
                                        <div style={{ color: 'red', fontSize: '12px' }}>
                                            {errors.price}
                                        </div>
                                    )}

                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 15 }}>
                                        <img
                                            src={cameraIcon}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            alt=""
                                        />
                                        <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Service picture (optional)</Typography>

                                    </Box>

                                    {!file && (
                                        <label
                                            htmlFor="file-upload"
                                            style={{
                                                display: 'inline-block',
                                                padding: '10px',
                                                border: '1px solid #6F6F6F',
                                                width: '137px',
                                                height: 40,
                                                borderRadius: '12px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#f5f5f5',
                                                cursor: 'pointer',
                                                transition: 'border-color 0.3s',
                                                marginTop: '6px'
                                            }}
                                        >
                                            <span style={{ fontWeight: 400, fontSize: '15px', color: '#6F6F6F' }}>Upload a photo</span>

                                            <input
                                                type="file"
                                                accept="image/*"
                                                id="file-upload"
                                                onChange={handleFileChange}
                                                style={{
                                                    display: 'none',
                                                }}
                                            />
                                        </label>
                                    )}

                                    {file && (
                                        <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
                                            <>
                                                <Paper
                                                    elevation={0}
                                                    style={{
                                                        width: '137px',
                                                        height: 40,
                                                        borderRadius: '12px',
                                                        border: '1px solid #367B3D',
                                                        opacity: 1,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '0 10px',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                >
                                                    <span style={{ fontWeight: 400, fontSize: '15px', color: '#367B3D' }}>Uploaded!</span>
                                                </Paper>

                                                <IconButton onClick={handleRemoveFile} style={{ padding: 0 }} disableRipple>
                                                    <img
                                                        src={DeleteIcon}
                                                        alt="Delete"
                                                        style={{ width: '17px', height: '19px', marginLeft: 20 }}
                                                    />
                                                </IconButton>
                                            </>
                                        </Stack>
                                    )}

                                    {isMachineVisible && (
                                        <>
                                            <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '22px', paddingLeft: 0, marginLeft: 0, cursor: 'pointer' }} onClick={() => setisMachine(!isMachine)}>
                                                <Checkbox
                                                    disableRipple
                                                    checked={isMachine}
                                                    onChange={() => setisMachine(!isMachine)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            border: '0.1px solid #A79C92',
                                                            borderRadius: '4px',
                                                        },
                                                        '&.Mui-checked .MuiSvgIcon-root': {
                                                            backgroundColor: 'transparent',
                                                            borderColor: '#A79C92',
                                                            color: 'green',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: 'transparent',
                                                        },
                                                        paddingLeft: 0
                                                    }}
                                                />

                                                <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Available to multiple clints at once</Typography>
                                            </Stack>


                                            {!isMachine && (
                                                <div style={{ marginTop: '10px' }}>
                                                    <SecondaryHeading fontColor='#6F6F6F' size={'15px'} text={'Please state the amount of clients this service will be available for at once.\nExample: If you have two machines to perform the service, you’ll have to enter 2'} />
                                                </div>
                                            )}

                                            {isMachine && (
                                                <div style={{ marginTop: '10px', width: '100%' }}>
                                                    <CustomTextField
                                                        value={howManyMachine}
                                                        onChange={(event) => handleNoOfPeople(event)}
                                                        placeholder={"Number of clients"}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}


                                    {!isMachineVisible && (
                                    <>
                                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center', marginTop: 15 }}>
                                        
                                            <Typography variant="body1" sx={{ color: '#6F6F6F', width: '100%' }}> Who offers the service?</Typography>
                                        </Box>

                                        <Box
                                            style={{
                                                display: 'flex', flexDirection: "column", scrollbarWidth: 'none',
                                                justifyContent: "flex-start", alignItems: 'center', width: '100%',
                                                marginTop: '10px', borderRadius: '12px', boxShadow: '0px 4px 55px 0px #0000001A',
                                                paddingBottom: 10
                                            }}>

                                            <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>

                                                <Box style={{ display: 'flex', justifyContent: 'flex-start', width: '15%', height: '175px', marginRight: 8 }}>
                                                    <img
                                                        src={AddEmployeeicon}
                                                        style={{ width: 35, height: 35, margin: 20, cursor: 'pointer' }}
                                                        alt=""
                                                        onClick={() => setShowEmployeeList(true)}
                                                    />
                                                </Box>

                                                <Stack flex={1} flexDirection={'row'} justifyContent={'center'} alignItems={'flex-start'} >
                                                    <Grid container spacing={0}>
                                                        {selectedEmplpoyeeList.map((employee) => (
                                                            <Grid item xs={12} sm={4} key={employee.id} sx={{ mt: 1 }}>
                                                                <Stack

                                                                    flex={1} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'}
                                                                    sx={{ m: 0, p: 0 }}
                                                           
                                                                >
                                                                   
                                                                    <Typography variant="caption" sx={{
                                                                        color: '#1f1f1f', width: '100%', textAlign: 'center', fontSize: 14, fontWeight: 600, textAlign: 'center', overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box",
                                                                        WebkitLineClamp: "2",
                                                                        WebkitBoxOrient: "vertical", lineHeight: 1.3
                                                                    }}>
                                                                        {employee.name}
                                                                    </Typography>

                                                                    <CustomTextField
                                                                        value={employee.price}
                                                                        onChange={(e) => handlePriceChange(employee.id, e.target.value)}
                                                                        width={'80%'}
                                                                        height={40}
                                                                        onKeyPress={(e) => {
                                                                            const regex = /^[0-9]*\.?[0-9]*$/;
                                                                            if (!regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        lineHeight={0.2}
                                                                        mt={0.4}
                                                                        pt={0}
                                                                        // placeholder={'Price'}
                                                                        placeholderFontSize={14}
                                                                        inputFontSize={14}
                                                                    // sx={{ pl:1,pt:0 }}

                                                                    />
                                                                </Stack>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Stack>
                                            </Box>

                                        </Box>

                                        {showError && (
                                            <div style={{ color: 'red', fontSize: '12px' }}>
                                                {'Please enter valid price for employee'}
                                            </div>
                                        )}
                                    </>
                                    )} 

                                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                                        <button
                                            type="button"
                                            onClick={handleSubmit}
                                            disabled={isLoading}
                                            style={{
                                                backgroundColor: '#44B904',
                                                color: 'white',
                                                border: 0,
                                                borderRadius: '12px',
                                                width: '100%',
                                                height: '40px',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                position: 'relative',
                                            }}
                                        >
                                            {isLoading ? (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: 'white',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                        zIndex: 1,
                                                    }}
                                                />
                                            ) : (
                                                <span style={{ zIndex: 2 }}>
                                                    {type === 'create' ? 'Create service' : 'Update service'}
                                                </span>
                                            )}
                                        </button>
                                    </Box>

                                </Box>

                            </Box>

                        </Form>
                    )}

                </Formik> */}

                {showEmployeeList &&
                    <Modal
                        open={showEmployeeList}

                        // onClose={() => setShowEmployeeList(false)}
                        onClose={() => { }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={[style, {
                            zIndex: 12,
                            justifyContent: 'center', alignItems: 'center',
                            overflowY: { xs: 'auto', sm: '85vh' },
                            overflowX: 'hidden',
                            maxHeight: { xs: '90vh', sm: '85vh' },
                            position: 'relative',
                            padding: 4,
                            maxWidth: '60vh',
                            borderRadius: '25px'
                        }]}>

                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={() => setShowEmployeeList(false)}
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '30px',
                                        zIndex: 10,
                                        color: 'black'
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            {/* <PrimaryHeading fontColor='##1f1f1f' text={'Employee list'}  sx={{textAlign:'center', }}/> */}
                            <Typography variant='h6' sx={{ fontWeight: 700, color: '#1f1f1f', textAlign: 'center' }}>
                                {t("Services.EmpList")}
                            </Typography>

                            <Box style={{
                                display: 'flex', flexDirection: "column", overflowY: 'auto',
                                justifyContent: "flex-start", alignItems: 'center', width: '100%', height: '500px',
                                marginTop: '10px', borderRadius: '12px', border: '1px solid #A79C92',
                            }}>

                                {/* <CustomSearchBar
                                    width={'98%'}
                                    value={employeeSearch}
                                    onChange={(e) => updateEmployeeSearch(e.target.value)}
                                /> */}

                                <List style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
                                    {filterList.map((employee) => (
                                        <Stack key={employee.id} sx={{ cursor: 'pointer' }} onClick={() => addEmployee(employee)}>
                                            {/* <React.Fragment key={employee.id}> */}
                                            <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <ListItemText>
                                                    <Typography variant='body1' sx={{ color: '#545454', fontWeight: 400 }}>
                                                        {employee.name}
                                                    </Typography>
                                                </ListItemText>

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disableRipple
                                                            checked={formik.values.selectedEmplpoyeeList.some((el) => el.id === employee.id)}
                                                            onChange={() => addEmployee(employee)}
                                                            sx={{
                                                                // '& .MuiSvgIcon-root': {
                                                                //     border: '0.1px solid #A79C92',
                                                                //     borderRadius: '4px',
                                                                // },
                                                                '&.Mui-checked .MuiSvgIcon-root': {
                                                                    backgroundColor: 'transparent',
                                                                    borderColor: '#A79C92',
                                                                    color: 'green',
                                                                },
                                                                // '&:hover': {
                                                                //     backgroundColor: 'transparent',
                                                                // },
                                                            }}
                                                        />
                                                    }
                                                    label=""
                                                />
                                            </ListItem>
                                            <Divider sx={{ borderColor: '#A79C92', margin: '3px 0', borderBottomWidth: 1, marginLeft: 2, marginRight: 2 }} />
                                            {/* </React.Fragment> */}
                                        </Stack>
                                    ))}
                                </List>

                            </Box>

                        </Box>

                    </Modal>
                }


            </DialogContent>

        </Dialog>


    )
}

export default ServiceModal;
