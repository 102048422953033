import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Divider,
  Tooltip,
  TextareaAutosize,
  Skeleton,
  TextField,
  Button,
  AppBar,
} from "@mui/material";
import CommonButton from "../commonButton";
import TooltipIcon from "../../../assets/IconTooltip.png";
import PrimaryHeading from "../commonPrimaryHeading";
import SecondaryHeading from "../commonSecondaryHeading";
import CustomSelect from "../commonCustomSelect";
import CustomTextField from "../commonTextinput";
import { useFormik } from "formik";
import apiFetcher from "../../../utils/interCeptor";

import { toast, ToastContainer } from "react-toastify";
import _ from "lodash";
import { MultipleContainers } from "../../MultipleContainers/MultipleContainers";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import FSwitch from "../../commonComponents/f-switch";
import { useSelector } from "react-redux";
import { Copy } from "lucide-react";

import { t } from "i18next";

const onlinBookingsIntervals = [
  { value: 5, label: "5 min" },
  { value: 15, label: "15 min (standard)" },
  { value: 30, label: "30 min" },
  { value: 60, label: "1 hour" },
];
const howLongBeforeCustomerOptions = [
  { value: 5, label: "5 min" },
  { value: 15, label: "15 min (standard)" },
  { value: 45, label: "30 min" },
  { value: 60, label: "1 hour" },
  { value: 720, label: "12 hours" },
  { value: 1440, label: "24 hours" },
];

const howFarFutureCustomerOptions = [
  { value: 7, label: "1 week" },
  { value: 14, label: "2 weeks" },
  { value: 30, label: "1 month (standard)" },
  { value: 180, label: "6 months" },
  { value: 365, label: "1 year" },
];

const longBeforeCustomerCancelBookingOptions = [
  { value: 120, label: "2 hours" },
  { value: 240, label: "4 hours" },
  { value: 360, label: "6 hours" },
  { value: 720, label: "12 hours" },
  { value: 1440, label: "24 hours (standard)" },
  { value: 2880, label: "48 hours" },
  { value: 4320, label: "72 hours" },
];

const OnlineBookingSettingsOption = () => {
  const user = useSelector((state) => state.user.data);
  const set = useSelector((state) => state.settings.data);
  // const settings = useSelector((state) => state.setting.data)
  const [Employee, setEmployee] = useState([]);
  const [reOrderEmp, setReorderEmp] = useState({});
  const dataNeedsFromClintsFirst = [
    { id: 1, label: t("Common.Name"), field: "name" },
    { id: 2, label: t("Calendar.PhoneNumber"), field: "phoneNumber" },
    { id: 3, label: t("Common.Email"), field: "email" },
    { id: 4, label: t("Common.Address"), field: "address" },
  ];

  const dataNeedsFromClintsSec = [
    { id: 11, label: t("Setting.NoteBox"), field: "noteBox" },
    { id: 12, label: t("Common.Birthday"), field: "birthday" },
    { id: 13, label: t("Setting.CPR"), field: "cpr" },
  ];


  const [isChanges, setIsChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    onlineBooking: {
      allowOnlineBooking: true,
      specificEmployee: [],
    },

    BookingInterval: {
      miniGapCalEmployee: [],
      sameIntervalForAllEmployee: true,
      intervalForOnlineBooking: 5,
      intervalForIndividualEmployee: null,
    },

    restrictionForBooking: {
      howLongBeforeCustomerBook: 15,
      howFarFutureCustomerbook: 30,
      longBeforeCustomerCancelBookingOptionBook: 1440,
      messageForTooLate: "",
    },

    DataFromClients: {
      name: true,
      phoneNumber: true,
      email: true,
      address: true,
      noteBox: true,
      birthday: false,
      cpr: false,
    },
    employeSectionOrder: [],
    iframeCode: "",
    policy: null,
  });

  const embedCode = (set.profile.web_store_name !== '' && set.profile.web_store_name != null) ? `<script 
     src="https://admin.fiind.app/assets/find-embed.js"
     data-booking-url="https://fiind.app/${set.profile.web_store_name}">
</script>`: null;


  const handleCopy = () => {
    navigator.clipboard
      .writeText(embedCode)
      .then(() => {
        // alert("Text copied");
        toast.info(t("Setting.iframeCodeCopied"))
      })
      .catch((err) => {
        // alert("Failed to copy text: " + err);
        console.error('error');

      });

  };

  async function submitOnlineBookingSettings(values) {
    try {
      const payload = {
        settings: [
          {
            settingCategory: "outlet",
            settingName: "OnlineBooking",
            value: JSON.stringify(values),
            type: "JSON",
          },
        ],
        policy: formik.values?.policy,
      };

      const response = await apiFetcher.patch(
        "/api/v1/store/outlet/setting",
        payload
      );
      const { success } = response.data;
      if (success) {
        setIsChanges(false);
        toast.success(t("Setting.OnlineBookingSettingsUpdated"));
        fetchSettings(Employee);
      }

      formik.setSubmitting(false);
    } catch (err) {
      toast.error(t("Setting.FailedToUpdateOnlineBookingSettings"));
      console.error("err", err);
      formik.setSubmitting(false);
    }
  }

  async function updateEmpSequence(payload) {
    try {
      //  const payload =  Employee.map((empObj)=>{return{id: empObj.id, sequence: empObj.sequence}})

      const response = await apiFetcher.post(
        "/api/v1/store/employee/sequence",
        payload
      );
      const { success } = response.data;
      if (success) {
        setIsChanges(false);
        toast.success(t("Setting.EmployeeSelectionOrderUpdated"));
      }
    } catch (err) {
      toast.error(t("Setting.FailedToUpdateEmployeeSelectionOrder"));
    }
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,

    onSubmit: (values) => {

      submitOnlineBookingSettings(values);
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await apiFetcher.get("/api/v1/store/employee/get");
        const data = res.data?.data;

        let empObj = {};
        // let empIds = []

        data.map((dataObj) => {
          delete dataObj.settings;
          empObj[dataObj?.id] = {
            ...dataObj,
            title: dataObj.name,
            groupId: dataObj?.id,
            services: [],
            noSubGroup: true,
          };
          // empIds.push(dataObj?.id)
        });


        setReorderEmp(empObj);



        setEmployee(data);
        fetchSettings(data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    setIsChanges(!_.isEqual(initialValues, formik.values));
  }, [formik.values]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await apiFetcher.get(
  //         "https://api.fiind.app/api/v1/store/outlet/setting"
  //       );

  //       const data = res.data.data.settings.filter(
  //         (setting) =>
  //           setting.settingCategory === "outlet" &&
  //           setting.settingName === "OnlineBooking"
  //       )[0].value;

  //       const parsedData = JSON.parse(data);
  //       // let newParsedData = {...parsedData}

  //       // let individualData = {}

  //       // Employee.map((empObj)=>{
  //       //   individualData[empObj?.id] = {
  //       //     id: empObj?.id,
  //       //     name: empObj?.name,
  //       //     interval: 15
  //       //   }
  //       // })

  //       // let newBookingInterval = {...newParsedData?.BookingInterval, 'intervalForIndividualEmployee': individualData}


  //       setInitialValues(parsedData);
  //     } catch (error) {
  //       console.error("error", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const fetchSettings = async (empList) => {
    try {
      const res = await apiFetcher.get("/api/v1/store/outlet/setting");



      const data1 = res.data.data.settings.filter(
        (setting) =>
          setting.settingCategory === "outlet" &&
          setting.settingName === "OnlineBooking"
      );

      let data = null


      if (data1.length) {
        data = data1[0].value
      } else {



        let specificEmployee = []
        let intervalForIndividualEmployee = {}
        empList.map((empObj) => {
          specificEmployee.push(empObj.id)
          intervalForIndividualEmployee[empObj.id] = {
            id: empObj.id,
            name: empObj.name,
            interval: 5
          }

        })


        setInitialValues({
          ...initialValues,
          onlineBooking: {
            allowOnlineBooking: true,
            specificEmployee,
          },
          BookingInterval: {
            miniGapCalEmployee: [],
            sameIntervalForAllEmployee: true,
            intervalForOnlineBooking: 5,
            intervalForIndividualEmployee,
          },
        });
        formik.setValues({
          ...formik.values,
          onlineBooking: {
            allowOnlineBooking: true,
            specificEmployee,
          },
          BookingInterval: {
            miniGapCalEmployee: [],
            sameIntervalForAllEmployee: true,
            intervalForOnlineBooking: 5,
            intervalForIndividualEmployee,
          },
        });
        setLoading(false);
        return
      }

      const parsedData = JSON.parse(data);
      let newParsedData = { ...parsedData };

      let individualData =
        parsedData?.BookingInterval?.intervalForIndividualEmployee;

      if (individualData && !Object.keys(individualData)?.length) {
        empList.map((empObj) => {
          individualData[empObj?.id] = {
            id: empObj?.id,
            name: empObj?.name,
            interval: 15,
          };
        });
      }
      let newBookingInterval = {
        ...newParsedData?.BookingInterval,
        intervalForIndividualEmployee: individualData,
      };


      setLoading(false);

      setInitialValues({
        ...parsedData,
        BookingInterval: newBookingInterval,
        policy: res.data.data?.policy,
      });
      formik.setValues({
        ...parsedData,
        BookingInterval: newBookingInterval,
        policy: res.data.data?.policy,
      });
    } catch (error) {
      setLoading(false);
      console.error("error", error);
    }
  };

  // useEffect(()=>{

  //   const newParsedData = formik.values
  //   let individualData = {}

  //   Employee.map((empObj)=>{
  //     individualData[empObj?.id] = {
  //       id: empObj?.id,
  //       name: empObj?.name,
  //       interval: 15
  //     }
  //   })

  //   let newBookingInterval = {...newParsedData?.BookingInterval, 'intervalForIndividualEmployee': individualData}

  //   setInitialValues({...initialValues,'BookingInterval':newBookingInterval })
  //   formik.setFieldValue({...initialValues,'BookingInterval':newBookingInterval })


  // },[Employee.length])

  const items = [
    { id: 1, name: "Item 1" },
    { id: 2, name: "Item 2" },
    { id: 3, name: "Item 3" },
    { id: 4, name: "Item 4" },
  ];

  const tooltipContent = <div style={{ padding: "5px" }}>content here</div>;

  const handleDataNeedsChange = (option) => {
    const { id, field } = option;
    if (![1, 2].includes(id)) {
      formik.setFieldValue(
        `DataFromClients.${field}`,
        !formik.values.DataFromClients[field]
      );
    }
  };

  const handleEmployeeToggle = (id) => {
    const selectedEmployees = formik.values.onlineBooking.specificEmployee;
    const updatedEmployees = selectedEmployees.includes(id)
      ? selectedEmployees.filter((empId) => empId !== id) // Remove if already selected
      : [...selectedEmployees, id]; // Add if not selected

    formik.setFieldValue("onlineBooking.specificEmployee", updatedEmployees);
  };

  const handleMiniGapCalEmployeeToggle = (id) => {
    const selectedEmployees = formik.values.BookingInterval.miniGapCalEmployee;
    const updatedEmployees = selectedEmployees.includes(id)
      ? selectedEmployees.filter((empId) => empId !== id) // Remove if already selected
      : [...selectedEmployees, id]; // Add if not selected

    formik.setFieldValue(
      "BookingInterval.miniGapCalEmployee",
      updatedEmployees
    );
  };

  const handleMiniGapCalEmployeeToggleAll = () => {
    let allEmpId = [];
    if (
      formik.values.BookingInterval.miniGapCalEmployee.length != Employee.length
    ) {
      allEmpId = Employee.map((empObj) => empObj.id);
    }

    // const selectedEmployees = formik.values.BookingInterval.miniGapCalEmployee;
    // const updatedEmployees = selectedEmployees.includes(id)
    //   ? selectedEmployees.filter((empId) => empId !== id) // Remove if already selected
    //   : [...selectedEmployees, id]; // Add if not selected

    formik.setFieldValue("BookingInterval.miniGapCalEmployee", allEmpId);
  };

  const setIndividualInterval = (id, intervalData) => {
    let updatedInterval = {
      ...formik.values.BookingInterval.intervalForIndividualEmployee,
    };
    updatedInterval[id] = { ...updatedInterval[id], interval: intervalData };
    formik.setFieldValue(
      "BookingInterval.intervalForIndividualEmployee",
      updatedInterval
    );
  };

  // const checkPermission = ({ id = 0 }) => {
  //   if (user?.role !== "ADMIN" && !user?.settings?.change_all_calender_interval) {
  //     if (user?.settings?.change_own_calender_interval && user?.id === id) {
  //       return true
  //     }
  //     return false;
  //   }
  //   return true;
  // }


  const isDisable = ({ id = 0, allowToAllOnly = false }) => {


    if (user?.role == "ADMIN") {
      return false
    } else {
      if (user?.settings?.change_all_calender_interval) {
        return false
      } else {
        if (allowToAllOnly) {
          return true
        }
        if (user?.settings?.change_own_calender_interval && user?.id === id) {
          return false
        }
        return true
      }
    }

  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>

        {isChanges && (
          <AppBar
            sx={{
              position: "sticky",
              zIndex: 20,
              top: 45,
              left: 0,
              py: 1,
              px: 4,
              height: 50,
              bgcolor: "#fff",
              display: "flex",
              // justifyContent: "flex-end",
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            <CommonButton
              onClick={formik.handleSubmit}
              width="auto"
              ml={'auto'}
              height={40}
              title={t("Setting.SaveChanges")}
            // loading={formik.isSubmitting}
            // disabled={formik.isSubmitting}
            />
          </AppBar>
        )}
        <Stack p={4}>
          <ToastContainer />
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              paddingTop: 2,
              paddingBottom: 2,
              paddingRight: 4,
              paddingLeft: 4,
            }}
          >
            {isChanges && (
              <CommonButton
                type="submit"
                width="auto"
                height={40}
                title={"Save changes"}
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              />
            )}
          </Box> */}

          <Stack
            spacing={6}
            sx={{
              bgcolor: "#fff",
              borderRadius: "25px",
              minHeight: "86vh",
              //    overflowY:'auto',
              scrollbarWidth: "none",
              overflowX: "hidden",
            }}
          >
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  bgcolor: "#FFFFFF",
                  borderRadius: "25px",
                  flexDirection: "column",
                  width: "100%",

                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 5,
                    minHeight: 100,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "33%",
                    }}
                  >
                    <PrimaryHeading text={t("Setting.OnlineBooking")} />
                    <SecondaryHeading text={t("Setting.Enable/disableOnlineBooking")} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "67%",
                    }}
                  >
                    {loading ? (
                      <Stack
                        flex={1}
                        flexDirection={"row"}
                        justifyContent={"space-evenly"}
                        alignItems={"flex-start"}
                      >
                        <Stack width="100%" spacing={1}>
                          <Box
                            sx={{
                              width: "52%",
                              display: "flex",
                              alignItems: "center",
                              mr: 2,
                            }}
                          >
                            <Skeleton
                              variant="rounded"
                              width="100%"
                              height={48}
                            />
                          </Box>
                        </Stack>
                        <Stack width="100%" spacing={1}>
                          {[...Array(9)].map((_, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                gap: 4,
                              }}
                            >
                              <Box
                                sx={{
                                  width: "52%",
                                  display: "flex",
                                  alignItems: "center",
                                  mr: 2,
                                }}
                              >
                                <Skeleton
                                  variant="rounded"
                                  width="100%"
                                  height={48}
                                />
                              </Box>
                            </Box>
                          ))}
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack
                        flex={1}
                        flexDirection={"row"}
                        justifyContent={"space-evenly"}
                        alignItems={"flex-start"}
                      >
                        <Stack
                          flex={1}
                          flexDirection={"column"}
                          justifyContent={"space-evenly"}
                          alignItems={"flex-start"}
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#1f1f1f", fontWeight: 700 }}
                          >
                            {t("Setting.AllowOnlineBooking")}
                          </Typography>

                          <FSwitch
                            checked={
                              formik.values.onlineBooking.allowOnlineBooking
                            }
                            onChange={(event) => {
                              formik.setValues({
                                ...formik.values,
                                onlineBooking: {
                                  ...formik.values.onlineBooking,
                                  allowOnlineBooking: event.target.checked,
                                },
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                "&.Mui-checked": {
                                  color: "#fff",
                                },
                                "&.Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: "#44B904",
                                },
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: "#D9D9D9",
                              },
                            }}
                          />
                        </Stack>
                        {formik.values.onlineBooking.allowOnlineBooking && (
                          <Stack
                            flex={1}
                            flexDirection={"column"}
                            justifyContent={"space-evenly"}
                            alignItems={"flex-start"}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#1f1f1f", fontWeight: 700 }}
                            >
                              {t("Setting.SelectEmployees")}
                            </Typography>
                            {Employee.map((option, index) => (
                              <Box
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginBottom: 1,
                                }}
                              >
                                <FSwitch
                                  checked={formik.values.onlineBooking.specificEmployee.includes(
                                    option.id
                                  )}
                                  onChange={() =>
                                    handleEmployeeToggle(option.id)
                                  }
                                  sx={{
                                    "& .MuiSwitch-switchBase": {
                                      "&.Mui-checked": { color: "#fff" },
                                      "&.Mui-checked + .MuiSwitch-track": {
                                        backgroundColor: "#44B904",
                                      },
                                    },
                                    "& .MuiSwitch-track": {
                                      backgroundColor: "#D9D9D9",
                                    },
                                  }}
                                />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: 400,
                                    color: "#1F1F1F",
                                  }}
                                >
                                  {option.name}
                                </Typography>
                              </Box>
                            ))}
                          </Stack>
                        )}
                      </Stack>
                    )}
                  </Box>
                </Box>

                {!loading && (
                  <>

                    <Divider
                      sx={{
                        marginTop: 5,
                        marginBottom: 5,
                        border: "2.5px solid #D9D9D9",
                        backgroundColor: "#F3F3F3",
                        width: "100%",
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: 5,
                        minHeight: 100,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "33%",
                          pr: 3,
                        }}
                      >
                        <PrimaryHeading text={t("Setting.BookingInterval")} />
                        <SecondaryHeading
                          text={t("Setting.Description4")}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "67%",
                        }}
                      >
                        <Stack
                          flex={1}
                          flexDirection={"row"}
                          justifyContent={"space-evenly"}
                          alignItems={"flex-start"}
                        >
                          <Stack
                            flex={1}
                            flexDirection={"column"}
                            justifyContent={"space-evenly"}
                            alignItems={"flex-start"}
                          >

                            <>
                              <Stack
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#1f1f1f", fontWeight: 700 }}
                                >
                                  {t("Setting.ChooseIntervalForOnlineBookings")}
                                </Typography>

                                <Tooltip arrow title={tooltipContent}>
                                  <img
                                    src={TooltipIcon}
                                    alt="IconOne"
                                    style={{
                                      marginLeft: 4,
                                      width: 18,
                                      height: 18,
                                    }}
                                  />
                                </Tooltip>
                              </Stack>
                              <CustomSelect

                                value={
                                  formik.values.BookingInterval
                                    .intervalForOnlineBooking
                                }
                                disabled={!formik.values.BookingInterval
                                  .sameIntervalForAllEmployee && isDisable({ id: user?.id, allowToAllOnly: true })}
                                onChange={(event) => {
                                  formik.setValues({
                                    ...formik.values,
                                    BookingInterval: {
                                      ...formik.values.BookingInterval,
                                      intervalForOnlineBooking:
                                        event.target.value,
                                    },
                                  });
                                }}
                                options={onlinBookingsIntervals}
                                sx={{ width: "50%", marginTop: 1 }}
                              />



                              <>
                                <Stack
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: 20,
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{ color: "#1f1f1f", fontWeight: 700 }}
                                  >
                                    {t("Setting.SameIntervalForAllEmployees")}
                                  </Typography>
                                  <Tooltip arrow title={tooltipContent}>
                                    <img
                                      src={TooltipIcon}
                                      alt="IconOne"
                                      style={{
                                        marginLeft: 4,
                                        width: 18,
                                        height: 18,
                                      }}
                                    />
                                  </Tooltip>
                                </Stack>

                                <FSwitch
                                  checked={
                                    formik.values.BookingInterval
                                      .sameIntervalForAllEmployee
                                  }
                                  disabled={isDisable({ id: user?.id, allowToAllOnly: true })}
                                  onChange={(event) => {
                                    formik.setValues({
                                      ...formik.values,
                                      BookingInterval: {
                                        ...formik.values.BookingInterval,
                                        sameIntervalForAllEmployee:
                                          event.target.checked,
                                      },
                                    });
                                  }}
                                  inputProps={{ "aria-label": "controlled" }}
                                  sx={{
                                    "& .MuiSwitch-switchBase": {
                                      "&.Mui-checked": {
                                        color: "#fff",
                                      },
                                      "&.Mui-checked + .MuiSwitch-track": {
                                        backgroundColor: "#44B904",
                                      },
                                    },
                                    "& .MuiSwitch-track": {
                                      backgroundColor: "#D9D9D9",
                                    },
                                  }}
                                />
                              </>


                            </>


                            {formik.values.BookingInterval
                              .intervalForIndividualEmployee &&
                              !formik.values.BookingInterval
                                .sameIntervalForAllEmployee &&
                              Object.values(
                                formik.values.BookingInterval
                                  .intervalForIndividualEmployee
                              ).map((obj) => {
                                return (
                                  !isDisable({ id: obj.id }) && (
                                    <Stack
                                      flexDirection={"row"}
                                      flex={1}
                                      alignItems={"center"}
                                      key={obj.id}
                                    >
                                      <CustomSelect
                                        width="50%"
                                        value={obj.interval}
                                        onChange={(event) => {
                                          setIndividualInterval(
                                            obj.id,
                                            event.target.value
                                          );
                                          // formik.setValues({
                                          //   ...formik.values,
                                          //   BookingInterval: {
                                          //     ...formik.values.BookingInterval,
                                          //     intervalForOnlineBooking: event.target.value,
                                          //   },
                                          // });
                                        }}
                                        options={onlinBookingsIntervals}
                                        sx={{
                                          max: "50%",
                                          marginTop: 1,
                                          minWidth: 160,
                                        }}
                                      />
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          color: "#1f1f1f",
                                          fontWeight: 700,
                                          ml: 2,
                                          width: "50%",
                                        }}
                                      >
                                        {obj.name}
                                      </Typography>
                                    </Stack>
                                  )
                                );
                              })}
                          </Stack>
                          {formik.values.onlineBooking.allowOnlineBooking && (
                            <Stack
                              flex={1}
                              flexDirection={"column"}
                              justifyContent={"space-evenly"}
                              alignItems={"flex-start"}
                            >
                              <Stack
                                flex={1}
                                flexDirection={"row"}
                                alignItems={"center"}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#1f1f1f", fontWeight: 700 }}
                                >
                                  {t("Setting.MinimizeGapsInTheCalendar")}
                                </Typography>
                                <Tooltip arrow title={tooltipContent}>
                                  <img
                                    src={TooltipIcon}
                                    alt="IconOne"
                                    style={{
                                      marginLeft: 4,
                                      width: 18,
                                      height: 18,
                                    }}
                                  />
                                </Tooltip>
                              </Stack>


                              {
                                !isDisable({ id: user?.id, allowToAllOnly: true }) &&


                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <FSwitch

                                    checked={
                                      formik.values.BookingInterval
                                        .miniGapCalEmployee.length ==
                                      Employee.length
                                    }
                                    onChange={() =>
                                      handleMiniGapCalEmployeeToggleAll()
                                    }
                                    sx={{
                                      "& .MuiSwitch-switchBase": {
                                        "&.Mui-checked": { color: "#fff" },
                                        "&.Mui-checked + .MuiSwitch-track": {
                                          backgroundColor: "#44B904",
                                        },
                                      },
                                      "& .MuiSwitch-track": {
                                        backgroundColor: "#D9D9D9",
                                      },
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontWeight: 400,
                                      color: "#1F1F1F",
                                    }}
                                  >
                                    {t("Common.All")}
                                  </Typography>
                                </Box>
                              }

                              {Employee.map((option, index) => (
                                !isDisable({ id: option.id }) && (
                                  <Box
                                    key={index}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FSwitch
                                      checked={formik.values.BookingInterval.miniGapCalEmployee.includes(
                                        option.id
                                      )}
                                      onChange={() =>
                                        handleMiniGapCalEmployeeToggle(option.id)
                                      }
                                      sx={{
                                        "& .MuiSwitch-switchBase": {
                                          "&.Mui-checked": { color: "#fff" },
                                          "&.Mui-checked + .MuiSwitch-track": {
                                            backgroundColor: "#44B904",
                                          },
                                        },
                                        "& .MuiSwitch-track": {
                                          backgroundColor: "#D9D9D9",
                                        },
                                      }}
                                    />
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontWeight: 400,
                                        color: "#1F1F1F",
                                      }}
                                    >
                                      {option.name}
                                    </Typography>
                                  </Box>
                                )
                              ))}
                            </Stack>
                          )}
                        </Stack>
                      </Box>
                    </Box>

                    <Divider
                      sx={{
                        marginTop: 5,
                        marginBottom: 5,
                        border: "2.5px solid #D9D9D9",
                        backgroundColor: "#F3F3F3",
                        width: "100%",
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: 5,
                        minHeight: "200px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "33%",
                          pr: 3,
                        }}
                      >
                        <PrimaryHeading text={t("Setting.RestrictionsForBookings")} />
                        <SecondaryHeading
                          text={t("Setting.Description5")}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "67%",
                        }}
                      >
                        <Stack
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#1f1f1f", fontWeight: 700 }}
                          >
                            {t("Setting.HowLongBeforeBookOnline")}
                          </Typography>

                          <Tooltip arrow title={tooltipContent}>
                            <img
                              src={TooltipIcon}
                              alt="IconOne"
                              style={{ marginLeft: 4, width: 18, height: 18 }}
                            />
                          </Tooltip>
                        </Stack>

                        <CustomSelect
                          value={
                            formik.values.restrictionForBooking
                              .howLongBeforeCustomerBook
                          }
                          onChange={(event) => {
                            formik.setValues({
                              ...formik.values,
                              restrictionForBooking: {
                                ...formik.values.restrictionForBooking,
                                howLongBeforeCustomerBook: event.target.value,
                              },
                            });
                          }}
                          options={howLongBeforeCustomerOptions}
                          sx={{ width: "25%", marginTop: 1 }}
                        />

                        <Stack
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#1f1f1f", fontWeight: 700 }}
                          >
                            {t("Setting.HowFarIntoTheFutureBook")}
                          </Typography>

                          <Tooltip arrow title={tooltipContent}>
                            <img
                              src={TooltipIcon}
                              alt="IconOne"
                              style={{ marginLeft: 4, width: 18, height: 18 }}
                            />
                          </Tooltip>
                        </Stack>

                        <CustomSelect
                          value={
                            formik.values.restrictionForBooking
                              .howFarFutureCustomerbook
                          }
                          onChange={(event) => {
                            formik.setValues({
                              ...formik.values,
                              restrictionForBooking: {
                                ...formik.values.restrictionForBooking,
                                howFarFutureCustomerbook: event.target.value,
                              },
                            });
                          }}
                          options={howFarFutureCustomerOptions}
                          sx={{ width: "25%", marginTop: 1 }}
                        />

                        <Stack
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                          alignItems={"center"}
                        >
                          {/* <PrimaryHeading
                    text={
                      "How long before the start of the appointment can the customer cancel booking?"
                    }
                  /> */}

                          <Typography
                            variant="body1"
                            sx={{ color: "#1f1f1f", fontWeight: 700 }}
                          >
                            {t("Setting.HowLongBeforeCustomerCancelBooking")}
                          </Typography>

                          <Tooltip arrow title={tooltipContent}>
                            <img
                              src={TooltipIcon}
                              alt="IconOne"
                              style={{ marginLeft: 4, width: 18, height: 18 }}
                            />
                          </Tooltip>
                        </Stack>

                        <CustomSelect
                          value={
                            formik.values.restrictionForBooking
                              .longBeforeCustomerCancelBookingOptionBook
                          }
                          onChange={(event) => {
                            formik.setValues({
                              ...formik.values,
                              restrictionForBooking: {
                                ...formik.values.restrictionForBooking,
                                longBeforeCustomerCancelBookingOptionBook:
                                  event.target.value,
                              },
                            });
                          }}
                          options={longBeforeCustomerCancelBookingOptions}
                          sx={{ width: "25%", marginTop: 1 }}
                        />

                        <Stack
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                          alignItems={"center"}
                        >
                          {/* <PrimaryHeading text={"Cancellation policy"} /> */}
                          <Typography
                            variant="body1"
                            sx={{ color: "#1f1f1f", fontWeight: 700 }}
                          >
                            {t("Setting.CancellationPolicy")}
                          </Typography>

                          <Tooltip arrow title={tooltipContent}>
                            <img
                              src={TooltipIcon}
                              alt="IconOne"
                              style={{ marginLeft: 4, width: 18, height: 18 }}
                            />
                          </Tooltip>
                        </Stack>
                        <TextareaAutosize
                          minRows={10}
                          maxRows={50}
                          value={formik.values.policy}
                          onChange={(event) => {
                            formik.setFieldValue("policy", event.target.value);
                          }}
                          style={{
                            width: "100%",
                            height: "20px",
                            padding: "10px",
                            fontSize: "16px",
                            borderRadius: "10px",
                            border: "2px solid #D9D9D9",
                            outline: "none",
                            resize: "none",
                            marginTop: 10,
                          }}
                        />

                        <Stack
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#1f1f1f", fontWeight: 700 }}
                          >
                            {t("Setting.MessageWhenCustomerTriesToCancelTooLate")}
                          </Typography>

                          <Tooltip arrow title={tooltipContent}>
                            <img
                              src={TooltipIcon}
                              alt="IconOne"
                              style={{ marginLeft: 4, width: 18, height: 18 }}
                            />
                          </Tooltip>
                        </Stack>

                        <CustomTextField
                          value={
                            formik.values.restrictionForBooking
                              .messageForTooLate
                          }
                          onChange={(event) => {
                            formik.setValues({
                              ...formik.values,
                              restrictionForBooking: {
                                ...formik.values.restrictionForBooking,
                                messageForTooLate: event.target.value,
                              },
                            });
                          }}
                        />
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        marginTop: 5,
                        marginBottom: 5,
                        border: "2.5px solid #D9D9D9",
                        backgroundColor: "#F3F3F3",
                        width: "100%",
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        padding: 5,
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          pr: 3,
                        }}
                      >
                        <PrimaryHeading
                          text={t("Setting.DataYouNeedFromYourClients")}
                        />
                        <SecondaryHeading
                          sx={{ whiteSpace: "pre-line" }}
                          text={t("Setting.Description6")}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        {dataNeedsFromClintsFirst.map((option, index) => (

                          <Box
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <FSwitch
                              disabled={option.id === 1 || option.id === 2}
                              checked={
                                formik.values.DataFromClients[option.field]
                              }
                              onChange={(e) => {
                                handleDataNeedsChange(option);
                              }}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: 400,
                                color: "#1F1F1F",
                              }}
                            >
                              {option.label}
                            </Typography>
                          </Box>
                        ))}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        {dataNeedsFromClintsSec.map((option, index) => (
                          <Box
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <FSwitch
                              checked={
                                formik.values.DataFromClients[option.field]
                              }
                              onChange={() => handleDataNeedsChange(option)}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: 400,
                                color: "#1F1F1F",
                              }}
                            >
                              {option.label}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        marginTop: 5,
                        marginBottom: 5,
                        border: "2.5px solid #D9D9D9",
                        backgroundColor: "#F3F3F3",
                        width: "100%",
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        padding: 5,
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {(user?.role === "ADMIN" ||
                        user?.settings.view_all_employees) && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "33%",
                                pr: 3,
                              }}
                            >
                              <PrimaryHeading text={t("Setting.EmployeeSelectionOrder")} />

                              <SecondaryHeading
                                text={t("Setting.Description7")}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "67%",
                                minHeight: "200px",
                                overflowY: "auto",
                              }}
                            >
                              {/* <DraggableListComponent list={items} /> */}
                              {Object.keys(reOrderEmp).length > 0 && (
                                <MultipleContainers
                                  modelType={"Emp"}
                                  itemCount={Object.keys(reOrderEmp).length}
                                  items={reOrderEmp}
                                  setItems={setReorderEmp}
                                  strategy={rectSortingStrategy}
                                  vertical
                                  modifiers={[
                                    restrictToVerticalAxis,
                                    restrictToWindowEdges,
                                  ]}
                                  // onRemove={(containerID) => handleRemoveContianer(containerID)}
                                  // onRemoveItem={(itemId, containerID) => handleRemoveItem(itemId, containerID)}
                                  onDragToAnotherContainer={(
                                    containerId,
                                    itemId
                                  ) => {
                                  }}
                                  onDragComplete={(
                                    isContainer,
                                    containerId,
                                    updatedcontainers
                                  ) => {

                                    let dataToUpdate = [];
                                    if (isContainer) {
                                      let newjournalGroups = { ...reOrderEmp };
                                      updatedcontainers.map(
                                        (containerId, index) => {
                                          if (containerId != 0) {
                                            dataToUpdate.push({
                                              id: containerId,
                                              sequence: index,
                                            });
                                          }
                                          newjournalGroups[containerId].sequence =
                                            index;
                                        }
                                      );

                                      setReorderEmp(newjournalGroups);
                                      updateEmpSequence(dataToUpdate);
                                    }
                                    // else {
                                    //     journalGroups[containerId].services.map((serviceObj) => {
                                    //         dataToUpdate.push({ id: serviceObj.id, group_id: containerId == '0' ? null : containerId, sequence: serviceObj?.sequence })
                                    //     })
                                    // }
                                  }}
                                />
                              )}
                            </Box>
                          </>
                        )}
                    </Box>
                    <Divider
                      sx={{
                        marginTop: 5,
                        marginBottom: 5,
                        border: "2.5px solid #D9D9D9",
                        backgroundColor: "#F3F3F3",
                        width: "100%",
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: 5,
                        minHeight: "200px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "33%",
                          pr: 3,
                        }}
                      >
                        <PrimaryHeading
                          text={t("Setting.IntegrateBookingOnYourWebsite")}
                        />

                        <SecondaryHeading
                          text={t("Setting.Description8")}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "67%",
                          minHeight: "200px",
                          overflowY: "auto",
                          position: "relative",
                        }}
                      >

                        <TextField
                          value={embedCode}
                          multiline
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{
                            "& .MuiInputBase-root": {
                              fontFamily:
                                'Menlo, Monaco, "Courier New", monospace',
                              fontSize: "0.875rem",
                              backgroundColor: "#D9D9D9",
                              color: "text.primary",
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },

                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none", // Remove internal border
                            },
                          }}
                        />

                        <Button
                          variant="contained"
                          onClick={handleCopy}
                          aria-label="Copy code"
                          disableRipple


                          sx={{
                            boxShadow: "none",
                            position: "absolute",
                            right: 0,
                            top: 2,
                            minWidth: "48px",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            height: "40px",
                            backgroundColor: "transparent",
                            "&:hover": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            },
                          }}
                        >
                          <Copy color="black" fontSize="small" />
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Stack>
        </Stack>
      </form>
    </>
  );
};

export default OnlineBookingSettingsOption;
