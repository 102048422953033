import React from 'react';
import { Typography } from '@mui/material';

const FPrimaryHeading = ({ text, fontColor = "#1F1F1F", fontSize = "22px" , sx }) => {
    return (
        <Typography variant='h6' sx={{ fontSize: fontSize, fontWeight: 700, color: fontColor , ...sx }}>
            {text}
        </Typography>
    );
};

export default FPrimaryHeading;