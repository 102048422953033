import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { FDialogTitle } from "../../components/commonComponents/F_DialogTItle";
import CustomTextField from "../../components/settings/commonTextinput";
import FButton from "../../components/commonComponents/F_Button";
import user from "../../assets/User.svg";
import callimg from "../../assets/call.svg";
import apiFetcher from "../../utils/interCeptor";
import { useState } from "react";
import { set } from "date-fns";
import { t } from "i18next";


export default function CustomerBookingForm({
  formik,
  open,
  closeForm,
}) {


  const [isCustomer, setIsCustomer] = useState(false);

  const formatPhoneNumber = (number) => {
    if (number !== "") {
      return number.replace(/(\d{2})(?=\d)/g, "$1 ");
    } else {
      return "";
    }
  };

  const handlePhoneNumberChange = async (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");

    // if (!/^\d*$/.test(value) || value.length > 8) {
    //   setError("Only numbers are allowed, with a maximum of 8 digits.");
    //   return;
    // }
    formik.setFieldValue("phone_number", value);
    setIsCustomer(false);
    if (value.length === 8) {
      try {
        const response = await apiFetcher.get(
          `api/v1/store/booking/customer?phone_no=${value}`
        );
        if (response.data.success && response.data.data) {
          if (response.data.data.name !== " ") {
            formik.setFieldValue("customerName", response.data.data.name);
            setIsCustomer(true);
          }


        } else if (
          response.data.success === false &&
          response.data.msg === "Customer not found"
        ) {


          setIsCustomer(false);
          formik.setFieldValue("customerName", "");
        }
      } catch (err) {
        console.error(err);
        formik.setFieldValue("customerName", "");
        setIsCustomer(false);

        formik.setFieldValue("customerName", "");

      }
    }
  };


  return (
    <>
      <Dialog
        open={open}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={closeForm}
        PaperProps={{
          style: {
            borderRadius: "10px",
          },
        }}
      >
        <FDialogTitle
          id="customized-dialog-title"
          onClose={closeForm}
          height={15}
        >
          {t("Customer.AddNewCustomer")}
        </FDialogTitle>

        <DialogContent
          sx={{ pl: 2, pr: 2, pt: 0, pb: 0, mt: 0, mb: 0, marginTop: 2 }}
        >
          <Box
            component="form"
            sx={{
              p: 0,
              mt: 0,
              // pl: 2,
              // pr: 2,
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "100%",
            }}
          >
            <Box
              noValidate
              component="form"
              sx={{
                p: 2,
                pl: 4,
                paddingTop: 0,
                pr: 4,
                display: "flex",
                flexDirection: "column",
                m: "auto",
                width: "100%",
              }}
              onSubmit={formik.handleSubmit}
            >
              <Stack flex={1} flexDirection={"column"} sx={{ marginBottom: 2 }}>
                <Stack
                  flex={1}
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <img
                    src={callimg}
                    style={{ width: 18, height: 18, marginRight: 2 }}
                    alt="Phone"
                  />
                  <Typography variant="body1" sx={{ color: "#6F6F6F" }}>
                   {t("Calendar.PhoneNumber")}
                  </Typography>
                </Stack>

                <CustomTextField
                  id="phone_number"
                  value={formatPhoneNumber(formik.values.phone_number)}


                  inputProps={{ maxLength: 11, mt: "auto", mb: "auto", }}
                  onChange={handlePhoneNumberChange}
                  placeholder={t("Calendar.PhoneNumber")}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography sx={{ color: "#1f1f1f", marginRight: 1 }}>+45</Typography>
                        </InputAdornment>
                      ),
                    },
                  }}
                  onBlur={() => formik.setFieldTouched("phone_number", true)}
                />
                {formik.touched.phone_number && formik.errors.phone_number && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>

                    <Typography variant="caption" color="red">
                      {formik.errors.phone_number}
                    </Typography>
                  </Box>
                )}
              </Stack>
              <Stack flex={1} flexDirection={"column"}>
                <Stack
                  flex={1}
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <img
                    src={user}
                    style={{ width: 18, height: 18, marginRight: 2 }}
                    alt="user"
                  />
                  <Typography
                    variant="body1"
                    sx={{ color: "#6F6F6F", width: "100%" }}
                  >
                    {" "}
                  {t("Customer.CustomerName")}
                  </Typography>

                </Stack>

                <CustomTextField
                  id="customerName"
                  name="customerName"
                  // disabled={isCustomer}
                  value={formik.values.customerName}
                  sx={{ marginTop: 1, width: "100%" }}
                  onChange={formik.handleChange}
                  placeholder={t("Customer.CustomerName")}
                />
                {formik.touched.customerName && formik.errors.customerName && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {/* <Error sx={{ color: "red" }} /> */}
                    <Typography variant="caption" color="red">
                      {formik.errors.customerName}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", mb: 2, gap: 2, mt: 1 }}>
          <FButton
            type={"submit"}
            style={{
              margin: "auto",
              marginBottom: 5,
              width: "60%",
              backgroundColor: "#BBB0A4",
              color: "white",
            }}
            title={isCustomer ? t("Common.Continue") : t("Customer.AddNewCustomer")}
            titleColor={"#fff"}
            onClick={formik.handleSubmit}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
