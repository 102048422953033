//map working

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import FPrimaryHeading from "../../../commonComponents/F_PrimaryHeading";
import moment from "moment";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import '../../../../cssQuill.css';
import FButton from "../../../commonComponents/F_Button";
import { ExpandMore } from "@mui/icons-material";
import FCommonTable from "../../../commonComponents/F_commonTable";
import DynamicDate from "./dynamicFields/DynamicDate";
import DynamicUpload from "./dynamicFields/DynamicUpload";
import DynamicCpr from "./dynamicFields/DynamicCpr";
import DynamicEditor from "./dynamicFields/DynamicEditor";
import DynamicTabs from "./dynamicFields/DynamicTabs";
import DynamicCheckBox from "./dynamicFields/DynamicCheckBox";
import DynamicSelect from "./dynamicFields/DynamicSelect";
import DynamicTextinput from "./dynamicFields/DynamicTextinput";
import DynamicTextarea from "./dynamicFields/DynamicTextarea";
import DynamicSignature from "./dynamicFields/DynamicSignature";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DynamicRadio from "./dynamicFields/DynamicRadio";
import apiFetcher from "../../../../utils/interCeptor";
import { HttpStatusCode } from "axios";
import CompairImage from "./CompairImage";
import { t } from "i18next";
import SaveModal from "./popup/SaveModal";
import { useSelector } from "react-redux";
import { useBlocker, useNavigate } from "react-router-dom";
import CustomDeleteModal from "../../../deleteAlertModal";
import { route } from "../../../../context/routeSlice";
import { useDispatch } from "react-redux";
import { apiJson } from "./apidata";
import DynamicImage from "./dynamicFields/DynamicImage";
import DynamicTitle from "./dynamicFields/DynamicTitle";

export default function AdvancedJournalView({ setShow, templates, selectedAdvancedJournalId, customer, recallApi }) {

    const [activeTab, setActiveTab] = useState();
    const [formattedData, setFormattedData] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [currentTabFields, setCurrentTabFields] = useState([]);
    const [remainingFields, setRemainingFields] = useState([]);
    const [validationSchema, setValidationSchema] = useState(null);
    const [current, setCurrent] = useState(1111);
    const [allImagesForCompair, setAllImagesForCompair] = useState([]);
    const [logs, setLogs] = useState([]);
    const [selectedAdvancedJournal, setSelectedAdvancedJournal] = useState({})
    const [loading, setLoading] = useState({})
    const [btnType, setBtnType] = useState(null);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const [confirmCompleted, setConfirmCompleted] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [compairModal, setCompairModal] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [propsForCompair, setPropsForCompair] = useState(null);
    const [confirmQuit, setConfirmQuit] = useState({
        modal: false,
        prop: null
    });
    const user = useSelector((state) => state.user.data);
    const dispatch = useDispatch();
    const selectedRoute = useSelector((state) => state.route.route);
    const pathname = new URL(window.location.href).pathname;
    const navigate = useNavigate();
    const [isBlocking, setIsBlocking] = useState(false);



    useEffect(() => {
        const fetchFixedData = async () => {
            setLoading(true);
            try {
                const response = await apiFetcher.get(`api/v1/store/advance_journal/${selectedAdvancedJournalId?.id}?employee_id=${user?.id}`);
                if (response.status === HttpStatusCode.Ok) {
                    let advancedJournal = response?.data?.data?.advance_journal;
                    setPropsForCompair({
                        date: advancedJournal?.created_at,
                        employee_name: advancedJournal?.employee_name,
                    })
                    if (advancedJournal?.data === null) {
                        const formatResponse = await apiFetcher.get(`api/v1/store/advance_journal/format`);
                        if (formatResponse.status === HttpStatusCode.Ok) {
                            setIsCreating(true);
                            advancedJournal.data = formatResponse?.data?.data;
                            // in the advancedjournal.format.taboptions[0].details[0].fields.map((field) => field.type = 'editor' ? field.value = templates.find((temp) => temp.id === selectedAdvancedJournalId.template_id).detail : null);
                            let newEditorFilled = {
                                ...advancedJournal,
                                data: {
                                    ...advancedJournal.data,
                                    format: {
                                        ...advancedJournal.data.format,
                                        tabsOptions: advancedJournal.data.format.tabsOptions.map((tabOption, index) => {
                                            if (index === 0) {
                                                return {
                                                    ...tabOption,
                                                    details: tabOption.details.map((detail, detailIndex) => {
                                                        if (detailIndex === 0) {
                                                            return {
                                                                ...detail,
                                                                fields: detail.fields.map((field) => {
                                                                    if (field.type === 'editor') {
                                                                        field.value = templates.find((temp) => temp.id === selectedAdvancedJournalId.template_id).detail;
                                                                    }
                                                                    return field;
                                                                })
                                                            };
                                                        }
                                                        return detail;
                                                    })
                                                };
                                            }
                                            return tabOption;
                                        })
                                    }
                                }
                            };

                            setSelectedAdvancedJournal(newEditorFilled || advancedJournal);
                            setLogs(response?.data?.data?.log);
                        }
                    } else {
                        setSelectedAdvancedJournal(advancedJournal);
                        setLogs(response?.data?.data?.log);
                    }

                    if (advancedJournal?.data?.format?.tabsOptions?.length > 0) {
                        setActiveTab(advancedJournal?.data?.format?.tabsOptions[0]?.tab_id);
                        handleCurrentChange(null, advancedJournal?.data?.format?.tabsOptions[0]?.tab_id);
                    }
                }
            } catch (error) {
                toast.error(t("Customer.ADVDefaultError"));
                console.error("Error fetching templates:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchFixedData()
    }, []);


    useEffect(() => {
        if (activeTab) {
            handleCurrentChange(null, activeTab);
        }
    }, [activeTab]);

    const handleCurrentChange = async (currentId, tabId, version = null) => {
        if (tabId) {
            const selectedTab = selectedAdvancedJournal?.data?.format?.tabsOptions.find((tab) => tab.tab_id === tabId);

            if (!selectedTab || !selectedTab.details?.length) {
                console.error("Tab details not found!");
                setCurrentTabFields([]);
                setFormattedData({});
                return;
            }

            setFormattedData(selectedTab);

            let currentDetail;
            if (version) {
                currentDetail = version; // Load the selected version instead of the latest detail
            } else {
                currentDetail = currentId
                    ? selectedTab.details.find((detail) => detail.detail_id === currentId)
                    : selectedTab.details[0]; // Default to first detail
            }

            if (!currentDetail) {
                console.error("Current detail not found!");
                return;
            }

            const filteredVersions = selectedTab.earlier_version?.length
                ? selectedTab.earlier_version.sort((a, b) => b.version_id - a.version_id)
                : [];

            const { schemaObject, initialValues } = createValidationSchema(currentDetail.fields);

            let disableField = selectedAdvancedJournalId.completed ? true : false;
            const images = currentDetail.fields.filter((field) => field.type === 'upload').flatMap((field) => field.value);

            setCurrent(currentDetail.detail_id);
            setCurrentTabFields([currentDetail]); // Set the selected version's fields
            setRemainingFields(filteredVersions);
            setInitialValues({ ...initialValues, disabled: disableField });
            setValidationSchema(schemaObject);
            setAllImagesForCompair(images);
        }
    };


    const createValidationSchema = (fields = []) => {
        const schemaObject = {};
        const initialValues = {};

        fields.forEach((field, i) => {
            let validation;

            // Initialize initial values for each field
            // if (field.type === 'editor') {
            //     // For editor, you need both value and name in initialValues
            //     initialValues[`${field.type}-${field?.field_id}-value`] = field?.value;
            //     initialValues[`${field.type}-${field?.field_id}-journal_title`] = field?.journal_title;
            // } else
            if (field.type === 'select') {
                initialValues[`${field.type}-${field?.field_id}-value`] = field?.value;
                initialValues[`${field.type}-${field?.field_id}-value_other`] = field?.value_other;
            } else if (field.type === 'autoFill_name' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.name; // Use ?? for nullish coalescing
            } else if (field.type === 'autoFill_email' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.email;
            } else if (field.type === 'autoFill_phone' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.phone_number;
            } else if (field.type === 'autoFill_birthday' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.birthday;
            } else if (field.type === 'autoFill_address' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.address;
            } else if (field.type === 'autoFill_cpr' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.cpr;
            } else if (field.type === 'autoFill_zip' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.zip_code;
            } else if (field.type === 'autoFill_city' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.city;
            } else if (field.type === 'autoFill_phone_alt' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.phone_number2;
            } else if (field.type === 'autoFill_notes' && field?.autofill) {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? customer?.marketplace_pointer;
            } else {
                initialValues[`${field.type}-${field?.field_id}`] = field?.value ?? ''; // Default empty string if value is null or undefined
            }

            // Now handle the validation schema based on the field type
            if (field.type === 'date') {
                validation = field.required ? Yup.date().required(`${field.name} ${t("Customer.IsRequired")}`).typeError(`${field.name} ${t("Customer.IsRequired")}`) : Yup.date();
            } else if (field.type === 'cpr' || field.type === 'autoFill_cpr') {
                validation = field.required ? Yup.string().required(`${field.name} ${t("Customer.IsRequired")}`).typeError(`${field.name} ${t("Customer.IsRequired")}`) : Yup.string();
            } else if (field.type === 'upload') {
                validation = field.required ? Yup.array().min(1, `At least one image ${t("Customer.IsRequired")}`).typeError(`At least one image ${t("Customer.IsRequired")}`) : Yup.array();
            } else if (field.type === 'select' || field.type === 'radio') {
                validation = field.required ? Yup.string().required(`${field.name} ${t("Customer.IsRequired")}`) : Yup.string().nullable();
            } else if (field.type === 'signature') {
                validation = field?.required ? Yup.object().required(`${field.name} ${t("Customer.IsRequired")}`).test('required', `${field.name} ${t("Customer.IsRequired")}`, (value) => value && Object.keys(value).length > 0) : Yup.object().nullable();
            } else if (field.type === 'checkbox') {
                validation = field?.required ? Yup.array().required(`${field.name} ${t("Customer.IsRequired")}`).test('required', `${field.name} ${t("Customer.IsRequired")}`, (value) => value && value.length > 0) : Yup.array().nullable();
            } else if (field.type === 'autoFill_phone' || field.type === 'autoFill_phone_alt') {
                validation = field?.required ? Yup.string().required(`${field.name} ${t("Customer.IsRequired")}`).min(8, t("Customer.PhoneNumberInvalid")).max(8, t("Customer.PhoneNumberInvalid")).typeError(`${field.name} ${t("Customer.IsRequired")}`) : Yup.string().min(8, t("Customer.PhoneNumberInvalid")).max(8, t("Customer.PhoneNumberInvalid")).nullable();
            } else if (field.type === 'autoFill_zip') {
                validation = field?.required ? Yup.number().required(`${field.name} ${t("Customer.IsRequired")}`) : Yup.number().nullable();
            } else if (field.type === 'autoFill_email') {
                validation = field?.required ? Yup.string().email(`${field.name} ${t("Setting.InvalidEmailFormat")}`).required(`${field.name} ${t("Customer.IsRequired")}`) : Yup.string().email(t(`${field.name} ${t("Setting.InvalidEmailFormat")}`)).nullable();
            } else {
                validation = field?.type !== 'editor' && field?.required ? Yup.string().required(`${field.name} ${t("Customer.IsRequired")}`) : Yup.string().nullable();
            }

            // Add the validation schema to schemaObject
            schemaObject[`${field.type}-${field?.field_id}`] = validation;
        });

        return { schemaObject, initialValues };
    };


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        onSubmit: (values) => {
            let { disabled, ...nonDisabled } = values;

            let newVal = {
                ...Object.entries(nonDisabled).reduce((acc, [key, value]) => {
                    const parts = key.split('-');

                    // if (parts[0] === 'editor') {
                    //     const editorKey = parts[2];

                    //     if (editorKey === 'journal_title' || editorKey === 'value') {
                    //         acc['editor'] = acc['editor'] || {};
                    //         acc['editor'][editorKey] = value;
                    //     }
                    // } else
                    if (parts[0] === 'select') {
                        const selectKey = parts[2];

                        if (selectKey === 'value' || selectKey === 'value_other') {
                            const fieldId = parts[1];
                            acc[`select-${fieldId}`] = acc[`select-${fieldId}`] || {};
                            acc[`select-${fieldId}`][selectKey] = value;
                        }
                    } else {
                        const newKey = parts[1];
                        acc[newKey] = value;
                    }
                    return acc;
                }, {})
            };


            const updateDetailUtil = ({ detail, current, newVal, isVersion = false }) => {
                if (detail.detail_id === current) {
                    const { save, posted, ...rest } = detail;
                    return {
                        ...rest,
                        fields: detail.fields.map((field) => {
                            if (newVal[field.field_id]) {
                                return {
                                    ...field,
                                    value: newVal[field.field_id]
                                };
                            }
                            // Specifically for 'editor' field, handle both 'name' and 'value'
                            // if (field.type === 'editor' && newVal.editor) {
                            //     return {
                            //         ...field,
                            //         journal_title: newVal.editor.journal_title || field.journal_title,
                            //         value: newVal.editor.value || field.value,
                            //     };
                            // } else
                            if (field.type === 'select' && newVal[`select-${field.field_id}`]) {
                                return {
                                    ...field,
                                    value: newVal[`select-${field.field_id}`].value || field.value,
                                    value_other: newVal[`select-${field.field_id}`].value_other || field.value_other
                                };
                            }

                            return field;
                        }),
                        version_id: isVersion ? moment().valueOf() : undefined,
                        version_date: isVersion ? moment().format('YYYY-MM-DDTHH:mm:ss') : undefined
                    };
                }
                return detail;
            };

            const newFormattedData = {
                ...formattedData,
                earlier_version: formattedData.earlier_version
                    ? [
                        ...formattedData.earlier_version.map((version) => ({
                            ...version,
                        })),
                        ...formattedData.details.map((detail) =>
                            updateDetailUtil({ detail, current, newVal, isVersion: true })
                        ),
                    ]
                    : formattedData.details.map((detail) =>
                        updateDetailUtil({ detail, current, newVal, isVersion: true })
                    ),
                details: formattedData.details.map((detail) =>
                    updateDetailUtil({ detail, current, newVal, isVersion: false })
                ),
            };

            // console.log('newFormattedData', newFormattedData);

            setFormattedData(newFormattedData);
            handleSaveOrPosted(newFormattedData);
        }
    });


    const handleSaveOrPosted = (data) => {

        const newSelectedAdvancedJournal = {
            id: selectedAdvancedJournal.id,
            employee_id: selectedAdvancedJournal.employee_id,
            completed: btnType === 'save' ? false : true,
            data: {
                ...selectedAdvancedJournal.data,
                format: {
                    ...selectedAdvancedJournal.data.format,
                    tabsOptions: selectedAdvancedJournal.data.format.tabsOptions.map((tab) => {
                        if (tab.tab_id === data.tab_id) {
                            return {
                                ...data
                            }

                        }
                        return tab;
                    })
                }
            }
        }

        let StringiFiedData = {
            ...newSelectedAdvancedJournal,
            data: JSON.stringify(newSelectedAdvancedJournal?.data)
        }

        if (newSelectedAdvancedJournal) {
            apiFetcher.patch(`/api/v1/store/advance_journal`, StringiFiedData).then((response) => {
                if (response.status === HttpStatusCode.Ok) {
                    toast.success(btnType === 'save' ? t("Customer.ADVSaveSuccess") : t("Customer.ADVCompleteSuccess"));
                    setTimeout(() => {
                        recallApi(customer?.id);
                        setShow('Table')
                    }, 1000)
                }
            }).catch((error) => {
                toast.error(t("Customer.ADVChangeError"));
            });
        }

        // if(newSelectedAdvancedJournal){
        //     formik.handleSubmit()
        // }

    }


    useEffect(() => {
        formik.setValues(initialValues)
    }, [initialValues])

    let logColumns = [
        { id: 'id', label: t('Common.ID'), sortable: false },
        { id: 'incident', label: t("Customer.Incedent"), sortable: false },
        { id: 'date', label: t("Common.Date"), sortable: true },
        { id: 'employees', label: t("Setting.Employees"), sortable: false },
        { id: 'ip', label: t("Customer.IP"), sortable: false },
    ]
    const logObj = {
        "OPENED": "Åbnet",
        "EDITED": "Redigeret",
        "CREATED": "Oprettet",
    }
    let lang = localStorage.getItem('language')

    let dataForLogs = logs && logs?.map((log) => ({
        id: log.id,
        incident: lang == 'en' ? log.event : logObj[log.event],
        // 2025-02-13T10:56:40.223348Z
        date: moment(log.denmark_created_at, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM-YY HH:mm'),
        employees: log.employee_name,
        ip: log.ip_address
    }))


    const handlePrint = async (id) => {
        try {
            setDownloading(true);
            const response = await apiFetcher.get(`api/v1/store/advance_journal/pdf/${id}`, { responseType: 'blob' });
            if (response.status === HttpStatusCode.Ok) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `journal-${customer?.name}.pdf`;
                link.click();
                URL.revokeObjectURL(url);
            }

        } catch (error) {
            toast.error(t("Customer.PDFError"));
        } finally {
            setDownloading(false);
        }
    }

    const handleTouched = () => {
        Object.keys(formik.values).forEach((key) => {
            formik.setFieldTouched(key, true);
        })
    }

    const handleBeforeUnload = (event) => {
        if (formik.dirty) {
            // window.onbeforeunload = function () {
            //     return;
            // };
            // setConfirmQuit((prev) => ({ modal: true, prop: activeTab }));
            event.preventDefault();
            event.returnValue = ""; // Required for Chrome
        }
    };



    const blocker = useBlocker(() => {
        return isBlocking;
    })

    useEffect(() => {
        if (formik && formik.dirty) {
            window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
            setIsBlocking(formik.dirty);
        } else {
            window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
            setIsBlocking(false);
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload, { capture: true });
        };
    }, [formik])


    const handleTabChange = (tab) => {
        if (formik?.dirty) {
            setConfirmQuit((prev) => ({ modal: true, prop: tab }));
        } else {
            setActiveTab(tab);
        }
    }

    // useEffect(() => { console.log("selectedAdvancedJournal", selectedAdvancedJournal) }, [selectedAdvancedJournal])

    if (loading) return <Stack sx={{
        position: 'absolute',
        zIndex: 110,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.1)'
    }}>
        <CircularProgress size='2.5rem' sx={{ color: '#6f6f6f' }} />
    </Stack>


    return (
        <Stack id={'top-of-customer-advanced-journal'} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <ToastContainer />
            <FPrimaryHeading text={t("Customer.AdvancedJournal")} fontColor="#545454" />


            <DynamicTabs activeTab={activeTab} setActiveTab={handleTabChange} tabOptions={selectedAdvancedJournal?.data?.format?.tabsOptions} />

            <Stack mt={-5} bgcolor={'#fff'} sx={{ display: "flex", flexDirection: "column", gap: 4, p: 6, borderRadius: 6, zIndex: 1, minHeight: "100vh" }}  >

                {(activeTab === formattedData?.tab_id) ?
                    <>
                        {!isCreating &&
                            <Stack ml={'auto'} display={'flex'} flexDirection={'column'} width={'15%'} style={{}} >
                                <FButton disabled={downloading} onClick={() => handlePrint(selectedAdvancedJournal?.id)} title={downloading ? <CircularProgress size='1.5rem' sx={{ color: '#fff' }} /> : t("Customer.PrintPDF")} variant={'save'} sx={{ width: '100%', bgcolor: '#d9d9d9' }} />
                            </Stack>
                        }
                        <Formik initialValues={initialValues} enableReinitialize onSubmit={formik.handleSubmit} >
                            <>
                                {(currentTabFields && currentTabFields[0]?.fields?.length) && currentTabFields[0]?.fields
                                    .sort((a, b) => a.fieldSequence - b.fieldSequence)
                                    .map((field, index) => (
                                        <>
                                            {
                                                (field.type === 'date' || field.type === 'autoFill_birthday') ? <DynamicDate btnType={btnType} formik={formik} field={field} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                    : (field.type === 'cpr' || field.type === 'autoFill_cpr') ? <DynamicCpr btnType={btnType} formik={formik} field={field} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                        : field.type === `upload` ? <DynamicUpload allImagesForCompair={allImagesForCompair} setAllImagesForCompair={setAllImagesForCompair} isCreating={isCreating} advancedJournalId={selectedAdvancedJournal?.id} compairImage={() => setCompairModal(true)} field={field} formik={formik} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                            : field.type === `image` ? <DynamicImage field={field} formik={formik} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                                : field.type === 'editor' ? <DynamicEditor templateValues={selectedAdvancedJournalId} templates={templates} formik={formik} field={field} TitleName={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}-journal_title`)} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}-value`)} />
                                                                    : field.type === 'checkbox' ? <DynamicCheckBox field={field} formik={formik} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                                        : field.type === 'select' ? <DynamicSelect formik={formik} field={field} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}-value`)} otherName={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}-value_other`)} />
                                                                            : field.type === 'radio' ? <DynamicRadio field={field} formik={formik} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                                                : (field.type === 'text' || field.type === 'autoFill_name' || field.type === 'autoFill_city' || field.type === 'autoFill_zip' || field.type === 'autoFill_email' || field.type === 'autoFill_phone' || field.type === 'autoFill_phone_alt' || field.type === 'small_text') ? <DynamicTextinput formik={formik} field={field} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                                                    : (field.type === 'textarea' || field.type === 'autoFill_address' || field.type === 'autoFill_note') ? <DynamicTextarea formik={formik} field={field} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                                                        : field.type === 'signature' ? <DynamicSignature advancedJournalId={selectedAdvancedJournal?.id} formik={formik} field={field} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                                                            : field.type === 'title' ? <DynamicTitle formik={formik} field={field} name={Object.keys(initialValues).find(key => key === `${field.type}-${field?.field_id}`)} />
                                                                                                : null
                                            }

                                        </>
                                    ))
                                }

                                {formik.values?.disabled === false &&
                                    <Stack m={0} display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                        <FButton
                                            onClick={() => {
                                                handleTouched();
                                                setBtnType('save')
                                                if (Object.keys(formik.errors).length > 0) {
                                                    toast.error(t("Customer.FormError"));
                                                    return;
                                                } else {
                                                    setSaveConfirm(true);
                                                }
                                            }}
                                            title={t("Common.Save")}
                                            variant="save"
                                            sx={{ width: '10%' }}
                                        />
                                        {!isCreating &&
                                            <FButton
                                                onClick={() => {
                                                    handleTouched();
                                                    setConfirmCompleted(true);
                                                }}
                                                title={t("Customer.CompleteJournal")}
                                                variant="primary"
                                                sx={{ width: '15%', ml: 'auto', bgcolor: '#E19957', color: 'white' }}
                                                titlesx={{ whiteSpace: 'nowrap' }}
                                            />
                                        }
                                    </Stack>}
                            </>
                        </Formik>



                        {remainingFields && remainingFields.length >= 1 ?
                            <Accordion
                                defaultExpanded
                                sx={{
                                    boxShadow: "none",
                                    display: "flex",
                                    flexDirection: "column",
                                    bgcolor: "#fff",
                                    borderRadius: "15px",
                                    border: "1px solid #D9D9D9",
                                    scrollbarWidth: "none",
                                    overflowX: "hidden",
                                    width: "100%",

                                }} >
                                <AccordionSummary
                                    sx={{ border: 'none' }}
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {/* <FPrimaryHeading text='Earlier versions' fontColor="#545454" /> */}
                                    <Typography variant="body1" fontWeight={700} color="#545454">{t("Customer.EarlierVersions")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0, overflow: 'hidden' }}>

                                    <TableContainer sx={{ borderRadius: 3 }} component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow sx={{ bgcolor: '#F8F8F8' }} >
                                                    <TableCell width={"10%"} ><Typography sx={{ ml: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1" fontWeight={700} > {t("Common.View")} </Typography></TableCell>
                                                    <TableCell width={"10%"} ><Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1" fontWeight={700} > {t("Common.Date")} </Typography></TableCell>
                                                    <TableCell width={"30%"} ><Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1" fontWeight={700} >{t("Customer.Journal")}  </Typography></TableCell>
                                                    <TableCell width={"15%"} ><Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1" fontWeight={700} > {t("Customer.PictureBefre")} </Typography></TableCell>
                                                    <TableCell width={"15%"} ><Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1" fontWeight={700} > {t("Customer.PictureAfter")} </Typography></TableCell>
                                                    <TableCell width={"15%"} ><Typography sx={{ ml: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1" fontWeight={700} > {t("Customer.JournalNotes")}  </Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody sx={{ bgcolor: '#fff' }}>
                                                {remainingFields.map((journal) => (
                                                    <TableRow key={journal.detail_id}>
                                                        <TableCell>
                                                            <FButton
                                                                variant={'save'}
                                                                title={t("Common.View")}
                                                                onClick={() => {
                                                                    // scroll to the top of the page
                                                                    let id = document.getElementById('top-of-customer-advanced-journal')
                                                                    id?.scrollIntoView({ behavior: 'smooth' })
                                                                    handleCurrentChange(journal?.detail_id, activeTab, journal);
                                                                }}
                                                                sx={{ backgroundColor: "#d9d9d9" }} />
                                                        </TableCell>

                                                        <TableCell>
                                                            <Typography variant="caption" >
                                                                {moment(journal?.version_date, "YYYY-MM-DDTHH:mm:ss").isValid() && moment(journal?.version_date, "YYYY-MM-DDTHH:mm:ss").format('DD/MM/YYYY HH:mm')}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell><div dangerouslySetInnerHTML={{ __html: journal?.fields?.find((field) => field.type === "editor")?.value }} /></TableCell>


                                                        <TableCell>
                                                            <Stack p={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: '180px', overflow: 'hidden', overflowX: 'scroll', gap: 2, scrollbarWidth: 'none' }}>
                                                                {journal?.fields
                                                                    ?.filter(field => field.type === 'upload') // Filter only the 'upload' type fields
                                                                    ?.slice(0, 1) // Get the first 'upload' field (Picture Before)
                                                                    ?.map((field, index) => (
                                                                        field?.value?.map((img, imgIndex) => (
                                                                            <Stack mt={2} key={imgIndex}>
                                                                                <img src={`${process.env.REACT_APP_IMG_URL}${img?.attachment}`} alt={`Uploaded-image`} style={{ width: '150px', height: '150px', objectFit: 'contain' }} />
                                                                            </Stack>
                                                                        ))
                                                                    ))
                                                                }
                                                            </Stack>
                                                        </TableCell>

                                                        <TableCell>
                                                            <Stack p={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: '180px', overflow: 'hidden', overflowX: 'scroll', gap: 2, scrollbarWidth: 'none' }}>
                                                                {journal?.fields
                                                                    ?.filter(field => field.type === 'upload') // Filter only the 'upload' type fields
                                                                    ?.slice(1, 2) // Get the second 'upload' field (Picture After)
                                                                    ?.map((field, index) => (
                                                                        field?.value?.map((img, imgIndex) => (
                                                                            <Stack mt={2} key={imgIndex}>
                                                                                <img src={`${process.env.REACT_APP_IMG_URL}${img?.attachment}`} alt={`Uploaded-image`} style={{ width: '150px', height: '150px', objectFit: 'contain' }} />
                                                                            </Stack>
                                                                        ))
                                                                    ))
                                                                }
                                                            </Stack>
                                                        </TableCell>



                                                        <TableCell>
                                                            <Typography variant="caption" >
                                                                {journal?.fields?.find((field) => field.type === "textarea")?.value}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>


                                </AccordionDetails>
                            </Accordion>
                            : null
                        }



                        <Accordion
                            sx={{
                                boxShadow: "none",
                                display: "flex",
                                flexDirection: "column",
                                bgcolor: "#fff",
                                borderRadius: "15px",
                                border: "1px solid #D9D9D9",
                                scrollbarWidth: "none",
                                overflowX: "hidden",
                                width: "100%",
                            }}
                            m={0}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ color: "#545454" }} variant="body1" fontWeight={700}>
                                    {t("Customer.Logs")}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 0, overflow: 'hidden' }}>

                                <FCommonTable
                                    headerColor="#F8F8F8"
                                    borderRadius={0}
                                    visibleColumns={['incident', 'date', 'employees', 'ip']}
                                    columnWidths={{ incident: '30%', date: '30%', employees: '30%', ip: '15%' }}
                                    columns={logColumns} data={dataForLogs}
                                    wrap={true}
                                // loading={asComponent ? loadingAsComp.previous : false} onRowClick={() => { }}
                                />


                            </AccordionDetails>
                        </Accordion>
                    </>
                    :
                    (activeTab === formattedData?.tab_id?.length === 0) ?
                        <Stack sx={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size='2.5rem' sx={{ color: '#6f6f6f' }} />
                        </Stack>
                        :
                        <Stack sx={{ height: '100dvh', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="body1">{t("Customer.NoDataFound")}</Typography>
                        </Stack>
                }
            </Stack>

            {saveConfirm &&
                <SaveModal
                    title={t("Customer.SaveJournal")}
                    open={saveConfirm}
                    handleClose={() => setSaveConfirm(false)}
                    dismissColor={"#fff"}
                    dismissBg={"#D9D9D9"}
                    ConfirmColor={"#fff"}
                    ConfirmBg={"#44B904"}
                    description={
                        <Typography sx={{ whiteSpace: 'pre-line' }}>
                            {t("Customer.SaveJournalMOdelText")}
                        </Typography>
                    }
                    onClickDismiss={() => setSaveConfirm(false)}
                    onClickConfirm={() => { formik.handleSubmit(); setSaveConfirm(false) }}
                />
            }


            {confirmCompleted &&
                <SaveModal
                    title={t("Common.Complete")}
                    open={confirmCompleted}
                    dismissColor={"#fff"}
                    dismissBg={"#D9D9D9"}
                    dismissText={t("Common.Dismiss")}
                    ConfirmColor={"#fff"}
                    ConfirmBg={"#44B904"}
                    ConfirmText={t("Common.Complete")}
                    handleClose={() => setConfirmCompleted(false)}
                    description={
                        <Typography sx={{ whiteSpace: 'pre-line' }}>
                            {t("Customer.CompleteAdvJournalMOdelText")}
                        </Typography>
                    }
                    onClickDismiss={() => setConfirmCompleted(false)}
                    onClickConfirm={() => { setConfirmCompleted(false); setBtnType('posted'); handleSaveOrPosted('posted') }}
                />
            }


            {compairModal &&
                <CompairImage isCreating={isCreating} propsForCompair={propsForCompair} oepn={compairModal} onClose={() => setCompairModal(false)} imagesForProps={allImagesForCompair}
                />}

            {(confirmQuit?.modal || blocker.state === 'blocked') &&
                <CustomDeleteModal
                    title={t("Customer.SaveChangesTitle")}
                    description={t("Customer.SaveChangesDescription")}
                    open={confirmQuit?.modal || blocker.state === 'blocked'}

                    handleClose={() => {
                        blocker.state === 'blocked' && blocker?.reset();
                        setConfirmQuit(() => ({ prop: null, modal: false }))
                        dispatch(route(pathname));
                    }}

                    onClickDismiss={() => {
                        blocker.state === 'blocked' && blocker?.reset();
                        setConfirmQuit(() => ({ prop: null, modal: false }))
                        dispatch(route(pathname));
                    }}

                    onClickConfirm={() => {
                        setActiveTab(confirmQuit?.prop);
                        blocker.state === 'blocked' && blocker?.proceed();
                        setConfirmQuit(() => ({ prop: null, modal: false }));
                        blocker.state === 'blocked' && navigate("/" + selectedRoute)
                    }}
                />
            }

        </Stack >
    );
}