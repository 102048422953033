import React from 'react';
import { Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CommonButton from './settings/commonButton';

import SecondaryHeading from './settings/commonSecondaryHeading';
import PrimaryHeading from './settings/commonPrimaryHeading';
import { t } from 'i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: '#FFFFFF',
    borderRadius: 3,
    boxShadow: 0,
    p: 3.5,
};

function CustomDeleteModal(props) {
    const { open, handleClose, description, title, type, onClickDismiss, onClickConfirm, dismissColor = "#44B904", dismissBg = "transparent", ConfirmColor = "#fff", ConfirmBg = "#D30000" } = props;

    const closeModal = () => {
        handleClose();
    };

    const handleCloseModal = (event, reason) => {
        if (reason === 'backdropClick') {
            return
        }
    };


    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={[style, {
                zIndex: 12,
                justifyContent: 'center', alignItems: 'center',
                overflowY: { xs: 'auto', sm: '85vh' },
                overflowX: 'hidden',
                maxHeight: { xs: '90vh', sm: '85vh' },
                position: 'relative'
            }]}>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => closeModal()}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '30px',
                            zIndex: 10,
                            color: 'black'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <PrimaryHeading text={title ? title : t("Common.Delete")} />

                <Box style={{ display: 'flex', width: '100%' }}>
                    <SecondaryHeading fontColor='#6F6F6F' text={description} />
                </Box>

                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', marginTop: 15, gap: 2 }}>
                    <CommonButton
                        width="150px"
                        height="40px"
                        minWidth="150px"
                        backgroundColor={dismissBg}
                        titleColor={dismissColor}
                        onClick={onClickDismiss}
                        title={t("Common.Dismiss")}
                    />

                    <CommonButton
                        width="150px"
                        height="40px"
                        backgroundColor={ConfirmBg}
                        titleColor={ConfirmColor}
                        onClick={onClickConfirm}
                        title={t("Common.Confirm")}
                    />
                </Box>

            </Box>

        </Modal>
    )
}

export default CustomDeleteModal;
