import React, { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import FPrimaryHeading from '../../../commonComponents/F_PrimaryHeading'
import FCommonTable from '../../../commonComponents/F_commonTable'
import FButton from '../../../commonComponents/F_Button'
import AddNewJournalForm from './popup/AddNewJournalForm'
import moment from 'moment'
import { ToastContainer } from 'react-toastify'
import { t } from 'i18next'


export default function TableView({ templates, getAdvancedTemplates, customerAdvancedTemplates, setSelectedAdvancedJournalId, loading, setShow, customer }) {

  const [addNewJournalModal, setAddNewJournalModal] = useState(false)

  const columns = [
    { id: 'created', label: t('Common.Created'), sortable: true },
    { id: 'name', label: t('Common.Name'), sortable: true },
    { id: 'template', label: t('Common.Template'), sortable: true },
    { id: 'updated_at', label: t('Common.LastEdited'), sortable: true },
    { id: 'created_by', label: t('Common.CreatedBy'), sortable: false },
    { id: 'status', label: t('Common.Status'), sortable: false },
    { id: 'journal_id', label: t('Common.JournalID'), sortable: false },
    { id: 'GoToComponent', label: t('Common.Edit'), sortable: false }
  ];

  const dataForTable = customerAdvancedTemplates?.map((item) => ({
    id: item.id,
    created: item.created_at ? moment.parseZone(item.created_at).format('DD/MM-YY HH:mm') : '',
    name: item.title,
    template: item.template_name,
    updated_at: item.updated_at ? moment.parseZone(item.updated_at).format('DD/MM-YY HH:mm') : '',
    created_by: item.employee_name,
    status: item.completed ? <Typography sx={{ color: '#367B3D' }}>{t('Common.Completed')}</Typography> : <Typography sx={{ color: '#E19957' }}>{t('Common.Open')}</Typography>,
    journal_id: item.id,
    GoToComponent: (
      <FButton
        onClick={() => {
          setSelectedAdvancedJournalId(item)
          setShow("Advanced");
        }}
        title={item.completed ? t('Common.View') : t('Common.Correct')}
        sx={{
          width: '100%',
          backgroundColor: item.completed ? '#D9D9D9' : '#E19957',
          color: '#ffffff'
        }}
      />
    ), // The custom component for the GoTo column
  }));


  return (
    <Stack sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
      <ToastContainer />
      <Stack sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <FPrimaryHeading fontSize={20} text={t("Customer.AdvancedJournal")} />

        <FButton sx={{ ml: 'auto', bgcolor: '#44B904' }} onClick={() => setAddNewJournalModal(true)} variant={"save"} title={`+ ${t("Customer.AddNewJournal")}`} />

      </Stack>

      <FCommonTable
        columns={columns}
        data={dataForTable}
        visibleColumns={['created', 'name', 'template', 'updated_at', 'created_by', 'status', 'journal_id', 'GoToComponent']}
        columnWidths={{ created: "10%", name: "20%", template: "15%", updated_at: "10%", created_by: "15%", status: "10%", journal_id: "10%", GoToComponent: "10%" }}
        loading={loading}
      />

      {addNewJournalModal && <AddNewJournalForm templates={templates} getAdvancedTemplates={getAdvancedTemplates} customer={customer} open={addNewJournalModal} onClose={() => setAddNewJournalModal(false)} />}
    </Stack>
  )
}