import React, { useEffect, useState } from "react";
import { AppBar, Box, Typography, Stack, Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { t } from "i18next";

const AppbarComponent = ({
  labels,
  selectedButton,
  handleClick,
  ShowUser = false,
  userName,
  UserImage,
}) => {
  const location = useLocation();
  const [isCustomer, setIsCustomer] = useState(false);

  const commonStyle = {
    marginLeft: "100px",
    padding: "10px 15px",
    cursor: "pointer",
    fontWeight: 500,
    color: "#BBB0A4",
  };

  useEffect(() => {
    setIsCustomer(location.pathname.includes("/customer"));
  }, [selectedButton]);

  

  return (
    <AppBar
      position="sticky"
      sx={{ boxShadow: "0px 1px 50px 0px rgba(0, 0, 0, 0.05)" }}
    >
      {ShowUser && (
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          py={2}
          px={6}
          gap={2}
          sx={{ backgroundColor: "#FFFFFF" }}
        >
          {UserImage ? (
            <Avatar
              src={UserImage}
              alt={userName || ""}
              onError={(e) => {
                e.target.src = (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 200 200"
                  >
                    <circle
                      cx="100"
                      cy="100"
                      r="90"
                      fill="white"
                      stroke="#BBB0A4"
                      stroke-width="5"
                    />
                    <circle cx="100" cy="80" r="25" fill="#0FA628" />
                    <ellipse cx="100" cy="135" rx="35" ry="25" fill="#0FA628" />
                  </svg>
                );
              }}
              sx={{
                width: 33.34,
                height: 33.34,
                outlineOffset: "1px",
                outlineStyle: "solid",
                outlineWidth: "1px",
                outlineColor: "#BBB0A4",
              }}
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 200 200"
            >
              <circle
                cx="100"
                cy="100"
                r="90"
                fill="white"
                stroke="#BBB0A4"
                stroke-width="5"
              />
              <circle cx="100" cy="80" r="25" fill="#0FA628" />
              <ellipse cx="100" cy="135" rx="35" ry="25" fill="#0FA628" />
            </svg>
          )}

          <Typography variant="h6" color="#545454">
            {userName || ""}
          </Typography>
        </Stack>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
        }}
      >
        {labels.map((label, index) => (
          <Typography
            key={index}
            variant="body1"
            sx={{
              ...commonStyle,
              ...(isCustomer
                ? location.pathname.split("/")[3] === "journalgroups" &&
                  String(label) === t("Common.JournalGroups")
                  ? { borderBottom: "3px solid #bbb0a4", fontWeight: 700 }
                  : selectedButton === String(label) &&
                  location.pathname.split("/")[3] !== "journalgroups" && {
                      borderBottom: "3px solid #BBB0A4",
                      fontWeight: 700,
                    }
                : selectedButton === String(label) && {
                    borderBottom: "3px solid #BBB0A4",
                    fontWeight: 700,
                  }),
            }}
            onClick={() => handleClick(String(label))}
          >
            {label}
          </Typography>
        ))}
      </Box>
    </AppBar>
  );
};

export default AppbarComponent;
