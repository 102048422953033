import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const token = localStorage.getItem("auth_token");
  const location = useLocation();

  // If token doesn't exist, redirect to login
  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace={true} />;
  }

  return element;
};

export default ProtectedRoute;
