import { formatPhoneNumber, generateTimeSlots, transformServiceList } from "./functions";
import apiFetcher from "../../../../utils/interCeptor";
import axios, { HttpStatusCode } from "axios";
import { debounce } from "lodash";
import moment from "moment";
import { Typography } from "@mui/material";
import { reasons } from "../../../../scenes/Calendar";

export class ApiManger {

    // get services 
    async fetchServiceApi({ setServiceList, setLoading, fields, t, setValue, getValues, rescheduleProps, setCardValues }) {
        setLoading((prev) => ({ ...prev, serviceApi: true }));
        try {
            const response = await apiFetcher.get(`api/v1/store/service_group`)
            if (response) {
                setServiceList(transformServiceList(response.data.data, fields, t));
                if (rescheduleProps) {
                    let values = getValues("cards");
                    let allServices = [];

                    response.data.data && response.data.data.flatMap((item) => ({
                        service: item?.services?.map((serv) => (
                            allServices.push({ ...serv })
                        ))
                    }))

                    let newCards = values.map((card, i) => ({
                        ...card,
                        employeeList: allServices && allServices.find((service) => service?.id === card?.selectedService?.id)?.employees,
                    }))

                    setValue("cards", newCards);
                    setCardValues(newCards);
                }
            } else {
                setServiceList([]);
            }
        } catch (error) {
            console.error(t("Calendar.ToastErrService"));
            setServiceList([]);
        } finally {
            setLoading((prev) => ({ ...prev, serviceApi: false }));
        }
    };

    // submit booking 
    async handleSubmit(payload, closeForm, toast, t, isEdit, setEvents) {

        let newData = payload.services.map((item, index) => ({
            id: moment().unix() + index,
            title: payload?.customer_name,
            start: moment(`${payload?.booking_date}T${item?.time_slot.split("-")[0]}:00`, "YYYY-MM-DDTHH:mm").toDate(),
            end: moment(`${payload?.booking_date}T${item?.time_slot.split("-")[1]}:00`, "YYYY-MM-DDTHH:mm").toDate(),
            status: "BOOKED",
            resourceId: item?.employee_id,
            customerName: payload?.customer_name,
            customerPhone: payload?.customer_phone_number,
            customerNote: payload?.note,
            serviceDuration: item?.duration,
            createdDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
            servicePrice: item?.total_amount,
            type: "BOOKING",
            employeeId: item?.employee_id,

        }))

        closeForm();

        setEvents((prev) => [...prev, ...newData]);
        let url = isEdit ? `api/v1/store/booking/edit-multi` : `api/v1/store/booking/multi`;
        try {
            const response = await apiFetcher.post(url, payload);
            if (response.status === HttpStatusCode.Ok || response.status === HttpStatusCode.Created) {
                toast.success(isEdit ? t("Calendar.UpdateToastBk") : t("Calendar.BookingToastSuccess"));
            }
        } catch (error) {
            toast.error(isEdit ? t("Calendar.UpdateToastBkFail") : t("Calendar.BookingToastError"));
        }
    }


    // debounced customer get api
    fetchSuggestions = debounce(async ({ cus, cancelToken, setCancelToken, setCustomers, setLoading, t }) => {

        if (cancelToken) {
            cancelToken.cancel('Canceling previous request');
        }
        const source = axios.CancelToken.source();
        setCancelToken(source);
        setLoading((prev) => ({
            ...prev,
            customerApi: true,
        }));
        apiFetcher(`api/v1/store/customer/outlet?search=${cus}`, {
            cancelToken: source.token
        })
            .then(response => {
                if (response.status === HttpStatusCode.Ok) {
                    let customerObj = response.data.data?.data.map((item) => ({ label: `${item.name} (${formatPhoneNumber(item.phone_number)})`, ...item }));
                    setCustomers([{ label: t("Customer.AddNewCustomer"), id: 0 }, ...customerObj]);
                }
            })
            .catch(thrown => {
                if (axios.isCancel(thrown)) {
                    console.error('Request canceled:', thrown.message);
                } else {
                    console.error('Error fetching suggestions:', thrown);
                }
            })
            .finally(() => {
                setLoading((prev) => ({
                    ...prev,
                    customerApi: false,
                }));
            });
    }, 500);


    // create customer 
    async createCustomer(payload, closeForm, toast, t, setCustomer) {
        try {
            const response = await apiFetcher.post(`api/v1/store/customer/outlet`, payload);
            if (response.status === HttpStatusCode.Ok || response.status === HttpStatusCode.Created) {
                let customerObj = { ...response.data.data, label: `${response.data.data.name} (${formatPhoneNumber(response.data.data.phone_number)})` };
                setCustomer([{ label: t("Customer.AddNewCustomer"), id: 0 }, customerObj]);
                toast.success(t("Customer.CustomerCreateSuccess"));
                closeForm();
            }
        } catch (error) {
            console.error("Error creating customer:", error);
            toast.error(error || t("Customer.CustomerCreateError"));
        }
    }


    // submit function for pause calender 
    async handleSubmitPause(payload, closeForm, toast, t, pauseProps, setEvents) {
        const lang = localStorage.getItem('language')
        let id = moment().unix();
        setEvents((prev) => [...prev,
        {
            id: id,
            title: lang == 'da' ? reasons[payload.reason] : payload.reason,
            reason: payload?.reason,
            headline: payload.headline,
            description: payload.description,
            start: moment(payload.datetime_start).toDate(),
            end: moment(payload.datetime_end).toDate(),
            status: null,
            resourceId: payload?.employee_ids[0],
            customerName: '',
            customerPhone: '',
            customerNote: '',
            employeeName: '',
            serviceDuration: '',
            createdDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
            servicePrice: 0,
            serviceType: '',
            serviceId: null, // Now correctly retrieving serviceId
            employeeId: payload?.employee_ids[0],
            type: 'CALENDAR_PAUSE'
        }
        ])
        closeForm();

        let call;
        if (pauseProps && pauseProps?.id) {
            let edit = {
                employee_id: payload?.employee_ids[0],
                ...payload
            }
            call = apiFetcher.put(`api/v1/store/employee/pause-calendar/${pauseProps?.id}`, edit);
        } else {
            call = apiFetcher.post(`api/v1/store/employee/pause-calendar`, payload);
        }
        try {
            const response = await call;
            if (response.status === HttpStatusCode.Ok || response.status === HttpStatusCode.Created) {
                toast.success(t("Calendar.PauseToastSuccess"));
            }
        } catch (error) {
            toast.error(t("Calendar.PauseToastError"));
            setEvents((prev) => prev.filter((item) => item.id !== id));
        }
    }


    async availableTimeSlots(rescheduleProps, setValue, setCardValues, setLoading, setting) {
        setLoading((prev) => ({ ...prev, timeSlotApi: prev.timeSlotApi.map(() => true) }));



        try {
            let timeSlots = await generateTimeSlots();
            let employees = [];
            let available_time = [];
            let exclude_employee_time_slots = {};
            let services = [];

            rescheduleProps && rescheduleProps?.booking.forEach((item) => {

                services.push({ booking_id: item?.booking_id, service_id: item?.selectedService?.id, employee_id: item?.selectedEmployee?.id, custom_duration: item?.duration_min });
                employees.push(item?.selectedEmployee?.id);
                let [startTime, endTime] = item?.available_time.split('-');

                let startMoment = moment(startTime.trim(), 'HH:mm'); // Parse the start time in "HH:mm" format
                let endMoment = startMoment.clone().add(item?.duration_min, 'minutes');
                let formattedEndTime = endMoment.format('HH:mm');

                let updatedAvailableTime = `${startTime.trim()} - ${formattedEndTime}`;

                available_time.push(updatedAvailableTime);

                if (item?.selectedEmployee?.id && updatedAvailableTime) {
                    if (!exclude_employee_time_slots[item?.selectedEmployee?.id]) {
                        exclude_employee_time_slots[item?.selectedEmployee?.id] = [];
                    }
                    exclude_employee_time_slots[item?.selectedEmployee?.id].push(updatedAvailableTime);
                }
            });

            const body = { services: services };
            const response = await apiFetcher.post(`api/v1/store/booking/multi-timings?date=${moment(rescheduleProps?.date).format('YYYY-MM-DD')}`, body);
            if (response.status === HttpStatusCode.Ok) {
                let data = response?.data?.data;

                let day = setting?.schedule?.find((schedule) => schedule?.day === rescheduleProps?.date.locale('en').format('dddd'))


                Object.keys(data).map((key) => {
                    let tms = timeSlots.map((item) => {
                        const startTime = item.split(' - ')[0];


                        const isTimeAvailable = data[key].some((timeSlot) => (timeSlot.split(' - ')[0] == startTime));

                        return {
                            value: item,
                            label: (
                                <Typography
                                    sx={{
                                        color: !moment(startTime, 'HH:mm').isBetween(moment(day?.open_time, 'HH:mm:ss'), moment(day?.close_time, 'HH:mm:ss'))
                                            ? '#d7d7d7'
                                            : !isTimeAvailable
                                                ? '#C60404'
                                                : '#1f1f1f',
                                        textDecoration: !isTimeAvailable ? 'line-through' : 'none'
                                    }}
                                    variant={startTime.endsWith(':00') ? 'body1' : 'body2'}
                                    fontWeight={startTime.endsWith(':00') ? 700 : 400}
                                >
                                    {startTime}
                                </Typography>

                            ),
                            disabled: !isTimeAvailable,
                        };
                    });

                    setCardValues((prev) => prev.map((card, i) => (card?.booking_id == key ? { ...card, availableTimeSlots: tms } : card)));
                    let indexOfCard = rescheduleProps?.booking.findIndex((card) => card?.booking_id == key);
                    setValue(`cards.${indexOfCard}.availableTimeSlots`, tms);
                })

            }


        } catch (error) {
            console.error(error);
        } finally {
            setLoading((prev) => ({ ...prev, timeSlotApi: prev.timeSlotApi.map(() => false) }));
        }
    }


    async removePause(payload, toast, t, setEvents) {
        setEvents((prev) => prev.filter((item) => item?.id !== payload?.id));
        try {
            const response = await apiFetcher.delete(`api/v1/store/employee/pause-calendar/${payload?.id}`);
            if (response.status === HttpStatusCode.Ok) {
                toast.success(t("Calendar.RemovePauseToastSuccess"));
            }
        } catch (error) {
            setEvents((prev) => [...prev,
            {
                id: payload?.id,
                title: payload.reason,
                reason: payload?.reason,
                headline: payload.headline,
                description: payload.description,
                start: payload?.start,
                end: payload?.end,
                status: null,
                resourceId: payload?.resourceId,
                customerName: '',
                customerPhone: '',
                customerNote: '',
                employeeName: '',
                serviceDuration: '',
                createdDate: payload?.createdDate,
                servicePrice: 0,
                serviceType: '',
                serviceId: null, // Now correctly retrieving serviceId
                employeeId: payload?.employeeId,
                type: 'CALENDAR_PAUSE'
            }
            ])
            toast.error(t("Calendar.RemovePauseToastError"));

        }

    }

}

export const apiMangerBooking = new ApiManger();