import moment from "moment";
import apiFetcher from "../../../../utils/interCeptor";
import { HttpStatusCode } from "axios";
import { Typography } from "@mui/material";
import { validateBooking } from "./functions";

export class HandlerBooking {

    // Function to handle service change
    async handleServiceChange(index, newValue, setValue, setCardValues, initialData, cardValues, setLoading, formik, timeSlots, setting) {

        let foundEmployee = newValue?.employees?.find((emp) => emp?.id == initialData?.employeeId);
        let empTimes = [];
        let emp_available_time = moment(initialData?.start_time).format('HH:mm') + ' - ' + moment(initialData?.start_time).add(30, 'minutes').format('HH:mm');
        let initialDuration = moment(initialData?.end_time).diff(moment(initialData?.start_time), 'minutes');
        setValue(`cards.${index}.selectedService`, newValue);

        if (index === 0) {
            emp_available_time = moment(initialData?.start_time).format('HH:mm') + ' - ' + moment(initialData?.start_time).add(30, 'minutes').format('HH:mm');;
        } else {
            const endTime = moment(cardValues[index - 1]?.available_time.split('-')[0], 'HH:mm').add(cardValues[index - 1]?.duration_min, 'minutes');
            emp_available_time = moment(endTime).format('HH:mm') + ' - ' + moment(endTime).add(30, 'minutes').format('HH:mm');
        }

        let day = setting?.schedule?.find((schedule) => schedule?.day === formik?.values?.date.locale('en').format('dddd'))

        if (newValue) {
            const initialDurationList = [];
            for (let minute = 5; minute <= 1440; minute += 5) {
                let label;
                if (minute < 60) {
                    label = `${minute} min`;
                } else if (minute % 60) {
                    label = `${Math.floor(minute / 60)} hr ${minute % 60} min`;
                } else if (minute % 60 === 0) {
                    const hours = Math.floor(minute / 60);
                    label = `${hours} hr`;
                }

                initialDurationList.push({
                    value: minute,
                    label,
                    minutes: minute,
                });
            }

            if (newValue.duration_min > 60) {
                const extraMinutes = newValue.duration_min;
                let extraLabel = '';

                const hours = Math.floor(extraMinutes / 60);
                const remainingMinutes = extraMinutes % 60;

                if (hours > 0) {
                    extraLabel += `${hours} hr`;
                    if (remainingMinutes > 0) {
                        extraLabel += ` ${remainingMinutes} min`;
                    }
                } else {
                    extraLabel += `${remainingMinutes} min`;
                };

                initialDurationList.push({
                    value: extraMinutes,
                    label: extraLabel,
                    minutes: extraMinutes,
                });
            }



            if (foundEmployee) {
                try {

                    let employees = [];
                    let available_time = [];
                    let exclude_employee_time_slots = {};

                    cardValues && cardValues.forEach((item, i) => {

                        employees.push(item?.selectedEmployee?.id);
                        let [startTime, endTime] = item?.available_time.split('-');

                        let startMoment = moment(startTime.trim(), 'HH:mm'); // Parse the start time in "HH:mm" format
                        let endMoment = startMoment.clone().add(item?.duration_min, 'minutes');
                        let formattedEndTime = endMoment.format('HH:mm');

                        let updatedAvailableTime = `${startTime.trim()} - ${formattedEndTime}`;

                        available_time.push(updatedAvailableTime);

                        if (item?.selectedEmployee?.id && updatedAvailableTime) {
                            if (!exclude_employee_time_slots[item?.selectedEmployee?.id]) {
                                exclude_employee_time_slots[item?.selectedEmployee?.id] = [];
                            }
                            exclude_employee_time_slots[item?.selectedEmployee?.id].push(updatedAvailableTime);
                        }
                    });

                    let body = {
                        exclude_employee_time_slots: exclude_employee_time_slots,
                    };

                    setLoading((prev) => ({ ...prev, timeSlotApi: { ...prev.timeSlotApi, [index]: true } }));

                    const response = await apiFetcher.post(
                        `api/v1/store/booking/timings?service_id=${newValue?.id}&date=${moment(formik.values.date).format("YYYY-MM-DD")}&employee_id=${foundEmployee?.id}&custom_duration=${initialDuration || newValue?.duration_min}`,
                        body
                    );

                    if (response.status === HttpStatusCode.Ok) {
                        let data = response?.data?.data;
                        let tms = timeSlots.map((item, i) => {
                            const startTime = item.split(' - ')[0];

                            const isTimeAvailable = data.some(timeSlot => (timeSlot.split(' - ')[0] === startTime));
                            return {
                                value: item,
                                label: (
                                    <Typography
                                        sx={{
                                            color: !moment(startTime, 'HH:mm').isBetween(moment(day?.open_time, 'HH:mm:ss'), moment(day?.close_time, 'HH:mm:ss'))
                                                ? '#D7D7D7'
                                                : !isTimeAvailable
                                                    ? '#C60404'
                                                    : '#1f1f1f',
                                            textDecoration: !isTimeAvailable
                                                ? 'line-through'
                                                : 'none'
                                        }}
                                        variant={startTime.endsWith(':00') ? 'body1' : 'body2'}
                                        fontWeight={startTime.endsWith(':00') ? 700 : 400}
                                    >
                                        {startTime}
                                    </Typography>

                                ),
                                disabled: !isTimeAvailable,
                            };
                        });


                        empTimes = tms;
                        // let newCards = cardValues.map((card, i) => (i === index ? { ...card, selectedEmployee: newValue, availableTimeSlots: tms } : card));
                        // setCardValues(newCards);
                    }

                } catch (error) {
                    console.error('error', error);
                } finally {
                    setLoading((prev) => ({ ...prev, timeSlotApi: { ...prev.timeSlotApi, [index]: false } }));
                }
            }

            setValue(`cards.${index}.duration_min`, initialDuration || newValue?.duration_min);
            setValue(`cards.${index}.durationList`, initialDurationList);
            setValue(`cards.${index}.employeeList`, newValue?.employees);
            setValue(`cards.${index}.selectedEmployee`, foundEmployee);
            setValue(`cards.${index}.price`, newValue?.price);
            setValue(`cards.${index}.availableTimeSlots`, empTimes);
            setValue(`cards.${index}.available_time`, emp_available_time);
            setCardValues((prev) => prev.map((card, i) => (i === index ? { ...card, selectedService: newValue, duration_min: initialDuration || newValue?.duration_min, durationList: initialDurationList, employeeList: newValue?.employees, selectedEmployee: foundEmployee, availableTimeSlots: empTimes, available_time: emp_available_time, price: newValue?.price } : card)));

        };

    };


    // Function to handle duration change
    handleDurationChange(index, value, setValue, setCardValues, cardValues) {

        setValue(`cards.${index}.duration_min`, value, { shouldDirty: true, shouldValidate: true });
        setValue(`cards.${index}.selectedEmployee`, '', { shouldDirty: true, shouldValidate: true });
        setValue(`cards.${index}.available_time`, '', { shouldDirty: true, shouldValidate: true });
        // Force re-render if necessary
        let newCards = cardValues.map((card, i) => (i === index ? { ...card, duration_min: value, selectedEmployee: '', available_time: '' } : card));
        setCardValues(newCards);
    };

    handlePriceChange(index, value, setValue, setCardValues, cardValues) {
        setValue(`cards.${index}.price`, value, { shouldDirty: true, shouldValidate: true });
        let newCards = cardValues.map((card, i) => (i === index ? { ...card, price: value } : card));
        setCardValues(newCards);
    }

    // Function to handle time change
    async handleBookingTimeChange(index, value, setValue, setCardValues, cardValues, toast, t, tempId) {
        let tempCard;
        if (index === 0) {
            tempCard = [{ ...cardValues[0], available_time: value }];
        } else {
            tempCard = cardValues.map((card, i) =>
                i === index ? { ...card, available_time: value } : card
            );
        }

        let valid = await validateBooking(tempCard.filter(card => card.id !== tempId));

        if (valid) {
            setValue(`cards.${index}.available_time`, value, { shouldDirty: true, shouldValidate: true });
            let newCards = cardValues.map((card, i) => (i === index ? { ...card, available_time: value } : card));
            setCardValues(newCards);
        } else {
            toast.error(t("Calendar.ValidationErr"));
        }
    };


    // Function to handle employee change
    async handleEmployeeChange(newValue, index, setValue, setLoading, setCardValues, cardValues, timeSlots, formik, setting) {
        setValue(`cards.${index}.selectedEmployee`, newValue);
        // Create arrays to hold employee ids and their available times
        let employees = [];
        let available_time = [];
        let exclude_employee_time_slots = {};
        let day = setting?.schedule?.find((schedule) => schedule?.day === formik?.values?.date.locale('en').format('dddd'))

        cardValues && cardValues.forEach((item, i) => {

            employees.push(item?.selectedEmployee?.id);
            let [startTime, endTime] = item?.available_time.split('-');

            let startMoment = moment(startTime.trim(), 'HH:mm'); // Parse the start time in "HH:mm" format
            let endMoment = startMoment.clone().add(item?.duration_min, 'minutes');
            let formattedEndTime = endMoment.format('HH:mm');

            let updatedAvailableTime = `${startTime.trim()} - ${formattedEndTime}`;

            available_time.push(updatedAvailableTime);

            if (item?.selectedEmployee?.id && updatedAvailableTime) {
                if (!exclude_employee_time_slots[item?.selectedEmployee?.id]) {
                    exclude_employee_time_slots[item?.selectedEmployee?.id] = [];
                }
                exclude_employee_time_slots[item?.selectedEmployee?.id].push(updatedAvailableTime);
            }
        });

        let body = {
            exclude_employee_time_slots: exclude_employee_time_slots,
        };

        setLoading((prev) => ({ ...prev, timeSlotApi: { ...prev.timeSlotApi, [index]: true } }));

        try {
            const response = await apiFetcher.post(
                `api/v1/store/booking/timings?service_id=${cardValues[index]?.selectedService?.id}&date=${moment(formik.values.date).format("YYYY-MM-DD")}&employee_id=${newValue?.id}&custom_duration=${cardValues[index]?.duration_min}`,
                body
            );

            if (response.status === HttpStatusCode.Ok) {
                let data = response?.data?.data;
                let tms = timeSlots.map((item, i) => {
                    const startTime = item.split(' - ')[0];


                    const isTimeAvailable = data.some(timeSlot => (timeSlot.split(' - ')[0] === startTime));
                    return {
                        value: item,
                        label: (
                            <Typography
                                sx={{
                                    color: !moment(startTime, 'HH:mm').isBetween(moment(day?.open_time, 'HH:mm:ss'), moment(day?.close_time, 'HH:mm:ss'))
                                        ? '#D7D7D7'
                                        : !isTimeAvailable ? '#C60404' : '#1f1f1f',
                                    textDecoration: !isTimeAvailable ? 'line-through' : 'none'
                                }}
                                variant={startTime.endsWith(':00') ? 'body1' : 'body2'}
                                fontWeight={startTime.endsWith(':00') ? 700 : 400}
                            >
                                {startTime}
                            </Typography>

                        ),
                        disabled: !isTimeAvailable,
                    };
                });


                setValue(`cards.${index}.availableTimeSlots`, tms);

                let newCards = cardValues.map((card, i) => (i === index ? { ...card, selectedEmployee: newValue, availableTimeSlots: tms } : card));
                setCardValues(newCards);
            }

        } catch (error) {
            console.error('error', error);
        } finally {
            setLoading((prev) => ({ ...prev, timeSlotApi: { ...prev.timeSlotApi, [index]: false } }));
        }
    };

    async handleWalkInChange(formik) {

        formik.setFieldValue('walk_in', !formik.values.walk_in);

        if (!formik.values.walk_in) {
            formik.setFieldValue('selectedCustomer', null);
            formik.setFieldValue('sendEmail', false);
            formik.setFieldValue('sendSms', false);
            formik.setFieldValue('disable', true);
        } else {
            formik.setFieldValue('disable', false);
        }
    }

}

export const HandleBooking = new HandlerBooking();