import React, {useCallback, useEffect, useRef, useState} from 'react';
import {createPortal, unstable_batchedUpdates} from 'react-dom';
import {
  closestCenter,
  pointerWithin,
  rectIntersection,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  getFirstCollision,
  useDroppable,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {Item, Container} from './components';

import {createRange} from '../../utils';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';




export default {
  title: 'Presets/Sortable/Multiple Containers',
};

let newModelType;

const animateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({...args, wasDragging: true});

function DroppableContainer({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  groupData,
  onclickContainer,
  ...props
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });

  
  
  // const isOverContainer = over
  //   ? (id === over.id && active?.data.current?.type !== 'container') ||
  //     items.includes(over?.id)
  //   : false;

  return (
    <Container
      modelType={newModelType}
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={false}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      id={id}
      groupData={groupData}
      columns={columns}
      onclickContainer={onclickContainer}
      {...props}
    >
      {/* {children} */}
      {(newModelType !== 'Journal-Groups' && newModelType !== 'Emp')  && children}
    </Container>
  );
}

const dropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};


export const TRASH_ID = 'void';
const PLACEHOLDER_ID = 'placeholder';
const empty = [];

export function MultipleContainers({
  adjustScale = false,
  // itemCount = 3,
  cancelDrop,
  columns,
  handle = true,
  // items: initialItems,
  containerStyle,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  trashable = false,
  vertical = false,
  scrollable,
  onRemove ,
  onRemoveItem,
  items,
  setItems,
  onDragToAnotherContainer,
  onDragComplete,
  modelType,
  onclickItem,
  onclickContainer,
}) {

  
  
  // const [items, setItems] = useState(
  //   () =>
  //     initialItems ?? {
  //       A: createRange(itemCount-2, (index) => `A${index + 1}`),
  //       B: createRange(itemCount+1, (index) => `B${index + 1}`),
  //       C: createRange(itemCount+3, (index) => `C${index + 1}`),
  //       D: createRange(itemCount, (index) => `D${index + 1}`),
  //     }
  // );
  
  const [containers, setContainers] = useState(
    // Object.keys(items)
    Object.values(items)
    .sort((a, b) => a.sequence - b.sequence)  // Sort by 'sequence'
    .map(item => item.groupId)
  );
  const [activeId, setActiveId] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId === TRASH_ID) {
          // If the intersecting droppable is the trash, return early
          // Remove this if you're not using trashable functionality in your app
          return intersections;
        }

        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{id: overId}];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{id: lastOverId.current}] : [];
    },
    [activeId, items]
  );
  const [clonedItems, setClonedItems] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  const findContainer = (id) => {
    if (id in items) {
      return id;
    }


    let foundContainer = null

    //  Object.keys(items).map((key)=>{
      // for (let key in  Object.keys(items)) {
        Promise.all(  Object.keys(items).map((key)=>{ 
        
        const found = items[key].services.find(obj => obj.id == id);
        
        if (found) {
          foundContainer = key;
          return key; // Return the object that matches the id
      }
      }))
    
    

    return foundContainer
    // return Object.keys(items).find((key) => items[key].services.find((serviceObj)=>serviceObj.id == id));
  };

  useEffect(()=>{
 
    // setContainers(Object.keys(items))
    setContainers(Object.values(items)
    .sort((a, b) => a.sequence - b.sequence)  // Sort by 'sequence'
    .map(item => item.groupId))
  },[items])


  const getIndex = (id) => {
    
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    // const index = items[container].indexOf(id);
    const index = items[container].services.findIndex(function(item, i){
      return item.id === id
    });

 

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const disabledItems = [0];
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({active}) => {
        setActiveId(active.id);
        setClonedItems(items);
        if( recentlyMovedToNewContainer.current){

          recentlyMovedToNewContainer.current = false;
        }
      }}
      onDragOver={({active, over}) => {
        const overId = over?.id;

        if (overId == null || overId === TRASH_ID || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer != overContainer) {

          
          
          setItems((items) => {

            
            const activeItems = items[activeContainer].services;
            const overItems = items[overContainer].services;
            const overIndex = overItems.findIndex(function(item, i){
              return item.id === overId
            });
            // const overIndex = overItems.indexOf(overId);
            // const activeIndex = activeItems.indexOf(active.id);

            const activeIndex = activeItems.findIndex(function(item, i){
              return item.id === active.id
            });

            let newIndex;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                  over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            
           
            return {
              ...items,
              [activeContainer]: {...items[activeContainer], 'services': items[activeContainer].services.filter(
                (item) => item.id !== active.id
              )},
              [overContainer]: {...items[overContainer],'services': [   ...items[overContainer].services.slice(0, newIndex),items[activeContainer].services[activeIndex],
                ...items[overContainer].services.slice(
                newIndex,
                items[overContainer].services.length
              ),
            ]}
            };
          });

        
        }
      }}
      onDragEnd={({active, over}) => {

        let dragContainer = false

        let updatedContainers = [...containers]
        
        if (active.id in items && over?.id) {
         
          
          setContainers((containers) => {
         
            
            const activeIndex = containers.indexOf(active.id);
            
            const overIndex = containers.indexOf(over.id);

            

            // we will check if both index is not equal so we have dragged it over 
            if(activeIndex != overIndex){

              dragContainer = true
            }
            
            updatedContainers =  arrayMove(containers, activeIndex, overIndex);
            return arrayMove(containers, activeIndex, overIndex);
          });
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        // if (overId === TRASH_ID) {
        //   setItems((items) => ({
        //     ...items,
        //     [activeContainer]: items[activeContainer].filter(
        //       (id) => id !== activeId
        //     ),
        //   }));
        //   setActiveId(null);
        //   return;
        // }

        // if (overId === PLACEHOLDER_ID) {
        //   const newContainerId = getNextContainerId();

        //   unstable_batchedUpdates(() => {
        //     setContainers((containers) => [...containers, newContainerId]);
        //     setItems((items) => ({
        //       ...items,
        //       [activeContainer]: items[activeContainer].filter(
        //         (id) => id !== activeId
        //       ),
        //       [newContainerId]: [active.id],
        //     }));
        //     setActiveId(null);
        //   });
        //   return;
        // }

        const overContainer = findContainer(overId);

        
        if (overContainer) {
          
          const activeIndex = items[activeContainer].services.findIndex(function(item, i){
            return item.id === active.id
          });
          const overIndex = items[overContainer].services.findIndex(function(item, i){
            return item.id === overId
          });


          
      
          

          if (activeIndex != overIndex) {

            let updatedService =  arrayMove(
              items[overContainer].services,
              activeIndex,
              overIndex
            )

            updatedService.map((serviceObj,index)=>serviceObj.sequence = index)

            setItems((items) => ({
              ...items,
              [overContainer]:{...items[overContainer], 'services':  updatedService}
            }));

          
          }

          

          // check if container is moved over another container or item moved to new index?
          if(activeContainer != overContainer || activeIndex != overIndex){

            onDragComplete(dragContainer,overContainer, updatedContainers )
            return
          }
         


        }


        onDragComplete(dragContainer,overContainer, updatedContainers)
        
        // if(recentlyMovedToNewContainer?.current){
        //   onDragToAnotherContainer(overContainer, activeId)
        // }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div
        style={{
          display: 'inline-grid',
          boxSizing: 'border-box',
          paddingRight: 0,
          paddingLeft:20,
          paddingTop:10,
          gridAutoFlow: vertical ? 'row' : 'column',
        }}
      >
        <SortableContext
          items={[...containers, PLACEHOLDER_ID]}
          isDisabled={(value) => disabledItems.includes(value)}
          strategy={
            vertical
              ? verticalListSortingStrategy
              : horizontalListSortingStrategy
          }
        >
          {containers.map((containerId) => (
            <DroppableContainer
              key={containerId}
              id={containerId}
              label={minimal ? undefined : `Column ${containerId}`}
              groupData={items[containerId]}
              columns={columns}
              items={items[containerId]}
              scrollable={scrollable}
              style={containerStyle}
              unstyled={minimal}
              onRemove={()=>handleRemove(containerId)}
              onclickContainer={onclickContainer}
            >
              {
                items[containerId]?.services &&
              <SortableContext items={items[containerId].services} strategy={strategy}>
                {items[containerId].services.map((value, index) => {
                  return (
                    <SortableItem
                    disabled={isSortingContainer}
                    key={value?.id}
                    id={value?.id}
                    index={index}
                    handle={handle}
                    style={getItemStyles}
                    wrapperStyle={wrapperStyle}
                    renderItem={renderItem}
                    containerId={containerId}
                    getIndex={getIndex}
                    onRemove={()=>handleRemoveItem(value?.id, containerId)}
                    data={value}
                    onclickItem={onclickItem}
                    
                    />
                  );
                })}
              </SortableContext> 
              }
            </DroppableContainer>
          ))}
          {/* {minimal ? undefined : (
            <DroppableContainer
              id={PLACEHOLDER_ID}
              disabled={isSortingContainer}
              items={empty}
              onClick={handleAddColumn}
              placeholder
            >
              + Add column
            </DroppableContainer>
          )} */}
        </SortableContext>
      </div>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? containers.includes(activeId)
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body
      )}
      {trashable && activeId && !containers.includes(activeId) ? (
        <Trash id={TRASH_ID} />
      ) : null}
    </DndContext>
  );

  function renderSortableItemDragOverlay(id) {
   
    

    const containerId =  findContainer(id) 
    
    const data = items[containerId].services.find((serviceObj)=>serviceObj.id == id)

    
    
    return (
      <Item
        modelType={modelType}
        value={id}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id) ,
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        color={getColor(id)}
        wrapperStyle={wrapperStyle({index: 0})}
        renderItem={renderItem}
        dragOverlay
        data={data}
      />
    );
  }

  function renderContainerDragOverlay(containerId) {
 
    return (
      <Container
        modelType={modelType}
        label={`${containerId}`}
        columns={columns}
        style={{
          height: '100%',
        }}
        shadow
        unstyled={false}
        groupData={items[containerId]}
      >
        {items[containerId].services.map((item, index) => (
          <Item
          modelType={modelType}
            key={item?.id}
            value={item?.id}
            handle={handle}
            onRemove={onRemove}
            style={getItemStyles({
              containerId,
              overIndex: -1,
              index: getIndex(item?.id),
              value: item,
              isDragging: false,
              isSorting: false,
              isDragOverlay: false,
            })}
            color={getColor(item)}
            wrapperStyle={wrapperStyle({index})}
            renderItem={renderItem}
            data={item}
          />
        ))}
      </Container>
    );
  }

  function handleRemove(containerID) {
    // setContainers((containers) =>
    //   containers.filter((id) => id !== containerID)
    // );
    onRemove(containerID)
  }




  function handleRemoveItem(itemId, containerID) {
    // let updatedItems = items[containerID].services.filter((serviceObj) => serviceObj?.id !== itemId)
    // let newContainer = {...items[containerID], services: updatedItems}
       
    
    // setItems( {...items, [containerID]: newContainer} )
    onRemoveItem(itemId, containerID)
  }

  function handleAddColumn() {
    const newContainerId = getNextContainerId();

    unstable_batchedUpdates(() => {
      setContainers((containers) => [...containers, newContainerId]);
      setItems((items) => ({
        ...items,
        [newContainerId]: [],
      }));
    });
  }

  function getNextContainerId() {
    const containerIds = Object.keys(items);
    const lastContainerId = containerIds[containerIds.length - 1];

    return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
  }
}

function getColor(id) {
  switch (String(id)[0]) {
    case 'A':
      return '#7193f1';
    case 'B':
      return '#ffda6c';
    case 'C':
      return '#00bcd4';
    case 'D':
      return '#ef769f';
  }

  return undefined;
}

function Trash({id}) {
  const {setNodeRef, isOver} = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: '50%',
        marginLeft: -150,
        bottom: 20,
        width: 300,
        height: 60,
        borderRadius: 5,
        border: '1px solid',
        borderColor: isOver ? 'red' : '#DDD',
      }}
    >
      Drop here to delete
    </div>
  );
}



function SortableItem({
  disabled,
  id,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  onRemove,
  data,
  onclickItem
}) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
    modelType={newModelType}
      ref={disabled ? undefined : setNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? {ref: setActivatorNodeRef} : undefined}
      index={index}
      wrapperStyle={wrapperStyle({index})}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      color={getColor(id)}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
      onRemove={onRemove}
      data={data}
      onclickItem={onclickItem}
      
    />
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
