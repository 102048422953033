import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FPrimaryHeading from "../../../commonComponents/F_PrimaryHeading";
import "react-quill/dist/quill.snow.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

import apiFetcher from "../../../../utils/interCeptor";
import CommonFormikModal from "./CommonFormikModal";
import { t } from "i18next";
import moment from "moment";

const ArchieveFooter = ({
  dataa,
  prove,
  setProvoke,
  Logs,
  changeSequence,
  setData,
  setLogsProvoke,
  setIsDirty,
 
}) => {
  
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    async function getdata() {
      try {
        const res = await apiFetcher.get(
          `api/v1/store/journal/group/list`
        );
        const templatesData = res.data.data;
        setTemplate(templatesData);
        
      } catch (error) {
        console.error("error", error);
      }
    }

    getdata();
  }, [prove]);

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        py: 3,
        px: 4,

        borderRadius: 4,
        mt: 2,
        overflow: "auto",
      }}
    >
      {dataa
        ?.sort((a, b) => a.group_sequence - b.group_sequence)
        ?.map((item) => {
      
          return (
            <>
              {item.group_id && (
                <CommonFormikModal
                  setLogsProvoke={setLogsProvoke}
                  dataa={dataa}
                  item={item}
                  setProvoke={setProvoke}
                  template={template}
                  changeSequence={changeSequence}
                  setData={setData}
                  setIsDirty={setIsDirty}
                 
                />
              )}
            </>
          );
        })}

      <Accordion
        sx={{
          border: "1.5px solid #D9D9D9",
          boxShadow: "none",
          borderRadius: 2,

          "&:before": {
            display: "none",
          },
        }}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <FPrimaryHeading
            fontColor="#545454"
            fontSize="22px"
            text={t("Customer.Logs")}
          />
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {Logs?.length > 0 ? (
            <Stack
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: -0.5,
              }}
            >
              <Stack
                sx={{
                  height: 45,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  backgroundColor: "#f8f8f8",
                  py: 1,
                }}
              >
                <Stack width={"15%"}>
                  {" "}
                  <Typography
                    variant="body1"
                    fontWeight={800}
                    textAlign={"start"}
                    ml={7}
                  >
                    {t("Customer.Incedent")}
                  </Typography>
                </Stack>

                <Stack width={"28%"}>
                  <Typography
                    variant="body1"
                    fontWeight={800}
                    textAlign={"start"}
                    pl={19.5}
                  >
                    {t("Common.Date")}
                  </Typography>
                </Stack>

                <Stack width={"28%"}>
                  <Typography
                    variant="body1"
                    fontWeight={800}
                    textAlign={"start"}
                    pl={17.5}
                  >
                    {t("Common.CapsEmployee")}
                  </Typography>
                </Stack>

                <Stack width={"28%"}>
                  <Typography
                    variant="body1"
                    fontWeight={800}
                    textAlign={"start"}
                    pl={17.5}
                  >
                    {t("Customer.IP")}
                  </Typography>
                </Stack>
              </Stack>

              {Logs?.length > 0 && Logs?.map((item, index) => {
                return (
                  <Stack
                    key={item.id}
                    sx={{
                      height: 35,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      pb: 1.8,

                      borderBottom:
                        index === Logs.length - 1
                          ? "none"
                          : "1.5px solid #D9D9D9",
                    }}
                  >
                    <Stack width={"15%"}>
                      <Typography variant="body2" textAlign={"start"} ml={7}>
                        {item.event}
                      </Typography>
                    </Stack>

                    <Stack width={"28%"}>
                      <Typography variant="body2" textAlign={"start"} ml={20}>
                     {moment(item.created_at, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY HH:mm")}
                      </Typography>
                    </Stack>

                    <Stack width={"28%"}>
                      <Typography variant="body2" textAlign={"start"} ml={18}>
                        {item.employee_name}
                      </Typography>
                    </Stack>
                    <Stack width={"28%"}>
                      <Typography variant="body2" textAlign={"start"} ml={18}>
                        {item.ip_address && item.ip_address}
                      </Typography>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          ) : (
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "600",
                color: "rgba(0, 0, 0, 0.6)",
                mb: 2,
              }}
            >
              {t("Customer.NoLogs")}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default ArchieveFooter;
