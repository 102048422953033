import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Stack } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import calener from "../../assets/calendar.png";
import settingsIcon from "../../assets/Settings.svg";
import employeeImg from "../../assets/Employees.png";
import Specialoffer from "../../assets/Specialoffer.svg";
import insights from "../../assets/Insights.svg";
import PreviousBooking from "../../assets/PreviousBooking.svg";
import Settingssettings from "../../assets/Settingssettings.svg";
import logo from "../../assets/fiind-white-logo.png"; // Logo image
import "./sidebar.css"; // Your custom CSS
import { useDispatch } from "react-redux";
import { route } from "../../context/routeSlice";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const location = useLocation();
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const iconName = {
    calendar: calener,
    customers: employeeImg,
    services: settingsIcon,
    specialoffers: Specialoffer,
    settings: Settingssettings,
    insights: insights,
    history: PreviousBooking,
  };

  useEffect(() => {
    const currentPath = location.pathname.split("/")[1];

    setSelected(currentPath);
  }, [location.pathname]);




  const Item = ({ title, to, selected, setSelected }) => {
    return (
      <MenuItem
        onClick={() => {
          setSelected(title);
          dispatch(route(title));
       
        }}
        icon={
          <div
            style={{
              padding: "10px",
              borderRadius: "8px",
              backgroundColor:
              location.pathname.includes("/customers") && title === "customers"
                ? "#D9D9D9"
                : location.pathname.includes("/services") && title === "services"
                ? "#D9D9D9" // Ensure 'isActive' only applies when the first condition is false
                : location.pathname.includes("/specialoffers") &&
                  title === "specialoffers"
                ? "#D9D9D9"
                : location.pathname.includes("/insights") && title === "insights"
                ? "#D9D9D9"
                : location.pathname.includes("/history") && title === "history"
                ? "#D9D9D9"
                : location.pathname.includes("/settings") && title === "settings"
                ? "#D9D9D9"
                : location.pathname.includes("/calendar") && title === "calendar"
                ? "#D9D9D9"
                : "transparent",
            
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "aliceblue",
            }}
          >
            <img
              src={iconName[title]}
              style={{ width: "30px", height: "30px" }}
              alt="Calendar Icon"
            />
          </div>
        }
        style={{
          marginTop: "-5px",
          paddingLeft: "20px",
        }}
      >
        <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
          <span style={{ display: "none" }}>{title}</span>{" "}
          {/* Hide the title text */}
        </Link>
      </MenuItem>
    );
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <Box
      className="sidebar"
      sx={{ maxWidth: 80, position: "-webkit-sticky", top: 0, zIndex: 1 }}
    >
      <ProSidebar
        collapsed={true}
        collapsedWidth={"100%"}
        style={{ height: "100vh", paddingTop: 0, paddingBottom: 0 }}
      >
        {" "}
        {/* Set sidebar to collapsed */}
        <Menu iconShape="square" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Stack
            flex={1}
            flexDirection={"column"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ mt: 0 }}
            height={"100vh"}
          >
            <Stack
              flex={1}
              flexDirection={"column"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              {/* Logo at the top */}
              <MenuItem
                className="mt-2 mb-5"
                icon={
                  <img
                    src={logo}
                    alt="Logo"
                    className="logo-img"
                    height={45}
                    width={45}
                    style={{ marginTop: "30px" }}
                  />
                }
              />
              {/* Sidebar menu items */}
              <Box p={0} m={0}>
                {[
                  "calendar",
                  "history",
                  "customers",
                  "services",
                  "specialoffers",
                  "insights",
                ].map((title) => (
                  <Item
                    key={title}
                    title={title}
                    to={`/${title.toLowerCase()}`}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))}
              </Box>
            </Stack>

            {/* Logout button */}
            {/* <MenuItem
            onClick={handleLogout}
            icon={<ExitToAppIcon style={{ marginLeft: "50px", marginRight: "30px" }} />}
            className="logout-item"
            >
            <span style={{ display: 'none' }}>Logout</span> 
          </MenuItem> */}

            <Item
              key={"settings"}
              title={"settings"}
              to={`/settings`}
              selected={selected}
              setSelected={setSelected}
            />
          </Stack>
        </Menu>
      </ProSidebar>
    </Box>
  );
};
export default Sidebar;
