import React, { useState, useEffect } from 'react';
import { Dialog, Stack, DialogContent, Grid2, Divider, Button, Box, Typography, Tooltip, CircularProgress, Modal, Paper, IconButton } from '@mui/material';

import CalenderIcon from '../../assets/calendar.png';
import ClockIcon from '../../assets/newClockDesign.svg';
import NoteIcon from '../../assets/note.svg';
import FSelect from '../../components/commonComponents/F_Select';
import CustomDeleteModal from '../../components/deleteAlertModal';
import NewRescheduleBookingModal from './NewRescheduleModal';

import RescheduleIcon from '../../assets/RescheduleIcon.svg';
import CancellationBookingIcon from '../../assets/CancelIcon.svg';
import PaymentIcon from '../../assets/paymentIcon.svg';
import CancelletionofferIcon from '../../assets/CancellationOfferIcon.svg';
import DeleteIcon from '../../assets/Delete.svg';
import SentSmsIcon from '../../assets/Vector(200).svg';
import SentEmailIcon from '../../assets/Vector(100).svg';
import ImportantNoteIcon from '../../assets/ImportantNoteIcon.svg';
import JournalIcon from '../../assets/Journalicon.svg';
import { formatAmount } from '../../utils/format-amout';

import { t } from 'i18next';
import { toast } from "react-toastify";
import moment from 'moment';

import apiFetcher from '../../utils/interCeptor';
import { HttpStatusCode } from 'axios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import FButton from '../../components/commonComponents/F_Button';
import FPrimaryHeading from '../../components/commonComponents/F_PrimaryHeading';

const commonStackStyle = {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    py: 0.5,
    px: 3,
};

const detailsTextStyle = {
    width: '75%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    textAlign: 'right'
}

const commonDevider = {
    borderBottomWidth: 2,
    borderColor: "#D9D9D9",
    mr: 1.5,
    ml: 1.5
};

export default function NewBookingDetailModal({ open, closeForm, bookingId, handleReschedule }) {

    const [status, setStatus] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const [bookingDetails, setBookingDetails] = useState({});
    const [loading, setLoading] = useState(false);

    const settings = useSelector(state => state.settings.data);

    const getDetails = async () => {
        try {
            const response = await apiFetcher(`api/v1/store/booking/${bookingId}`);
            if (response.status === HttpStatusCode.Ok) {
                setBookingDetails(response?.data?.data);
                setStatus(response?.data?.data?.booking?.status);
            }
        } catch (error) {
            toast.error(t("History.FetchErr"));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        if (bookingId && bookingId) {
            getDetails();
        }
    }, [bookingId]);

    const CustomBox = ({
        borderColor,
        bgColor,
        text,
        IconComponent,
        height = '32px',
        width = '30px',
        textMerginTop = 0.5,
        onClick
    }) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100px',
                    border: `1.5px solid ${borderColor || '#ccc'}`,
                    backgroundColor: bgColor || 'transparent',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderRadius: 3,
                    cursor: 'pointer',
                }}
                onClick={onClick}
            >
                <Stack alignItems="center">
                    <img
                        src={IconComponent}
                        style={{ height: height, width: width }}
                        alt="Icon"
                    />
                    <Typography variant="body2" sx={{ color: '#1f1f1f', fontWeight: 700, mt: textMerginTop, textAlign: 'center' }}>
                        {text}
                    </Typography>
                </Stack>
            </Box>
        )
    };

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return phoneNumber;

        const digits = phoneNumber.replace(/\D/g, "");

        return digits.length === 8
            ? `${digits.slice(0, 2)} ${digits.slice(2, 4)} ${digits.slice(4, 6)} ${digits.slice(6, 8)}`
            : phoneNumber;
    };

    const formatDate = (dateString) => {
        if (!dateString) return dateString;

        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear()).slice(2, 4);
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${day}/${month}-${year} (${hours}:${minutes})`;
    };

    const formatNormalDate = (dateString) => {
        if (!dateString) return dateString;

        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}/${month}-${year}`;
    };

    const formatStartTimeEndTime = (startTime, endTime) => {
        const formatSingleTime = (timeString) => {
            if (!timeString) return timeString;

            const time = new Date(timeString);

            const hours = String(time.getHours()).padStart(2, "0");
            const minutes = String(time.getMinutes()).padStart(2, "0");

            return `${hours}:${minutes}`;
        };

        const startFormatted = formatSingleTime(startTime);
        const endFormatted = formatSingleTime(endTime);

        return `${startFormatted} - ${endFormatted}`;
    };

    const confirmDeleteBooking = async () => {
        const result = await updateBookingStatus("DELETED");
        if (result) {
            setShowDeleteConfirmation(false);
            closeForm();
        }
    };

    const confirmCancelBooking = async () => {
        const result = await updateBookingStatus("CANCELLED");
        if (result) {
            setShowCancelConfirmation(false);
            closeForm();
        }
    };

    const handleCancelBooking = () => {
        setShowCancelConfirmation(true);
    };

    const apiPayloadFormatDate = (date) => moment(date).format("YYYY-MM-DD");

    const apiPayloadFormatTime = (start, end) => `${moment(start).format("HH:mm")} - ${moment(end).format("HH:mm")}`;


    const updateBookingStatus = async (status) => {
        if (!bookingDetails?.booking?.id) {
            toast.error(t("Calendar.ToastErrIdMissing"));
            return false;
        }

        const payload = { id: bookingDetails?.booking?.id };
        let endpoint = "";

        switch (status) {
            case "COMPLETED":
                endpoint = `api/v1/store/booking/complete`;
                break;
            case "CANCELLED":
                endpoint = `api/v1/store/booking/cancel`;
                break;
            case "BOOKED":
                endpoint = `api/v1/store/booking/pending`;
                break;
            case "NOSHOW":
                endpoint = `api/v1/store/booking/noshow`;
                break;
            case "DELETED":
                endpoint = `api/v1/store/booking/delete`;
                break;
            case "RESCHEDULED":
                endpoint = `api/v1/store/booking/reschedule`;
                payload.booking_date = apiPayloadFormatDate(bookingDetails?.booking?.booking_datetime_start);
                payload.time_slot = `${apiPayloadFormatTime(bookingDetails?.booking?.booking_datetime_start, bookingDetails?.booking?.booking_datetime_end)}`;
                payload.employee_id = bookingDetails?.booking?.employee_id;
                payload.total_amount = bookingDetails?.booking?.booking_details?.price;
                break;
            default:
                return false;
        }

        try {
            const response = await apiFetcher.post(endpoint, payload)
            if (response.data.success) {
                toast.success(
                    response.data.msg || t("Calendar.ToastSuccessStatus")
                );
                closeForm();
                // refreshBookings();
                // onClose();
                return true;
            } else {
                toast.error(response.data.msg || t("Calendar.ToastErrStatus"));
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    const handleSend = async (type) => {
        let param = type === "SMS" ? 'send-sms' : 'send-email'

        try {
            const response = await apiFetcher.post(`api/v1/store/booking/${param}/${bookingId}`)
            if (response.data.success) {
                toast.success(param === "send-sms" ? t("Calendar.SendSmsSuccess") : t("Calendar.SendEmailSuccess"));
                getDetails();
            }
        } catch (error) {
            toast.error(param === "send-sms" ? t("Calendar.SendSmsError") : t("Calendar.SendEmailError"));
        }
    }

    return (
        <Modal
            // PaperProps={{
            //     style: {
            //         borderRadius: '25px',
            //         height: "90vh",
            //         width: '80vw',
            //     },
            // }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}
            onClose={closeForm}
            keepMounted
            fullWidth
            maxWidth="md"
            open={open}
        >



            <Paper sx={{ position: 'relative', px: 5, py: 3, minWidth: '60%', borderRadius: 7, overflow: 'hidden', maxWidth: '80%', maxHeight: '90%', overflowY: 'scroll', scrollbarWidth: 'none' }}>

                <IconButton aria-label="close" disableRipple sx={{ position: 'absolute', right: 8, top: 8, color: '#6f6f6f' }} onClick={closeForm}>
                    <Close />
                </IconButton>

                {loading ?
                    <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '30dvw', minHeight: '50dvh', px: 0, py: 3, mt: 0, mb: 0 }}>
                        <CircularProgress size='2.5rem' sx={{ color: '#6f6f6f' }} />
                    </DialogContent>

                    :
                    <React.Fragment>
                        <Grid2 marginTop={1} paddingBottom={1} container spacing={2} >

                            <Grid2 item size={{ xs: 12, md: 5.75 }}>

                                <Stack sx={{ display: 'flex', width: '100%' }}>

                                    <FPrimaryHeading text={t("Calendar.BookingDetails")} sx={{ fontSize: '20px', ml: 4 }} />

                                    <Stack sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 3, px: 1 }}>
                                        <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img
                                                src={CalenderIcon}
                                                alt="calender"
                                                style={{ height: '30px', width: '30px' }}
                                            />

                                            <Typography variant='h6' sx={{ pl: 2 }}>
                                                {formatNormalDate(bookingDetails?.booking?.booking_datetime_start)}
                                            </Typography>
                                        </Stack>

                                        <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <img
                                                src={ClockIcon}
                                                alt="clock"
                                                style={{ height: '27px', width: '27px' }}
                                            />

                                            <Typography variant='h6' sx={{ pl: 2 }}>
                                                {formatStartTimeEndTime(bookingDetails?.booking?.booking_datetime_start, bookingDetails?.booking?.booking_datetime_end)}
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                </Stack>

                                <Stack sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', mt: 3 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                        {t("Insights.Customer")}
                                    </Typography>

                                    <Box sx={{ flexGrow: 1 }} />

                                    <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>

                                        {bookingDetails?.booking?.outlet_customer?.marketplace_pointer &&
                                            <Tooltip placement="top" title={bookingDetails?.booking?.outlet_customer?.marketplace_pointer}>
                                                <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }} >
                                                    <img
                                                        src={ImportantNoteIcon}
                                                        style={{ height: '30px', width: '30px', marginLeft: 10 }}
                                                        alt="important note"
                                                    />
                                                    <Typography variant="body1" ml={1} mr={2} sx={{ fontStyle: 'italic' }}>
                                                        {t("Calendar.impNote")}!
                                                    </Typography>
                                                </Stack>
                                            </Tooltip>
                                        }

                                        {(settings?.profile?.allow_journal && bookingDetails?.booking?.outlet_customer?.id) &&
                                            <Link style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center' }} to={`/customers/${bookingDetails?.booking?.outlet_customer?.id}/journalgroups`}>
                                                <img
                                                    src={JournalIcon}
                                                    style={{ height: '20px', width: '20px', marginLeft: 10 }}
                                                    alt="journal"
                                                />
                                                <Typography variant="body1" ml={1} sx={{ fontStyle: 'italic' }}>
                                                    {t("Customer.Journal")}
                                                </Typography>
                                            </Link>
                                        }
                                    </Stack>
                                </Stack>

                                <Stack
                                    border={"1.5px solid #D9D9D9"}
                                    borderRadius={3}
                                    mt={1}
                                    height="auto"
                                >
                                    <Stack sx={commonStackStyle}>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', width: '25%', fontWeight: 700 }}>{t("Common.Name")}</Typography>
                                        <Typography variant="body1" sx={{ ...detailsTextStyle, color: bookingDetails?.booking?.outlet_customer?.id && ' #1C5DE9', textAlign: 'right' }}>
                                            {bookingDetails?.booking?.outlet_customer?.id ?
                                                <Link to={bookingDetails?.booking?.outlet_customer?.id ? `/customers/${bookingDetails?.booking?.outlet_customer?.id}/customerinformation` : ''} style={{ textDecoration: 'none' }}>
                                                    {bookingDetails?.booking?.outlet_customer?.name}
                                                </Link>
                                                : t("Calendar.DropInCust")
                                            }
                                        </Typography>
                                    </Stack>
                                    <Divider sx={commonDevider} />

                                    <Stack sx={commonStackStyle}>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', width: '25%', fontWeight: 700 }}>{t("Calendar.PhoneNo")}</Typography>
                                        <Typography variant="body1" sx={detailsTextStyle}>
                                            {formatPhoneNumber(bookingDetails?.booking?.outlet_customer?.phone_number)}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={commonDevider} />

                                    <Stack sx={commonStackStyle}>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', width: '25%', fontWeight: 700 }}>
                                            {t("Common.Email")}
                                        </Typography>
                                        <Typography noWrap variant="body1" sx={detailsTextStyle}>
                                            {bookingDetails?.booking?.outlet_customer?.email}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={commonDevider} />

                                    <Stack sx={commonStackStyle}>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', width: '25%', fontWeight: 700 }}>{t("Setting.CPR")}</Typography>
                                        <Typography variant="body1" sx={detailsTextStyle}>
                                            {bookingDetails?.booking?.outlet_customer?.cpr}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={commonDevider} />

                                    <Stack sx={commonStackStyle}>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', width: '25%', fontWeight: 700 }}>{t("Common.Address")}</Typography>
                                        <Typography noWrap variant="body1" sx={detailsTextStyle}>
                                            {bookingDetails?.booking?.outlet_customer?.address}
                                        </Typography>
                                    </Stack>

                                </Stack>

                                <Typography variant="body1" sx={{ color: '#1F1F1F', mt: 3, fontWeight: 700 }}>
                                    {t("Insights.Booking")}
                                </Typography>

                                <Stack
                                    border={"1.5px solid #D9D9D9"}
                                    borderRadius={3}
                                    mt={1}
                                    height="auto"
                                >
                                    <Stack sx={commonStackStyle}>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', width: '25%', fontWeight: 700 }}>{t("Setting.Employee")}</Typography>
                                        <Typography variant="body1" sx={detailsTextStyle}>
                                            {bookingDetails?.booking?.booking_details?.employee_name}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={commonDevider} />

                                    <Stack sx={commonStackStyle}>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', width: '25%', fontWeight: 700 }}>{t("Calendar.BkdBY")}</Typography>
                                        <Typography variant="body1" sx={detailsTextStyle}>
                                            {bookingDetails?.booking?.source == "WEBMARKETPLACE" ? t("Insights.Customer") : bookingDetails?.booking?.booking_details?.created_by_emp_name}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={commonDevider} />

                                    <Stack sx={commonStackStyle}>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', width: '25%', fontWeight: 700 }}>{t("Common.Created")}</Typography>
                                        <Typography variant="body1" sx={detailsTextStyle}>
                                            {moment.parseZone(bookingDetails?.booking?.created_at).format('DD/MM-YY (HH:mm)')}
                                        </Typography>
                                    </Stack>

                                </Stack>

                                <Stack sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', my: 3 }}>
                                    <Typography variant="body1" sx={{ color: '#1F1F1F', fontWeight: 700 }}>
                                        {t("Calendar.Confirmation")}
                                    </Typography>

                                    <Stack direction="row" m={0} p={0} gap={3}>
                                        <Tooltip title={bookingDetails?.booking?.last_sms_time ? moment(bookingDetails?.booking?.last_sms_time).format('YYYY-MM-DD HH:mm:ss') : ''} >
                                            <Box
                                                onClick={() => { handleSend("SMS") }}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-evenly"
                                                height="35px"
                                                bgcolor={bookingDetails?.booking?.last_sms_time ? "#B4F0BA" : "#E5E5E5"}
                                                border={1}
                                                borderColor={bookingDetails?.booking?.last_sms_time ? "#367B3D" : "#A7A7A7"}
                                                p={0.3}
                                                borderRadius={2}
                                                ml={5}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <img
                                                    src={bookingDetails?.booking?.last_sms_time ? SentEmailIcon : SentSmsIcon}
                                                    style={{ height: '20px', width: '20px', marginLeft: 5 }}
                                                    alt="SentSmsIcon"
                                                />
                                                <Typography variant="body1" ml={3} mr={1.5} sx={{ fontStyle: 'italic' }}>
                                                    {t("Calendar.SMS")}
                                                </Typography>
                                            </Box>
                                        </Tooltip>

                                        <Tooltip title={bookingDetails?.booking?.last_email_time ? moment(bookingDetails?.booking?.last_email_time).format('YYYY-MM-DD HH:mm:ss') : ''} >
                                            <Box
                                                onClick={() => { bookingDetails?.booking?.outlet_customer?.email && settings?.profile?.enable_email && handleSend("EMAIL") }}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-evenly"
                                                height="35px"
                                                bgcolor={bookingDetails?.booking?.last_email_time ? "#B4F0BA" : "#E5E5E5"}
                                                border={1}
                                                borderColor={bookingDetails?.booking?.last_email_time ? "#367B3D" : "#A7A7A7"}
                                                p={0.3}
                                                borderRadius={2}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <img
                                                    src={bookingDetails?.booking?.last_email_time ? SentEmailIcon : SentSmsIcon}
                                                    style={{ height: '20px', width: '20px', marginLeft: 5 }}
                                                    alt="SentEmailIcon"
                                                />
                                                <Typography variant="body1" ml={3} mr={1.5} sx={{ fontStyle: 'italic' }}>
                                                    {t("Common.Email")}
                                                </Typography>
                                            </Box>
                                        </Tooltip>

                                    </Stack>

                                </Stack>

                            </Grid2>

                            <Grid2 item sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center' }} size={{ xs: 12, md: 0.5 }}>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        display: { xs: 'none', md: 'block' },
                                        color: '#D9D9D9',
                                        borderWidth: '0.5',
                                    }}
                                />
                            </Grid2>

                            <Grid2 item size={{ xs: 12, md: 5.75 }} sx={{ display: 'flex', flexDirection: 'column', py: 3 }}>


                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    {t("Common.Status")}
                                </Typography>

                                <FSelect
                                    sx={{ width: "100%", mt: 1 }}
                                    fontWeight={700}
                                    fontSize={'17px'}
                                    placeholderText='Select Status'
                                    options={[
                                        { value: 'BOOKED', label: <Typography fontWeight={700} variant="body1">{t("Calendar.Booked")}</Typography> },
                                        { value: 'RESCHEDULED', label: <Typography fontWeight={700} variant="body1">{t("Calendar.Rescheduled")}</Typography> },
                                        { value: 'COMPLETED', label: <Typography fontWeight={700} variant="body1">{t("Common.Completed")}</Typography> },
                                        { value: 'CANCELLED', label: <Typography fontWeight={700} variant="body1">{t("Common.Cancelled")}</Typography> },
                                        // { value: 'OFFER_ACCEPTED', label: <Typography fontWeight={700} variant="body1">{t("Calendar.CofAcpt")}</Typography> },
                                        { value: 'NOSHOW', label: <Typography fontWeight={700} variant="body1">{t("Calendar.AbsenceBooking")}</Typography> },
                                        // { value: 'OFFERED', label: <Typography fontWeight={700} variant="body1">{t("Calendar.AwaitNewCus")}</Typography> },
                                    ]}
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value)
                                        updateBookingStatus(e.target.value);
                                    }}
                                    colorMap={{
                                        'BOOKED': '#A79C92',
                                        'RESCHEDULED': '#A79C92',
                                        'COMPLETED': '#367B3D',
                                        'CANCELLED': '#C74141',
                                        'OFFER_ACCEPTED': '#447BCD',
                                        'NOSHOW': '#A36437',
                                        'OFFERED': '#E19957'
                                    }}
                                />

                                <Typography variant="body1" sx={{ mt: 2, fontWeight: 700 }}>
                                    {t("Calendar.Treatment")}
                                </Typography>

                                <Stack
                                    border={"1.5px solid #D9D9D9"}
                                    borderRadius={3}
                                    mt={1}
                                    height="auto"
                                >
                                    <Stack sx={{ ...commonStackStyle, px: 3, py: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', fontWeight: 700 }}>{t("Calendar.Performance")}</Typography>
                                        <Typography variant="body1" sx={{ color: '#1F1F1F', fontWeight: 700 }}> {t("Common.Price")} </Typography>
                                    </Stack>
                                    <Divider sx={commonDevider} />

                                    <Stack sx={{ ...commonStackStyle, width: '100%', alignItems: 'flex-start', px: 3, py: 1 }}>
                                        <Typography variant="body1" sx={{ width: '60%', fontWeight: 700, mr: 2 }}>{bookingDetails?.booking?.booking_details?.service_name}</Typography>
                                        <Typography variant="body1" sx={{ width: '40%', textAlign: 'right' }}> {formatAmount(bookingDetails?.booking?.booking_details?.price, false)}</Typography>
                                    </Stack>

                                    {bookingDetails?.booking?.booking_details?.note &&
                                        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', px: 1.5, mt: 1, mb: 3 }}>
                                            <img
                                                src={NoteIcon}
                                                alt="booking note"
                                                style={{ height: '16px', width: '16px', marginTop: 3 }}
                                            />

                                            <Typography variant="body1" sx={{ color: '#000000', ml: 1, fontStyle: 'italic' }}>{`${t("Common.Note")}: ${bookingDetails?.booking?.booking_details?.note}`}</Typography>
                                        </Stack>
                                    }

                                    <Divider sx={commonDevider} />
                                    <Stack sx={{ ...commonStackStyle, px: 3, py: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 700 }}>{t("Common.Total")}</Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 700 }}> {formatAmount(bookingDetails?.booking?.total_amount, false)}</Typography>
                                    </Stack>

                                </Stack>

                                <Typography variant="body1" sx={{ mt: 'auto', fontWeight: 700 }}>
                                    {t("Calendar.Actions")}
                                </Typography>

                                <Grid2 container sx={{ mt: 2 }} spacing={2}>

                                    <Grid2 item size={{ xs: 4 }}>
                                        <CustomBox
                                            borderColor="#847A71"
                                            bgColor="#F1D5BD"
                                            text={t("Calendar.Reschedule")}
                                            IconComponent={RescheduleIcon}
                                            onClick={() => handleReschedule(bookingDetails)}
                                        />
                                    </Grid2>

                                    <Grid2 item size={{ xs: 4 }}>
                                        {status === 'CANCELLED' ?
                                            <CustomBox
                                                borderColor="#005BD9"
                                                bgColor='#BEBBFF'
                                                text={t("Calendar.CanceOff")}
                                                IconComponent={CancelletionofferIcon}
                                            // onClick={() => console.log('CancellationOffer clicked!')}
                                            />
                                            :
                                            <CustomBox
                                                borderColor="#E80006"
                                                bgColor='#FFCECF'
                                                text={t("Setting.Cancel")}
                                                IconComponent={CancellationBookingIcon}
                                                onClick={() => handleCancelBooking()}
                                            />
                                        }
                                    </Grid2>

                                    <Grid2 item size={{ xs: 4 }}>
                                        <CustomBox
                                            borderColor="green"
                                            bgColor="lightgreen"
                                            text={t("Calendar.Payment")}
                                            height='42px'
                                            width='42px'
                                            textMerginTop={0}
                                            IconComponent={PaymentIcon}
                                        // onClick={() => console.log('Payment clicked!')}
                                        />
                                    </Grid2>
                                </Grid2>

                            </Grid2>

                        </Grid2>

                        <Divider sx={{ borderWidth: '0.5', borderColor: '#696969' }} />

                        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 2, px: 5 }}>
                            {/* <CommonButton
                                height={40}
                                title={t("Common.Back")}
                                backgroundColor={'#D9D9D9'}
                                onClick={() => closeForm()}
                            /> */}

                            <FButton
                                height={40}
                                title={t("Common.Back")}
                                variant={'save'}
                                onClick={() => closeForm()}
                                sx={{ backgroundColor: '#D9D9D9', minWidth: '150px' }}
                            />



                            <FButton
                                variant="contained"
                                title={<Typography variant="body2" sx={{ fontStyle: 'italic', color: '#000000' }}>{t("Calendar.DeleteBooking")}</Typography>}
                                startIcon={<img src={DeleteIcon} alt="icon" style={{ width: '20px', height: '20px' }} />}
                                sx={{
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none',
                                    },
                                    textTransform: 'none',
                                    borderRadius: 2,
                                    backgroundColor: '#FFCECF',
                                    border: '1px solid #D30000'
                                }}
                                onClick={() => setShowDeleteConfirmation(true)}
                            />
                            {/* <Typography sx={{ fontStyle: 'italic', fontWeight: 'normal', ml: 2, color: 'black' }}>
                                 
                                </Typography>
                            </Button> */}

                        </Stack>

                        {showDeleteConfirmation &&
                            <CustomDeleteModal
                                open={showDeleteConfirmation}
                                handleClose={() => setShowDeleteConfirmation(false)}
                                description={t("Calendar.DeleteDescription")}
                                title={t("Calendar.DeleteBooking")}
                                onClickDismiss={() => setShowDeleteConfirmation(false)}
                                onClickConfirm={confirmDeleteBooking}
                            />
                        }

                        {showCancelConfirmation &&
                            <CustomDeleteModal
                                open={showCancelConfirmation}
                                handleClose={() => setShowCancelConfirmation(false)}
                                description={t("Calendar.CancelDescription")}
                                title={t("Calendar.CancelBooking")}
                                onClickDismiss={() => setShowCancelConfirmation(false)}
                                onClickConfirm={confirmCancelBooking}
                            />
                        }

                        {showRescheduleModal &&
                            <NewRescheduleBookingModal
                                open={showRescheduleModal}
                                closeForm={() => setShowRescheduleModal(false)}
                            />
                        }
                    </React.Fragment>

                }

            </Paper>

        </Modal >
    )
};