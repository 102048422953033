import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-calendar/dist/Calendar.css";
import "../../index.css";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import "dayjs/locale/en-gb";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import apiFetcher from "../../utils/interCeptor";
import { useFormik } from "formik";
import CustomerBookingForm from "./CustomerBookingForm";
import SelectServiceDialog from "./SelectServiceDialog";
import BookingTimeSlotModal from "./BookingTimeSlotModal";
import ConfirmationBookingDialog from "./CustomerBookingModal";
import { t } from "i18next";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isoWeek);
dayjs.locale("en-gb");

const BookingForm = ({ closeForm, initialData, refreshBookings, open, asComponetOFBooking = false, customer = {} }) => {

  const [isSelected, setIsSelected] = useState("");

  const validationSchema = Yup.object({
    customerName: Yup.string()
      .required(t("Calendar.NameFieldError"))
      .typeError(t("Calendar.NameFieldError")),
    phone_number: Yup.string()
      .required(t("Calendar.PhoneFieldError"))
      .min(8, t("Calendar.PhoneLengthError"))
      .max(8)
      .typeError(t("Calendar.PhoneFieldError")),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerName: "",
      phone_number: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission here
      handleOpenServiceModal();
    },
  });
  useEffect(() => {
    // Set default values from initialData if provided
    if (initialData) {
      setSelectedDate(dayjs(initialData.date));
      setSelectedService(initialData.serviceId);
      setSelectedEmployee({
        id: initialData.employeeId,
        name: initialData.employeeName,
      });
      setSelectedTimeSlots([initialData.timeSlot]);
      setServicePrice(initialData.price);
    }
  }, [initialData]);

  const authTokenUser = localStorage.getItem("auth_token");
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showTimeSlotModal, setShowTimeSlotModal] = useState(false);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [servicePrice, setServicePrice] = useState(0); // Store service price
  const [openingHours, setOpeningHours] = useState(null);
  const [closingHours, setClosingHours] = useState(null);
  const [scheduldes, setSchedules] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [employees, setEmployees] = useState([]); // State to hold employees
  const [selectedEmployee, setSelectedEmployee] = useState({
    id: "",
    name: "",
  });
  const [selctedServiceName, setSelectedServiceName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const openConfirmationModal = () => {
    // setShowConfirmationModal(true);
    setShowTimeSlotModal(false);
    if (!selectedEmployee) {
      toast.error(t("Calendar.ToastErrEmpSelect"));
      // return;
      setShowServiceModal(false);
      setShowTimeSlotModal(true); // Open the time slot modal only if employees are available
      return;
    } else {
      setShowTimeSlotModal(false);
      setShowConfirmationModal(true);
      return;
    }
  };

  // Function to fetch booking times
  const fetchBookingTimes = async () => {
    try {
      const response = await apiFetcher.gept(`api/v1/store/schedule`);
      // Assuming response contains an array of time slots with 'start' and 'end' properties
      const responseData = response.data.data;
      setSchedules(responseData);
      const openTimes = response?.data?.data.map((item) => item.open_time);
      setOpeningHours(openTimes);
      const closeTimes = response?.data?.data.map((item) => item.close_time);
      setClosingHours(closeTimes);
    } catch (error) {
      console.error("Error fetching booking times:", error);
    }
  };
  useEffect(() => {
    fetchBookingTimes();
  }, []);

  const dayMap = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  // Function to generate time slots from 9:00 to 16:55 in 5-minute intervals
  const generateTimeSlots = () => {
    const slotsByHour = {};
    const slots = [];
    let startHour = 0;
    let endHour = 23;

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        if (!(hour === endHour && minute > 55)) {
          // Only allow until the end hour's last 5-minute slot
          const formattedTime = `${hour}:${minute.toString().padStart(2, "0")}`;
          slots.push(formattedTime);
        }
      }
      slotsByHour[hour] = slots;
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();
  const selectedDayIndex = dayjs(selectedDate).day();
  const selectedDayName = dayMap[selectedDayIndex];
  const scheduleForSelectedDay = scheduldes.find(
    (schedule) => schedule.day === selectedDayName
  );
  const openinghours = scheduleForSelectedDay?.open_time;

  useEffect(() => {
    if (selectedService) {
      fetchAvailableTimeSlots(selectedService);
      setSelectedTimeSlots("");
      setIsSelected("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedService, selectedEmployee]);

  useEffect(() => {
    if (asComponetOFBooking) {
      handleOpenServiceModal()
    }
  }, [asComponetOFBooking])

  // Function to open the service modal
  const handleOpenServiceModal = async () => {
    try {
      const serviceGroupResponse = await axios.get(
        `${process.env.REACT_APP_URL}/api/v1/store/service_group`,
        {
          headers: {
            Authorization: `Bearer ${authTokenUser}`,
          },
        }
      );

      const serviceGroupsData = serviceGroupResponse.data.data;
      setServiceGroups(serviceGroupsData);

      setShowServiceModal(true);
      setShowTimeSlotModal(false); // Ensure time slot modal is closed
    } catch (error) {
      console.error("Error fetching service group data:", error);
      toast.error(t("Calendar.ToastErrService"));
    }
  };

  const fetchAvailableTimeSlots = async (serviceId) => {
    try {
      setIsLoading(true)
      const response = await apiFetcher.get(
        `api/v1/store/booking/timings?service_id=${serviceId}&whole_day=true&date=${selectedDate.format(
          "YYYY-MM-DD"
        )}${selectedEmployee.id ? `&employee_id=${selectedEmployee.id}` : ""}`
      );

      if (response.data.success) {
        const bookedSlots = response.data.data; // Assume this contains booked slots for the selected date
        const filteredSlots = bookedSlots.map((element) => {
          if (element.startsWith("0")) {
            return element.slice(1);
          } else {
            return element;
          }
        });

        setAvailableTimeSlots(filteredSlots);
        setShowTimeSlotModal(true);
        setShowServiceModal(false); // Close the previous modal when this opens
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching time slots:", error);
    }
  };

  // Function to handle service selection
  const handleServiceSelect = (service) => {
    const employeesForService = service.employees || [];

    // If no employees are available for the service, show a toast and do not proceed
    if (employeesForService.length === 0) {
      toast.error(t("Calendar.ToastErrUnavailableService"));
      return;
    }

    setSelectedServiceName(service.name);
    setSelectedService(service.id);
    setServicePrice(service.price);
    setEmployees(employeesForService);
    setShowServiceModal(false);
    setShowTimeSlotModal(true); // Open the time slot modal only if employees are available
  };

  useEffect(() => {
    const selectedId = employees.length > 0 ? employees[0].id : "";
    const selectedEmployeeObj = employees.find((emp) => emp.id === selectedId);
    const selectedName = selectedEmployeeObj ? selectedEmployeeObj.name : "";

    setSelectedEmployee({
      id: selectedId,
      name: selectedName,
    });
  }, [employees]);

  const handleSelectedTimeSlot = (selectedSlot) => {

    const matchedSlot = availableTimeSlots.find((slot) => {
      return slot.startsWith(selectedSlot);
    });


    if (matchedSlot) {
      setSelectedTimeSlots((prevSelectedSlot) => {
        if (
          prevSelectedSlot &&
          prevSelectedSlot !== "" &&
          prevSelectedSlot === matchedSlot
        ) {
          // alert("if")
          return ""; // Deselect
        } else {
          // alert("else")
          // Otherwise, select the matched slot
          return matchedSlot;
        }
      });
    } 
  };

  const handleBookingConfirmation = async () => {
    const bookingData = {
      booking_date: selectedDate.format("YYYY-MM-DD"),
      customer_name: asComponetOFBooking ? customer?.name : formik.values.customerName,
      customer_phone_number: asComponetOFBooking ? customer?.phone_number : formik.values.phone_number,
      employee_id: selectedEmployee.id,
      service_id: selectedService,
      time_slot: selectedTimeSlots,
      total_amount: servicePrice,
    };

    try {
      const response = await apiFetcher.post(
        `api/v1/store/booking`,
        bookingData
      );

      if (response.data.success) {
        toast.success(t("Calendar.ToastSuccessBookingConfirm"));
        setTimeout(() => {
          setShowServiceModal(false);
          setShowTimeSlotModal(false);
          setShowConfirmationModal(false);
          closeForm(); // Close the form
          refreshBookings();
        }, 500)

        // Close all modals and form

        return;
      } else {
        // Show error toast with backend message if booking failed
        toast.error(response.data.msg || t("Calendar.ToastErrBookingFailed"));
      }
    } catch (error) {
      toast.error(t("Calendar.ToastErrTimeSlot"));
    }
  };

  const groupTimeSlotsByHour = (timeSlots) => {
    return timeSlots.reduce((acc, time) => {
      const hour = time.split(":")[0];
      if (!acc[hour]) acc[hour] = [];
      acc[hour].push(time);
      return acc;
    }, {});
  };

  useEffect(() => {
    if (availableTimeSlots.length > 0) {
      const openinghour = parseInt(openinghours?.split(":")[0], 10);

      // Find the element whose text content matches the opening hour
      const elements = document.querySelectorAll(".hour-label"); // Select all elements with the class 'hour-label'
      const element = Array.from(elements).find(
        (el) => el.textContent.trim() === String(openinghour)
      );
      if (element) {
        // element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [openinghours, availableTimeSlots]);

  return (
    <>
      <ToastContainer />
      {!showServiceModal && !showTimeSlotModal && !showConfirmationModal && !asComponetOFBooking && (
        <CustomerBookingForm
          formik={formik}
          open={open}
          closeForm={closeForm}
        />
      )}
      {/* Modal to display service groups and services */}
      <SelectServiceDialog
        handleServiceSelect={handleServiceSelect}
        showServiceModal={showServiceModal}
        setShowServiceModal={setShowServiceModal}
        closeForm={closeForm}
        serviceGroups={serviceGroups}
      />
      {/* Modal to display available time slots */}
      <BookingTimeSlotModal
        setShowTimeSlotModal={setShowTimeSlotModal}
        setShowServiceModal={setShowServiceModal}
        showTimeSlotModal={showTimeSlotModal}
        selectedDate={selectedDate}
        selectedEmployee={selectedEmployee}
        selectedTimeSlots={selectedTimeSlots}
        employees={employees}
        setSelectedEmployee={setSelectedEmployee}
        setSelectedDate={setSelectedDate}
        availableTimeSlots={availableTimeSlots}
        groupTimeSlotsByHour={groupTimeSlotsByHour}
        timeSlots={timeSlots}
        openinghours={openingHours}
        closinghours={closingHours}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        handleSelectedTimeSlot={handleSelectedTimeSlot}
        openConfirmationModal={openConfirmationModal}
        closeForm={closeForm}
        isLoadingSlots={isLoading}
      />
      {/* Confirmation Modal */}
      <ConfirmationBookingDialog
        handleBookingConfirmation={handleBookingConfirmation}
        servicePrice={servicePrice}
        selectedDate={selectedDate}
        selctedServiceName={selctedServiceName}
        selectedEmployee={selectedEmployee}
        selectedTimeSlots={selectedTimeSlots}
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        setShowTimeSlotModal={setShowTimeSlotModal}
        closeForm={closeForm}

      />
    </>
  );
};

export default BookingForm;
