import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Modal, Select, MenuItem, Switch, IconButton,
    Typography, Checkbox, TextField, Autocomplete, FormControl
} from '@mui/material';

import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import axios from 'axios';
import { toast } from "react-toastify";

import CustomTextField from '../settings/commonTextinput';
import CustomSelect from '../settings/commonCustomSelect';
// import PrimaryHeading from '../settings/commonPrimaryHeading';
import CommonButton from '../settings/commonButton';
import CustomDatePicker from '../settings/commonDatePicker';
import CustomTimePicker from '../settings/commonTimePicker';

import { useFormik, Form, Formik } from "formik";
import * as Yup from "yup";

import moment from 'moment';
import { t } from 'i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    // maxWidth: "md",
    bgcolor: '#FFFFFF',
    borderRadius: 3,
    boxShadow: 0,
    p: 3.5,
};

function AddSpecialOfferModal(props) {
    const { user , open, handleClose, selectedSpecialOffer, type } = props;

    const authTokenUser = localStorage.getItem('auth_token');

    const [isLoading, setLoading] = useState(false);
    const [standardprice, setStandardPrice] = useState('0');
    const [serviceList, setServiceList] = useState([]);
    const [searchService, setSearchService] = useState('');
    const [initialValues, setInitialValues] = useState({
        start_date: null,
        start_time: null,
        ending_date: null,
        ending_time: null,
        endOf_Offer: '',
        customStartDate: '',
        customEndDate: '',
        customStartTime: '',
        customEndTime: '',
        newPrice: '',
        percentage: '',
        isActive: false,
        selectedService: null,
        selectedEmployeeIds: [],
    });


    useEffect(() => {
        if (type === 'edit' && selectedSpecialOffer) {
            const startDate = new Date(selectedSpecialOffer?.start_datetime);
            const endDate = new Date(selectedSpecialOffer?.end_datetime);

            formik.setValues({
                start_date: moment(startDate),
                start_time: moment(startDate),
                ending_date: moment(endDate),
                ending_time: moment(endDate),
            });
        }
    }, [type, selectedSpecialOffer]);

    useEffect(() => {
        fetchServiceApi();
    }, []);

    async function fetchServiceApi() {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/service`, {
                headers: {
                    Authorization: `Bearer ${authTokenUser}`
                }
            });

            if (response) {
                setServiceList(response.data.data);
                setLoading(false);
            } else {
                setServiceList([]);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching service data:", error);
            setServiceList([]);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (type === 'edit' && selectedSpecialOffer && serviceList.length > 0) {


            serviceList.forEach(service => {
                if (service.id === selectedSpecialOffer?.service.special_source_id) {
                    formik.setFieldValue('selectedService', service);

                    setStandardPrice(parseFloat(service.price));

                    const selectedEmployeeIds = selectedSpecialOffer?.employees?.map(employee => employee.id) || [];
                    const matchedEmployeeIds = service.employees?.filter(employee =>  selectedEmployeeIds.includes(employee.id)).map(employee => employee.id) || [];
                    formik.setFieldValue('selectedEmployeeIds', matchedEmployeeIds);

                    const newPrice = parseFloat(selectedSpecialOffer?.service.special_price);
                    const percentage = parseFloat(selectedSpecialOffer?.service.special_percentage);
                    formik.setFieldValue('newPrice', newPrice);
                    formik.setFieldValue('percentage', percentage);
                }
            });
        }
    }, [serviceList]);

    const handleServiceChange = (event) => {
        const selectedServiceId = event.target.value;
        const service = serviceList.find(service => service.id === selectedServiceId);

        if (service) {
            formik.setFieldValue('selectedService', service)
            setStandardPrice(parseFloat(service.price));
            // formik.setFieldValue('selectedEmployeeIds', service.employees.map(employee => employee.id))
            formik.setFieldValue('selectedEmployeeIds', []);
        } else {
            formik.setFieldValue('selectedService', null)
            setStandardPrice(0);
            formik.setFieldValue('selectedEmployeeIds', []);
        }
    };

    // const handleEmployeeToggle = (employeeId) => {
    //     const currentSelectedEmployeeIds = formik.values.selectedEmployeeIds;

    //     const isEmployeeSelected = currentSelectedEmployeeIds.includes(employeeId);

    //     const newSelectedEmployeeIds = isEmployeeSelected
    //         ? currentSelectedEmployeeIds.filter(id => id !== employeeId)
    //         : [...currentSelectedEmployeeIds, employeeId];

    //     formik.setFieldValue('selectedEmployeeIds', newSelectedEmployeeIds);
    // };

    let timeoutId;

    const handleEmployeeToggle = (employeeId) => {
        clearTimeout(timeoutId);
    
        timeoutId = setTimeout(() => {
            const currentSelectedEmployeeIds = formik.values.selectedEmployeeIds || [];
    
            const isEmployeeSelected = currentSelectedEmployeeIds.includes(employeeId);
    
            const newSelectedEmployeeIds = isEmployeeSelected
                ? currentSelectedEmployeeIds.filter(id => id !== employeeId)
                : [...currentSelectedEmployeeIds, employeeId];
    
            formik.setFieldValue('selectedEmployeeIds', newSelectedEmployeeIds);
        }, 300);
    };

    const resetFields = (command) => {
        handleClose(command);
    };

    const handleCloseModal = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
    };

    const handlePercentageChange = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;

        if (value === '.') {
            return;
        }

        if (value === '') {
            formik.setFieldValue('percentage', '');
            formik.setFieldValue('newPrice', '');
            return;
        }

        if (regex.test(value)) {
            const percentage = parseFloat(value);

            if (percentage > 100) {
                formik.setFieldValue('percentage', '100');
                formik.setFieldValue('newPrice', (standardprice * 1).toFixed(2));
            } else {
                const calculatedNewPrice = standardprice - (standardprice * (percentage / 100));
                formik.setFieldValue('percentage', value);
                formik.setFieldValue('newPrice', calculatedNewPrice.toFixed(2));
            }
        }
    };

    const handleNewPriceChange = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;

        if (value === '.') {
            return;
        }

        if (value === '') {
            formik.setFieldValue('newPrice', '');
            formik.setFieldValue('percentage', '');
            return;
        }

        if (regex.test(value)) {
            const newPrice = parseFloat(value);

            if (newPrice > standardprice) {
                formik.setFieldValue('newPrice', standardprice.toString());
                formik.setFieldValue('percentage', '0.00');
            } else {
                const calculatedPercentage = ((standardprice - newPrice) / standardprice) * 100;
                formik.setFieldValue('newPrice', value);
                formik.setFieldValue('percentage', calculatedPercentage.toFixed(2));
            }
        }
    };

    // const validationSchema = Yup.object({
    //     isActive: Yup.boolean(),

    //     // start_date: Yup.string().when('isActive', {
    //     //     is: false,
    //     //     then: Yup.string().required('Start date is required')
    //     // }),
    //     start_date: Yup.date().nullable().when('isActive', {
    //         is: false,
    //         then: Yup.date().required('Start date is required').nullable().typeError('Invalid date'),
    //     }),

    //     start_time: Yup.string().when('isActive', {
    //         is: false,
    //         then: Yup.string().required('Start time is required').nullable()
    //     }),

    //     // customStartDate: Yup.date().when('start_date', {
    //     //     is: 'custom',
    //     //     then: Yup.date().required('Custom start date is required'),
    //     //     otherwise: Yup.date().nullable()
    //     // }),

    //     // customStartTime: Yup.string().when('start_time', {
    //     //     is: 'custom',
    //     //     then: Yup.string().required('Custom start time is required'),
    //     //     otherwise: Yup.string().nullable()
    //     // }),

    //     // endOf_Offer: Yup.string().when('isActive', {
    //     //     is: (value) => value === true || value === false,
    //     //     then: Yup.string().required('End of offer is required')
    //     // }),

    //     // ending_date: Yup.date().when('endOf_Offer', {
    //     //     is: 'custom',
    //     //     then: Yup.date().required('Ending date is required'),
    //     //     otherwise: Yup.date().nullable()
    //     // }),
    //     ending_date: Yup.date().nullable().when('isActive', {
    //         is: false,
    //         then: Yup.date().required('Ending date is required').nullable().typeError('Invalid date'),
    //     }),

    //     // ending_time: Yup.string().when('endOf_Offer', {
    //     //     is: 'custom',
    //     //     then: Yup.string().required('Ending time is required'),
    //     //     otherwise: Yup.string().nullable()
    //     // }),
    //     ending_time: Yup.string().when('isActive', {
    //         is: false,
    //         then: Yup.string().required('Ending time is required').nullable()
    //     }),

    //     // customEndDate: Yup.date().when('ending_date', {
    //     //     is: 'custom',
    //     //     then: Yup.date().required('Custom ending date is required'),
    //     //     otherwise: Yup.date().nullable()
    //     // }),

    //     // customEndTime: Yup.string().when('ending_time', {
    //     //     is: 'custom',
    //     //     then: Yup.string().required('Custom ending time is required'),
    //     //     otherwise: Yup.string().nullable()
    //     // }),

    //     selectedService: Yup.object()
    //         .required('Service is required')
    //         .nullable(),

    //     // selectedEmployeeIds: Yup.array()
    //     //     .of(Yup.string().required('Employee ID is required'))
    //     //     .min(1, 'At least one employee must be selected')
    //     //     .required('Employees are required'),

    //     newPrice: Yup.number()
    //         .required('New Price is required')
    //         .positive('New Price must be a positive number')
    //         .min(0, 'New Price cannot be negative')
    //         .typeError('New Price must be a valid number'),

    //     percentage: Yup.number()
    //         .required('Percentage is required')
    //         .min(0, 'Percentage must be between 0 and 100')
    //         .max(100, 'Percentage must be between 0 and 100')
    //         .typeError('Percentage must be a valid number'),
    // });

    const validationSchema = Yup.object({
        isActive: Yup.boolean(),
    
        start_date: Yup.date()
            .nullable()
            .when('isActive', {
                is: false,
                then: Yup.date()
                    .required(t('SpOffers.YupErrStartDateRequired'))
                    .nullable()
                    .typeError(t('SpOffers.YupErrStartDateTypeError')),
                otherwise: Yup.date().nullable(),
            }),
    
        start_time: Yup.string()
            .when('isActive', {
                is: false,
                then: Yup.string()
                    .required(t('SpOffers.YupErrStartTimeRequired'))
                    .nullable(),
                otherwise: Yup.string().nullable(),
            }),
    
        ending_date: Yup.date()
            .nullable()
            .when('isActive', {
                is: false,
                then: Yup.date()
                    .required(t('SpOffers.YupErrEndingDateRequired'))
                    .nullable()
                    .typeError(t('SpOffers.YupErrEndingDateTypeError')),
                otherwise: Yup.date()
                    .required(t('SpOffers.YupErrEndingDateRequired'))
                    .nullable()
                    .typeError(t('SpOffers.YupErrEndingDateTypeError')),
            }),
    
        ending_time: Yup.string()
            .when('isActive', {
                is: false,
                then: Yup.string()
                    .required(t('SpOffers.YupErrEndingTimeRequired'))
                    .nullable(),
                otherwise: Yup.string()
                    .required(t('SpOffers.YupErrEndingTimeRequired'))
                    .nullable(),
            }),
    
        selectedService: Yup.object()
            .required(t('SpOffers.YupErrSelectedServiceRequired'))
            .nullable(),
    
        newPrice: Yup.number()
            .required(t('SpOffers.YupErrNewPriceRequired'))
            .positive(t('SpOffers.YupErrNewPricePositive'))
            .min(0, t('SpOffers.YupErrNewPriceMin'))
            .typeError(t('SpOffers.YupErrNewPriceTypeError')),
    
        percentage: Yup.number()
            .required(t('SpOffers.YupErrPercentageRequired'))
            .min(0, t('SpOffers.YupErrPercentageMin'))
            .max(100, t('SpOffers.YupErrPercentageMax'))
            .typeError(t('SpOffers.YupErrPercentageTypeError')),
    });
    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: async (values) => {


            let startDate = null;
            let startTime = null;
            let endDate = null;
            let endTime = null;

            const formatDate = (date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            };

            const formatTime = (date) => {
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                return `${hours}:${minutes}:${seconds}`;
            };

            if (!values.isActive) {
                startDate = formatDate(new Date(values.start_date));
                startTime = formatTime(new Date(values.start_time));
                endDate = formatDate(new Date(values.ending_date));
                endTime = formatTime(new Date(values.ending_time));
            } else {
                const currentDate = new Date();
                startDate = formatDate(currentDate);
                startTime = formatTime(currentDate);
                endDate = formatDate(new Date(values.ending_date));
                endTime = formatTime(new Date(values.ending_time));
            }

            const specialPrice = Number(values.newPrice);
            const formattedPercentage = Number(values.percentage) % 1 === 0 ? Number(values.percentage) : Number(values.percentage).toFixed(2).replace('.00', '');

            try {
                const data = {
                    start_datetime: `${startDate} ${startTime}`,
                    end_datetime: `${endDate} ${endTime}`,
                    service_id: type === 'edit' ? selectedSpecialOffer?.service_id : values.selectedService?.id,
                    special_price: specialPrice,
                    special_percentage: formattedPercentage,
                    employee_ids: values.selectedEmployeeIds,
                };

                let response;

                if (type === 'create') {
                    response = await axios.post(`${process.env.REACT_APP_URL}/api/v1/store/special_offer`, data, {
                        headers: {
                            Authorization: `Bearer ${authTokenUser}`
                        }
                    });

                    if (response?.status === 200) {
                        toast.success(t("SpOffers.ToastSSpOFCr"));
                        resetFields('Callapi');
                    }

                } else if (type === 'edit') {
                    response = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/store/special_offer/${selectedSpecialOffer?.id}`, data, {
                        headers: {
                            Authorization: `Bearer ${authTokenUser}`
                        }
                    });

                    if (response?.status === 200) {
                        toast.success(t("SpOffers.ToastSSpOU"));
                        resetFields('Callapi');
                    }

                } else {
                    // toast.error(`Failed to ${type} special offer`);
                    toast.error(t("SpOffers.ToastSSpOU" ));
                }

            } catch (error) {
                console.error(`Error while ${type} special offer:`, error);
            } finally {
                setLoading(false);
            }
        },
    });

    const filteredServices = serviceList.filter(service =>
        service.name.toLowerCase().includes(searchService?.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchService(e.target.value);
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={[style, {
                zIndex: 12,
                justifyContent: 'center', alignItems: 'center',
                overflowY: { xs: 'auto', sm: '85vh' },
                overflowX: 'hidden',
                maxHeight: { xs: '90vh', sm: '85vh' },
                position: 'relative'
            }]}>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => resetFields()}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '30px',
                            zIndex: 10,
                            color: 'black'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                }}>
                    {/* <PrimaryHeading text={type == 'edit' ? 'Update special offer' : 'Create a special offer'} fontSize='26px' /> */}
                    <Typography variant='h6' sx={{ fontWeight: 700, color: '#1f1f1f', textAlign: 'center' }}>
                        {type == 'edit' ? t("SpOffers.UpSpOf") : t("SpOffers.CrSpOf")}
                    </Typography>
                </Box>

                <form onReset={() => formik.resetForm()} onSubmit={formik.handleSubmit}>

                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '49%', alignItems: 'flex-start' }}>

                            {/* <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 3,
                                width: '50%',
                                maxWidth: '1440px',
                                '@media (max-width: 1440px)': {
                                    width: '63%',
                                }
                            }}>
                                <Box sx={{ ml: 2.5 }}>
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>
                                        {'Start instantly'}
                                    </Typography>
                                </Box>

                                <Switch
                                    checked={formik.values.isActive}
                                    onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{
                                        '& .MuiSwitch-switchBase': {
                                            '&.Mui-checked': { color: '#fff' },
                                            '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#44B904' },
                                        },
                                        '& .MuiSwitch-track': { backgroundColor: '#D9D9D9' },
                                    }}
                                />
                            </Box> */}

                            {!formik.values.isActive &&
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: formik.values.start_date === 'custom' ? null : 'center', mt: 2 }}>
                                        {/* <PrimaryHeading text={'Set starting date'} /> */}
                                        <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f',  width:'50%',  textAlign:'right' , pr:3 }}>
                                            {t("SpOffers.SetStDate")}
                                        </Typography>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            maxWidth: '60%',
                                            '@media (max-width: 1440px)': {
                                                maxWidth: '50%',
                                            },
                                            '@media (max-width: 900px)': {
                                                maxWidth: '40%',
                                            }
                                        }}>
                                            {/* <CustomSelect
                                                value={selectedValues.startDate.value}
                                                onChange={(e) => handleDateChange('startDate', e.target.value)}
                                                options={dateOptions}
                                                sx={{ width: '100%' }}
                                            /> */}

                                            {/* <CustomSelect
                                                disabled={!user?.settings?.crud_special_offers}
                                                value={formik.values.start_date}
                                                onChange={(e) => { formik.setFieldValue('start_date', e.target.value) }}
                                                options={dateOptions}
                                                sx={{ width: '100%' }}
                                                onBlur={formik.handleBlur}
                                            /> */}
                                            
                                        <CustomDatePicker
                                            disabled={!user?.settings?.crud_special_offers}
                                            value={formik.values.start_date}
                                            onChange={(e) =>{ formik.setFieldValue('start_date', e)}}
                                            sx={{ width: '100%' }}
                                            onBlur={formik.handleBlur}
                                            size="small"
                                            borderColor="#D9D9D9"
                                            format="DD/MM-YYYY"
                                            padding={1}
                                            borderThickness="2px"
                                            inputColor="#A0A0A0"
                                            iconVisibility={false}
                                        />
                                            {formik.errors.start_date && formik.touched.start_date && (
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography variant="caption" color="red">{formik.errors.start_date}</Typography>
                                                </Box>
                                            )}

                                            {/* {formik.values.start_date === 'custom' &&
                                                <>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            // value={customStartDate}
                                                            // onChange={(newValue) => setCustomStartDate(newValue)}
                                                            disabled={!user?.settings?.crud_special_offers}
                                                            value={formik.values.customStartDate}
                                                            onChange={(e) => { formik.setFieldValue('customStartDate', e) }}
                                                            sx={{
                                                                mt: 2,
                                                                '& .MuiInputBase-root': {
                                                                    border: '1px solid #D9D9D9',
                                                                    borderRadius: '13px',
                                                                    height: 40,
                                                                },
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none',
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#D9D9D9',
                                                                },
                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#D9D9D9',
                                                                }
                                                            }}
                                                            slotProps={{
                                                                popper: {
                                                                    sx: {
                                                                        ".MuiPaper-root": { borderRadius: "10px" },
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>

                                                    {formik.errors.customStartDate && formik.touched.customStartDate && (
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Typography variant="caption" color="red">{formik.errors.customStartDate}</Typography>
                                                        </Box>
                                                    )}
                                                </>
                                            } */}

                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: formik.values.start_time === 'custom' ? null : 'center', mt: 2 }}>
                                        {/* <PrimaryHeading text={'Set starting time'} /> */}
                                        <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f',   width:'50%',  textAlign:'right',pr:3  }}>
                                            {t('SpOffers.SetStTime')}
                                        </Typography>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            // gap: 2,
                                            width: '100%',
                                            maxWidth: '60%',
                                            '@media (max-width: 1440px)': {
                                                maxWidth: '50%',
                                            },
                                            '@media (max-width: 900px)': {
                                                maxWidth: '40%',
                                            }
                                        }}>
                                            {/* <CustomSelect
                                                // value={selectedValues.startDate.time}
                                                // onChange={(e) => handleTimeChange('startDate', e.target.value)}
                                                disabled={!user?.settings?.crud_special_offers}
                                                value={formik.values.start_time}
                                                onChange={(e) => { formik.setFieldValue('start_time', e.target.value) }}
                                                options={timeOptions}
                                                sx={{ width: '100%' }}
                                            /> */}
                                        <CustomTimePicker
                                            disabled={!user?.settings?.crud_special_offers}
                                            value={formik.values.start_time}
                                            onChange={(newTime) => { formik.setFieldValue('start_time', newTime)}}
                                            sx={{ width: '100%' }}
                                        />
                                            {formik.errors.start_time && formik.touched.start_time && (
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography variant="caption" color="red">{formik.errors.start_time}</Typography>
                                                </Box>
                                            )}

                                            {/* {formik.values.start_time === 'custom' &&
                                                <>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <TimePicker
                                                            // value={customStartTime}
                                                            // onChange={(newValue) => setCustomStartTime(newValue)}
                                                            disabled={!user?.settings?.crud_special_offers}
                                                            value={formik.values.customStartTime}
                                                            onChange={(e) => { formik.setFieldValue('customStartTime', e) }}
                                                            sx={{
                                                                mt: 2,
                                                                '& .MuiInputBase-root': {
                                                                    border: '1px solid #D9D9D9',
                                                                    borderRadius: '13px',
                                                                    height: 40,
                                                                },
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none',
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#D9D9D9',
                                                                },
                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#D9D9D9',
                                                                }
                                                            }}
                                                            slotProps={{
                                                                popper: {
                                                                    sx: {
                                                                        ".MuiPaper-root": { borderRadius: "10px" },
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>

                                                    {formik.errors.customStartTime && formik.touched.customStartTime && (
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Typography variant="caption" color="red">{formik.errors.customStartTime}</Typography>
                                                        </Box>
                                                    )}

                                                </>
                                            } */}
                                        </Box>
                                    </Box>
                                </>
                            }

                            {/* <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', mt: 5 }}>
                                <Box sx={{ ml: 5 }}>
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>
                                        {'End of offer'}
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: 2,
                                    width: '100%',
                                    maxWidth: '60%',
                                    '@media (max-width: 1440px)': {
                                        maxWidth: '50%',
                                    },
                                    '@media (max-width: 900px)': {
                                        maxWidth: '40%',
                                    }
                                }}>
                                    <CustomSelect
                                        // value={selectedEndDate}
                                        // onChange={handleEndDateChange}
                                        disabled={!user?.settings?.crud_special_offers}
                                        value={formik.values.endOf_Offer}
                                        onChange={(e) => { formik.setFieldValue('endOf_Offer', e.target.value) }}
                                        options={endDateOptions}
                                        sx={{ width: '100%' }}
                                    />

                                    {formik.errors.endOf_Offer && formik.touched.endOf_Offer && (
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Typography variant="caption" color="red">{formik.errors.endOf_Offer}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box> */}

                            {/* {formik.values.endOf_Offer === 'custom' && */}
                                {/* <> */}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: formik.values.ending_date === 'custom' ? null : 'center', mt: 2 }}>
                                        {/* <PrimaryHeading text={'Set ending date'} /> */}
                                        <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f',  width:'50%', textAlign:'right', pr:3 }}>
                                            {t('SpOffers.SetEndDate')}
                                        </Typography>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            // gap: 2,
                                            width: '100%',
                                            maxWidth: '60%',
                                            '@media (max-width: 1440px)': {
                                                maxWidth: '50%',
                                            },
                                            '@media (max-width: 900px)': {
                                                maxWidth: '40%',
                                            }
                                        }}>
                                            {/* <CustomSelect
                                                // value={selectedValues.endDate.value}
                                                // onChange={(e) => handleDateChange('endDate', e.target.value)}
                                                disabled={!user?.settings?.crud_special_offers}
                                                value={formik.values.ending_date}
                                                onChange={(e) => { formik.setFieldValue('ending_date', e.target.value) }}
                                                options={dateOptions}
                                                sx={{ width: '100%' }}
                                            /> */}
                                    <CustomDatePicker
                                        disabled={!user?.settings?.crud_special_offers}
                                        value={formik.values.ending_date}
                                        onChange={(e) => { formik.setFieldValue('ending_date', e) }}
                                        sx={{ width: '100%' }}
                                        onBlur={formik.handleBlur}
                                        size="small"
                                        borderColor="#D9D9D9"
                                        padding={1}
                                        format="DD/MM-YYYY"
                                        borderThickness="2px"
                                        inputColor="#A0A0A0"
                                        iconVisibility={false}
                                    />
                                            {formik.errors.ending_date && formik.touched.ending_date && (
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography variant="caption" color="red">{formik.errors.ending_date}</Typography>
                                                </Box>
                                            )}

                                            {/* {formik.values.ending_date === 'custom' &&
                                                <>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            // value={customEndDate}
                                                            // onChange={(newValue) => setCustomEndDate(newValue)}
                                                            disabled={!user?.settings?.crud_special_offers}
                                                            value={formik.values.customEndDate}
                                                            onChange={(e) => { formik.setFieldValue('customEndDate', e) }}
                                                            sx={{
                                                                mt: 2,
                                                                '& .MuiInputBase-root': {
                                                                    border: '1px solid #D9D9D9',
                                                                    borderRadius: '13px',
                                                                    height: 40,
                                                                },
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none',
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#D9D9D9',
                                                                },
                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#D9D9D9',
                                                                }
                                                            }}
                                                            slotProps={{
                                                                popper: {
                                                                    sx: {
                                                                        ".MuiPaper-root": { borderRadius: "10px" },
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>

                                                    {formik.errors.customEndDate && formik.touched.customEndDate && (
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Typography variant="caption" color="red">{formik.errors.customEndDate}</Typography>
                                                        </Box>
                                                    )}
                                                </>
                                            } */}
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: formik.values.ending_time === 'custom' ? null : 'center', mt: 2 }}>
                                        <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' ,  width:'50%',  textAlign:'right',pr:3 }}>
                                            {t('SpOffers.SetEndTime')}
                                        </Typography>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            maxWidth: '60%',
                                            '@media (max-width: 1440px)': {
                                                maxWidth: '50%',
                                            },
                                            '@media (max-width: 900px)': {
                                                maxWidth: '40%',
                                            }
                                        }}>
                                            {/* <CustomSelect
                                                // value={selectedValues.endDate.time}
                                                // onChange={(e) => handleTimeChange('endDate', e.target.value)}
                                                disabled={!user?.settings?.crud_special_offers}
                                                value={formik.values.ending_time}
                                                onChange={(e) => { formik.setFieldValue('ending_time', e.target.value) }}
                                                options={timeOptions}
                                                sx={{ width: '100%' }}
                                            /> */}
                                    <CustomTimePicker
                                        disabled={!user?.settings?.crud_special_offers}
                                        value={formik.values.ending_time}
                                        onChange={(newTime) => formik.setFieldValue('ending_time', newTime)}
                                        sx={{ width: '100%' }}
                                    />
                                            {formik.errors.ending_time && formik.touched.ending_time && (
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography variant="caption" color="red">{formik.errors.ending_time}</Typography>
                                                </Box>
                                            )}

                                            {/* {formik.values.ending_time === 'custom' &&
                                                <>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <TimePicker
                                                            // value={customEndTime}
                                                            // onChange={(newValue) => setCustomEndTime(newValue)}
                                                            disabled={!user?.settings?.crud_special_offers}
                                                            value={formik.values.customEndTime}
                                                            onChange={(e) => { formik.setFieldValue('customEndTime', e) }}
                                                            sx={{
                                                                mt: 2,
                                                                '& .MuiInputBase-root': {
                                                                    border: '1px solid #D9D9D9',
                                                                    borderRadius: '13px',
                                                                    height: 40,
                                                                },
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none',
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#D9D9D9',
                                                                },
                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#D9D9D9',
                                                                }
                                                            }}
                                                            slotProps={{
                                                                popper: {
                                                                    sx: {
                                                                        ".MuiPaper-root": { borderRadius: "10px" },
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>

                                                    {formik.errors.customEndTime && formik.touched.customEndTime && (
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Typography variant="caption" color="red">{formik.errors.customEndTime}</Typography>
                                                        </Box>
                                                    )}
                                                </>
                                            } */}
                                        </Box>
                                    </Box>
                                {/* </> */}
                            {/* } */}
                        </Box>
                        

                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '59%', alignItems: 'flex-start' }}>


                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                                <Box sx={{ mr: 4 }}>
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>
                                        {t("SpOffers.ChServ")}
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    maxWidth: '60%',
                                    '@media (max-width: 1440px)': { maxWidth: '50%' },
                                    '@media (max-width: 900px)': { maxWidth: '40%' }
                                }}>
                                    <Box sx={{ width: '100%' }}>
                                        <Autocomplete
                                            disabled={type === 'edit' || !user?.settings?.crud_special_offers}
                                            value={formik.values.selectedService}
                                            onChange={handleServiceChange}
                                            options={filteredServices}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            fullWidth
                                            popupIcon={<KeyboardArrowDownIcon style={{ color: '#d9d9d9' }} />}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    value={searchService}
                                                    onChange={handleSearchChange}
                                                    placeholder={t("Common.SelectService")}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        mt: 1,
                                                        height: 40,
                                                        fontSize: '0.85rem',
                                                        borderRadius: '13px',
                                                        border: `1px solid #D9D9D9`,
                                                        '& .MuiInputBase-root': { padding: '5px' },
                                                        '& .MuiInputBase-input': {
                                                            color: '#545454',
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                        },
                                                        '& .MuiInputBase-input::placeholder': { fontSize: '14px' },
                                                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                                                        '& .MuiAutocomplete-popupIndicator': { color: '#000' },
                                                    }}
                                                    onFocus={(event) => { event.stopPropagation(); }}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <MenuItem
                                                    {...props}
                                                    value={option.id}
                                                    sx={{
                                                        color: '#545454',
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        '&:hover': { backgroundColor: '#f5f5f5' },
                                                    }}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            )}
                                            noOptionsText={t("SpOffers.NoRes")}
                                            disableClearable
                                        />
                                        {formik.errors.selectedService && formik.touched.selectedService && (
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Typography variant="caption" color="red">{formik.errors.selectedService}</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>



                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>

                                <Box sx={{ mr: 3.3 }}>
                                    {/* <PrimaryHeading text={'Who has the offer?'} /> */}
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>
                                        {`${t("SpOffers.WhOffer")} ?`}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                        maxWidth: '60%',
                                        '@media (max-width: 1440px)': {
                                            maxWidth: '50%',
                                        },
                                        '@media (max-width: 900px)': {
                                            maxWidth: '40%',
                                        }
                                    }}>
                                    <Select
                                        multiple
                                        fullWidth
                                        value={formik.values.selectedEmployeeIds}
                                        onChange={() => { }}
                                        disabled={formik.values.selectedService == null ? true : false || !user?.settings?.crud_special_offers}
                                        IconComponent={(props) => (
                                            <KeyboardArrowDownIcon style={{ color: '#d9d9d9' }} {...props} />
                                        )}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#fff',
                                                    color: '#A0A0A0',
                                                    maxHeight: '300px',
                                                    overflowY: 'auto',
                                                },
                                            },
                                        }}
                                        renderValue={(selected = []) => {
                                            const serviceEmployees = formik.values.selectedService ? formik.values.selectedService?.employees : [];
                                            
                                            if (selected.length === 0) {
                                                return t("Common.SelectEmployees");
                                            }
                                            
                                            if (selected.length === serviceEmployees.length) {
                                                return t("Common.AllEmployees");
                                            }
                                            
                                            if (selected.length === 1) {
                                                const selectedEmployee = serviceEmployees.find(employee => employee.id === selected[0]);
                                                return selectedEmployee ? selectedEmployee.name : '';
                                            }
                                            
                                            return `${selected.length} ${t("Common.Employees")}`;
                                        }}
                                        // renderValue={(selected) => {
                                        //     const serviceEmployees = formik.values.selectedService ? formik.values.selectedService?.employees : [];

                                        //     if (selected.length === serviceEmployees.length) {
                                        //         return "All employees";
                                        //     }

                                        //     if (selected.length === 1) {
                                        //         const selectedEmployee = serviceEmployees.find(employee => employee.id === selected[0]);
                                        //         return selectedEmployee ? selectedEmployee.name : '';
                                        //     }

                                        //     return `${selected.length} employees`;
                                        // }}
                                        sx={{
                                            height: 40,
                                            fontSize: '0.85rem',
                                            borderRadius: '13px',
                                            border: `1px solid #D9D9D9`,
                                            '& .MuiSelect-select': {
                                                color: '#545454',
                                                fontSize: '15px',
                                                fontWeight: 400
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#D9D9D9',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#D9D9D9',
                                            },
                                            '& .MuiSelect-icon': {
                                                color: '#000',
                                            },
                                            backgroundColor: formik.values.selectedService == null ? '#D9D9D9' : 'transparent' == null ? '#D9D9D9' : 'transparent'
                                        }}
                                        displayEmpty
                                    >
                                        {formik.values.selectedService?.employees.length > 1 && (
                                            <MenuItem
                                                value="selectAll"
                                                onClick={() => {
                                                    const allEmployeeIds = formik.values.selectedService?.employees.map((employee) => employee.id);
                                                    if (formik.values.selectedEmployeeIds.length === formik.values.selectedService?.employees.length) {
                                                        formik.setFieldValue('selectedEmployeeIds', []);
                                                    } else {
                                                        formik.setFieldValue('selectedEmployeeIds', allEmployeeIds);
                                                    }
                                                }}
                                                sx={{
                                                    color: '#545454',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    margin: 0,
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                }}
                                            >
                                                <Checkbox
                                                    checked={
                                                        formik.values.selectedEmployeeIds.length ===
                                                        formik.values.selectedService?.employees.length
                                                    }
                                                    sx={{
                                                        '&.Mui-checked .MuiSvgIcon-root': {
                                                            backgroundColor: 'transparent',
                                                            borderColor: '#A79C92',
                                                            color: 'green',
                                                        },
                                                    }}
                                                />
                                                {formik.values.selectedEmployeeIds.length ===
                                                    formik.values.selectedService?.employees.length
                                                    ? t("SpOffers.DSelAll")
                                                    : t("SpOffers.SelAll")}
                                            </MenuItem>
                                        )}
  
                                        {formik.values.selectedService && formik.values.selectedService?.employees.length === 0 ? (
                                            <MenuItem disabled sx={{ color: 'black', fontSize: '15px' }}>
                                                {t("Calendar.NoEmpAvailable")}
                                            </MenuItem>
                                        ) : (
                                            formik.values.selectedService && formik.values.selectedService?.employees.map(employee => (
                                                <MenuItem
                                                    key={employee.id}
                                                    value={employee.id}
                                                    onClick={() => handleEmployeeToggle(employee.id)}
                                                    sx={{
                                                        color: '#545454',
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                        margin: 0,
                                                        '&:hover': {
                                                            backgroundColor: '#f5f5f5',
                                                        },
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={formik.values.selectedEmployeeIds.includes(employee.id)}
                                                        sx={{
                                                            '&.Mui-checked .MuiSvgIcon-root': {
                                                                backgroundColor: 'transparent',
                                                                borderColor: '#A79C92',
                                                                color: 'green',
                                                            }
                                                        }}
                                                    />
                                                    {employee.name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </Box>
                            </Box>

                            {/* <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                                <Box sx={{ mr: 4 }}>
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>
                                        {'Choose service'}
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    maxWidth: '60%',
                                    '@media (max-width: 1440px)': {
                                        maxWidth: '50%',
                                    },
                                    '@media (max-width: 900px)': {
                                        maxWidth: '40%',
                                    }
                                }}>
                                    <Autocomplete
                                    disabled={!user?.settings?.crud_special_offers}    
                                    value={formik.values.selectedService}
                                        onChange={handleServiceChange}
                                        options={filteredServices}
                                        getOptionLabel={(option) => option.name}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        fullWidth
                                        disabled={type === 'edit' ? true : false}
                                        popupIcon={<KeyboardArrowDownIcon style={{ color: '#d9d9d9' }} />}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                value={searchService}
                                                onChange={handleSearchChange}
                                                placeholder="Select service"
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    mt: 1,
                                                    height: 40,
                                                    fontSize: '0.85rem',
                                                    borderRadius: '13px',
                                                    border: `1px solid #D9D9D9`,
                                                    '& .MuiInputBase-root': {
                                                        padding: '5px',
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        color: '#545454',
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                    },
                                                    '& .MuiInputBase-input::placeholder': {
                                                        fontSize: '14px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#D9D9D9',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#D9D9D9',
                                                    },
                                                    '& .MuiAutocomplete-popupIndicator': {
                                                        color: '#000',
                                                    },
                                                }}
                                                onFocus={(event) => {
                                                    event.stopPropagation();
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <MenuItem
                                                {...props}
                                                value={option.id}
                                                sx={{
                                                    color: '#545454',
                                                    fontSize: '15px',
                                                    fontWeight: 400,
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                }}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        )}
                                        noOptionsText="No results found"
                                        disableClearable
                                    />
                                </Box>

                                {formik.errors.selectedService && formik.touched.selectedService && (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography variant="caption" color="red">{formik.errors.selectedService}</Typography>
                                    </Box>
                                )}

                            </Box> */}

                      
                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                                <Box sx={{ ml: 4 }}>
                                    {/* <PrimaryHeading text={'Standard price'} /> */}
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>
                                      {t("SpOffers.AddStPR")}
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    ml: 3.5,
                                    width: '100%',
                                    maxWidth: '60%',
                                    '@media (max-width: 1440px)': {
                                        maxWidth: '50%',
                                    },
                                    '@media (max-width: 900px)': {
                                        maxWidth: '40%',
                                    }
                                }}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#D9D9D9',
                                            width: '100px',
                                            padding: '6px',
                                            borderRadius: '13px',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            border: '1px solid #D9D9D9',
                                            mt: 1.5,
                                        }}
                                    >
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                color: 'black',
                                                // fontSize: '20px',
                                                fontWeight: 400,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '100%',
                                            }}
                                        >
                                            {`${standardprice} kr.`}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                                <Box sx={{ mr: 4 }}>
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>
                                      {t("SpOffers.NewPR")}
                                    </Typography>
                                </Box>

                                <Box sx={{ width: '100%', maxWidth: '60%', '@media (max-width: 1440px)': { maxWidth: '50%' }, '@media (max-width: 900px)': { maxWidth: '40%' } }}>
                                    <CustomTextField
                                        disabled={formik.values.selectedService == null ? true : false || !user?.settings?.crud_special_offers }
                                        bgColor={formik.values.selectedService == null ? '#D9D9D9' : 'transparent'}
                                        placeholder={t("SpOffers.NewPrPlHLD")}
                                        placeholderFontSize={'15px'}
                                        height={40}
                                        value={formik.values.newPrice}
                                        onChange={handleNewPriceChange}
                                    />
                                    {formik.errors.newPrice && formik.touched.newPrice && (
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Typography variant="caption" color="red">{formik.errors.newPrice}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                                <Box sx={{ mr: 4 }}>
                                    {/* <PrimaryHeading text={'Percentage'} /> */}
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>
                                       {t("SpOffers.Percentage")}
                                    </Typography>
                                </Box>

                                <Box sx={{ width: '100%', maxWidth: '60%', '@media (max-width: 1440px)': { maxWidth: '50%' }, '@media (max-width: 900px)': { maxWidth: '40%' } }}>
                                    <CustomTextField
                                        disabled={formik.values.selectedService == null ? true : false || !user?.settings?.crud_special_offers}
                                        bgColor={formik.values.selectedService == null ? '#D9D9D9' : 'transparent'}
                                        placeholder={t("SpOffers.PerPlHLD")}
                                        placeholderFontSize={'15px'}
                                        height={40}
                                        value={formik.values.percentage}
                                        onChange={handlePercentageChange}
                                    />
                                    {formik.errors.percentage && formik.touched.percentage && (
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Typography variant="caption" color="red">{formik.errors.percentage}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                        </Box>

                    </Box>

                </form>

            {user?.settings?.crud_special_offers && 
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    marginTop: 4
                }}>
                    <CommonButton
                        width="40%"
                        height="40px"
                        backgroundColor={'#44B904'}
                        onClick={formik.handleSubmit}
                        title={type == 'edit' ? t("SpOffers.UpSingleOff") :t("SpOffers.AddSingleOff")}
                    />
                </Box>}

            </Box>

        </Modal >
    )
}

export default AddSpecialOfferModal;

// const handleEmployeeToggle = (id) => {
//     const newSelection = formik.values.selectedEmployeeIds.includes(id)
//         ? formik.values.selectedEmployeeIds.filter((empId) => empId !== id)
//         : [...formik.values.selectedEmployeeIds, id];
//     formik.setFieldValue('selectedEmployeeIds', newSelection);
// };

// async function createOrUpdateSingleOffer() {
//     let startDate = null;
//     let startTime = null;
//     let endDate = null;
//     let endTime = null;

//     const formatDate = (date) => {
//         const year = date.getFullYear();
//         const month = String(date.getMonth() + 1).padStart(2, '0');
//         const day = String(date.getDate()).padStart(2, '0');
//         return `${year}-${month}-${day}`;
//     };

//     const formatTime = (date) => {
//         const hours = String(date.getHours()).padStart(2, '0');
//         const minutes = String(date.getMinutes()).padStart(2, '0');
//         const seconds = String(date.getSeconds()).padStart(2, '0');
//         return `${hours}:${minutes}:${seconds}`;
//     };

//     if (!checked) {
//         if (selectedValues.startDate.value === 'custom' || selectedValues.startDate.time === 'custom') {
//             if (!customStartDate || !customStartTime) {
//                 toast.error('Custom start date and time must be provided.');
//                 return;
//             }
//             startDate = formatDate(new Date(customStartDate));
//             startTime = formatTime(new Date(customStartTime));
//         } else if (selectedValues.startDate.value === 'today') {
//             const today = new Date();
//             startDate = formatDate(today);
//             startTime = formatTime(today);
//         } else if (selectedValues.startDate.value === 'tomorrow') {
//             const tomorrow = new Date();
//             tomorrow.setDate(tomorrow.getDate() + 1);
//             startDate = formatDate(tomorrow);
//             startTime = formatTime(tomorrow);
//         }

//         if (!selectedEndDate) {
//             toast.error('End date must be selected.');
//             return;
//         }

//         if (selectedEndDate === 'custom') {

//             if (!selectedValues.endDate.value && !selectedValues.endDate.time) {
//                 toast.error('Custom end date and time must be provided.');
//                 return;
//             }

//             if (selectedValues.endDate.value === 'custom') {
//                 if (!customEndDate) {
//                     toast.error('Custom end date must be provided.');
//                     return;
//                 }
//                 endDate = formatDate(new Date(customEndDate));
//             } else if (selectedValues.endDate.value === 'today') {
//                 endDate = formatDate(new Date());
//             } else if (selectedValues.endDate.value === 'tomorrow') {
//                 const tomorrow = new Date();
//                 tomorrow.setDate(tomorrow.getDate() + 1);
//                 endDate = formatDate(tomorrow);
//             } else {
//                 toast.error('Invalid end date value.');
//                 return;
//             }

//             if (selectedValues.endDate.time === 'custom') {
//                 if (!customEndTime) {
//                     toast.error('Custom end time must be provided.');
//                     return;
//                 }
//                 endTime = formatTime(new Date(customEndTime));
//             } else if (selectedValues.endDate.time === 'now') {
//                 endTime = formatTime(new Date());
//             } else if (selectedValues.endDate.time === 'tomorrow') {
//                 const tomorrow = new Date();
//                 tomorrow.setDate(tomorrow.getDate() + 1);
//                 endTime = formatTime(tomorrow);
//             } else {
//                 toast.error('Invalid end time value.');
//                 return;
//             }

//         } else if (selectedEndDate === '24hrs') {
//             const endOf24hrs = new Date();
//             endOf24hrs.setHours(endOf24hrs.getHours() + 24);
//             endDate = formatDate(endOf24hrs);
//             endTime = formatTime(endOf24hrs);
//         } else if (selectedEndDate === '3days') {
//             const endOf3Days = new Date();
//             endOf3Days.setDate(endOf3Days.getDate() + 3);
//             endDate = formatDate(endOf3Days);
//             endTime = formatTime(endOf3Days);
//         } else if (selectedEndDate === '1week') {
//             const endOf1Week = new Date();
//             endOf1Week.setDate(endOf1Week.getDate() + 7);
//             endDate = formatDate(endOf1Week);
//             endTime = formatTime(endOf1Week);
//         } else if (selectedEndDate === '2weeks') {
//             const endOf2Weeks = new Date();
//             endOf2Weeks.setDate(endOf2Weeks.getDate() + 14);
//             endDate = formatDate(endOf2Weeks);
//             endTime = formatTime(endOf2Weeks);
//         } else if (selectedEndDate === '1month') {
//             const endOf1Month = new Date();
//             endOf1Month.setMonth(endOf1Month.getMonth() + 1);
//             endDate = formatDate(endOf1Month);
//             endTime = formatTime(endOf1Month);
//         } else if (selectedEndDate === 'endOfMonth') {
//             const endOfMonth = new Date();
//             endOfMonth.setMonth(endOfMonth.getMonth() + 1);
//             endOfMonth.setDate(0);
//             endDate = formatDate(endOfMonth);
//             endTime = formatTime(endOfMonth);
//         }
//     } else {
//         const currentDate = new Date();
//         startDate = formatDate(currentDate);
//         startTime = formatTime(currentDate);

//         if (!selectedEndDate) {
//             toast.error('End date must be selected.');
//             return;
//         }

//         if (selectedEndDate === '24hrs') {
//             const endOf24hrs = new Date();
//             endOf24hrs.setHours(endOf24hrs.getHours() + 24);
//             endDate = formatDate(endOf24hrs);
//             endTime = formatTime(endOf24hrs);
//         } else if (selectedEndDate === '3days') {
//             const endOf3Days = new Date();
//             endOf3Days.setDate(endOf3Days.getDate() + 3);
//             endDate = formatDate(endOf3Days);
//             endTime = formatTime(endOf3Days);
//         } else if (selectedEndDate === '1week') {
//             const endOf1Week = new Date();
//             endOf1Week.setDate(endOf1Week.getDate() + 7);
//             endDate = formatDate(endOf1Week);
//             endTime = formatTime(endOf1Week);
//         } else if (selectedEndDate === '2weeks') {
//             const endOf2Weeks = new Date();
//             endOf2Weeks.setDate(endOf2Weeks.getDate() + 14);
//             endDate = formatDate(endOf2Weeks);
//             endTime = formatTime(endOf2Weeks);
//         } else if (selectedEndDate === '1month') {
//             const endOf1Month = new Date();
//             endOf1Month.setMonth(endOf1Month.getMonth() + 1);
//             endDate = formatDate(endOf1Month);
//             endTime = formatTime(endOf1Month);
//         } else if (selectedEndDate === 'endOfMonth') {
//             const endOfMonth = new Date();
//             endOfMonth.setMonth(endOfMonth.getMonth() + 1);
//             endOfMonth.setDate(0);
//             endDate = formatDate(endOfMonth);
//             endTime = formatTime(endOfMonth);
//         } else if (selectedEndDate === 'custom') {
//             if (!selectedValues.endDate.value && !selectedValues.endDate.time) {
//                 toast.error('Custom end date and time must be provided.');
//                 return;
//             }

//             if (selectedValues.endDate.value === 'custom') {
//                 if (!customEndDate) {
//                     toast.error('Custom end date must be provided.');
//                     return;
//                 }
//                 endDate = formatDate(new Date(customEndDate));
//             } else if (selectedValues.endDate.value === 'today') {
//                 endDate = formatDate(new Date());
//             } else if (selectedValues.endDate.value === 'tomorrow') {
//                 const tomorrow = new Date();
//                 tomorrow.setDate(tomorrow.getDate() + 1);
//                 endDate = formatDate(tomorrow);
//             } else {
//                 toast.error('Invalid end date value.');
//                 return;
//             }

//             if (selectedValues.endDate.time === 'custom') {
//                 if (!customEndTime) {
//                     toast.error('Custom end time must be provided.');
//                     return;
//                 }
//                 endTime = formatTime(new Date(customEndTime));
//             } else if (selectedValues.endDate.time === 'now') {
//                 endTime = formatTime(new Date());
//             } else if (selectedValues.endDate.time === 'tomorrow') {
//                 const tomorrow = new Date();
//                 tomorrow.setDate(tomorrow.getDate() + 1);
//                 endTime = formatTime(tomorrow);
//             } else {
//                 toast.error('Invalid end time value.');
//                 return;
//             }
//         }
//     };

//     if (!startDate || !startTime ||
//         (selectedValues.endDate.value === 'custom' && (!endDate || !endTime))) {
//         toast.error('Invalid date or time selection');
//         return;
//     };

//     if (selectedService === null) {
//         toast.error('Please select service for special offer');
//         return;
//     };

//     if (cancellationOfferDetails.newPrice === '' || cancellationOfferDetails.percentage === '') {
//         toast.error('Please enter discounted price or percentage');
//         return;
//     };

//     const specialPrice = Number(cancellationOfferDetails.newPrice);
//     const formattedPercentage = Number(cancellationOfferDetails.percentage) % 1 === 0 ? Number(cancellationOfferDetails.percentage) : Number(cancellationOfferDetails.percentage).toFixed(2).replace('.00', '');

//     try {
//         const data = {
//             start_datetime: `${startDate} ${startTime}`,
//             end_datetime: `${endDate} ${endTime}`,
//             service_id: type === 'edit' ? selectedSpecialOffer?.service_id : selectedService?.id,
//             special_price: specialPrice,
//             special_percentage: formattedPercentage,
//             employee_ids: selectedEmployeeIds,
//         };

//         let response;

//         if (type === 'create') {
//             response = await axios.post(`${process.env.REACT_APP_URL}/api/v1/store/special_offer`, data, {
//                 headers: {
//                     Authorization: `Bearer ${authTokenUser}`
//                 }
//             });

//             if (response?.status === 200) {
//                 toast.success("Special offer created successfully!");
//             }

//             setTimeout(() => {
//                 resetFields('Callapi');
//             }, 2000);

//         } else if (type === 'edit') {
//             response = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/store/special_offer/${selectedSpecialOffer?.id}`, data, {
//                 headers: {
//                     Authorization: `Bearer ${authTokenUser}`
//                 }
//             });

//             if (response?.status === 200) {
//                 toast.success("Special offer updated successfully!");
//             }

//             setTimeout(() => {
//                 resetFields('Callapi');
//             }, 2000);

//         } else {
//             toast.error(`Failed to ${type} special offer`);
//         }

//     } catch (error) {
//     } finally {
//         setLoading(false);
//     }
// };


// const [checked, setChecked] = React.useState(false);
// const [selectedEndDate, setSelectedEndDate] = useState('');
// const [selectedService, setSelectedService] = useState(null);
// const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);

// const handleChange = (event) => {
//     setChecked(event.target.checked);
// };

// const handleEndDateChange = (event) => {
//     setSelectedEndDate(event.target.value);
// };


// const [cancellationOfferDetails, setCancellationOfferDetails] = useState({
//     newPrice: '',
//     percentage: '',
// });

// const [selectedValues, setSelectedValues] = useState({
//     startDate: { value: '', time: '' },
//     endDate: { value: '', time: '' },
// });

// const [customStartDate, setCustomStartDate] = useState(null);
// const [customEndDate, setCustomEndDate] = useState(null);
// const [customStartTime, setCustomStartTime] = useState(null);
// const [customEndTime, setCustomEndTime] = useState(null);

// const handleEmployeeToggle = (employeeId) => {
//     formik.setFieldValue('selectedEmployeeIds', (prevSelectedEmployeeIds) => {
//         if (prevSelectedEmployeeIds.includes(employeeId)) {
//             return prevSelectedEmployeeIds.filter(id => id !== employeeId);
//         } else {
//             return [...prevSelectedEmployeeIds, employeeId];
//         }
//     });
// };

// const handleEmployeeToggle = (employeeId) => {

//     formik.setFieldValue('selectedEmployeeIds', (prevSelectedEmployeeIds) => {
//         // Ensure that prevSelectedEmployeeIds is always an array
//         const selectedEmployeeIds = Array.isArray(prevSelectedEmployeeIds) ? prevSelectedEmployeeIds : [];


//         if (selectedEmployeeIds.includes(employeeId)) {
//             return selectedEmployeeIds.filter(id => id !== employeeId);
//         } else {
//             return [...selectedEmployeeIds, employeeId];
//         }
//     });
// };


// const changeText = useCallback((text, key) => {
//     setCancellationOfferDetails((prevDetails) => ({
//         ...prevDetails,
//         [key]: text,
//     }));
// }, []);

// const handlePercentageChange = (e) => {
//     const value = e.target.value;
//     const regex = /^\d*\.?\d{0,2}$/;

//     if (value == '.') {
//         return;
//     };

//     if (value === '') {
//         changeText('', 'percentage');
//         changeText('', 'newPrice');
//         return;
//     }

//     if (regex.test(value)) {
//         const percentage = parseFloat(value);

//         if (percentage > 100) {
//             changeText('100', 'percentage');
//         } else {
//             const calculatedNewPrice = (selectedService?.price * percentage) / 100;
//             changeText(value, 'percentage');
//             changeText(calculatedNewPrice.toFixed(2), 'newPrice');
//         }
//     }
// };

// const handleNewPriceChange = (e) => {
//     const value = e.target.value;
//     const regex = /^\d*\.?\d{0,2}$/;

//     if (value == '.') {
//         return;
//     };

//     if (value === '') {
//         changeText('', 'newPrice');
//         changeText('', 'percentage');
//         return;
//     }

//     if (regex.test(value)) {
//         const newPrice = parseFloat(value);

//         if (newPrice > selectedService?.price) {
//             changeText(selectedService?.price.toString(), 'newPrice');
//         } else {
//             const calculatedPercentage = (newPrice / selectedService?.price) * 100;
//             changeText(value, 'newPrice');
//             changeText(calculatedPercentage.toFixed(2), 'percentage');
//         }
//     }
// };

// const handleDateChange = (field, selectedValue) => {
//     setSelectedValues((prev) => ({
//         ...prev,
//         [field]: { ...prev[field], value: selectedValue },
//     }));
// };

// const handleTimeChange = (field, selectedTime) => {
//     setSelectedValues((prev) => ({
//         ...prev,
//         [field]: { ...prev[field], time: selectedTime },
//     }));
// };

    // useEffect(() => {
    //     if (type === 'edit') {
            
    //         const startDate = new Date(selectedSpecialOffer?.start_datetime);
    //         const endDate = new Date(selectedSpecialOffer?.end_datetime);

    //         let startDateOption = 'custom';
    //         if (startDate.toDateString() === new Date().toDateString()) {
    //             startDateOption = 'today';
    //         } else if (startDate.toDateString() === new Date(new Date().setDate(new Date().getDate() + 1)).toDateString()) {
    //             startDateOption = 'tomorrow';
    //         }

    //         let startTimeOption = 'custom';
    //         if (startDate.getHours() === new Date().getHours() && startDate.getMinutes() === new Date().getMinutes()) {
    //             startTimeOption = 'now';
    //         }

    //         let endDateOption = 'custom';
    //         if (endDate.toDateString() === new Date().toDateString()) {
    //             endDateOption = 'today';
    //         } else if (endDate.toDateString() === new Date(new Date().setDate(new Date().getDate() + 1)).toDateString()) {
    //             endDateOption = 'tomorrow';
    //         }

    //         let endTimeOption = 'custom';
    //         if (endDate.getHours() === new Date().getHours() && endDate.getMinutes() === new Date().getMinutes()) {
    //             endTimeOption = 'now';
    //         }

    //         // setSelectedEndDate('custom');

    //         // setSelectedValues({
    //         //     startDate: { value: startDateOption, time: startTimeOption },
    //         //     endDate: { value: endDateOption, time: endTimeOption },
    //         // });

    //         // setCustomStartDate(startDate);
    //         // setCustomStartTime(startDate);

    //         // setCustomEndDate(endDate);
    //         // setCustomEndTime(endDate);
    //     }
    // }, [selectedSpecialOffer, type]);

// renderValue = {(selected) => {
//     const serviceEmployees = formik.values.selectedService ? formik.values.selectedService?.employees : [];

//     if (selected?.length === 0) {
//         return 'Select employees';
//     }

//     if (selected.length === serviceEmployees.length) {
//         return 'All employees';
//     }

//     if (selected.length === 1) {
//         const selectedEmployee = serviceEmployees.find(employee => employee.id === selected[0]);
//         return selectedEmployee ? selectedEmployee.name : '';
//     }

//     return `${selected.length} employees`;
// }}


// if (!values.isActive) {
//     if (values.start_date === 'custom' || values.start_time === 'custom') {
//         startDate = formatDate(new Date(values.customStartDate));
//         startTime = formatTime(new Date(values.customStartTime));
//     } else if (values.start_date === 'today') {
//         const today = new Date();
//         startDate = formatDate(today);
//         startTime = formatTime(today);
//     } else if (values.start_date === 'tomorrow') {
//         const tomorrow = new Date();
//         tomorrow.setDate(tomorrow.getDate() + 1);
//         startDate = formatDate(tomorrow);
//         startTime = formatTime(tomorrow);
//     }

//     if (values.endOf_Offer === 'custom') {

//         if (values.ending_date === 'custom') {
//             endDate = formatDate(new Date(values.customEndDate));
//         } else if (values.ending_date === 'today') {
//             endDate = formatDate(new Date());
//         } else if (values.ending_date === 'tomorrow') {
//             const tomorrow = new Date();
//             tomorrow.setDate(tomorrow.getDate() + 1);
//             endDate = formatDate(tomorrow);
//         }

//         if (values.ending_time === 'custom') {
//             endTime = formatTime(new Date(values.customEndTime));
//         } else if (values.ending_time === 'now') {
//             endTime = formatTime(new Date());
//         } else if (values.ending_time === 'tomorrow') {
//             const tomorrow = new Date();
//             tomorrow.setDate(tomorrow.getDate() + 1);
//             endTime = formatTime(tomorrow);
//         }

//     } else if (values.endOf_Offer === '24hrs') {
//         const endOf24hrs = new Date();
//         endOf24hrs.setHours(endOf24hrs.getHours() + 24);
//         endDate = formatDate(endOf24hrs);
//         endTime = formatTime(endOf24hrs);
//     } else if (values.endOf_Offer === '3days') {
//         const endOf3Days = new Date();
//         endOf3Days.setDate(endOf3Days.getDate() + 3);
//         endDate = formatDate(endOf3Days);
//         endTime = formatTime(endOf3Days);
//     } else if (values.endOf_Offer === '1week') {
//         const endOf1Week = new Date();
//         endOf1Week.setDate(endOf1Week.getDate() + 7);
//         endDate = formatDate(endOf1Week);
//         endTime = formatTime(endOf1Week);
//     } else if (values.endOf_Offer === '2weeks') {
//         const endOf2Weeks = new Date();
//         endOf2Weeks.setDate(endOf2Weeks.getDate() + 14);
//         endDate = formatDate(endOf2Weeks);
//         endTime = formatTime(endOf2Weeks);
//     } else if (values.endOf_Offer === '1month') {
//         const endOf1Month = new Date();
//         endOf1Month.setMonth(endOf1Month.getMonth() + 1);
//         endDate = formatDate(endOf1Month);
//         endTime = formatTime(endOf1Month);
//     } else if (values.endOf_Offer === 'endOfMonth') {
//         const endOfMonth = new Date();
//         endOfMonth.setMonth(endOfMonth.getMonth() + 1);
//         endOfMonth.setDate(0);
//         endDate = formatDate(endOfMonth);
//         endTime = formatTime(endOfMonth);
//     }
// } else {
//     const currentDate = new Date();
//     startDate = formatDate(currentDate);
//     startTime = formatTime(currentDate);

//     if (values.endOf_Offer === '24hrs') {
//         const endOf24hrs = new Date();
//         endOf24hrs.setHours(endOf24hrs.getHours() + 24);
//         endDate = formatDate(endOf24hrs);
//         endTime = formatTime(endOf24hrs);
//     } else if (values.endOf_Offer === '3days') {
//         const endOf3Days = new Date();
//         endOf3Days.setDate(endOf3Days.getDate() + 3);
//         endDate = formatDate(endOf3Days);
//         endTime = formatTime(endOf3Days);
//     } else if (values.endOf_Offer === '1week') {
//         const endOf1Week = new Date();
//         endOf1Week.setDate(endOf1Week.getDate() + 7);
//         endDate = formatDate(endOf1Week);
//         endTime = formatTime(endOf1Week);
//     } else if (values.endOf_Offer === '2weeks') {
//         const endOf2Weeks = new Date();
//         endOf2Weeks.setDate(endOf2Weeks.getDate() + 14);
//         endDate = formatDate(endOf2Weeks);
//         endTime = formatTime(endOf2Weeks);
//     } else if (values.endOf_Offer === '1month') {
//         const endOf1Month = new Date();
//         endOf1Month.setMonth(endOf1Month.getMonth() + 1);
//         endDate = formatDate(endOf1Month);
//         endTime = formatTime(endOf1Month);
//     } else if (values.endOf_Offer === 'endOfMonth') {
//         const endOfMonth = new Date();
//         endOfMonth.setMonth(endOfMonth.getMonth() + 1);
//         endOfMonth.setDate(0);
//         endDate = formatDate(endOfMonth);
//         endTime = formatTime(endOfMonth);
//     } else if (values.endOf_Offer === 'custom') {

//         if (values.ending_date === 'custom') {
//             endDate = formatDate(new Date(values.customEndDate));
//         } else if (values.ending_date === 'today') {
//             endDate = formatDate(new Date());
//         } else if (values.ending_date === 'tomorrow') {
//             const tomorrow = new Date();
//             tomorrow.setDate(tomorrow.getDate() + 1);
//             endDate = formatDate(tomorrow);
//         }

//         if (values.ending_time === 'custom') {
//             endTime = formatTime(new Date(values.customEndTime));
//         } else if (values.ending_time === 'now') {
//             endTime = formatTime(new Date());
//         } else if (values.ending_time === 'tomorrow') {
//             const tomorrow = new Date();
//             tomorrow.setDate(tomorrow.getDate() + 1);
//             endTime = formatTime(tomorrow);
//         }
//     }
// };