import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  Outlet,
} from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import { CircularProgress, CssBaseline, Stack, ThemeProvider, Typography } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import apiFetcher from "./utils/interCeptor";
import { user } from "./context/permissionSlice";
import { settings } from "./context/settingsSlice";
import moment from "moment";
import { SocketProvider } from "./context/SocketContext";
import axios, { HttpStatusCode } from "axios";
import { t } from "i18next";

function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  const dispatch = useDispatch();
  const LogedInuser = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const [redirctLoading, setRedirctLoading] = useState(false);
  const auth2 = localStorage.getItem("auth_token")

  useEffect(() => {

    async function getdata() {
      try {
        setRedirctLoading(true)
        const params = new URLSearchParams(window.location.search);
        const auth_token = params.get("aid");
        const employee_role = params.get("er");
        const employee_id = params.get("eid");


        if (auth_token) {
          localStorage.setItem("auth_token", auth_token);
          localStorage.setItem("employee_role", employee_role);
          localStorage.setItem("employee_id", employee_id);
          localStorage.setItem("image_url", process.env.REACT_APP_IMG_URL)
          const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/store/profile/info`, {
            headers: {
              Authorization: `Bearer ${auth_token}`,
            }
          });
          if (response.status === HttpStatusCode.Ok) {
            const employess = response?.data?.data?.employees;
            localStorage.setItem("employees", JSON.stringify(employess));
            const selectedEmp = employess && employess.find((emp) => emp.id == employee_id);
            dispatch(user({ ...selectedEmp, "settings": selectedEmp?.settings }))
            dispatch(settings({ "from_dashboard": true }))
          }
          // navigate("/calendar")
        } else if (!auth_token && !auth2) {
          navigate("/")
        }
      } catch (error) {
        navigate("/")
      } finally {
        setRedirctLoading(false)
      }
    }
    getdata();
  }, [])



  moment.locale("da");
  moment.updateLocale("da", { week: { dow: 1 } });

  useEffect(() => {
    async function val() {
      try {
        const res = await apiFetcher.get(
          "/api/v1/store/profile/info"
        );
        const { schedules, holidays, employees, is_individual_opening_hour, employees_opening_hour } = res.data.data

        let profileData = { ...res.data.data }
        delete profileData?.settings
        delete profileData?.schedules
        delete profileData?.holidays
        delete profileData?.employees
        delete profileData?.employees_opening_hour
        delete profileData?.is_individual_opening_hour

        dispatch(settings({ schedule: schedules, outlet_holidays: holidays, profile: profileData, is_individual_opening_hour, employees_opening_hour: employees_opening_hour, employees }))

        employees.find((emp) => {
          if (emp.id === LogedInuser.id) {
            dispatch(user(emp));
            return true;
          }
          return false;
        });

        //  if(auth){
        //   navigate("/calendar")
        //  }

      } catch (error) {
        console.error('error', error);


      }


    }
    val()
  }, [location.pathname]);

  if (redirctLoading)
    return <Stack sx={{
      position: 'absolute',
      zIndex: 110,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.1)'
    }}>
      <CircularProgress size='2.5rem' sx={{ color: '#6f6f6f' }} />
      <Typography variant="body1" sx={{ color: '#6f6f6f', mt: 2 }}>{t("Common.Redirecting")}...</Typography>
    </Stack>

  return (
    <SocketProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {/* <ToastContainer /> */}
            {/* Sidebar remains collapsed on all pages except login */}
            {location.pathname !== "/" && <Sidebar className="collapsed" />}
            <main
              className={`content ${location.pathname !== "/" ? "shifted" : ""}`}
            >
              <Outlet />
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </SocketProvider>
  );
}

export default App;
