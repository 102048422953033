import React, { useEffect, useMemo, useState } from "react";
import { Box, Tooltip, Stack, Divider, Switch, Typography, AppBar } from "@mui/material";
import PrimaryHeading from "../commonPrimaryHeading";
import SecondaryHeading from "../commonSecondaryHeading";
import CustomSelect from "../commonCustomSelect";
import TooltipIcon from '../../../assets/IconTooltip.png';
import { useFormik } from "formik";
import CommonButton from "../commonButton";
import _ from "lodash"
import { toast, ToastContainer } from "react-toastify";
import apiFetcher from "../../../utils/interCeptor";
import FSwitch from "../../commonComponents/f-switch";
import { useSelector } from "react-redux";
import { settings } from "../../../context/settingsSlice";
import { useDispatch } from "react-redux";

import { t } from "i18next";



// const initialValues = {
//     "setCalendarOpeningHour": 120,
//     "setCalendarClosingHour": 120,
//     "grayOutClosedHours": false,
//     "showOnlyAvailableEmployee": false,
//     "calendarInterval": 15
// }

const CalendarSettingsOption = () => {

    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.data);

    const options = [
        { value: 120, label: t("Setting.2HoursStandard") },
        { value: 240, label: t("Setting.4Hours") },
        { value: 360, label: t("Setting.6Hours") },
    ];

    const optionsAfter = [
        { value: 120, label: t("Setting.2HoursStandard") },
        { value: 240, label: t("Setting.4Hours") },
        { value: 360, label: t("Setting.6Hours") },
    ];

    const calendarIntervals = [
        { value: 5, label: t("Setting.5Min") },
        { value: 10, label: t("Setting.10Min") },
        { value: 15, label: t("Setting.15Min") },
        { value: 30, label: t("Setting.30Min") },
        { value: 60, label: t("Setting.60Min") },
    ];

    const [enableSave, setEnableSave] = useState(false)
    const [initialValues, setInitialValues] = useState({
        "setCalendarOpeningHour": 120,
        "setCalendarClosingHour": 120,
        "grayOutClosedHours": false,
        "showOnlyAvailableEmployee": false,
        "calendarInterval": 15
    })

    const settingsSelector = useSelector((state) => state.settings);
    const { data } = settingsSelector

    const { calendar } = data

    const tooltipContent = (
        <div style={{ padding: '5px' }}>content here</div>
    );


    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: values => {
            calendarAPI(values)

        },
    });

    const calendarAPI = async (value) => {

        try {

            const payload = {
                settings: [
                    {
                        "settingCategory": "outlet",
                        "settingName": "calendar",
                        value: JSON.stringify(value),
                        "type": "JSON"
                    }
                ]

            }
            const response = await apiFetcher.patch('/api/v1/store/outlet/setting', payload)
            const { success, data } = response.data
            if (success) {
                toast.success(t("Setting.CalendarSettingsUpdated"));

                // getCalendarsettingsAPI()

                dispatch(settings({ 'calendar': value }))
            }

            setEnableSave(false)

            formik.setSubmitting(false)

        } catch (err) {
            toast.error(t("Setting.FailedToUpdateCalendarSettings"));
            console.error('err', err);
            formik.setSubmitting(false)

        }
    }

    useMemo(() => {

        setInitialValues(calendar)
        formik.setValues(calendar)

    }, [calendar])


    // const getCalendarsettingsAPI=async ()=>{

    //     try{


    //         const response =  await apiFetcher.get('/api/v1/store/outlet/setting')

    //         const {success, data} = response.data
    //         if(success){

    //             const {settings} = data
    //             const calendarSettings = settings.find((settingObj)=>settingObj.settingName == 'calendar')
    //             if(calendarSettings){

    //                 setInitialValues(JSON.parse(calendarSettings?.value))
    //             }

    //         }

    //     }catch(err){

    //     }
    // }

    // useEffect(()=>{
    //     getCalendarsettingsAPI()
    // },[])

    useEffect(() => {
        setEnableSave(!_.isEqual(initialValues, formik.values))
    }, [formik.values]);

    return (
        <form onSubmit={formik.handleSubmit}>

            {enableSave && (
                <AppBar
                    sx={{
                        position: "sticky",
                        zIndex: 20,
                        top: 45,
                        left: 0,
                        py: 1,
                        px: 4,
                        height: 50,
                        bgcolor: "#fff",
                        display: "flex",
                        // justifyContent: "flex-end",
                        alignItems: "flex-end",
                        width: "100%",
                    }}
                >
                    <CommonButton
                        onClick={formik.handleSubmit}
                        width="auto"
                        ml={'auto'}
                        height={40}
                        title={t("Setting.SaveChanges")}
                        // loading={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />
                </AppBar>
            )}
            <Stack p={4}>
                {/* <Box sx={{ width: '100%', padding: 5 }} /> */}

                <ToastContainer />

                <Stack spacing={6} sx={{
                    bgcolor: "#fff", borderRadius: "25px",
                    minHeight: "86vh",
                    //    overflowY:'auto',
                    scrollbarWidth: 'none',
                    overflowX: 'hidden'
                }}>

                    <Box sx={{ display: "flex", bgcolor: '#FFFFFF', borderRadius: '25px', flexDirection: 'column', width: '100%', }}>

                        <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%', padding: 5, }}>

                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                                <PrimaryHeading text={t("Setting.CalendarAppearance")} />
                                <SecondaryHeading text={t("Setting.Description9")} />
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '67%' }}>
                                <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1F1F1F' }}>
                                        {t("Setting.HowManyHoursBeforeCalendarStart")}
                                    </Typography>
                                    <Tooltip arrow title={tooltipContent} sx={{ ml: 10 }}>
                                        <img
                                            src={TooltipIcon}
                                            alt="IconOne"
                                            style={{ marginLeft: 4, width: 14, height: 14 }}
                                        />
                                    </Tooltip>
                                </Stack>
                                <CustomSelect
                                    id={'setCalendarOpeningHour'}
                                    name={'setCalendarOpeningHour'}
                                    value={formik.values.setCalendarOpeningHour}
                                    onChange={formik.handleChange}
                                    options={options}
                                    sx={{ width: 180, mt: 1 }}
                                />

                                <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1F1F1F' }}>
                                        {t("Setting.HowManyHoursAfterCalendarStop")}
                                    </Typography>
                                    <Tooltip arrow title={tooltipContent} sx={{ ml: 10 }}>
                                        <img
                                            src={TooltipIcon}
                                            alt="IconOne"
                                            style={{ marginLeft: 4, width: 14, height: 14 }}
                                        />
                                    </Tooltip>
                                </Stack>
                                <CustomSelect
                                    id={'setCalendarClosingHour'}
                                    name={'setCalendarClosingHour'}
                                    value={formik.values.setCalendarClosingHour}
                                    onChange={formik.handleChange}
                                    options={optionsAfter}
                                    sx={{ width: 180, mt: 1 }}
                                />

                                <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }} />

                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1F1F1F' }}>
                                    {t("Setting.GreyOutClosedHours")}
                                </Typography>
                                <FSwitch
                                    id={'grayOutClosedHours'}
                                    name={'grayOutClosedHours'}
                                    checked={formik.values.grayOutClosedHours}
                                    onChange={formik.handleChange}
                                    sx={{ width: 0 }}

                                />

                                <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }} />

                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1F1F1F' }}>
                                    {t("Setting.ShowOnlyEmployeesWhoAreAtWork")}
                                </Typography>
                                <FSwitch
                                    id={'showOnlyAvailableEmployee'}
                                    name={'showOnlyAvailableEmployee'}
                                    checked={formik.values.showOnlyAvailableEmployee}
                                    onChange={formik.handleChange}
                                    sx={{ width: 0 }}

                                />

                            </Box>

                        </Box>
                        <Divider sx={{ marginTop: 5, marginBottom: 5, border: '2.5px solid #D9D9D9', backgroundColor: '#F3F3F3', width: '100%' }} />

                        <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%', padding: 5, }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                                <PrimaryHeading text={t("Setting.CalendarInterval")} />
                                <SecondaryHeading text={t("Setting.Description10")} />
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '67%' }}>
                                <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1F1F1F' }}>
                                        {t("Setting.CalendarInterval")}
                                    </Typography>
                                    <Tooltip arrow title={tooltipContent} sx={{ ml: 10 }}>
                                        <img
                                            src={TooltipIcon}
                                            alt="IconOne"
                                            style={{ marginLeft: 4, width: 14, height: 14 }}
                                        />
                                    </Tooltip>
                                </Stack>
                                <CustomSelect
                                    disabled={(user?.role !== "ADMIN" && (!user?.settings.change_own_calender_interval && !user?.settings.change_all_calender_interval))}
                                    id={'calendarInterval'}
                                    name={'calendarInterval'}
                                    value={formik.values.calendarInterval}
                                    //  onChange={(event)=>onChangeValue('calendarInterval', event)}
                                    onChange={formik.handleChange}
                                    options={calendarIntervals}
                                    sx={{ width: 180, mt: 1 }}
                                />
                            </Box>
                        </Box>

                    </Box>

                </Stack>

            </Stack>
        </form>
    )
};

export default CalendarSettingsOption;