import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IconButton, Modal, Paper, Stack, Slider, Typography, CircularProgress } from '@mui/material';
import FButton from '../../../../commonComponents/F_Button';
import { Close, Mode } from '@mui/icons-material';
import moment from 'moment';
import apiFetcher from '../../../../../utils/interCeptor';
import { toast, ToastContainer } from 'react-toastify';
import { t } from 'i18next';

export default function EditImage({ selectedImage, open, onClose, employeeId, images, setImages, advancedJournalId }) {
    const [isImageLoading, setIsImageLoading] = useState(false);
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [penColor, setPenColor] = useState('#000000');
    const [penWidth, setPenWidth] = useState(2);
    const ctxRef = useRef(null);
    const [localImage, setLocalImage] = useState(null);
    const [showTools, setShowTools] = useState(false);

    useEffect(() => {
        if (selectedImage?.attachment && !isImageLoading) {
            setIsImageLoading(true);
            const imgSrc = `${process.env.REACT_APP_IMG_URL}${selectedImage?.attachment}`;
            fetch(imgSrc)
                .then(response => response.blob())
                .then(blob => {
                    const localURL = URL.createObjectURL(blob);
                    setLocalImage(localURL);
                    setIsImageLoading(false);
                })
                .catch(err => {
                    console.error('Failed to load image:', err);
                    toast.error(t("Customer.ADVLoadFailed"));
                    setIsImageLoading(false);
                });
        }
    }, [selectedImage]);

    useLayoutEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            ctx.lineWidth = penWidth || 2;
            ctx.strokeStyle = penColor || '#000000';
            ctxRef.current = ctx;
        }
    }, [penColor, penWidth]);

    useEffect(() => {
        if (localImage) {
            const img = new Image();
            img.src = localImage;
            img.onload = () => {
                const canvas = canvasRef.current;
                if (canvas) {
                    canvas.width = img.width; // Set canvas width to 600px
                    canvas.height = img.height; // Set canvas height to 400px
                    const ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas before drawing new image
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw image scaled to fit canvas

                }
            };
        }
    }, [localImage]);


    const startDrawing = (e) => {
        const ctx = ctxRef.current;
        if (ctx) {
            ctx.beginPath();
            ctx.moveTo(getCursorPosition(e).x, getCursorPosition(e).y);
            setIsDrawing(true);
        }
    };

    const draw = (e) => {
        if (!isDrawing) return;
        const ctx = ctxRef.current;
        if (ctx) {
            const { x, y } = getCursorPosition(e);
            ctx.lineTo(x, y);
            ctx.stroke();
        }
    };

    const stopDrawing = () => setIsDrawing(false);

    const getCursorPosition = (e) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const scaleX = canvasRef.current.width / rect.width;
        const scaleY = canvasRef.current.height / rect.height;
        return {
            x: (e.clientX - rect.left) * scaleX,
            y: (e.clientY - rect.top) * scaleY,
        };
    };

    const saveEditedImage = () => {


        const canvas = canvasRef.current;
        canvas.toBlob((blob) => {
            const file = new File([blob], `edited-${moment().unix()}.png`, { type: 'image/png' });
            let body = { id: advancedJournalId, employee_id: employeeId };
            const formData = new FormData();
            formData.append('attachment', file);
            formData.append('req_body', JSON.stringify(body));

            const toastId = toast.loading(`${t("Common.Uploading")}...`);
            apiFetcher.patch(`api/v1/store/advance_journal/attachment/${selectedImage.id}`, formData)
                .then(response => {
                    let updatedImages = images.map(img => img.id === selectedImage.id ? { ...img, attachment: response.data.data.attachment } : img);
                    setImages(updatedImages);
                    toast.update(toastId, {
                        type: 'success',
                        isLoading: false,
                        render: t("Customer.ADVUploadSuccess"),
                        autoClose: 1000
                    });
                    onClose()


                })
                .catch(error => {
                    toast.update(toastId, {
                        type: 'error',
                        isLoading: false,
                        render: t("Customer.ADVUploadFailed"),
                        autoClose: 2000
                    });
                    console.error("Upload Error:", error)
                });
        }, 'image/png');
    };

    const clearEdit = () => {
        const ctx = ctxRef.current;
        if (ctx) {
            // Redraw the background image on the canvas
            const img = new Image();
            img.src = localImage;
            img.onload = () => {
                const canvas = canvasRef.current;
                ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the drawing area
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Redraw the background image
            };
        }
    };


    return (
        <React.Fragment>
            <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Paper sx={{ position: "relative", maxWidth: "90%", maxHeight: "80%", overflow: 'auto', display: 'flex', flexDirection: 'column', borderRadius: 8, padding: 4 }}>
                    <ToastContainer />
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={onClose}><Close /></IconButton>
                    <Stack mt={2} p={0} direction={'row'}>

                        <Stack width={"20%"} direction={'column'} gap={2}>
                            <Typography>{t("Customer.PenColor")}</Typography>
                            <input type="color" value={penColor} onChange={(e) => setPenColor(e.target.value)} />
                        </Stack>

                        {!showTools ?
                            <Stack
                                flex={1}
                                flexDirection={'column'}
                                justifyContent={'space-evenly'}
                                alignContent={'center'}
                                disableFocusRipple disableRipple disableTouchRipple disableTouchFocusRipple
                                sx={{ p: 0, flexDirection: "column", }}

                                width={"20%"}
                            >
                                <Typography >{t("Customer.SelectMarker")}</Typography>
                                <Mode sx={{ fontSize: '2rem', cursor: 'pointer', alignItems: 'center', ml: 3 }} onClick={() => {
                                    setPenWidth(1)

                                    // setPenColor('#000001')
                                    setShowTools(!showTools)
                                }} />
                            </Stack>
                            :
                            <Stack width={"30%"} direction={'column'} gap={2}>
                                <Typography>{t("Customer.PenWidth")}</Typography>
                                <Slider value={penWidth} min={0.1} max={10} step={1} onChange={(e, newValue) => setPenWidth(newValue)} />
                            </Stack>
                        }
                    </Stack>
                    <Stack mt={2}>
                        {isImageLoading ? (
                            <Stack height={400} width={"100%"} justifyContent={'center'} alignItems={'center'}><CircularProgress size={"2.5rem"} sx={{ color: '#6f6f6f' }} /></Stack>
                        ) : (
                            <canvas ref={canvasRef} onMouseDown={startDrawing} onMouseMove={draw} onMouseUp={stopDrawing} onMouseLeave={stopDrawing} style={{ border: '1px solid #d9d9d9' }} />
                        )}
                        <Stack direction={'row'} gap={2} p={2}>
                            <FButton variant={'save'} onClick={saveEditedImage} title={t("Customer.SaveEditedPicture")} />
                            <FButton variant={'delete'} onClick={clearEdit} title={t("Common.Clear")} />
                        </Stack>
                    </Stack>
                </Paper>
            </Modal>
        </React.Fragment>
    );
}
