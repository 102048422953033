import React, { useEffect, useState } from "react";
import { AppBar, Box, MenuItem, Paper, Select, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import OnlineBookingSettingsOption from "../../components/settings/onlineBooking";
import GeneralSettingsOption from "../../components/settings/general";
import EmployeeSettingsOption from "../../components/settings/employee";
import CalendarSettingsOption from "../../components/settings/calendar";
import JournalGroupSettingsOption from "../../components/settings/journalGroup";
import AdvanceJournalGroupSettingsOption from "../../components/settings/advanceJournal";
import OpeningHours from "../../components/settings/opningHours";
import { useNavigate } from "react-router-dom";
import CustomDeleteModal from "../../components/deleteAlertModal";
import apiFetcher from "../../utils/interCeptor";
import { useDispatch } from "react-redux";
import { settings } from "../../context/settingsSlice";
import { t } from "i18next";
import usimg from "../../assets/us.png"
import denImg from "../../assets/den.png"
import { useSelector } from "react-redux";



const commonStyle = {
  padding: "10px 15px",
  cursor: "pointer",
  fontWeight: 500,
  color: "#BBB0A4",
};

const SettingsOption = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("General");
  const [logoutModal, setLogoutModal] = useState(false);
  const language = (localStorage.getItem("language"));
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [settingApiData, setSettingApiData] = useState([]);

  const getSettings = useSelector((state) => state.settings.data);

  const handleClick = (option) => {
    setSelectedOption(option);
  };

  const dispatch = useDispatch();

  const fetchSettings = async () => {

    try {
      const res = await apiFetcher.get("/api/v1/store/outlet/setting");
      setSettingApiData(res.data.data);

      let settingsObj = {
        OnlineBooking: null,
        calendar: {
          setCalendarOpeningHour: 240,
          setCalendarClosingHour: 240,
          grayOutClosedHours: false,
          showOnlyAvailableEmployee: false,
          calendarInterval: 5,
        },
      };

      res.data.data.settings.map((settingObj) => {
        if (settingObj.settingName == "OnlineBooking") {
          settingsObj["OnlineBooking"] = settingObj?.value
            ? JSON.parse(settingObj?.value)
            : null;
        }
        if (settingObj.settingName == "calendar") {
          settingsObj["calendar"] = settingObj?.value
            ? JSON.parse(settingObj?.value)
            : null;
        }
      });

      dispatch(settings(settingsObj));

      getEmployees();
    } catch (error) {
      console.error("error", error);
    }
  };

  const getEmployees = async () => {
    try {
      const response = await apiFetcher.get("/api/v1/store/employee/get");

      const { success, data } = response.data;
      if (success) {

        const dataValue = data.map((dataObj) => {
          let newData = JSON.stringify(dataObj);
          let newObj = { ...JSON.parse(newData) };
          const permission = { ...newObj.settings };
          delete newObj.settings;

          return {
            ...newObj,
            label: newObj.name,
            value: newObj.id,
            permission,
            selectedEmployee: false,
          };
        });

        // setEmployees(dataValue);
        dispatch(settings({ employees: dataValue }));
      }
    } catch (err) {
      console.error("err", err);
    }
  };

  useEffect(() => {

    fetchSettings();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  function handleLanguageChange(value) {
    localStorage.setItem("language", value);
    window.location.reload();
  }

  return (
    // <Paper style={{position: 'fixed'}}>
    <Stack sx={{ maxHeight: "100%", overflowY: "scroll" }}>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 1px 50px 0px rgba(0, 0, 0, 0.05)",
          zIndex: 1,
        }}
      >
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          pr: 3,
          gap: 8,
          position: "relative",
        }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pl: 8,
              pr: 8,
              gap: 5.5,
              maxWidth: "90%",
              scrollbarWidth: "none",
              overflowX: "scroll",
              overflowY: "hidden",
            }}
          >
            <Typography
              noWrap
              variant="body1"
              sx={{
                ...commonStyle,
                whiteSpace: "nowrap",
                flexShrink: 0,
                ...(selectedOption === "General"
                  ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 }
                  : {}),
              }}
              onClick={() => handleClick("General")}
            >
              {t("Setting.General")}
            </Typography>

            <Typography
              noWrap
              variant="body1"
              sx={{
                ...commonStyle,
                whiteSpace: "nowrap",
                flexShrink: 0,
                ...(selectedOption === "Online booking"
                  ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 }
                  : {}),
              }}
              onClick={() => handleClick("Online booking")}
            >
              {t("Setting.OnlineBooking")}
            </Typography>

            <Typography
              noWrap
              variant="body1"
              sx={{
                ...commonStyle,
                whiteSpace: "nowrap",
                flexShrink: 0,
                ...(selectedOption === "Calendar"
                  ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 }
                  : {}),
              }}
              onClick={() => handleClick("Calendar")}
            >
              {t("Setting.Calendar")}
            </Typography>

            <Typography
              noWrap
              variant="body1"
              sx={{
                ...commonStyle,
                whiteSpace: "nowrap",
                flexShrink: 0,
                ...(selectedOption === "Employees"
                  ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 }
                  : {}),
              }}
              onClick={() => handleClick("Employees")}
            >
              {t("Setting.Employees")}
            </Typography>

            <Typography
              noWrap
              variant="body1"
              sx={{
                ...commonStyle,
                whiteSpace: "nowrap",
                flexShrink: 0,
                ...(selectedOption === "Opening hours"
                  ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 }
                  : {}),
              }}
              onClick={() => handleClick("Opening hours")}
            >
              {t("Setting.OpeningHours")}
            </Typography>

            {getSettings?.profile?.allow_journal &&
              <Typography
                noWrap
                variant="body1"
                sx={{
                  ...commonStyle,
                  whiteSpace: "nowrap",
                  flexShrink: 0,
                  ...(selectedOption === "Journal groups"
                    ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 }
                    : {}),
                }}
                onClick={() => handleClick("Journal groups")}
              >
                {t("Common.JournalGroups")}
              </Typography>}

            {getSettings?.profile?.allow_advance_journal &&
              <Typography
                noWrap
                variant="body1"
                sx={{
                  ...commonStyle,
                  whiteSpace: "nowrap",
                  flexShrink: 0,
                  ...(selectedOption === "Advanced journals"
                    ? { borderBottom: "3px solid #BBB0A4", fontWeight: 700 }
                    : {}),
                }}
                onClick={() => handleClick("Advanced journals")}
              >
                {t("Setting.AdvancedJournals")}
              </Typography>}

            <Typography
              noWrap
              variant="body1"
              sx={{
                ...commonStyle,
                whiteSpace: "nowrap",
                flexShrink: 0,
              }}
              onClick={() => setLogoutModal(true)}
            >
              {t("Setting.Logout")}
            </Typography>

          </Box>
          <Box sx={{ position: 'absolute', right: 0, display: 'flex', alignItems: 'center', pr: 2 }}>
            <Select
              value={language ? language : 'da'}
              onChange={(e) => {

                handleLanguageChange(e.target.value)
              }}
              sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none' }, pt: 2 }}

              renderValue={() => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                  {isSmallScreen ?
                    <img
                      src={language === 'en' ? usimg : denImg}
                      alt="flag"
                      style={{ width: 20, marginRight: 8, mt: -3 }}
                    />
                    :
                    <>
                      <img
                        src={language === 'en' ? usimg : denImg}
                        alt="flag"
                        style={{ width: 20, marginRight: 8, marginBottom: "20%" }}
                      />
                      <p>{language === 'en' ? 'English' : 'Dansk'}</p>
                    </>
                  }


                </div>
              )}
            >


              <MenuItem value="en">
                <img src={usimg} alt="English" style={{ width: 20, marginRight: 8 }} />
                English
              </MenuItem>
              <MenuItem value="da">
                <img src={denImg} alt="Danish" style={{ width: 20, marginRight: 8 }} />
                Dansk
              </MenuItem>
            </Select>
          </Box>

        </Box>
      </AppBar>


      <Stack>
        {selectedOption === "General" && <GeneralSettingsOption />}

        {selectedOption === "Online booking" && <OnlineBookingSettingsOption />}

        {selectedOption === "Calendar" && <CalendarSettingsOption />}

        {selectedOption === "Employees" && <EmployeeSettingsOption />}

        {selectedOption === "Opening hours" && <OpeningHours />}

        {selectedOption === "Journal groups" && <JournalGroupSettingsOption />}

        {selectedOption === "Advanced journals" && (
          <AdvanceJournalGroupSettingsOption />
        )}

        {logoutModal && (
          <CustomDeleteModal
            open={logoutModal}
            title={"Logout"}
            handleClose={() => setLogoutModal(false)}
            description={
              <>
                {t("Setting.AreYouSureYouWantToLogout")}
                {/* <span style={{ marginLeft: 5, color: '#1F1F1F', fontWeight: 'bold', marginRight: 5 }}>
                                    {itemsToDelete?.type == 'container' ? itemsToDelete?.container.title : itemsToDelete?.item.name}
                                </span> */}
                {/* {itemsToDelete?.type == 'container' ? 'service group': 'service'} */}
              </>
            }
            onClickDismiss={() => setLogoutModal(false)}
            onClickConfirm={() => handleLogout()}
          />
        )}
      </Stack>
    </Stack>
    // </Paper>
  );
};

export default SettingsOption;
