import React from 'react'
import FButton from '../../../commonComponents/F_Button';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { t } from 'i18next';
import ReactQuill from 'react-quill';
import FPersonalSelect from './FPersonalSelect';
import FTextInput from '../../../commonComponents/F_TextInput';
import FPrimaryHeading from '../../../commonComponents/F_PrimaryHeading';
import { toast } from 'react-toastify';

const modules = {
  toolbar: [
    [{ font: ["Arial", "sans-serif"] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false, // Ensures Quill inserts proper <p> tags
  },
};

const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "blockquote",
  "code-block",
  "list",
  "script",
  "indent",
  "direction",
  "align",
  "link",
  "image",
  "video",
  "header",
];

const FormikForm = ({formik,item,changeSequence, arrow , user , useTemplate , setSelectedTemplateName , setOpen , selectedTemplatename  , fileName , setFileName , setDeleteidx , ispending , setShowSaveModal}) => {

    const op = [
        ...useTemplate.map((itemm) => ({
          value: itemm?.id,
          label: itemm?.name,
        })),
        {
          value: -1, // Generate a random ID
          label: "+ Save draft as a template",
        },
      ];

      
  const handleRemoveFile = async (index) => {
    const updatedImages = formik.values?.images.filter((i) =>
      i.name ? i.name !== index : i.attachment_name !== index
    );
    const ind = formik.values?.images.find((i) => i.attachment_name === index);

    const updatedFileNames = fileName.filter((i) => i !== index);
    if (ind) {
      setDeleteidx((prev) => [...prev, ind.id]);
    }
    formik.setFieldValue("images", updatedImages);
    setFileName(updatedFileNames);
  };


  const handleFileChange = (event) => {
    const fileList = event.target.files; // FileList object
    const filesArray = Array.from(fileList); // Convert FileList to an array

    if (filesArray.length > 4) {
      toast.error("You can only upload up to 4 files.");
      return;
    }

    if (filesArray.length > 0) {
      formik.setFieldValue("images", [...formik.values?.images, ...filesArray]);
      setFileName((prev) => [...prev, ...filesArray?.map((item) => item.name)]);
    }
  };


  return (
  <>
   <Stack
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",

            mb: 2,
          }}
        >
          {" "}
          <FPrimaryHeading
            fontColor="#545454"
            fontSize="22px"
            text={`${item?.group_name}`}
          />
          <Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                my: "auto",
              }}
            >
              <Stack
                onClick={() => {
                  // setInitialValues({
                  //   id: null,
                  //   type: "",
                  //   writer: "",
                  //   title: "",
                  //   template_id: null,
                  //   journal_entry: ``,
                  //   images: [],
                  //   group_id: null,
                  // });
                  // setFileName([]);
                  changeSequence(item?.group_id, "down");
                }}
                sx={{
                  "&:hover": { cursor: "pointer" },
                  backgroundColor: "#D9D9D9",
                  p: 1,
                  borderRadius: "50%",
                }}
              >
                <Stack
                  component={"img"}
                  src={arrow}
                  alt="arr"
                  sx={{ height: 18, width: 18, objectFit: "contain" }}
                />
              </Stack>

              <Stack
                onClick={() => {
                  // setInitialValues({
                  //   id: null,
                  //   type: "",
                  //   writer: "",
                  //   title: "",
                  //   template_id: null,
                  //   journal_entry: ``,
                  //   images: [],
                  //   group_id: null,
                  // });
                  // setFileName([]);
                  changeSequence(item?.group_id, "up");
                }}
                sx={{
                  "&:hover": { cursor: "pointer" },
                  backgroundColor: "#D9D9D9",
                  p: 1,
                  borderRadius: "50%",
                }}
              >
                <Stack
                  component={"img"}
                  src={arrow}
                  alt="arr"
                  sx={{
                    height: 18,
                    width: 18,
                    objectFit: "contain",
                    rotate: "180deg",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            border: "1.5px solid  #D9D9D9",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 2,
          }}
        >
          <FTextInput
            id={`writer`}
            value={user?.name}
            disabled
            placeholder="Written by"
            sx={{
              border: "no3ne",
              mt: 0,
              borderBottom: "1.5px solid  #D9D9D9",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />

          {formik.errors.writer && formik.touched.writer && (
            <Stack
              component={"span"}
              style={{ color: "red", fontSize: "12px", marginLeft: 10 }}
            >
              {formik.errors.writer}
            </Stack>
          )}

          <FTextInput
            // id={`title${item.group_id}`}
            value={formik.values.title}
            onChange={(e) => {
              formik.setFieldValue("title", e.target.value);
            }}
            placeholder={t("Customer.TitleOfJournal")}
            sx={{
              border: "none",
              mt: 0,
              borderBottom: "1.5px solid  #D9D9D9",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />

          {formik.errors.title && formik.touched.title && (
            <Stack
              component={"span"}
              style={{ color: "red", fontSize: "12px", marginLeft: 10 }}
            >
              {formik.errors.title}
            </Stack>
          )}

          <Stack p={0.5} display={"flex"} flexDirection={"row"} gap={2}>
            <FPersonalSelect
              id={`template_id${item.group_id}`}
              value={
                formik.values.template_id === null
                  ? null
                  : formik.values.template_id
              }
              onChange={(e) => {
                const selectedName = useTemplate?.find(
                  (itemm) => itemm.id === e.target.value
                )?.name;

                if (selectedName) {
                  setSelectedTemplateName(selectedName);
                }
                if (typeof e.target.value === "number") {
                  if (e.target.value == -1) {
                    setOpen(true);
                    return;
                  }
                  formik.setFieldValue(
                    "journal_entry",
                    useTemplate.find((item) => item.id === e.target.value)
                      .detail
                  );
                  formik.setFieldValue(`template_id`, e.target.value);
                }
              }}
              options={useTemplate && op}
              showPlaceHolder={true}
              placeholderText={
                selectedTemplatename
                  ? selectedTemplatename
                  : t("Customer.JournalChooseTemplate")
              }
              sx={{ width: "17%", height: 35 }}
            />
            {/* 
          <FButton
            type={"button"}
            title={"+ Add new template"}
            sx={{
              width: "18%",
              height: 35,
              px: 0,
              backgroundColor: "#D9D9D9",
            }}
            variant={"save"}
          /> */}
          </Stack>

          <ReactQuill
            id={item.group_id}
            value={formik.values.journal_entry}
            onChange={(value) => {
                formik.setFieldValue("template_id", null);
              formik.setFieldValue(`journal_entry`, value);
            }}
            modules={modules}
            formats={formats}
            className="yug-quill"
            style={{
              border: "none",
              borderRadius: 0,
              width: "100%",
            }}
          />
          {formik.errors.journal_entry && formik.touched.journal_entry && (
            <Stack
              component={"span"}
              style={{ color: "red", fontSize: "12px" }}
            >
              {formik.errors.journal_entry}
            </Stack>
          )}

          <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={2}
            height={40}
            pr={2}
            borderBottom={"1.5px solid #D9D9D9"}
          >
            <Stack
              sx={{
                width: "15%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                borderRight: "1.5px solid  #D9D9D9",
              }}
            >
              <Typography color="#BBB0A4">
                {t("Customer.JournalUploadImg")}
              </Typography>
            </Stack>

            <input
              multiple
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              id={`image-upload${item.group_id}`}
              onChange={handleFileChange}
            />
            <label
              htmlFor={`image-upload${item.group_id}`}
              style={{
                width: "15%",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Button
                id={item.group_id}
                type="button"
                disableRipple
                component="span"
                sx={{
                  border: "1px solid #000000",

                  width: "100%",
                  height: 32,
                  px: 0,
                  backgroundColor: "#D9D9D9",
                  my: "auto",

                  color: "black",
                  textTransform: "capitalize",
                }}
              >
                <Typography variant="body1">
                  {t("Customer.JournalChooseImg")}
                </Typography>
              </Button>
            </label>
            <Typography
              color="#BBB0A4"
              ml={2}
              my="auto"
              sx={{
                width: "70%",
                overflowX: "auto",
                height: 32,
                display: "flex",
                flexDirection: "row",
                gap: 2,
                whiteSpace: "nowrap",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                pr: 1,
              }}
            >
              {fileName.length > 0
                ? fileName.map((item, index) => (
                    <span
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {item}{" "}
                      <IconButton
                        disableRipple
                        onClick={() => handleRemoveFile(item)}
                        size="small"
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </span>
                  ))
                : <span style={{marginTop:3.5}}>{t("Customer.JournlNoFileSelected")}</span>}
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: "100%",
              height: 40,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 2,
              px: 3,
            }}
          >
            <FButton
              disabled={ispending}
              titleColor={"white"}
              type={"button"}
              onClick={() => {
                setShowSaveModal(true);
              }}
              title={t("Customer.JournalSave")}
              sx={{
                width: "8%",
                height: 30,
                px: 0,
                my: "auto",
                backgroundColor: "#E19957",
              }}
              variant={"delete"}
            />

            <FButton
              disabled={ispending}
              type={"button"}
              onClick={() => {
                formik.setFieldValue("group_id", item.group_id);

                formik.setFieldValue("type", "post");
                formik.handleSubmit();
              }}
              titleColor={"white"}
              title={t("Customer.JournalPost")}
              sx={{
                width: "15%",
                height: 32,
                px: 0,
                my: "auto",
                color: "white",
              }}
              variant={"save"}
            />
          </Stack>
        </Stack></>
  )
}

export default FormikForm