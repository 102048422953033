import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Skeleton } from "@mui/material";
import PrimaryHeading from "../commonPrimaryHeading";
import SecondaryHeading from "../commonSecondaryHeading";
import apiFetcher from "../../../utils/interCeptor";

import { toast, ToastContainer } from "react-toastify";
import _ from "lodash";
import { MultipleContainers } from "../../MultipleContainers/MultipleContainers";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import {
    restrictToVerticalAxis,
    restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { useSelector } from "react-redux";

import CreateJournalGroupTemplate from "../journalGroup/createJournalGroupTemplate";

import CustomDeleteModal from "../../deleteAlertModal";

import { t } from "i18next";

const AdvanceJournalGroupSettingsOption = () => {
    const user = useSelector((state) => state.user.data);

    const [showCreateJournalGroupTemplateModal, setShowCreateJournalGroupTemplateModal] = useState(false);
    const [reOrderAdvancedJournalTemplate, setReOrderAdvancedJournalTemplate] = useState({});

    const [loading, setLoading] = useState(false);
    const [showJournalGroupsDeleteModal, setShowJournalGroupsDeleteModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetchJournalAdvancedTemplate();
    }, []);

    const fetchJournalAdvancedTemplate = async () => {
        try {
            const response = await apiFetcher.get(`api/v1/store/journal/template/list?advance=true`)
            if (response) {
                const data = response?.data?.data;

                let journalGroupObj = {};
                data.map((dataObj) => {
                    journalGroupObj[dataObj?.id] = {
                        ...dataObj,
                        title: dataObj.name,
                        groupId: dataObj?.id,
                        sequence: dataObj?.advance_sequence,
                        templates: [],
                        services: [],
                        noSubGroup: true,
                        forJournal: true,
                    };
                });
                setReOrderAdvancedJournalTemplate(journalGroupObj);
            };
        } catch (error) {
            toast.error(t("Setting.FailedToFetchAdvancedJournalList"));
        } finally {
            setLoading(false);
        }
    };

    const removeAdvancedJournalTemplate = (id) => {
        const matchedObject = reOrderAdvancedJournalTemplate[id];
        if (matchedObject) {
            setSelectedTemplate(matchedObject);
            setShowJournalGroupsDeleteModal(true);
        }
    };

    const removeJournalTemplate = async (id) => {
        try {
            const response = await apiFetcher.delete(`api/v1/store/journal/template/${id}`);
            if (response.data.success) {
                toast.success(t("Setting.AdvancedJournalTemplateDeleted"));
                handleCloseAdvancedJournalTemplateDeleteModal();
                setSelectedTemplate(null);
                fetchJournalAdvancedTemplate();
                return;
            } else {
                toast.error(t("Setting.FailedToDeleteAdvancedJournalTemplate"));
                handleCloseAdvancedJournalTemplateDeleteModal();
                setSelectedTemplate(null);
            }
        } catch (error) {
            toast.error(t("Setting.FailedToDeleteAdvancedJournalTemplate"));
            handleCloseAdvancedJournalTemplateDeleteModal();
            setSelectedTemplate(null);
        }
    };

    const handleCloseAdvancedJournalTemplateDeleteModal = () => {
        setShowJournalGroupsDeleteModal(!showJournalGroupsDeleteModal);
    };

    async function updateJournalGroupSequence(payload) {
        try {
            const response = await apiFetcher.post("/api/v1/store/journal/template/sequence", payload);
            const { success } = response.data;
            if (success) {
                toast.success(t("Setting.AdvancedJournalTemplateOrderUpdated"));
                fetchJournalAdvancedTemplate();
            }
        } catch (err) {
            toast.error(t("Setting.FailedToUpdateAdvancedJournalTemplateOrder"));
        }
    };

    return (
        <>
            <Stack sx={{ paddingRight: 4, paddingLeft: 4, paddingBottom: 4 }}>
                <ToastContainer />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingRight: 4,
                        paddingLeft: 4,
                    }}
                />

                <Stack
                    spacing={6}
                    sx={{
                        bgcolor: "#fff",
                        borderRadius: "25px",
                        minHeight: "auto",
                        scrollbarWidth: "none",
                        overflowX: "hidden",
                    }}
                >
                    <Box style={{ display: "flex", justifyContent: "center" }}>

                        <Box
                            sx={{
                                display: "flex",
                                bgcolor: "#FFFFFF",
                                borderRadius: "25px",
                                flexDirection: "column",
                                width: "100%",
                                padding: 5,
                            }}
                        >
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    {(user?.role === "ADMIN" ||
                                        user?.settings.view_all_employees) && (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "33%",
                                                        pr: 3,
                                                    }}
                                                >
                                                    <PrimaryHeading text={t("Setting.AdvancedJournals")} />

                                                    <SecondaryHeading text={t("Setting.Description14")} />
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "67%",
                                                        minHeight: "200px",
                                                        overflowY: "auto",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ fontWeight: 700, color: "#1F1F1F", ml: 5 }}
                                                    >
                                                        {t("Customer.TmpName")}
                                                    </Typography>

                                                    {loading ?
                                                        <Stack sx={{ width: 'auto', marginLeft: 2.5, marginRight: 1, mt: 2, mb: 1 }}>
                                                            {[...Array(4)].map((_, index) => (
                                                                <Skeleton variant="rounded" width="100%" height={40} sx={{ mt: 1 }} />
                                                            ))}
                                                        </Stack>
                                                        :
                                                        (Object.keys(reOrderAdvancedJournalTemplate).length > 0 && (
                                                            <MultipleContainers
                                                                modelType={"Journal-Groups"}
                                                                itemCount={Object.keys(reOrderAdvancedJournalTemplate).length}
                                                                items={reOrderAdvancedJournalTemplate}
                                                                setItems={setReOrderAdvancedJournalTemplate}
                                                                strategy={rectSortingStrategy}
                                                                vertical
                                                                modifiers={[
                                                                    restrictToVerticalAxis,
                                                                    restrictToWindowEdges,
                                                                ]}
                                                                onDragToAnotherContainer={(
                                                                    containerId,
                                                                    itemId
                                                                ) => {
                                                                }}
                                                                onDragComplete={(
                                                                    isContainer,
                                                                    containerId,
                                                                    updatedcontainers
                                                                ) => {

                                                                    let dataToUpdate = [];
                                                                    if (isContainer) {
                                                                        let newjournalGroups = { ...reOrderAdvancedJournalTemplate };
                                                                        updatedcontainers.map(
                                                                            (containerId, index) => {
                                                                                if (containerId != 0) {
                                                                                    dataToUpdate.push({
                                                                                        id: containerId,
                                                                                        sequence: index,
                                                                                    });
                                                                                }
                                                                                newjournalGroups[containerId].sequence =
                                                                                    index;
                                                                            }
                                                                        );

                                                                        setReOrderAdvancedJournalTemplate(newjournalGroups)
                                                                        updateJournalGroupSequence(dataToUpdate);
                                                                    }
                                                                }}
                                                                onclickContainer={(e) => {
                                                                    setShowCreateJournalGroupTemplateModal(!showCreateJournalGroupTemplateModal);
                                                                    setSelectedTemplate(e);
                                                                }}
                                                                onRemove={(e) => removeAdvancedJournalTemplate(e)}
                                                            />
                                                        ))

                                                    }

                                                    <Stack
                                                        flex={1}
                                                        onClick={() => { setSelectedTemplate(null); setShowCreateJournalGroupTemplateModal(true) }}
                                                        flexDirection={"row"}
                                                        pl={2}
                                                        pr={2}
                                                        pt={1}
                                                        pb={1}
                                                        justifyContent={"center"}
                                                        alignItems={"center"}
                                                        sx={{
                                                            width: "auto",
                                                            margin: "5px 0",
                                                            backgroundColor: "#ffffff",
                                                            borderRadius: "15px",
                                                            border: "1px solid #D9D9D9",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            maxHeight: 40,
                                                            marginLeft: 2.5,
                                                            marginRight: 1
                                                        }}
                                                    >
                                                        <Stack
                                                            flex={1}
                                                            flexDirection="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    width: 400,
                                                                    size: "20px",
                                                                    color: "#A0A0A0",
                                                                    mt: 0.2,
                                                                    mb: 0.2,
                                                                }}
                                                            >
                                                                {t("Setting.NewJournalTemplate")}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>

                                                </Box>
                                            </>
                                        )}
                                </Box>
                            </>
                        </Box>

                    </Box>

                    {showCreateJournalGroupTemplateModal && (
                        <CreateJournalGroupTemplate
                            open={showCreateJournalGroupTemplateModal}
                            data={null}
                            handleRemove={() => {
                                setShowCreateJournalGroupTemplateModal(false);
                            }}
                            journalGroupData={reOrderAdvancedJournalTemplate}
                            selectedTemplate={selectedTemplate}
                            onClose={() => {
                                fetchJournalAdvancedTemplate();
                                setShowCreateJournalGroupTemplateModal(!showCreateJournalGroupTemplateModal)
                            }}
                            advancedJournal
                        />
                    )}

                    {showJournalGroupsDeleteModal &&
                        <CustomDeleteModal
                            open={showJournalGroupsDeleteModal}
                            handleClose={handleCloseAdvancedJournalTemplateDeleteModal}
                            description={
                                <>
                                    {t("Setting.AreYouSureYouWantToDelete")}
                                    <span style={{ marginLeft: 5, color: '#1F1F1F', fontWeight: 'bold', marginRight: 5 }}>
                                        {selectedTemplate?.name}
                                    </span>
                                </>
                            }
                            onClickDismiss={handleCloseAdvancedJournalTemplateDeleteModal}
                            onClickConfirm={() => { removeJournalTemplate(selectedTemplate.id) }}
                        />
                    }

                </Stack>

            </Stack>
        </>
    );
};

export default AdvanceJournalGroupSettingsOption;
