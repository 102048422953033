import { Grid2, InputAdornment, Stack, Tooltip, Typography, IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FTextInput from '../../../commonComponents/F_TextInput';
import FPrimaryHeading from '../../../commonComponents/F_PrimaryHeading';
import FButton from '../../../commonComponents/F_Button';
import FTextArea from '../../../commonComponents/F_TextArea';
import LockIcon from '../../../../assets/lock.png';
import moment from 'moment';
import apiFetcher from '../../../../utils/interCeptor';
import { HttpStatusCode } from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomDeleteModal from '../../../deleteAlertModal';
import FSelect from '../../../commonComponents/F_Select';
import { t } from 'i18next';
import CustomTextField from '../../../settings/commonTextinput';
import { useSelector } from 'react-redux';
import _ from "lodash";
import Delete from '../../../../assets/Delete.svg';
import Upload from '../../../../assets/uploadFile.svg'

const dateObject = {
    dates: Array.from({ length: 31 }, (_, i) => i + 1),
    months: Array.from({ length: 12 }, (_, i) => i + 1),
    years: Array.from({ length: 100 }, (_, i) => moment().year() - i)
}


const CustomerInformation = () => {


    const navigate = useNavigate()


    // const [loading, setLoading] = useState(false);
    const [isChanges, setIsChanges] = useState(false);
    const [havePermission, setHavePermission] = useState(false);
    const [deleteCustomerModel, setDeleteCustomerModel] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [deletedFileIds, setDeletedFileIds] = useState([]);
    const [selectedDate, setSelectedDate] = useState({
        day: '',
        month: '',
        year: '',
    })
    const { id } = useParams();
    const [customer, setCustomer] = useState({});
    // const location = useLocation()

    const isEdit = id ? true : false
    const user = useSelector((state) => state.user.data)

    useEffect(() => {
        if (user && (user?.settings?.edit_customers || user?.role == 'ADMIN')) {
            setHavePermission(true);
        }
    }, [user])

    useEffect(() => {
        if (selectedDate && selectedDate.year && selectedDate.month && selectedDate.day) {
            const formattedDate = `${selectedDate.year}-${selectedDate.month < 10 ? `0${selectedDate.month}` : selectedDate.month}-${selectedDate.day < 10 ? `0${selectedDate.day}` : selectedDate.day}`;
            formik.setFieldValue('birthday', formattedDate);
        }
    }, [selectedDate]);




    const fetchCustomerDetails = async () => {
        if (id) {
            try {
                const response = await apiFetcher.get(
                    `api/v1/store/customer/${id}`
                );
                if (response.status === HttpStatusCode.Ok) {
                    setCustomer(response?.data?.data);
                    setUploadedFiles(response?.data?.data?.attachment_objs)
                }
            } catch (error) {
                toast.error("Failed to fetch customer details");
                navigate("/customers");
            }
        };
    }

    useEffect(() => {
        fetchCustomerDetails();
    }, [])







    const isLeapYear = (year) => moment(`${year}-02-29`, 'YYYY-MM-DD').isValid();


    const getDisabledMonths = () => {
        const { day } = selectedDate;
        if (!day) return [];
        if (day === 30) return [2];
        if (day === 31) return [2, 4, 6, 9, 11];
        return [];
    };


    const getDisabledYears = () => {
        const { day, month } = selectedDate;
        if (day === 29 && month === 2) {
            return dateObject.years.filter(year => !isLeapYear(year));
        }
        return [];
    };


    const mapOptions = (array, disabledItems = []) => {
        return array.map(item => ({
            value: item,
            label: item,
            disabled: disabledItems.includes(item),
        }));
    };

    const handleSelectDate = ({ date, type }) => {
        if (type === 'day') {
            setSelectedDate((prevState) => ({
                ...prevState,
                month: 0,
                year: 0,
            }));
        }
        if (type === 'month') {
            setSelectedDate((prevState) => ({
                ...prevState,
                year: 0,
            }));
        }
        setSelectedDate((prevState) => ({
            ...prevState,
            [type]: date,
        }));
    };


    useEffect(() => {
        if (customer?.birthday) {
            setSelectedDate({
                day: moment(customer.birthday).date(),
                month: moment(customer.birthday).month() + 1,
                year: moment(customer.birthday).year(),
            })
        }
    }, [customer?.birthday])

    const validationSchema = Yup.object({
        name: Yup.string().required(t("Customer.CustomerNameError")),
        address: Yup.string().nullable(),
        email: Yup.string().email(t("Customer.EmailError")).nullable(),
        zip_code: Yup.string().nullable(),
        city: Yup.string().nullable(),
        phone_number: Yup.string()
            .required(t("Customer.PhoneNumberError"))
            .matches(/^\d{8}$/, t("Customer.PhoneNumberInvalid"))
            .typeError(t("Customer.PhoneNumberTypeError"))
            .required(t("Customer.PhoneNumberError")),
        marketplace_pointer: Yup.string().nullable(),
        phone_number2: Yup.string()
            .matches(/^\d{8}$/, t("Customer.PhoneNumber2Invalid"))
            .typeError(t("Customer.PhoneNumber2TypeError"))
            .nullable()
            .notRequired(),
        birthday: Yup.string()
            .nullable()
            .notRequired()
            .matches(
                /^\d{4}-\d{2}-\d{2}$/,
                t("Customer.BirthdayInvalidFormat")
            )
            .test('is-valid-date', t("Customer.BirthdayInvalidDate"), value => {
                return (
                    !value ||
                    (moment(value, 'YYYY-MM-DD', true).isValid() &&
                        moment(value, 'YYYY-MM-DD', true).format('YYYY-MM-DD') === value)
                );
            })
            .max(new Date(), t("Customer.BirthdayMaxDate")),

        cpr: Yup.string().nullable().min(11, t("Customer.CprError")),
        attachments: Yup.array().nullable()
    });




    const formik = useFormik({
        initialValues: {
            name: customer?.name,
            address: customer?.address,
            email: customer?.email,
            zip_code: customer?.zip_code,
            city: customer?.city,
            phone_number: customer?.phone_number,
            marketplace_pointer: customer?.marketplace_pointer,
            phone_number2: customer?.phone_number2,
            birthday: customer?.birthday,
            cpr: customer?.cpr,
            attachments: customer?.attachments
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const payload = {
                name: values.name || '',
                address: values.address || '',
                email: values.email || '',
                zip_code: values.zip_code || '',
                city: values.city || '',
                phone_number: values.phone_number?.startsWith('+45') ? values?.phone_number?.replace("+45", '') : values.phone_number || '',
                marketplace_pointer: values.marketplace_pointer || '',
                phone_number2: values.phone_number2?.startsWith('+45') ? values?.phone_number2?.replace("+45", '') : values.phone_number2 || '',
                birthday: values.birthday || null,
                cpr: values.cpr || '',
                attachments: values?.attachments || []
            }

            if (payload) {
                submitUser(payload);
                // formik.resetForm();
            }
        }

    });

    // const submitUser = async (payload) => {
    //     let apiRequestMaker;

    //     if (!isEdit) {
    //         apiRequestMaker = await apiFetcher.post(`api/v1/store/customer/outlet`, payload);
    //     } else {
    //         if (!customer?.id) {
    //             toast.error(t("Customer.ToastCustomerIDError"));
    //             return;
    //         }
    //         apiRequestMaker = await apiFetcher.patch(`api/v1/store/customer/outlet?id=${customer?.id}`, payload);
    //     }

    //     try {
    //         // setLoading(true);
    //         const response = apiRequestMaker;


    //         if (response.status === 201 || response.status === 200) {
    //             toast.success(isEdit ? t("Customer.CustomerUpdateSuccess") : t("Customer.CustomerCreateSuccess"));

    //             // navigate(`/customers/${response?.data?.data?.id} `);
    //             if (!isEdit) {
    //                 setTimeout(() => {
    //                     navigate(`/customers`)
    //                 }, 800);
    //             } else {

    //                 setTimeout(() => {
    //                     navigate(`/customers/${response?.data?.data?.id} `);
    //                 }, 800);


    //             }
    //         } else if (response.status === 400) {
    //             toast.error(`${isEdit ? t("Customer.CustomerUpdateError") : t("Customer.CustomerCreateError")} -  ${response?.data?.detail}`);
    //         }
    //     } catch (error) {

    //         toast.error(`Failed to ${isEdit ? 'update' : 'create'} customer - ${error ? error : ''}`);
    //         console.error('Error creating customer:', error?.response || error?.message);
    //     } finally {
    //         // setLoading(false);
    //     }
    // };

    const submitUser = async (payload) => {
        let apiRequestMaker;
        try {
            // First, delete the files that were removed locally
            if (deletedFileIds.length > 0) {
                try {
                    await Promise.all(
                        deletedFileIds.map(fileId =>
                            apiFetcher.delete(`api/v1/store/file/${fileId}`)
                        )
                    );
                } catch (error) {
                    console.error('Error deleting files:', error);
                    toast.error(t("Customer.FileDeleteError"));
                    return; // Stop the save process if file deletion fails
                }
            }

            if (!isEdit) {
                apiRequestMaker = await apiFetcher.post(`api/v1/store/customer/outlet`, payload);
            } else {
                if (!customer?.id) {
                    toast.error(t("Customer.ToastCustomerIDError"));
                    return;
                }
                apiRequestMaker = await apiFetcher.patch(`api/v1/store/customer/outlet?id=${customer?.id}`, payload);
            }

            const response = apiRequestMaker;

            if (response.status === 201 || response.status === 200) {
                toast.success(isEdit ? t("Customer.CustomerUpdateSuccess") : t("Customer.CustomerCreateSuccess"));
                const ids = response.data.data.id;
                setIsChanges(false);
                setDeletedFileIds([]); // Reset deleted files tracking
                navigate(`/customers/${ids}/customerinformation`, { state: { data: customer } });
            } else if (response.status === 400) {
                toast.error(`${isEdit ? t("Customer.CustomerUpdateError") : t("Customer.CustomerCreateError")} - ${response?.data?.detail}`);
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error?.message || 'Unknown error';
            toast.error(`Failed to ${isEdit ? 'update' : 'create'} customer - ${error}`);
            console.error('Error creating customer:', errorMessage, error?.response || error);
        }
    };

    const deleteCustomer = (id) => {
        apiFetcher.delete(`api/v1/store/customer?id=${id}`)
            .then(response => {
                if (response.status === HttpStatusCode.Ok) {
                    toast.success(t("Customer.CustomerDeleteSuccess"));
                    setTimeout(() => {
                        navigate(`/customers`);
                    }, 800);
                }
            })
            .catch(error => {
                console.error("Error deleting customer:", error);
                toast.error(t("Customer.CustomerDeleteError"));
            });
    }

    const handleCprChange = (e) => {
        let input = e.target.value?.replace(/\D/g, "");
        if (input.length > 10) {
            input = input.slice(0, 10);
        }
        const formattedCpr = input.length > 6 ? `${input.slice(0, 6)}-${input.slice(6)}` : input;
        // setCprNumber(formattedCpr);
        formik.setFieldValue('cpr', formattedCpr);
        if (formattedCpr.length === 11) {
            // setIsCprValid(true);
            formik.setFieldValue('cpr', formattedCpr);
            // setValidationMessage('');
        } else {
            // setIsCprValid(false);
            // setValidationMessage('CPR must be in the format: 000000-XXXX');
        }
    };

    const formatPhoneNumber = (number) => {
        if (number != "") {
            return number?.replace(/(\d{2})(?=\d)/g, "$1 ");
        } else {
            return "";
        }
    };

    useEffect(() => {
        setIsChanges(!_.isEqual(formik.initialValues, formik.values));
    }, [formik.values]);

    const handleFileUpload = async (event) => {
        const files = Array.from(event.target.files);
        const maxSize = 15 * 1024 * 1024; // 15MB in bytes
        const validFiles = [];
        const invalidFiles = [];

        if (files.length > 3) {
            toast.error(t("Customer.FileLimit"));
            return;
        }

        files.forEach(file => {
            if (file.size > maxSize) {
                invalidFiles.push(file.name);
            } else {
                validFiles.push(file);
            }
        });

        if (invalidFiles.length > 0) {
            toast.error(`${t("Customer.FileSize")}: ${invalidFiles.join(', ')}`);
            return;
        }

        if (validFiles.length > 0) {
            try {
                const formData = new FormData();
                validFiles.forEach(file => {
                    formData.append('files', file);
                });

                const response = await apiFetcher.post('api/v1/store/file/multi', formData);

                if (response.data.success) {
                    const uploadedFiles = response.data.data.map(file => ({
                        id: file.id,
                        file_name: file.file_name,
                        url: file.url,
                        denmark_created_at: moment(file.denmark_created_at).format('YYYY-MM-DD HH:mm:ss')
                    }));

                    // Update formik attachments value with new file IDs
                    const currentattachmentss = formik.values.attachments || [];
                    formik.setFieldValue('attachments', [...currentattachmentss, ...uploadedFiles.map(f => f.id)]);

                    // Update table display
                    setUploadedFiles(prev => [...prev, ...uploadedFiles]);

                    toast.success(t("Customer.FileUploadSuccess"));
                }
            } catch (error) {
                toast.error(t("Customer.FileUploadError"));
                console.error('Error uploading files:', error);
            }
        }
    };

    const handleRemoveFile = async (fileId) => {
        // Add fileId to deletedFileIds array
        setDeletedFileIds(prev => [...prev, fileId]);

        // Optimistically remove file from state
        setUploadedFiles(prev => prev.filter(file => file.id !== fileId));
        const currentAttachments = formik.values.attachments || [];
        formik.setFieldValue('attachments', currentAttachments.filter(id => id !== fileId));

        // Set changes to true to enable save button
        setIsChanges(true);
    };

    return (
        <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 2, px: 10 }}>
            <ToastContainer />

            {/* {!loading && */}
            <Stack display={"flex"} flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'flex-end'}>

                {isChanges && (

                    isEdit ?
                        (user?.settings.edit_customers || user?.role == 'ADMIN') &&
                        <FButton title={t("Customer.SaveCh")} sx={{ borderRadius: 50, py: 1 }} variant="save" color="primary" onClick={() => formik.handleSubmit()} />
                        :
                        (user?.settings.create_customers || user?.role == 'ADMIN') &&
                        <FButton title={t("Customer.AddCust")} sx={{ borderRadius: 50, py: 1 }} variant="save" color="primary" onClick={() => formik.handleSubmit()} />

                )}

            </Stack>


            <Stack
                spacing={1}
                sx={{
                    bgcolor: "#fff",
                    borderRadius: "25px",
                    py: 6,
                    px: 18,
                    scrollbarWidth: "none",
                    overflowX: "hidden",
                }}
            >

                <FPrimaryHeading fontColor='#545454' fontSize='22px' text={t("Customer.CustomerInformation")} />

                <form style={{ width: '100%' }} onSubmit={(e) => { e.preventDefault() }}>
                    <Grid2 container spacing={2} columnSpacing={20} marginTop={5} paddingBottom={2}>
                        <Grid2 sx={{ display: 'flex', flexDirection: 'column', gap: 3 }} item size={{ xs: 12, md: 6 }} >
                            <Stack sx={{ width: 'full', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>{t("Customer.CustomerName")}</Typography>
                                <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                    <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>
                                        <FTextInput
                                            disabled={isEdit && !havePermission}
                                            onBlur={formik.handleBlur}
                                            mt={1}
                                            name="name"
                                            placeholder={t("Customer.CustomerName")}
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            fontColor='#545454'
                                        />
                                        {formik.touched.name && formik.errors.name &&
                                            <Typography variant='caption' color='red'>
                                                {formik.errors.name}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack sx={{ width: 'full', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>{t("Common.Email")}</Typography>
                                <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                    <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>
                                        <FTextInput
                                            disabled={isEdit && !havePermission}
                                            onBlur={formik.handleBlur}
                                            mt={1}
                                            name="email"
                                            placeholder={t("Common.Email")}
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            fontColor='#545454'
                                        />
                                        {formik.touched.email && formik.errors.email &&
                                            <Typography variant='caption' color='red'>
                                                {formik.errors.email}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack sx={{ width: 'full', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>{t("Common.Phone")}</Typography>
                                <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                    <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>
                                        <CustomTextField
                                            disabled={isEdit && !havePermission}
                                            id="phone_number"
                                            value={
                                                formik.values.phone_number
                                                    ? formatPhoneNumber(formik.values.phone_number)
                                                    : ""
                                            }
                                            mt={1}
                                            // maxLength={8}
                                            inputProps={{ maxLength: 11 }}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, ''); // This ensures only numbers are allowed
                                                formik.setFieldValue("phone_number", value);
                                            }}
                                            placeholder={t("Common.MobileNumber")}
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography sx={{ color: "#1f1f1f" }}>
                                                                +45
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                },
                                            }}
                                            onBlur={() =>
                                                formik.setFieldTouched("phone_number", true)
                                            }
                                        />
                                        {formik.touched.phone_number && formik.errors.phone_number &&
                                            <Typography variant='caption' color='red'>
                                                {formik.errors.phone_number}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack sx={{ width: 'full', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>{t("Common.AlternativePhoneNumber")} ({t("Common.Optional")})</Typography>
                                <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                    <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>
                                        <CustomTextField
                                            disabled={isEdit && !havePermission}
                                            id="phone_number2"
                                            value={
                                                formik.values.phone_number2
                                                    ? formatPhoneNumber(formik.values.phone_number2)
                                                    : ""
                                            }
                                            mt={1}
                                            // maxLength={8}
                                            inputProps={{ maxLength: 11 }}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, ''); // This ensures only numbers are allowed
                                                formik.setFieldValue("phone_number2", value);
                                            }}
                                            placeholder={t("Common.MobileNumber")}
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography sx={{ color: "#1f1f1f" }}>
                                                                +45
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                },
                                            }}
                                            onBlur={() =>
                                                formik.setFieldTouched("phone_number2", true)
                                            }
                                        />
                                        {formik.touched.phone_number2 && formik.errors.phone_number2 &&
                                            <Typography variant='caption' color='red'>
                                                {formik.errors.phone_number2}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack sx={{ width: 'full', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>{t("Common.Birthday")} </Typography>
                                <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                    <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>


                                        <Stack width={'100%'} gap={2} alignItems={'center'} flexDirection={'row'}>


                                            <FSelect
                                                disabled={isEdit && !havePermission}
                                                onBlur={formik.handleBlur}
                                                value={selectedDate.day}
                                                placeholderText={"DD"}
                                                sx={{ width: '30%' }}
                                                onChange={(e) => handleSelectDate({ date: e.target.value, type: 'day' })}
                                                options={mapOptions(dateObject.dates,)}
                                            />

                                            <FSelect
                                                disabled={isEdit && !havePermission}
                                                value={selectedDate.month}
                                                placeholderText={"MM"}
                                                sx={{ width: '30%' }}
                                                onChange={(e) => handleSelectDate({ date: e.target.value, type: 'month' })}
                                                // disabled={!selectedDate.day}
                                                options={mapOptions(
                                                    dateObject.months,
                                                    getDisabledMonths()
                                                )}
                                            />



                                            <FSelect
                                                disabled={isEdit && !havePermission}
                                                value={selectedDate.year}
                                                sx={{ width: '40%' }}
                                                placeholderText={"YYYY"}
                                                onChange={(e) => handleSelectDate({ date: e.target.value, type: 'year' })}
                                                // disabled={!selectedDate.month}
                                                options={mapOptions(
                                                    dateObject.years,
                                                    getDisabledYears()
                                                )}
                                            />
                                        </Stack>


                                        {formik.touched.birthday && formik.errors.birthday &&
                                            <Typography variant='caption' color='red'>
                                                {formik.errors.birthday}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack sx={{ width: 'full', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}> {t("Common.CPRNumber")}
                                    <Tooltip placement="right" couser title={t("Customer.CprHoverMsg")}>
                                        <img style={{ marginLeft: 5 }} src={LockIcon} alt='lock' height={'15px'} width={'15px'} />
                                    </Tooltip>
                                </Typography>
                                <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                    {/* <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>
                                        <FTextInput
                                            onBlur={formik.handleBlur}
                                            isPrivate={true}
                                            mt={1}
                                            name="cpr"
                                            placeholder="CPR number"
                                            value={formik.values.cpr}
                                            onChange={formik.handleChange}
                                            fontColor='#545454'
                                        />
                                        {formik.touched.cpr && formik.errors.cpr &&
                                            <Typography variant='caption' color='red'>
                                                {formik.errors.cpr}
                                            </Typography>
                                        }
                                    </Stack> */}
                                    <FTextInput
                                        disabled={isEdit && !havePermission}
                                        size="small"
                                        id={'cpr'}
                                        name={'cpr'}
                                        value={formik.values.cpr}
                                        onChange={handleCprChange}
                                        // onChange={(e => formik.setFieldValue('cpr', e.target.value))}
                                        // onBlur={handleBlurCpr}
                                        onBlur={formik.handleBlur}
                                        placeholder="000000-XXXX"
                                        inputProps={{ maxLength: 11 }}
                                        sx={{
                                            width: '100%',
                                            '& input::placeholder': { color: '#747474', fontSize: '1rem', opacity: 1 },
                                            fontSize: '1rem',
                                        }}
                                    />
                                </Stack>
                                <div style={{ zIndex: 50 }}>
                                    {formik.touched.cpr && formik.errors.cpr &&
                                        <Typography variant='caption' color='red'>
                                            {formik.errors.cpr}
                                        </Typography>
                                    }
                                </div>
                            </Stack>


                        </Grid2>

                        <Grid2 sx={{ display: 'flex', flexDirection: 'column', gap: 3 }} item size={{ xs: 12, md: 6 }} >

                            <Stack sx={{ width: 'full', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>{t("Common.Address")}</Typography>
                                <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                    <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>
                                        <FTextInput
                                            disabled={isEdit && !havePermission}
                                            onBlur={formik.handleBlur}
                                            mt={1}
                                            name="address"
                                            placeholder={t("Common.Address")}
                                            value={formik.values.address}
                                            onChange={formik.handleChange}
                                            fontColor='#545454'
                                        />
                                        {formik.touched.address && formik.errors.address &&
                                            <Typography variant='caption' color='red'>
                                                {formik.errors.address}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }} item size={6} >
                                <Stack sx={{ width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>{t("Common.ZipCode")}</Typography>
                                    <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                        <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>
                                            <FTextInput
                                                disabled={isEdit && !havePermission}
                                                onBlur={formik.handleBlur}
                                                mt={1}
                                                name="zip_code"
                                                placeholder={t("Common.ZipCode")}
                                                value={formik.values.zip_code}
                                                onChange={formik.handleChange}
                                                fontColor='#545454'
                                            />
                                            {formik.touched.zip_code && formik.errors.zip_code &&
                                                <Typography variant='caption' color='red'>
                                                    {formik.errors.zip_code}
                                                </Typography>
                                            }
                                        </Stack>
                                    </Stack>
                                </Stack>


                                <Stack sx={{ width: '60%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>{t("Common.City")}</Typography>
                                    <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                        <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>
                                            <FTextInput
                                                disabled={isEdit && !havePermission}
                                                onBlur={formik.handleBlur}
                                                mt={1}
                                                name="city"
                                                placeholder={t("Common.City")}
                                                value={formik.values.city}
                                                onChange={formik.handleChange}
                                                fontColor='#545454'
                                            />
                                            {formik.touched.city && formik.errors.city &&
                                                <Typography variant='caption' color='red'>
                                                    {formik.errors.city}
                                                </Typography>
                                            }
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack sx={{ width: 'full', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f' }}>{t("Customer.CustomerNotes")}</Typography>
                                <Stack width={'100%'} flexDirection={'row'} sx={{ alignItems: 'center' }}>
                                    <Stack flex={1} flexDirection={'column'} justifyContent={'center'}>
                                        {/* <FTextInput
                                            onBlur={formik.handleBlur}
                                                mt={1}
                                                name="marketplace_pointer"
                                                placeholder="Marketplace pointer"
                                                value={formik.values.marketplace_pointer}
                                                onChange={formik.handleChange}
                                                fontColor='#545454'
                                            /> */}

                                        <FTextArea
                                            disabled={isEdit && !havePermission}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.marketplace_pointer}
                                            placeholder={t("Customer.CustomerNotes")}
                                            onChange={formik.handleChange}
                                            name='marketplace_pointer'
                                            showCopyButton={false}
                                            fontColor='#545454'
                                            rows={14}
                                        />

                                        {formik.touched.marketplace_pointer && formik.errors.marketplace_pointer &&
                                            <Typography variant='caption' color='red'>
                                                {formik.errors.marketplace_pointer}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>

                        </Grid2>



                    </Grid2>






                </form>

                <Stack sx={{ mt: 4 }}>
                    <Typography variant='body1' sx={{ fontWeight: 700, color: '#1f1f1f', mb: 2 }}>{t("Customer.UploadImageText")}</Typography>
                    <Button
                        variant="outlined"
                        component="label"
                        sx={{
                            width: '200px',
                            mb: 2,
                            gap: 2,
                            borderRadius: 3,
                            textTransform: 'none',
                            borderColor: '#D9D9D9',
                            color: '#1f1f1f',
                            '&:hover': {
                                backgroundColor: 'rgba(31, 31, 31, 0.04)'
                            }
                        }}
                    >

                        <img src={Upload} alt='upload' style={{ height: '20px', width: '20px' }} />
                        {t("Customer.ChsFiles")}
                        <input
                            type="file"
                            multiple
                            hidden
                            accept="*/*"
                            onChange={handleFileUpload}
                        />
                    </Button>

                    {uploadedFiles.length > 0 && (
                        <TableContainer component={Paper} sx={{ mt: 2, boxShadow: 'none', border: '1px solid #e0e0e0', borderRadius: 4 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 600, color: '#1f1f1f', borderBottom: '1px solid #e0e0e0' }}>
                                            {t("Common.Date")}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 600, color: '#1f1f1f', borderBottom: '1px solid #e0e0e0' }}>
                                            {t("Customer.File")}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 600, color: '#1f1f1f', borderBottom: '1px solid #e0e0e0', width: '100px' }}>
                                            {t("Customer.Action")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ bgcolor: '#fff' }}>
                                    {uploadedFiles.map((file) => (
                                        <TableRow key={file.id}>
                                            <TableCell sx={{ color: '#545454', borderBottom: '1px solid #e0e0e0' }}>
                                                {moment.parseZone(file.denmark_created_at).format("YYYY-MM-DD HH:mm")}
                                            </TableCell>
                                            <TableCell sx={{ color: '#545454', borderBottom: '1px solid #e0e0e0' }}>
                                                <Link
                                                    to={`${process.env.REACT_APP_IMG_URL}${file.url}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        color: 'blue',
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    {file?.file_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid #e0e0e0' }}>
                                                <IconButton
                                                    onClick={() => handleRemoveFile(file.id)}
                                                    color="error"
                                                    size="small"
                                                >
                                                    <img src={Delete} alt="delete" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Stack>

                {/* </Formik> */}
            </Stack>
            {(isEdit && (user?.settings.delete_customers || user?.role == "ADMIN")) && <FButton title={<Typography noWrap fontWeight={700} >{t("Customer.DelCust")}</Typography>} sx={{ ml: 'auto', width: '15%', borderRadius: 50, py: 1 }} variant="delete" color="primary" onClick={() => { setDeleteCustomerModel(true) }} disabled={(customer?.id === 'create')} />}

            {deleteCustomerModel &&
                <CustomDeleteModal
                    open={deleteCustomerModel}
                    handleClose={() => setDeleteCustomerModel(false)}
                    title={t("Common.Delete")}
                    description={
                        <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }} >
                            {/* This action is permanent and cannot be reversed.<br />
                            Previous bookings will still be shown */}
                            {t("Customer.DeleteMsg")}
                            {/* <span style={{ marginLeft: 5, color: '#1F1F1F', fontWeight: 'bold', marginRight: 5 }}>
                                    {itemsToDelete?.type == 'container' ? itemsToDelete?.container.title : itemsToDelete?.item.name}
                                </span> */}
                            {/* {itemsToDelete?.type == 'container' ? 'service group': 'service'} */}
                        </Typography>
                    }
                    onClickDismiss={() => setDeleteCustomerModel(false)}
                    onClickConfirm={() => deleteCustomer(customer?.id)}
                />}
        </Stack>
    );
}
export default CustomerInformation;