import { Stack } from '@mui/material'
import React from 'react'
import AppbarComponent from '../../../AppBar'
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import CustomerInformation from '../Customer-Information';

const CreateCustommerForm = () => {
    const location = useLocation();

      let labels = [
       
        t("Customer.CustomerInformation"),
      
      ];

  return (
    <Stack id="top-here" sx={{ maxHeight: "100%", overflowY: "scroll" }}>
    <>
      <AppbarComponent
        labels={
          location?.pathname === "/customers/create"
            ? [t("Customer.CustomerInformation")]
            : labels
        }
        handleClick={() => {}}
      selectedButton={location?.pathname === "/customers/create" ? t("Customer.CustomerInformation") : t("Customer.CustomerInformation")}
        ShowUser={location?.pathname !== "/customers/create"}
      
      />
      <Stack sx={{ px: 4, pb: 4, pt: 0 }}>
       <CustomerInformation/>
      </Stack>
    </>
  </Stack>
  )
}

export default CreateCustommerForm