import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Stack, Typography } from "@mui/material";
import FButton from "../../components/commonComponents/F_Button";
import { t } from "i18next";

const TimeSlotPicker = ({
  availableTimeSlots,
  timeSlots,
  openingHours,
  closingHours,
  selectedTimeSlots,
  setIsSelected,
  handleSelectedTimeSlot,
  isSelected,
  selectedDate,
  isLoading
}) => {



const allowedTimes = [];
for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 15) {
        allowedTimes.push(`${h}:${m.toString().padStart(2, '0')}`);
    }
}

function isAllowedTime(time) {
  return allowedTimes.includes(time);
}


  const groupTimeSlotsByHour = (timeSlots) => {
    return timeSlots.reduce((acc, slot) => {
      const hour = slot.split(":")[0] + ":00"; // Extract hour part
      if (!acc[hour]) acc[hour] = [];
      acc[hour].push(slot);
      return acc;
    }, {});
  };

  useEffect(()=>{
    
    if(selectedDate.isSame(moment(), 'date')){

      const currentTime = moment().format('H:00')
      
      if(currentTime){


        const timeDiv =  document.getElementById(currentTime)
        if(timeDiv){

          timeDiv.scrollIntoView({
            behavior: 'smooth',  // This will make the scrolling smooth
            block: 'start',      // Align the element to the top of the viewport
          });
        }
      }
    }
  },[availableTimeSlots])


  
  

  return (

   
    <Box sx={{ width: "100%", overflowY: "auto", height: "100%", p: 1 }}>

      {isLoading ?
      <Stack flex={1} flexDirection={'column'} minWidth={440}  justifyContent={'center'} alignItems={'center'}  height={200} sx={{scrollbarWidth:'none', overflowX:'hidden'}}>

      <Typography >{t("Common.Loading")} ...</Typography>
      </Stack>
    :  
    
      availableTimeSlots.length > 0 ? (
        Object.entries(groupTimeSlotsByHour(timeSlots)).map(
          ([hour, slots], index) => (
            <Box key={index} sx={{ mt: 2 }}>
              {/* Hour Label */}
              <Typography
                id={hour}
                variant="h6"
                sx={{
                  color: "#1F1F1F",
                  fontWeight: "bold",
                  mb: 1,
                  textAlign: "left",
                }}
              >
                {hour}
              </Typography>
              
              <Box
                sx={{
                  width:"100%",
                  display: "flex",
                  flexWrap: "wrap",
                  gap:1,
                   // Space between slots
                  justifyContent: "flex-start",
                }}
              >
                {slots.map((slot, slotIndex) => {
                  const startTime = slot.split(" - ")[0]; // Extract start time
                  const isStartTimeAvailable = availableTimeSlots.some(
                    (availableSlot) => availableSlot.startsWith(startTime)
                  );

                  // const isOutOfWorkingHours =
                  //   moment(startTime, "HH:mm:ss").isBefore(
                  //     moment(openingHours, "HH:mm:ss")
                  //   ) ||
                  //   moment(startTime, "HH:mm:ss").isSameOrAfter(
                  //     moment(closingHours, "HH:mm:ss")
                  //   );
                  const isOutOfWorkingHours = false

                  let bgColor;

                  if (!isOutOfWorkingHours) {
                    bgColor = isStartTimeAvailable
                      ? "white"
                      : "rgba(199,65,65,50%)"; // Red for unavailable slots
                  } else {
                    bgColor = "rgba(84,84,84,10%)"; // Gray for out-of-working-hours slots
                  }

                  if (selectedTimeSlots.split(" - ")[0] === startTime) {
                    bgColor = "#BBB0A4"; // Brown background for selected slot
                  }

              

                  return (
                    <FButton
                    disabled={isOutOfWorkingHours}
                      key={slotIndex}
                      variant="contained"
                      onClick={() => {
                        if (isSelected === slot) return; // Prevent redundant updates
                        setIsSelected(slot);
                        handleSelectedTimeSlot(slot);
                      }}
                      sx={{
                        width: "15%",
                        height: 48,
                        boxShadow: "none",
                        backgroundColor: (isAllowedTime(slot) && !isOutOfWorkingHours && isStartTimeAvailable && (isSelected!==slot)) ? "#0000000D" : bgColor,
                        borderRadius: "12px",
                        border: "1px solid #ccc",
                         // Added margin between buttons
                        "&:hover": {
                          boxShadow: "none",   
                          cursor: (isOutOfWorkingHours || !isStartTimeAvailable ) ? "not-allowed" : "pointer",
                        },
                      
                       color: !isStartTimeAvailable && !isOutOfWorkingHours ? "white" : isSelected===slot ? "white" :   "black",
                      }}
                       title={<Typography sx={{fontSize:15, fontWeight: 400, width: "15%"}} variant="body1">{slot}</Typography>}
                    />
                
                  );
                })}
              </Box>
            </Box>
          )
        )
      ) : (
        <Typography>{t("Calendar.NoSlotsMsg")}</Typography>
      )}
    
    
    
    </Box>
  );
};

export default TimeSlotPicker;

;
