import React, { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import FSelect from '../../../../commonComponents/F_Select'
import ReactQuill from 'react-quill'
import '../../../../../cssQuill.css'


const modules = {
  toolbar: [
    [{ 'font': ['Arial', 'sans-serif'] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'align': [] }],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

const formats = [
  'font', 'size', 'bold', 'italic', 'underline', 'strike',
  'color', 'background', 'blockquote', 'code-block',
  'list', 'script', 'indent', 'direction', 'align',
  'link', 'image', 'video'
];


export default function DynamicEditor({ formik, field, name = 'editor', TitleName, templates, templateValues }) {


  const [selectedTemplte, setSelectedTemplate] = useState({})

  // useEffect(() => {
  //   if (templateValues?.template_id && templates) {
  //     handleTemplateChange(templateValues?.template_id)
  //   }
  // }, [])

  const handleTemplateChange = (id) => {

    let temp = templates.find((temp) => temp.id === id)
    setSelectedTemplate(temp)
    formik.setFieldValue(name, temp?.detail)
  }

  return (
    <Stack m={0}>
      <Typography fontWeight={700} variant='body1'>{field?.name}</Typography>
      <Stack ml={2} border={'1px solid #D9D9D9'} mt={1} borderRadius={2}>
        {/* <FTextInput sx={{ border : 'none', borderRadius : '0', width: "100%" , boxShadow : 'none' }} disabled={formik?.values?.disabled} onBlur={formik.handleBlur} value={formik?.values?.[TitleName]} placeholder={t("Customer.NameOfJournal")} onChange={(e) => formik.setFieldValue(TitleName, e.target.value)} name={TitleName} />
        {formik?.touched?.[TitleName] && formik?.errors?.[TitleName] && <Typography style={{ color: 'red' }}>{formik?.errors?.[TitleName]}</Typography>} */}
        <Stack display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'} p={2} >
          <FSelect
            disabled={formik?.values?.disabled}
            value={selectedTemplte?.id}
            onChange={(e) => { handleTemplateChange(e.target.value) }}
            defaultValue={templateValues?.template_id}
            options={templates && templates.map((temp) => ({ value: temp.id, label: temp.name }))}
          />


        </Stack>
        <ReactQuill
          readOnly={formik?.values?.disabled}
          disabled={formik?.values?.disabled}
          style={{ borderRadius: "none" }}
          value={formik?.values?.[name]}
          onChange={(content) => { formik.setFieldValue(name, content) }}
          modules={modules}
          formats={formats}
        // className="custom-quill"
        />
      </Stack>
      {formik?.touched?.[name] && formik?.errors?.[name] && <Typography style={{ color: 'red' }}>{formik?.errors?.[name]}</Typography>}
    </Stack>
  )
}
