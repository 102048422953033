import { Box, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryHeading from "../../commonPrimaryHeading";
import SecondaryHeading from "../../commonSecondaryHeading";
import CalendarComponent from "./calander";
import Shifter from "./calander/Shifter";
import { getCurrentWeekInfo } from "../utils/Functions";
import moment from "moment";
import FormModel from "./calander/popup/FormModel";
import FSwitch from "../../../commonComponents/f-switch";
import { useSelector } from "react-redux";

import { t } from "i18next";
import CustomDeleteModal from "../../../deleteAlertModal";
import { SequenceManager } from "../utils/NewLogic";


export default function IndividualOpeningHours({ data, setData, handleSave }) {
    const user = useSelector((state) => state.user.data);

    // dont change it otherwise it will not compare clots and day names
    moment.locale("en");
    moment.updateLocale("en", { week: { dow: 1 } });



    const { employees_opening_hour, is_individual_opening_hour } = data

    const [controller, setController] = useState({
        date: moment(),
        format: "all",
    });


    const localeValue = localStorage.getItem('language')


    const [showCalander, setShowCalander] = useState(false);
    const [openFormModel, setOpenFormModel] = useState(false);
    const [formProps, setFormProps] = useState({});
    const [selectedLanguage, setSelectedLanguage] = useState('da')
    const [openDeleteModal, setOpenDeleteModal] = useState(false)


    useEffect(() => {
        if (localeValue) {

            setSelectedLanguage(localeValue)
        }

    }, [localeValue])

    useEffect(() => {
        const currentWeekInfo = getCurrentWeekInfo(controller.date);


        setController((prev) => ({
            ...prev,
            weekNumber: currentWeekInfo?.weekNumber,
            weekDates: currentWeekInfo?.weekDates,
            weekArray: currentWeekInfo?.weekArray
        }))
    }, [controller.date]);


    useEffect(() => {

        setShowCalander(is_individual_opening_hour)

    }, [is_individual_opening_hour])

    const isDisable = ({ id = 0, allowToAllOnly = false }) => {


        if (user?.role == "ADMIN") {
            return false
        } else {
            if (user?.settings?.change_all_opening_hours) {
                return false
            } else {
                if (allowToAllOnly) {
                    return true
                }
                if (user?.settings?.change_own_opening_hours && user?.id === id) {
                    return false
                }
                return true
            }
        }

    }

    // useEffect(()=>{
    //     if(employees_opening_hour !== undefined){

    //         // const {employees_opening_hour} = data
    //         const isEnable = employees_opening_hour.find((empObj)=>empObj.detail.length > 0)
    //         setShowCalander(isEnable)
    //     }

    // },[employees_opening_hour])


    // const updateEvent = (updatedEvent) => {

    //     const { finalValues, detailId, employee } = updatedEvent
    //     let { employees_opening_hour } = data

    //     // const foundEmployee = employees_opening_hour.map((empObj) => {


    //     //     // if (empObj.id == finalValues.employee_id) {
    //     //     //     if (detailId) {
    //     //     //         const detailIndex = empObj.detail.findIndex((detailObj) => detailObj.id == detailId)
    //     //     //         let newDetail = [...empObj.detail]
    //     //     //         if (detailIndex != -1) {

    //     //     //             newDetail[detailIndex] = finalValues
    //     //     //             return {
    //     //     //                 ...empObj,
    //     //     //                 detail: newDetail
    //     //     //             }
    //     //     //         } else {
    //     //     //             return {
    //     //     //                 ...empObj,
    //     //     //                 detail: [...empObj.detail, finalValues]
    //     //     //             }
    //     //     //         }

    //     //     //     } else {

    //     //     //         return {
    //     //     //             ...empObj,
    //     //     //             detail: [...empObj.detail, finalValues]
    //     //     //         }
    //     //     //     }


    //     //     // } else {
    //     //     //     if (detailId) {


    //     //     //         // change employee 
    //     //     //         if (employee.id == empObj.id) {
    //     //     //             const newDetail = employee.detail.filter((detailObj) => detailObj.id !== detailId)


    //     //     //             return {
    //     //     //                 ...empObj,
    //     //     //                 detail: newDetail
    //     //     //             }
    //     //     //             // filter existing details of employee   
    //     //     //         } else {
    //     //     //             return empObj
    //     //     //         }
    //     //     //     } else {

    //     //     //         return empObj
    //     //     //     }
    //     //     // }
    //     // })


    //     const updatedEmployees = employees_opening_hour.map((empObj) => {
    //         if (Array.isArray(finalValues)) {
    //             return finalValues.reduce((acc, item) => {
    //                 if (empObj.id === item.employee_id) {

    //                     const detailIndex = empObj?.detail?.findIndex((detailObj) => detailObj?.id == detailId);

    //                     let newDetail = [...empObj.detail];

    //                     if (detailId) {
    //                         if (detailIndex !== -1) {
    //                             newDetail[detailIndex] = item;
    //                         } else {
    //                             newDetail.push(item);
    //                         }
    //                     } else {
    //                         newDetail.push(item);
    //                     }

    //                     return { ...empObj, detail: newDetail };
    //                 } else {
    //                     if (detailId) {
    //                         const newDetail = empObj.detail.filter((detailObj) => detailObj.id !== detailId);
    //                         return { ...empObj, detail: newDetail };
    //                     } else {
    //                         return acc;
    //                     }
    //                 }
    //             }, empObj);
    //         }
    //         return empObj;
    //     });


    //     setData({
    //         ...data,
    //         employees_opening_hour: updatedEmployees,
    //         is_individual_opening_hour: showCalander

    //     })




    // }

    // const handleRemove = (event) => {



    //     let newEmployee = {
    //         ...formProps?.employee,
    //         detail: formProps?.employee?.detail.filter((item) => item.id !== event?.id)
    //     };



    //     setData(prev => ({
    //         ...prev,
    //         employees_opening_hour: prev?.employees_opening_hour?.map((item) => {
    //             if (item.id === formProps?.employee?.id) {
    //                 return newEmployee;
    //             } else {
    //                 return item;
    //             }
    //         })
    //     }));


    //     setOpenFormModel(false)
    // };

    // const handleOffDayAdd = ({ event, date }) => {
    //     let newEmployee = {
    //         ...formProps?.employee,
    //         detail: formProps?.employee?.detail.map((item) => ({
    //             ...item,
    //             off_days: item?.off_days ? [...item?.off_days, date] : [date]
    //         }))
    //     };

    //     setData(prev => ({
    //         ...prev,
    //         employees_opening_hour: prev?.employees_opening_hour?.map((item) => {
    //             if (item.id === formProps?.employee?.id) {
    //                 return newEmployee;
    //             } else {
    //                 return item;
    //             }
    //         })
    //     }))
    // }

    const handleOffDayRemove = () => {

        let newEmployee = formProps?.SequenceManager?.removeHoliday();

        // setData(prev => ({
        //     ...prev,
        //     employees_opening_hour: prev?.employees_opening_hour?.map((item) => {
        //         if (item.id === formProps?.employee?.id) {
        //             return newEmployee;
        //         } else {
        //             return item;
        //         }
        //     })
        // }))

        let finalVals = {
            ...data,
            employees_opening_hour: data?.employees_opening_hour?.map((item) => {
                if (item.id === formProps?.employee?.id) {
                    return newEmployee;
                } else {
                    return item;
                }
            })
        }

        handleSave({ changes : finalVals})

    }

    const changeIndividualOpeningHour = (e) => {
        setShowCalander(e.target.checked)

        setData({
            ...data,
            is_individual_opening_hour: e.target.checked
        })
    }



    return (
        <Box sx={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "start", pb: 10, mt: 0, pl: 4, pr: 4 }}>
            <Box sx={{ width: "33%", display: "flex", flexDirection: "column", justifyContent: "between", alignItems: "start", pr: 3 }}>
                <PrimaryHeading text={t("Setting.IndividualOpeningHours")} />
                <SecondaryHeading text={t("Setting.DescriptionOfIndividualOpeningHours")} />
            </Box>



            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "67%", overflow: 'hidden' }}>

                {(user?.role === "ADMIN" || (user?.settings.change_own_opening_hours || user?.settings.change_all_opening_hours)) && <>
                    <Stack display={"flex"} width={"100%"} flexDirection={"column"} justifyContent={"start"} alignItems={"start"} >



                        <Typography variant='body1' sx={{ fontWeight: 700, color: '#1F1F1F' }}>
                            {t("Setting.IndividualOpeningHours")}
                        </Typography>

                        <FSwitch
                            sx={{ mx: 0 }}
                            disabled={(user?.role !== "ADMIN" && isDisable({ id: user?.id, allowToAllOnly: true }))}
                            checked={showCalander}
                            onChange={(e) => changeIndividualOpeningHour(e)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </Stack>
                </>}
                {showCalander &&
                    <React.Fragment >
                        <Shifter controller={controller} setController={setController} />
                        <Stack position={"relative"}>
                            <CalendarComponent data={data} controller={controller} selectedLanguage={selectedLanguage} setFormProps={setFormProps} setOpenFormModel={(item) => {

                                const { openModel, formProps, openDeleteModal } = item
                                const sequence = new SequenceManager({ ...formProps, employees_opening_hour: data?.employees_opening_hour })

                                setFormProps({ ...formProps, SequenceManager: sequence })
                                setOpenFormModel(openModel)
                                setOpenDeleteModal(openDeleteModal)
                            }} />
                        </Stack>

                        {/* <Stack mt={10} display={"flex"} width={"100%"} flexDirection={"column"} justifyContent={"start"} alignItems={"start"} mr={'auto'}>
                            <HolidaysList data={data.employees_opening_hour} setData={setData} />
                        </Stack> */}
                    </React.Fragment>
                }

            </Box>

            {openFormModel && <FormModel
                formProps={formProps}
                // setData={(eventObj) => updateEvent(eventObj)}
                StateData={data}
                // updateState={setData}
                handleSave={handleSave}
                // handleRemove={handleRemove}
                // handleOffDayAdd={handleOffDayAdd}
                open={openFormModel}
                onClose={() => setOpenFormModel(false)} />}


            {openDeleteModal &&
                <CustomDeleteModal
                    open={openDeleteModal}
                    handleClose={() => { setOpenDeleteModal(false) }}
                    title={t("Setting.DelHoliday")}
                    description={t("Setting.DelHolidayDesc")}
                    onClickDismiss={() => { setOpenDeleteModal(false) }}
                    onClickConfirm={() => {
                        handleOffDayRemove()
                        setOpenDeleteModal(false)
                    }}
                />}

        </Box>
    );
}
