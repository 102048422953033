import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";
import PermissionSlice from "./permissionSlice";
import SettingsSlice from "./settingsSlice";
import RouteSlice from "./routeSlice";

// Configuration for redux-persist
const persistConfig = {
    key: "root",
    storage,
};

const settingPersistConfig = {
    key: "settings",
    storage,
};

const persistedReducer = persistReducer(persistConfig, PermissionSlice);
const persistedSettingsReducer = persistReducer(settingPersistConfig, SettingsSlice);
const persistedRouteReducer = persistReducer(settingPersistConfig, RouteSlice);

const store = configureStore({
    reducer: {
        user: persistedReducer,
        settings: persistedSettingsReducer,
        route:persistedRouteReducer
    },
});

export const persistor = persistStore(store);
export default store;
