import React, { useState, useEffect, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";


import "../../index.css";
import NewBookingForm from "./NewBookingForm";
import "moment/locale/nb"; // Import Norwegian Bokmål locale

import NewBookingDetailModal from "./NewBookingDetailModal";
import "moment/locale/da"

import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/en-gb';
import { CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import apiFetcher from "../../utils/interCeptor";
import { isEmpAvailable, isEmpAvailableOnSpecificSlot } from '../../components/settings/opningHours/utils/Functions';
import CustomToolBar from "./CustomToolBar"
import { useDispatch } from "react-redux";
import { settings } from "../../context/settingsSlice";
import { t } from "i18next";

import { useSocket } from "../../context/SocketContext";
import { formatPhoneNumber } from "../../components/calanderComponents/booking/utils/functions";
import Reschedule from "../../components/calanderPopups/Reschedule";
import ImportantNoteIcon from '../../assets/ImportantNoteIcon.svg';
import { HttpStatusCode } from "axios";

let emIDs = 0;
let stw = moment().startOf("week").format("YYYY-MM-DD");
let enw = moment().endOf("week").format("YYYY-MM-DD");

const DnDCalendar = withDragAndDrop(Calendar);


dayjs.extend(isoWeek);
dayjs.locale('da');
moment.locale("da"); // Set the global locale to Norwegian Bokmål
moment.updateLocale("da", { week: { dow: 1 } }); // Ensure the week starts on Monday
const localizer = momentLocalizer(moment);


export const reasons = {
  "Private": t("Calendar.private"),
  "Sick leave": t("Calendar.sickLeave"),
  "Lunch break": t("Calendar.lunchBreak"),
  "Vacation": t("Calendar.vacation"),
  "Time off": t("Calendar.timeOff"),
  "Course training": t("Calendar.courseTraining"),
  "School": t("Calendar.school"),
  "ChildSickLeave": t("Calendar.childSickLeave"),
  "Parental leave": t("Calendar.parentalLeave")
}

const CustomCalendar = () => {


  const [showForm, setShowForm] = useState({ booking: false, detail: false, reschedule: false });
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [employeeId, setEmployeeId] = useState(localStorage.getItem("employee_id"));
  const [view, setView] = useState("week");
  const [employees, setEmployees] = useState([]);
  const [showAllEmployeesCalendar, setShowAllEmployeesCalendar] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [lastValidWeek, setLastValidWeek] = useState(null);
  const [empavailability, setEmpAvailability] = useState([])
  const [timeSlotHeight, setTimeSlotHeight] = useState('50px');
  const [loading, setLoading] = useState(true)
  const [rescheduleProps, setRescheduleProps] = useState(null)
  const [createEventObj, setCreateEventObj] = useState(null)
  const [pauseProps, setPauseProps] = useState(null)
  const [scheduleChange, setScheduleChange] = useState()

  const [tempId, setTempId] = useState(null)



  const settingsSelector = useSelector((state) => state.settings);
  const dispatch = useDispatch()
  const { data } = settingsSelector

  const apiUrl = process.env.REACT_APP_URL;
  const user = useSelector((state) => state.user.data);
  const socket = useSocket();
  const selectedLanguage = localStorage.getItem('language')


  useEffect(() => {
    if (employeeId) {
      emIDs = employeeId
      stw = moment(selectedDate).startOf("week").format("YYYY-MM-DD");
      enw = moment(selectedDate).endOf("week").format("YYYY-MM-DD");

    }
  }, [employeeId, selectedDate])


  useEffect(() => {
    if (socket) {
      socket.on("bookings_updated", () => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchBookings(signal, stw, enw, emIDs);
        return () => controller.abort();
      })
    }
  }, [socket])






  const [calendar, setCalendar] = useState({
    setCalendarOpeningHour: 240,
    setCalendarClosingHour: 240,
    grayOutClosedHours: false,
    showOnlyAvailableEmployee: false,
    calendarInterval: 15
  })


  useEffect(() => {

    if (data?.calendar) {
      const retValue = data?.calendar
      setCalendar(retValue)
    }

  }, [data?.calendar])


  useEffect(() => {
    if (calendar) {

      const { calendarInterval } = calendar

      let slotHeightsinPixel = '50px';
      if (calendarInterval == 5) {
        slotHeightsinPixel = '15px';
      } else if (calendarInterval == 10) {
        slotHeightsinPixel = '25px';
      } else if (calendarInterval == 15) {
        slotHeightsinPixel = '35px';
      } else if (calendarInterval == 30) {
        slotHeightsinPixel = '55px';
      } else if (calendarInterval == 60) {
        slotHeightsinPixel = '120px';
      }

      setTimeSlotHeight(slotHeightsinPixel);
    }
  }, [calendar])


  const handleEventClick = (event) => {

    if (event.type === "CALENDAR_PAUSE") {
      setPauseProps(event)
      setShowForm((prev) => ({ ...prev, booking: true }));
    } else {
      setSelectedBooking(event?.id)
      setShowForm((prev) => ({ ...prev, detail: true }));
    }

  };

  useEffect(() => {

    fetchSettingsNew()
    const selectedEmpId = localStorage.getItem("employee_id")

    setEmployeeId(selectedEmpId)
    setSelectedDate(dayjs());

  }, []);

  useEffect(() => {
    fetchBookings();
  }, [selectedDate, view, employeeId]);


  useEffect(() => {
    if (showAllEmployeesCalendar) {
      if (calendar?.showOnlyAvailableEmployee) {
        checkAvailability(selectedDate, view)
      }
    } else {
      setEmpAvailability(employees)
    }

  }, [showAllEmployeesCalendar, selectedDate, calendar, employees])

  const fetchBookings = async (signal, stw, enw, emIds) => {

    try {

      const response = await apiFetcher.get(`/api/v1/store/booking`,
        {
          params: {
            date: stw ? stw : moment(selectedDate).startOf("week").format("YYYY-MM-DD"),
            to_date: enw ? enw : moment(selectedDate).endOf("week").format("YYYY-MM-DD"),
            limit: 100,
            offset: 0,
            booking_type: "All",
            filter_equipment_bookings: false,
            employee_id: emIds ? emIds : employeeId,
            include_calendar_pauses: true
          },
        },
        signal
      );

      if (response.data.success) {
        let bookings = response.data.data.map((booking) => ({
          id: booking.id,
          title: booking.booking_details.service_name,
          start: moment(booking.booking_datetime_start).toDate(),
          end: moment(booking.booking_datetime_end).toDate(),
          status: booking.status,
          resourceId: booking.employee_id,
          customerName: booking.booking_details.customer_name,
          customerPhone: booking.booking_details.customer_phone_number,
          customerNote: booking.booking_details.note,
          employeeName: booking.booking_details.employee_name,
          serviceDuration: booking.booking_details.duration_text,
          createdDate: booking.created_at,
          servicePrice: booking.booking_details.price,
          serviceType: booking.booking_details.service_type,
          serviceId: booking.service_id, // Now correctly retrieving serviceId
          employeeId: booking.employee_id,
          outlet_customer_note: booking.booking_details.outlet_customer_note,
          type: "BOOKING"
        }

        ));

        const calendarPause = response.data.calendar_pauses.map((pauseEvent) => ({
          id: pauseEvent.id,
          title: reasons[pauseEvent.reason],
          reason: pauseEvent.reason,
          headline: pauseEvent.headline,
          description: pauseEvent.description,
          start: moment(pauseEvent.datetime_start).toDate(),
          end: moment(pauseEvent.datetime_end).toDate(),
          status: null,
          resourceId: pauseEvent.employee_id,
          customerName: '',
          customerPhone: '',
          customerNote: '',
          employeeName: '',
          serviceDuration: '',
          createdDate: pauseEvent.created_at,
          servicePrice: 0,
          serviceType: '',
          serviceId: null, // Now correctly retrieving serviceId
          employeeId: pauseEvent.employee_id,
          type: 'CALENDAR_PAUSE',

        }))
        setEvents([...bookings, ...calendarPause]);

        // setEvents(bookings);
        // setDuration(bookings.serviceDuration);
      }
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  const fetchSettingsNew = async () => {
    try {
      const res = await apiFetcher.get(
        "/api/v1/store/profile/info"
      );

      let settingsObj = {
        'OnlineBooking': null,
        'calendar': {
          setCalendarOpeningHour: 240,
          setCalendarClosingHour: 240,
          grayOutClosedHours: false,
          showOnlyAvailableEmployee: false,
          calendarInterval: 15
        },
        outlet_holidays: [],
        schedule: [],
        is_individual_opening_hour: false,
        employees_opening_hour: [],
      }

      const { settings: settingList, schedules, holidays, employees_opening_hour, is_individual_opening_hour } = res.data.data


      settingList.map((settingObj) => {
        if (settingObj.settingName == 'OnlineBooking') {
          settingsObj['OnlineBooking'] = settingObj?.value ? JSON.parse(settingObj?.value) : null
        }
        if (settingObj.settingName == 'calendar') {
          settingsObj['calendar'] = settingObj?.value ? JSON.parse(settingObj?.value) : null

        }

      })

      settingsObj.outlet_holidays = holidays
      settingsObj.schedule = schedules
      settingsObj.is_individual_opening_hour = is_individual_opening_hour
      settingsObj.employees_opening_hour = employees_opening_hour






      let employeesList = []

      employees_opening_hour.map((empObj) => {


        employeesList.push({
          id: empObj?.id, name: empObj?.name,
          label: empObj?.name, value: empObj?.id,
          title: empObj?.name ?? 'Unnamed Employee',
          availability: is_individual_opening_hour ? (empObj.detail.length ? true : false) : true,
          //  availabilityDay: empObj.detail.length ? empObj.detail[0]: null})
          availabilityDay: is_individual_opening_hour ? (empObj.detail.length ? empObj.detail : null) : null
        })
      })

      employeesList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      setEmployees(employeesList)
      dispatch(settings(settingsObj))
      setLoading(false)


    } catch (error) {
      setLoading(false)
      console.error("error", error);
    }
  };



  const checkAvailability = (currentDate, view) => {
    moment.locale('en-gb')
    let availableEmployees = []
    employees.map((empObj) => {
      if (empObj?.availability) {

        // it means individual opining hour is false
        if (empObj?.availabilityDay == null) {
          availableEmployees.push(empObj)
          return null
        }

        let cDate = currentDate
        if (dayjs.isDayjs(currentDate)) {
          cDate = moment(currentDate.toDate())

        } else {
          cDate = moment(currentDate)
        }

        const available = isEmpAvailable(empObj, cDate)
        if (available) {

          availableEmployees.push(empObj)
        }
      }
    })

    setEmpAvailability(availableEmployees)

  }


  const isSlotAvailable = (currentSlot, resourceId) => {

    const { is_individual_opening_hour, employees_opening_hour } = data
    // if individual opening hour is false then all slots will be available
    // so no need to check individual employee opening hour
    if (!is_individual_opening_hour) {
      return true
    }


    // if there is no resourceId then it means it is day selected

    let selectedEmpId = employeeId
    if (resourceId) {
      selectedEmpId = resourceId
    }

    let selectedEmployeeHours = employees_opening_hour.find((empObj) => empObj.id == selectedEmpId)
    if (selectedEmployeeHours) {

      return isEmpAvailableOnSpecificSlot(selectedEmployeeHours, moment(currentSlot))

    } else {
      return false
    }


  }

  const openForm = () => {
    setCreateEventObj(null)
    setShowForm((prev) => ({ ...prev, booking: true }))
  };
  const closeForm = () => {
    setRescheduleProps(null)
    setShowForm((prev) => ({ ...prev, booking: false }));
    setPauseProps(null);
    setSelectedBooking(null);
    setTempId(null);
    setEvents((prev) => prev.filter((item) => item?.id !== tempId));
  }


  const closeReschedule = () => {
    setShowForm((prev) => ({ ...prev, reschedule: false }));
    setScheduleChange(null);
  }

  const scrollToTime = dayjs()
    .set('hour', dayjs().hour() - 1)
    .set('minute', dayjs().minute() - 30);

  const handleEmployeeSelection = (id) => {
    setEmployeeId(id);
    setShowAllEmployeesCalendar(id === "0");
  };

  const formats = {

    dayFormat: (date, culture, localizer) => {
      if (employeeId === "0") {
        return employees
          .map((employee) => employee.name || "No Name")
          .join("\n");
      } else {
        return (
          localizer.format(date, "dddd", culture).charAt(0).toUpperCase() +
          localizer.format(date, "dddd", culture).slice(1) +
          `\n${localizer.format(date, "DD/MM", culture)}`
        );
      }
    },
    selectRangeFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'HH:mm', culture) +
      ' - ' +
      localizer.format(end, 'HH:mm', culture),
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'HH:mm', culture) +
      ' - ' +
      localizer.format(end, 'HH:mm', culture),

    timeGutterFormat: 'HH:mm',
  };

  useEffect(() => {
    setView(showAllEmployeesCalendar ? 'day' : 'week');
  }, [showAllEmployeesCalendar]);

  const refreshBookings = () => {
    fetchBookings();
  };
  const isToday = moment(selectedDate).isSame(dayjs(), "day");

  const updateTimeIndicator = (view) => {
    const timeIndicator = document.querySelector('.rbc-current-time-indicator');

    if (timeIndicator) {

      const nDayOfWeek = dayjs().day();

      let nOfEmployee = employees.length;
      if (employeeId == 0) {
        nOfEmployee = empavailability.length
      }
      let left;
      let width;

      if (view == 'day') {
        left = 0;
        width = 100;

        if (employeeId == 0) {

          if (nOfEmployee > 0) {
            width = nOfEmployee * 100;
          }
        }
      }
      else {
        if (nDayOfWeek == 0) {
          left = -600
        } else {

          left = (nDayOfWeek - 1) * -100;
        }
        width = 700;
      }


      timeIndicator.style.setProperty('--width', `${width}%`);
      timeIndicator.style.setProperty('--left', `${left}%`);
      timeIndicator.style.setProperty('--display', `flex`);

    }
  };

  const CustomTimeHeader = ({ date, culture, localizer }) => {
    const validDate = date;

    if (validDate === undefined) {
      setCurrentWeek(lastValidWeek);
    }
    else {
      const weekNumber = getWeekNumber(validDate);
      setCurrentWeek(weekNumber);
      setLastValidWeek(weekNumber);
    }

    return (
      <div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4px' }}>
          {t("Common.Week")}
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 700 }}>{currentWeek ?? moment().isoWeek()}</div>
      </div>
    );
  };

  // Function to calculate the week number
  const getWeekNumber = (date) => {
    return moment(date).isoWeek();
  };

  function isEventOverlapping(eventsList, newEvent) {

    let events = [...eventsList]

    const newStart = moment(newEvent.start);
    const newEnd = moment(newEvent.end);

    let updatedEvents = []

    for (const event of events) {

      const start = moment(event.start);
      const end = moment(event.end);

      // Check if new event overlaps with the current event
      if ((event.id !== newEvent?.event?.id) && newStart.isBefore(end) && newEnd.isAfter(start)) {
        return { isOverlap: true, updatedEvents }; // Overlap found
      } else {

        if (event.id === newEvent?.event?.id) {
          updatedEvents.push({ ...event, start: newEvent.start, end: newEvent.end })
          // event.start = start;
          // event.end = end;
        } else {
          updatedEvents.push(event)
        }
      }
    }

    return { isOverlap: false, updatedEvents }; // Overlap found
  }


  const allowReschdule = (eventData) => {

    if (eventData) {


      if (!user?.settings.reschedule_all_bookings &&
        (user?.id != eventData.employeeId) && (user?.role != "ADMIN")) {
        toast.error(t("Calendar.ToastErrPermission"), {
          toastId: "customId"
        })
        return false
      } else {

        if ((!user?.settings.reschedule_own_bookings &&
          (user?.id == eventData.employeeId) && (user?.role != "ADMIN"))) {
          toast.error(t("Calendar.ToastErrPermission"), {
            toastId: "customId"
          })
          return false
        }
      }
    }

    return true

  }

  const calendartOpeningHour = useMemo(() => {

    let momentStartTime = null
    let momentEndTime = null

    const { schedule, outlet_holidays, calendar } = data

    const { setCalendarOpeningHour, setCalendarClosingHour } = calendar

    if (!schedule.length) {
      momentStartTime = moment().startOf('day')
      momentEndTime = moment().endOf('day')
      return { momentStartTime, momentEndTime }

    }

    if (view == 'week') {


      schedule.map((dayObj) => {

        if (momentStartTime) {
          if (momentStartTime.isAfter(moment(dayObj.open_time, 'HH:mm:ss'), 'minute')) {
            momentStartTime = moment(dayObj.open_time, 'HH:mm:ss')
          }
        } else {
          momentStartTime = moment(dayObj.open_time, 'HH:mm:ss')
        }

        if (momentEndTime) {
          if (momentEndTime.isBefore(moment(dayObj.close_time, 'HH:mm:ss'), 'minute')) {
            momentEndTime = moment(dayObj.close_time, 'HH:mm:ss')
          }
        } else {
          momentEndTime = moment(dayObj.close_time, 'HH:mm:ss')
        }
      })


    } else {
      let currentDay = moment(selectedDate).locale('en-gb').format('dddd')
      let foundDay = schedule.find((dayObj) => dayObj.day == currentDay)

      if (foundDay) {
        momentStartTime = moment(foundDay.open_time, 'HH:mm:ss')
        momentEndTime = moment(foundDay.close_time, 'HH:mm:ss')

      } else {
        momentStartTime = moment().startOf('day')
        momentEndTime = moment().endOf('day')
      }

    }


    return {
      momentStartTime: momentStartTime && momentStartTime.format('HH:mm:ss') !== '00:00:00'
        ? momentStartTime.subtract(setCalendarOpeningHour, 'minutes')
        : momentStartTime,

      momentEndTime: momentEndTime && momentEndTime.format('HH:mm:ss') !== '23:59:59'
        ? momentEndTime.add(setCalendarClosingHour, 'minutes')
        : momentEndTime

    }
    // return {momentStartTime: momentStartTime, momentEndTime: momentEndTime}
  }, [data, selectedDate, view])


  const rescheduleBooking = async (payload) => {
    try {
      const endpoint = `${apiUrl}/api/v1/store/booking/reschedule`;

      const response = await apiFetcher.post(endpoint, payload);

      if (response.status === HttpStatusCode.Ok) {
        toast.success(t("Calendar.ToastSuccessBookingReschedule"));
      } else {
        toast.error(t("Calendar.ToastErrBookingReschedule"));
      }
      refreshBookings();
    } catch (error) {
      console.error("Error rescheduling booking:", error);
      toast.error(error);
      refreshBookings();
    }
  };

  const reschedulePauseHours = async (eventId, payload) => {
    try {

      const endpoint = `${apiUrl}/api/v1/store/employee/pause-calendar/${eventId}`;
      const response = await apiFetcher.put(endpoint, payload);

      if (response.data.success) {
        toast.success(t("Calendar.ToastSuccessPauseReschedule"));
      } else {
        toast.error(t("Calendar.ToastErrPauseReschedule"));
      }
      refreshBookings();
    } catch (error) {
      console.error(
        "Error rescheduling pause hours:",
        error.response.data.detail
      );
      toast.error(error.response.data.detail);
      refreshBookings();
    }
  };

  const handleEventUpdate = async (eventData, isResizing = false) => {
    const { event, start, end, resourceId, send_email, send_sms } = eventData;

    if (!allowReschdule(event)) {
      return false;
    }

    let newEvents = [...events];
    if (resourceId) {
      newEvents = events.filter((eventObj) => eventObj?.employeeId === resourceId);
    }

    const { isOverlap, updatedEvents } = isEventOverlapping(newEvents, eventData);

    if (isOverlap) {
      toast.error(t("Calendar.ToastErrOverlap"));
      return;
    }

    if (updatedEvents.length > 0) {
      setEvents(updatedEvents);
    }

    const date = moment(eventData?.date ? eventData?.date : start).format("YYYY-MM-DD");
    const timeSlot = `${moment(start).format("HH:mm")} - ${moment(end).format("HH:mm")}`;

    if (event.type !== "BOOKING") {
      const payload = {
        id: event.id,
        employee_id: event.employeeId,
        datetime_start: moment(start).format("YYYY-MM-DD HH:mm:ss"),
        datetime_end: moment(end).format("YYYY-MM-DD HH:mm:ss"),
      };
      reschedulePauseHours(event.id, payload);
      return;
    }

    const payload = {
      id: event.id,
      booking_date: date,
      time_slot: timeSlot,
      employee_id: resourceId || event.employeeId,
      total_amount: event.servicePrice,
      update_duration: isResizing,
      allow_past_date: true,
      send_email: send_email,
      send_sms: send_sms
    };

    rescheduleBooking(payload);
  };


  if (loading) {

    return (
      <Stack sx={{
        position: 'absolute',
        zIndex: 110,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.1)'
      }}>
        <CircularProgress size='2.5rem' sx={{ color: '#6f6f6f' }} />
      </Stack>
    )
  }

  const calendartSettings = (date) => {

    const { schedule, outlet_holidays } = data

    const closedDays = []
    if (schedule) {

      schedule.map((dayObj) => {
        if (dayObj?.is_closed) {
          closedDays.push(dayObj.day)
        }
      })
    }




    if (closedDays.includes(`${moment(date).locale('en-gb').format('dddd')}`)) {

      return true
    } else {

      const outletValues = outlet_holidays.map((outletObj) => {

        if (moment(date).isSameOrAfter(moment(outletObj?.start_date, 'YYYY-MM-DD'), 'date') && moment(date).isSameOrBefore(moment(outletObj?.end_date, 'YYYY-MM-DD'), 'date')) {
          return true
        }
      })

      if (outletValues.includes(true)) {
        // return true
        return true
      }
    }

    // return false
    return false


  }



  const handleReschedule = (event) => {

    let allBookings = [event?.booking, ...event?.group_bookings]
    let duration = [];
    for (let minute = 5; minute <= 1440; minute += 5) {
      let label;
      if (minute < 60) {
        label = `${minute} min`;
      } else if (minute % 60) {
        label = `${Math.floor(minute / 60)} hr ${minute % 60} min`;
      } else if (minute % 60 === 0) {
        const hours = Math.floor(minute / 60);
        label = `${hours} hr`;
      }

      duration.push({
        value: minute,
        label,
        minutes: minute,
      });
    }

    let formatBooking = allBookings && allBookings.map((item) => ({
      booking_id: item?.id,
      selectedService: { id: item?.service_id, name: item?.booking_details?.service_name },
      selectedEmployee: { id: item?.employee_id, name: item?.booking_details?.employee_name, label: item?.booking_details?.employee_name, value: item?.employee_id },
      available_time: `${moment(item?.booking_datetime_start).format('HH:mm')} - ${moment(item?.booking_datetime_start).add(30, 'minutes').format('HH:mm')}`,
      duration_min: item?.booking_details?.duration_min,
      price: item?.booking_details?.price,
      durationList: duration
    }))

    let formattedEvent = {
      booking_id: event?.booking?.id,
      selectedCustomer: { id: event?.booking?.outlet_customer?.id, label: `${event?.booking?.outlet_customer?.name} (${formatPhoneNumber(event?.booking?.outlet_customer?.phone_number)})`, name: event?.booking?.outlet_customer?.name, phone_number: event?.booking?.outlet_customer?.phone_number },
      date: moment(event?.booking?.booking_datetime_start),
      note: event?.booking?.booking_details?.note,
      walk_in: event?.booking?.booking_details?.walk_in,
      send_email: event?.booking?.send_email,
      send_sms: event?.booking?.send_sms,
    }


    let prop = { booking: formatBooking, ...formattedEvent }
    setRescheduleProps(prop)
    setShowForm({ detail: false, booking: true });
  }


  const validatePause = (prop) => {
    let valid = events && !events.some((event) => {
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
      const propStart = moment(prop.datetime_start, 'YYYY-MM-DD HH:mm:ss');
      const propEnd = moment(prop.datetime_end, 'YYYY-MM-DD HH:mm:ss');

      if (event?.id === prop?.pauseProps) {
        return false; // Do not consider the event with the same id as a conflict
      } else {
        return (
          propStart.isBefore(eventEnd) &&
          propEnd.isAfter(eventStart) &&
          prop.employee_ids.includes(event.employeeId) &&
          !event?.id === tempId
        ); // Overlap detected
      }
    });

    return valid;
  };





  const handleSelectSlot =
    ({ start, end, resourceId }) => {

      const startDate = moment(start)
      const endDate = moment(end)

      setCreateEventObj({
        date: startDate.format('YYYY-MM-DD'),
        serviceId: null,
        employeeId: resourceId ?? employeeId,
        employeeName: '',
        timeSlot: `${startDate.format("HH:MM")} - ${endDate.format('HH:MM')}`,
        start_time: startDate.format('YYYY-MM-DD HH:mm:ss'),
        end_time: endDate.format('YYYY-MM-DD HH:mm:ss'),
        price: null

      })
      let id = moment().format('x')
      setTempId(id)
      let obj = {
        id: id,
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        status: "BOOKED",
        resourceId: resourceId,
        employeeId: resourceId ?? employeeId,
        type: "BOOKING"
      }

      setEvents((prev) => [...prev, obj])

      setShowForm((prev) => ({ ...prev, booking: true }))


      // const title = window.prompt('New Event Name')
      // if (title) {
      //   setEvents((prev) => [...prev, { start, end, title }])
      // }
    }


  const CustomEvent = ({ event }) => {
    const eventDetails = event?.event;

    if (!eventDetails) {
      return null;
    }

    let duration = moment(eventDetails.end).diff(moment(eventDetails.start), 'minutes');

    return (
      <Stack title="" className="time-slot-wrapper" >
        {(duration > 15 && eventDetails?.type !== 'CALENDAR_PAUSE') && <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} > <strong>{eventDetails.customerName}</strong>{(eventDetails?.type !== 'CALENDAR_PAUSE' && eventDetails?.outlet_customer_note) && <Tooltip title={eventDetails?.outlet_customer_note} ><img src={ImportantNoteIcon} alt="note" /></Tooltip>}</Typography>}
        {duration > 30 && <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} > {eventDetails.title} </Typography>}
        {duration > 50 && <Typography > {eventDetails?.customerPhone} </Typography>}
      </Stack>
    );
  };




  return (
    <div className="calendar-wrapper" style={{ backgroundColor: "#f0f8ff" }}>
      <ToastContainer />
      <DnDCalendar
        culture={selectedLanguage ?? 'da'}
        localizer={localizer}
        formats={formats}
        events={events}
        view={showAllEmployeesCalendar ? 'day' : view}
        date={selectedDate}
        onNavigate={(date) => setSelectedDate(date)}
        onView={(newView) => setView(newView)}
        onSelectEvent={handleEventClick}
        defaultDate={dayjs()}
        scrollToTime={scrollToTime}
        style={{ height: "100vh", backgroundColor: "#FFFFFF" }}
        step={calendar?.calendarInterval ?? 15} //5
        timeslots={parseInt(60 / calendar?.calendarInterval) ?? 4} //12
        min={calendartOpeningHour ? (calendartOpeningHour?.momentStartTime.isBefore(moment().startOf('day'), 'minutes') ? moment().startOf('day') : calendartOpeningHour?.momentStartTime) : null}
        max={calendartOpeningHour ? (calendartOpeningHour?.momentEndTime.isAfter(moment().endOf('day'), 'minutes') ? moment().endOf('day') : calendartOpeningHour?.momentEndTime) : null}
        // step={5} //5
        // timeslots={12} //12
        // dayPropGetter={date => (moment(date).day() === 2) && ({ className: 'rbc-selected-day' })}
        dayPropGetter={date => (calendar?.grayOutClosedHours && calendartSettings(date)) && { className: 'rbc-selected-day' }}
        onEventDrop={(e) => {
          if (e?.resourceId !== e?.event?.resourceId && e?.resourceId !== null && e?.event?.type == "CALENDAR_PAUSE") {
            toast.error(t("Calendar.InvalidAction"))
          } else if (e?.event?.type == "CALENDAR_PAUSE") {
            handleEventUpdate(e, false);
          } else {
            setScheduleChange({ data: e, isResizing: false });
            setShowForm((prev) => ({ ...prev, reschedule: true }));
          }
        }}

        // slotPropGetter={(date, resourceId) => {

        //   if(!isSlotAvailable(date, resourceId)){
        //     return {
        //       className: "rbc-selected-day",
        //     };
        //   }
        //   // else{
        //   //   return {
        //   //     className:'rbc-unselected-day'
        //   //   }
        //   // }

        //   // const day = moment(date).day();
        //   // if (day === 0 || day === 4) {

        //   //   return {
        //   //     className: "rbc-selected-day",
        //   //   };
        //   // }
        // }}
        onEventResize={(e) => {

          if (e?.event?.type == "CALENDAR_PAUSE") {
            handleEventUpdate(e, true)
          } else {
            setScheduleChange({ data: e, isResizing: true });
            setShowForm((prev) => ({ ...prev, reschedule: true }));
          }
          // resizeEvent(e)
        }}
        dayLayoutAlgorithm={'no-overlap'}

        eventPropGetter={(event) => {

          let backgroundColor = "#3174ad";
          let fontSize = "1rem";

          if (event.type != 'BOOKING') {
            backgroundColor = "#C74141";
          } else {
            if (
              event.status === "BOOKED" ||
              event.status === "Awaiting new customer"
            ) {
              backgroundColor = "#A79C92";
            } else if (
              event.status === "CANCELLED" ||
              event.status === "Cancelled by customer"
            ) {
              backgroundColor = "#C74141";
            } else if (event.status === "COMPLETED") {
              backgroundColor = "#367B3D";
            } else if (
              event.status === "OFFERED" ||
              event.status === "Awaiting new customer"
            ) {
              backgroundColor = "#E19957";

            } else if (
              event.status === "Cancellation offer accepted" ||
              event.status === "OFFER_ACCEPTED"
            ) {
              backgroundColor = "#447BCD";
            } else if (
              event.status === "Absence from booking NOSHOW " ||
              event.status === "NOSHOW"
            ) {
              backgroundColor = "#E19957";
            } else if (event.status === "RESCHEDULED") {
              backgroundColor = "#A79C92";
            }
          }

          const durationInMinutes =
            (dayjs(event.end) - dayjs(event.start)) / (1000 * 60);

          let flexDirection = 'column';
          let justifyContent = 'start';
          let alignContent = 'center';
          let textAlign = "left";

          if (durationInMinutes <= 5) {
            fontSize = "0.7rem";
            flexDirection = 'row';
            justifyContent = 'space-between';
            alignContent = 'center';
            // textAlign = "right";
          } else if (durationInMinutes >= 6 && durationInMinutes <= 10) {
            fontSize = "0.7rem";
            flexDirection = 'row';
            alignContent = 'center';
            // textAlign = "right";
          }
          else if (durationInMinutes >= 11 && durationInMinutes <= 15) {
            fontSize = "0.8rem";
            flexDirection = 'row';
            alignContent = 'center';
            // textAlign = "right";
          }
          else if (durationInMinutes >= 16 && durationInMinutes <= 20) {
            fontSize = "0.8rem";
            flexDirection = 'column';
          }
          else if (durationInMinutes >= 21 && durationInMinutes <= 40) {
            fontSize = "1rem";
            flexDirection = 'column';
          }
          else if (durationInMinutes >= 41 && durationInMinutes <= 60) {
            fontSize = "1.1rem";
            flexDirection = 'column';
          }
          else if (durationInMinutes >= 60 && durationInMinutes <= 120) {
            fontSize = "1.1rem";
            flexDirection = 'column';
          } else if (durationInMinutes > 120) {
            fontSize = "1.1rem";
            flexDirection = 'column';
          }



          return {
            style: {
              backgroundColor,
              borderRadius: "15px",
              color: "blue",
              fontSize,
              display: 'flex',
              flex: 1,
              paddingTop: 0.1 * durationInMinutes,
              flexDirection,
              justifyContent,
              alignContent,
              textAlign,
              marginTop: -2
            },
          };
        }}


        components={{
          timeGutterHeader: CustomTimeHeader,
          event: (event) => { return (<CustomEvent event={event} />) },

          toolbar: (props) => {
            updateTimeIndicator(view)

            return (
              <CustomToolBar
                {...props}
                openForm={openForm}
                view={view}
                setView={setView}
                employeeId={employeeId}
                setEmployeeId={handleEmployeeSelection}
                showAllEmployeesCalendar={showAllEmployeesCalendar}
                isToday={isToday}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                CustomTimeHeader={CustomTimeHeader}
                setCurrentWeek={setCurrentWeek}
                dayLayoutAlgorithm="no-overlap"
                employees={employees}
                selectedLanguage={selectedLanguage}

              />
            )
          },
          timeSlotWrapper: ({ children, value, resource }) => {
            return <div className="time-slot-wrapper" style={{ height: timeSlotHeight, backgroundColor: (calendar?.grayOutClosedHours && !isSlotAvailable(value, resource)) ? '#c4c4c4' : '#ffffff' }}>
              {children}
            </div>
          }
        }}

        resources={
          showAllEmployeesCalendar
            ? empavailability.map((employee) => ({
              id: employee.id,
              title: employee.name || t("Calendar.UnnamedEmployee"),
            }))
            : null
        }
        resourceIdAccessor="id"
        resourceTitleAccessor="title"
        messages={{
          next: "Neste",
          previous: "Forrige",
          today: "I dag",
          month: "Måned",
          week: "Uke",
          day: "Dag",
          agenda: "Agenda",
          date: "Dato",
          time: "Tid",
          event: "Hendelse",
          allDay: "Hele dagen",
        }}
        selectable
        onSelectSlot={handleSelectSlot}
      />

      <NewBookingDetailModal
        bookingId={selectedBooking}
        open={showForm?.detail}
        handleReschedule={handleReschedule}
        closeForm={() => {
          setShowForm((prev) => ({ ...prev, detail: false }))
          setSelectedBooking(null)
        }}
      />

      {showForm?.reschedule &&
        <Reschedule open={showForm?.reschedule} onClose={closeReschedule} handleEventUpdate={handleEventUpdate} scheduleChange={scheduleChange} />
      }

      {showForm.booking &&
        <NewBookingForm closeForm={closeForm} open={showForm.booking} validatePause={validatePause} rescheduleProps={rescheduleProps} initialData={createEventObj} refreshBookings={() => refreshBookings()} pauseProps={pauseProps} setEvents={setEvents} tempId={tempId} />
      }
    </div>
  );
};



export default CustomCalendar;