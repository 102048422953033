import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import FButton from "../../components/commonComponents/F_Button";
import { FDialogTitle } from "../../components/commonComponents/F_DialogTItle";
import { t } from "i18next";

export default function ConfirmationBookingDialog({
  handleBookingConfirmation,
  servicePrice,
  selctedServiceName,
  selectedTimeSlots,
  selectedDate,
  selectedEmployee,
  showConfirmationModal,
  setShowConfirmationModal,
  setShowTimeSlotModal,
  closeForm,
}) {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showConfirmationModal}
        PaperProps={{
          style: {
            width: "40%",
            maxHeight: "63vh",
            overflowY: "auto",
            margin: "auto",
            borderRadius: 10,
          },
        }}
        onClose={() => setShowConfirmationModal(true)}
      >
        <Box sx={{ width: "100%" }}>
          <FDialogTitle
            height={3}
            onClose={closeForm}
            onBack={() => {
              setShowConfirmationModal(false);
              setShowTimeSlotModal(true);
            }}
          >
          {t("Calendar.ManualBooking")}
          </FDialogTitle>

          <DialogContent
            sx={{
              padding: 5,

              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack sx={{ width: "100%", paddingLeft: 2, marginTop: 2 }}>
              {" "}
              <Typography
                variant="h5"
                sx={{
                  color: "#545454",
                  fontWeight: "bold",
                  pb: "15px",
                  display: "flex",

                  justifyContent: "flex-start", // or simply remove this line
                }}
              >
              {t("Calendar.Detail")}
              </Typography>
            </Stack>

            <Stack
              sx={{
                width: "100%",
                height: 140,
                backgroundColor: "#F6F6F6",
                padding: 1,
                borderRadius: 5,
                boxShadow:
                  "4px 4px 4px rgba(0, 0, 0, 0.1) , -4px -4px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                height={"auto"}
                sx={{
                  width: "100%",
                  padding: "8px 10px",
                  borderBottom: "1px solid #BBB0A4",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: "#6F6F6F",
                    fontStyle: "DM Sans",
                  }}
                >
                  {t("Common.CapsEmployee")}:
                </Typography>{" "}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    fontStyle: "DM Sans",
                    color: "#6F6F6F",
                  }}
                >
                  {selectedEmployee.name}
                </Typography>
              </Typography>
              <Typography
                height={"auto"}
                sx={{
                  padding: "8px 10px",
                  borderBottom: "1px solid #BBB0A4",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: "#6F6F6F",
                    fontStyle: "DM Sans",
                  }}
                >
                  {t("Common.Date")}:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    fontStyle: "DM Sans",
                    color: "#6F6F6F",
                  }}
                >
                  {selectedDate.format("DD/MM-YYYY")}
                </Typography>
              </Typography>
              <Typography
                height={"auto"}
                sx={{
                  padding: "8px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",

                  margin: 0,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: "#6F6F6F",
                    fontStyle: "DM Sans",
                  }}
                >
                 {t("Calendar.TimeSlot")}:
                </Typography>{" "}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    fontStyle: "DM Sans",
                    color: "#6F6F6F",
                  }}
                >
                  {selectedTimeSlots}
                </Typography>
              </Typography>
            </Stack>

            <Stack
              sx={{
                marginTop: 3,
                width: "100%",
                height: 100,
                backgroundColor: "#F6F6F6",
                padding: 1,
                borderRadius: 5,
                boxShadow:
                  "4px 4px 4px rgba(0, 0, 0, 0.1) , -4px -4px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                height={"auto"}
                sx={{
                  width: "100%",
                  padding: "8px 10px",
                  borderBottom: "1px solid #BBB0A4",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: "#6F6F6F",
                    fontStyle: "DM Sans",
                  }}
                >
                  {selctedServiceName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    fontStyle: "DM Sans",
                    color: "#6F6F6F",
                  }}
                >
                  {servicePrice} kr.
                </Typography>
              </Typography>
              <Typography
                height={"auto"}
                sx={{
                  padding: "8px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",

                  margin: 0,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: "#6F6F6F",
                    fontStyle: "DM Sans",
                  }}
                >
                  {t("Calendar.TotalAmount")}:
                </Typography>{" "}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    fontStyle: "DM Sans",
                    color: "#6F6F6F",
                  }}
                >
                  {servicePrice} kr.
                </Typography>
              </Typography>
            </Stack>

            <FButton
              variant={"save"}
              sx={{
                backgroundColor: "#BBB0A4",
               marginTop:4.2,
               marginBottom:"auto"  
              }}
              onClick={handleBookingConfirmation}
              fullWidth
              title={t("Calendar.ConfirmBooking")}
            />
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}
