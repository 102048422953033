import {  Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import apiFetcher from "../../../../utils/interCeptor";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import arrow from "../../../../assets/downarchivearrow.png";
import { useParams } from "react-router-dom";
import _ from "lodash";
import FormikFooter from "./FormikFooter";
import { FooterModalsContainer } from "./FooterModalsContainer";
import FormikForm from "./FormikForm";
import { t } from "i18next";

const CommonFormikModal = ({
  item,
  template,
  changeSequence,
  setData,
  dataa,
  setLogsProvoke,
  setIsDirty,
}) => {
  const user = useSelector((state) => state.user.data);

  const [fileName, setFileName] = useState([]);
  const [useTemplate, setUSeTemplate] = useState([]);
  const [ispending, setIspending] = useState(false);
  const [isDelete, setISDelete] = useState(false);
  const [ids, SetID] = useState(null);
  const [journals, setJournals] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(null);
  const [JournalDeleteId, setJournalDeleteId] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [selectedTemplatename, setSelectedTemplateName] = useState("");
  const [initialValues, setInitialValues] = useState({
    type: "",
    writer: "",
    title: "",
    template_id: null,
    journal_entry: "<p><br></p>",
    images: [],
    group_id: null,
  });
  const [img, setimages] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteidx, setDeleteidx] = useState([]);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [storeImages, setStoreImages] = useState([]);

  const outlet_customer_id = id;

  useEffect(() => {
    setJournals(item.journals);

    const val = item.journals?.find((item) => item.post === false);

    if (val) {
      if (val?.attachments.length > 0)
        setFileName(
          val?.attachments.map((item) =>
            item.attachment_name ? item.attachment_name : item.name
          )
        );

      const valuesData = {
        writer: val?.employee_name ? val?.employee_name : "",
        title: val?.title ? val?.title : "",
        template_id: val?.template_id ? val?.template_id : null,
        journal_entry: val?.journal_entry ? val?.journal_entry : "",
        images: val?.attachments ? val?.attachments : [],
      };
      setInitialValues(valuesData);
    }
  }, [dataa]);

  useEffect(() => {
    const val = journals?.find((item) => item.post === false);
    if (val) {
      SetID(val.id);
    } else SetID(null);
  }, [journals, dataa]);

  const ValidationSchema = Yup.object({
    title: Yup.string().trim(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (v) => {
      setIspending(true);

      v.type === "post" ? handlePost() : handleSave();
    },
  });


  function handleChange() {
    const validation =
      _.isEqual(formik.values, initialValues) &&
      _.isEqual(String(formik.values.images), String(initialValues.images));

    if (validation)
      setIsDirty((prev) => prev?.filter((ite) => ite !== item.group_id));
    else {
      setIsDirty((prev) => {
        return prev?.find((ite) => ite === item.group_id)
          ? prev
          : [...prev, item.group_id];
      });
    }
  }

  const handlePost = async () => {
    if (!formik.isValid) return;
    setShowSaveModal(false);
    setIsDirty((prev) => prev?.filter((ite) => ite !== item.group_id));

    const formdata = new FormData();
    const employee_id = user?.id;
    const cond = journals.find((j) => j.id === ids);
    const search = cond ? true : false;

    const journal_datetime = new Date();
    const requestBody = {
      template_id: formik.values.template_id ? formik.values.template_id : null,
      title: formik.values.title,
      journal_entry: formik.values.journal_entry,
      employee_id,
      outlet_customer_id,
      post: true,
      group_id: formik.values.group_id,
      journal_datetime,
    };

    formdata.append("req_body", JSON.stringify(requestBody));
    if (JSON.stringify(formik.values.images) !== JSON.stringify(img)) {
      // Find new images that are in formik.values.images but NOT in img
      const newImages = formik.values.images.filter(
        (image) =>
          !img.some(
            (existingImage) =>
              JSON.stringify(existingImage) === JSON.stringify(image)
          )
      );

      newImages.forEach((image) => {
        if (image instanceof File) {
          formdata.append("attachments", image);
        }
      });
    }

    const jsonData = Object.fromEntries(formdata);

    if (ids) {
      try {
        const response = await apiFetcher.patch(
          `api/v1/store/journal/${ids}`,
          formdata
        );
        if (response.data.success) {
          formik.handleReset();
          toast.success(t("Customer.JournalResponsePostSuccess"));

          setLogsProvoke((prev) => prev + 1);
          setSelectedTemplateName("");

          if (search) {
            // if journal already exists then it will  update the dataa

            setData((prevGroups) => {
              return prevGroups?.map((group) => {
                if (group.group_id === item.group_id) {
                  return {
                    ...group,
                    journals: group.journals?.map((journal) =>
                      journal.id ===
                      Number(response.data.data?.logs[0]?.journal_id)
                        ? {
                            ...journal,
                            ...JSON.parse(jsonData.req_body), // Updating with new data
                            attachments: [...response.data.data.attachments],
                            created_at: response.data.data.created_at,
                          }
                        : journal
                    ),
                  };
                }
                return group;
              });
            });
          } else {
            // if it is a new journal
            setJournals((prevJournals) => {
              return prevJournals?.map((journal) =>
                journal.id === Number(response.data.data?.logs[0]?.journal_id)
                  ? {
                      ...journal,
                      ...JSON.parse(jsonData.req_body), // Updating with new data
                      attachments: [...response.data.data.attachments],
                      created_at: response.data.data.created_at,
                      employee_name: response.data.data.employee_name,
                    }
                  : journal
              );
            });
          }

          setimages([]);
          setInitialValues({
            type: "",
            writer: "",
            title: "",
            template_id: null,
            journal_entry: "<p><br></p>",
            images: [],
            group_id: null,
          });
          formik.resetForm();
          setFileName([]);

          setIspending(false);
        }
      } catch (error) {
        toast.error(error);
        setIspending(false);
      }
    } else {
      try {
        const response = await apiFetcher.post(
          `api/v1/store/journal`,
          formdata
        );

        if (response.data.success) {
          setLogsProvoke((prev) => prev + 1);

          setJournals((prev) =>
            prev?.filter((i) => i.id !== response.data.data.id)
          );

          setData((prevGroups) => {
            return prevGroups?.map((group) => {
              if (group.group_id === item.group_id) {
                return {
                  ...group,
                  journals: [
                    {
                      created_at: response.data.data.created_at,
                      ...JSON.parse(jsonData.req_body),
                      id: Number(response.data.data.logs[0].journal_id),
                      attachments: [...response.data.data.attachments],
                      employee_name: response.data.data.employee_name,
                    },
                    ...group.journals,
                  ],
                };
              }

              return group;
            });
          });

          setInitialValues({
            type: "",
            writer: "",
            title: "",
            template_id: null,
            journal_entry: "<p><br></p>",
            images: [],
            group_id: null,
          });
          formik.resetForm();

          setSelectedTemplateName("");
          setimages([]);
          setFileName([]);
          setIspending(false);
          toast.success(t("Customer.JournalResponsePostSuccess"));
        }
      } catch (error) {
        toast.error(error);
        console.error("error", error);
        setIspending(false);
      }
    }
  };

  useEffect(() => {
    handleChange();
  }, [dataa, formik.values]);

  
  async function handleSave() {
    if (!formik.isValid) return;

    setShowSaveModal(false);

    setIsDirty((prev) => prev?.filter((ite) => ite !== item.group_id));
    const formdata = new FormData();
    const employee_id = user?.id;
    const outlet_customer_id = id;
    const journal_datetime = new Date();
    const requestBody = {
      remove_attachments: deleteidx,
      template_id: formik.values.template_id ? formik.values.template_id : null,
      title: formik.values.title,
      journal_entry: formik.values.journal_entry,
      employee_id,
      outlet_customer_id,
      post: false,
      group_id: formik.values.group_id,
      journal_datetime,
    };

    formdata.append("req_body", JSON.stringify(requestBody));

    const cond = journals.find((j) => j.id === ids);
    const search = cond ? true : false;

    if (JSON.stringify(formik.values.images) !== JSON.stringify(img)) {
      const newImages = formik.values.images.filter(
        (image) => !img.some((existingImage) => _.isEqual(existingImage, image))
      );

      newImages.forEach((image) => {
        if (image instanceof File) {
          formdata.append("attachments", image);
        }
      });
    }

    if (ids) {
      try {
        const response = await apiFetcher.patch(
          `api/v1/store/journal/${ids}`,
          formdata
        );

        if (response.data.success) {
          // setStoreImages(response.data.data.attachments)
          const jsonData = Object.fromEntries(formdata);
          setLogsProvoke((prev) => prev + 1);

          if (search) {
            setData((prevGroups) => {
              return prevGroups?.map((group) => {
                // Check if the current group matches the target group_id
                if (group.group_id === item.group_id) {
                  return {
                    ...group,
                    journals: group.journals?.map((journal) =>
                      journal.id ===
                      Number(response.data.data.logs[0]?.journal_id)
                        ? {
                            ...journal,
                            ...JSON.parse(jsonData.req_body), // Updating with new data
                            attachments: [...response.data.data.attachments],
                            created_at: response.data.data.created_at,
                          }
                        : journal
                    ),
                  };
                }
                return group;
              });
            });
          } else {
            setData((prevGroups) => {
              return prevGroups?.map((group) => {
                if (group.group_id === item.group_id) {
                  return {
                    ...group,
                    journals: [
                      {
                        ...JSON.parse(jsonData.req_body),
                        id: ids,
                        attachments: [...response.data.data.attachments],
                        created_at: response.data.data.created_at,
                      },
                      ...group.journals,
                    ],
                  };
                }

                return group;
              });
            });
          }

          setimages([...response.data.data.attachments]);

          setIspending(false);
          toast.success(t("Customer.JournalResponseSaveSuccess"));
        }
      } catch (error) {
        toast.error(error);
        console.error("error", error);

        setIspending(false);
      }
    } else {
      try {
        const response = await apiFetcher.post(
          `api/v1/store/journal`,
          formdata
        );

        if (response.data.success) {
          setLogsProvoke((prev) => prev + 1);

          const jsonData = Object.fromEntries(formdata);

          setData((prevGroups) => {
            return prevGroups?.map((group) => {
              if (group.group_id === item.group_id) {
                return {
                  ...group,
                  journals: [
                    {
                      ...JSON.parse(jsonData.req_body),
                      id: response.data.data.logs[0].journal_id,
                      created_at: response.data.data.created_at,

                      attachments: [...response.data.data.attachments],
                    },
                    ...group.journals,
                  ],
                };
              }

              return group;
            });
          });
          setimages([...formik.values.images]);

          setIspending(false);

          toast.success(t("Customer.JournalResponseSaveSuccess"));
        }
      } catch (error) {
        toast.error(error);
        console.error("error", error);
        setIspending(false);
      }
    }
  }

 
  useEffect(() => {
    if (template && item.group_id) {
      template?.map((itemm) => {
        const hasGroup26 = itemm.templates?.some((templatee) => {
          const f = templatee.groups.includes(item?.group_id);
          if (f) {
            setUSeTemplate((prev) => {
              if (!prev?.some((item) => item?.id === templatee?.id)) {
                return [...prev, templatee];
              }
              return prev;
            });
          }
        });
        return hasGroup26;
      });
    }
  }, [template, item.group_id]);


  return (
    <>
 
      <Stack mb={5} key={item?.group_id}>

       <FormikForm
          formik={formik}
          item={item}
          changeSequence={changeSequence}
          arrow={arrow}
          user={user}
          useTemplate={useTemplate}
          setSelectedTemplateName={setSelectedTemplateName}
          setOpen={setOpen}
          selectedTemplatename={selectedTemplatename}
          fileName={fileName}
          setFileName={setFileName}
          setDeleteidx={setDeleteidx}
          ispending={ispending}
          setShowSaveModal={setShowSaveModal}/>

        <FormikFooter
          journals={journals}
          setShowDeleteModal={setShowDeleteModal}
          setJournalDeleteId={setJournalDeleteId}
          setStoreImages={setStoreImages}
          setIsOpen={setIsOpen}
          setPhotoIndex={setPhotoIndex}
          isDelete={isDelete}
          setUSeTemplate={setUSeTemplate}
          item={item}
          setOpen={setOpen}
          formik={formik}
          open={open}
        />
      </Stack>

      <FooterModalsContainer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        photoIndex={photoIndex}
        storeImages={storeImages}
        showSaveModal={showSaveModal}
        setShowSaveModal={setShowSaveModal}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        JournalDeleteId={JournalDeleteId}
        setISDelete={setISDelete}
        formik={formik}
        item={item}
        journals={journals}
        setData={setData}
      />
    </>
  );
};

export default CommonFormikModal;
