import { Delete } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import React from 'react'
import AddJournalGroupTemplate from './AddJournalGroupTemplate';
import { useSelector } from 'react-redux';

const FormikFooter = ({ journals, setStoreImages, setIsOpen, setPhotoIndex, setShowDeleteModal, setJournalDeleteId, isDelete, setUSeTemplate, item, setOpen, formik, open }) => {

  const settings = useSelector((state) => state.settings.data);

  return (
    <>
      {journals &&
        journals.map(
          (ar) =>
            ar.post && (
              <Stack
                key={ar.id}
                sx={{
                  border: "1.5px solid  #D9D9D9",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  pb: 3,

                  mt: 4,
                  borderRadius: 2,
                }}
              >
                {settings?.from_dashboard &&
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    loading={isDelete}
                    onClick={() => {
                      setShowDeleteModal(true);
                      setJournalDeleteId(ar.id);
                    }}
                    color="red"
                    sx={{ position: "absolute", top: 0, right: 0 }}
                  >
                    <Delete color="red" />
                  </IconButton>
                }
                <Stack
                  sx={{
                    width: "20%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Stack ml={2}>
                    <Typography
                      variant="body1"
                      fontWeight={800}
                      color="text.secondary"
                    >
                      {t("Customer.WrittenBy")}
                    </Typography>
                    <Typography color="#545454">
                      {ar.employee_name}
                    </Typography>
                  </Stack>

                  <Stack ml={2}>
                    <Typography
                      variant="body1"
                      fontWeight={800}
                      color="text.secondary"
                    >
                      {t("Common.Date")}
                    </Typography>
                    <Typography color="#545454">
                      {moment.parseZone(ar.created_at).format("DD-MM-YYYY")}
                    </Typography>
                  </Stack>

                  <Stack ml={2}>
                    <Typography
                      variant="body1"
                      fontWeight={800}
                      color="text.secondary"
                    >
                      {t("Common.Time")}
                    </Typography>
                    <Typography color="#545454">
                      {moment.parseZone(ar.created_at).format(
                        "HH:mm"
                      )}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  sx={{
                    width: "80%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {ar.attachments.length > 0 && (
                    <Stack
                      sx={{
                        maxHeight: "10%",
                        width: "96%",
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        mt: 4,

                        overflowX: "scroll",
                        scrollbarWidth: "none",
                      }}
                    >
                      {ar.attachments.map((item, index) => (
                        <>
                          <Stack
                            onClick={() => {
                              setPhotoIndex(index);
                              setIsOpen(true);
                              let imgArray = [];
                              ar.attachments.map((attachment) =>
                                imgArray.push({
                                  src: `${process.env.REACT_APP_IMG_URL}${attachment.attachment}`,
                                })
                              );

                              setStoreImages(imgArray);
                            }}
                            key={item.id}
                            component="img"
                            src={
                              item.attachment &&
                                typeof item.attachment === "string"
                                ? `${process.env.REACT_APP_IMG_URL}${item.attachment}`
                                : URL.createObjectURL(item)
                            }
                            alt="Patient photo"
                            sx={{
                              maxWidth: 150,
                              maxHeight: 100,

                              objectFit: "cover",
                              ':hover': {
                                cursor: 'pointer'
                              }
                            }}
                          />
                        </>
                      ))}
                    </Stack>
                  )}

                  <Stack mt={2}>
                    <Typography
                      variant="body1"
                      fontWeight={800}
                      color="text.secondary"
                    >
                      {ar.title}
                    </Typography>

                    <div
                      style={{ marginTop: "8px" }}
                      dangerouslySetInnerHTML={{
                        __html: ar.journal_entry || "",
                      }}
                    ></div>
                  </Stack>
                </Stack>
              </Stack>
            )
        )}
      <AddJournalGroupTemplate
        open={open}
        onClose={() => setOpen(false)}
        val={formik.values.journal_entry}
        groupID={item.group_id}
        setUSeTemplate={setUSeTemplate}
      />
    </>
  )

}

export default FormikFooter